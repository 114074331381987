import React, { useRef } from 'react';
import {Box, Grid, Tooltip, Typography} from '@material-ui/core';
import {useStyles} from './styles';
import ProjectItem from '../ProjectItem/ProjectItem';
import { getAllProjectsRequest, getProjectOfUserRequest, deleteProject } from '../../../../ProjectsApiActions'
import { connect } from 'react-redux';
import MessageModal from '../../../../../../utilities/MessageModal/MessageModal'

const TeamItem = ({history, teamDetails,projectDelete,getAllProjects,getProjectsOfUser}) => {
    const classes = useStyles();
    const modalRef = useRef();

    const deleteProjectHandler = (projectId, e) => {
        const title = ``
        const message = `Deleting project will erase in all data related to this project, which cannot be recovered. Do you still want to continue?`
        modalRef.current.setState({
            showModal: true,
            message: message,
            title: title,
            showOkButton: true,
            showCancelButton: true,
            showNote: false,
            note: '',
            handleOk: (note) => {
                e.stopPropagation();
                projectDelete(projectId).then((res) => {
                    getAllProjects(false)
                    getProjectsOfUser(false)
                })
            },
            okText: "Yes",
            cancelText: 'No'
        });
    }

    return (
        <>
            {
                teamDetails.projects.length >= 1
                    ?
                    <Box className={classes.teamContainer}>
                        <Box className={classes.teamInfo}>
                            <Box className={classes.teamHeader}>
                                <Box className={classes.teamName}>
                                    <Typography variant="h2">{teamDetails.name}</Typography>
                                </Box>
                                <Box className={classes.teamLead}>
                                    <Tooltip title={teamDetails.headName}>
                                        <img
                                            src={`https://ui-avatars.com/api/?name=${teamDetails.headName.charAt(0)}&background=random`}
                                            alt='profile'
                                            className={classes.teamLeadImage}
                                        />
                                    </Tooltip>
                                    <Typography variant="h3">{teamDetails.headName}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.teamMembers}>
                                {
                                    teamDetails && teamDetails.members && teamDetails.members.map((member, index) => {
                                        return (
                                            <Tooltip key={index} title={member.firstName + " " + member.lastName}>
                                                <img
                                                    src={`https://ui-avatars.com/api/?name=${member.firstName}&background=random`}
                                                    alt='profile'
                                                    className={classes.teamMembersImage}
                                                />
                                            </Tooltip>
                                        )
                                    })
                                }
                            </Box>
                        </Box>

                        <Box className={classes.projectsContainer}>
                            <Box spacing={3} className={classes.projectsItemContainer}>
                                {
                                    teamDetails.projects && teamDetails.projects.length > 0 && teamDetails.projects.map((project, index) => (
                                        <Grid key={index}>
                                            <ProjectItem
                                                history={history}
                                                projectDetails={project}
                                                deleteProjectHandler={deleteProjectHandler}
                                            />
                                        </Grid>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Box>
                    :
                    <></>
            }
             <MessageModal
                ref={modalRef}
            />
        </>

    );
};

const mapStateToProps = (state) => {
    return {}
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllProjects: (isLoaderShow) => {
            return dispatch(getAllProjectsRequest(isLoaderShow)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getProjectsOfUser: (isLoaderShow) => {
            return dispatch(getProjectOfUserRequest(isLoaderShow)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        projectDelete: (projectId) => {
            return dispatch(deleteProject(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamItem);