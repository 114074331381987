import React, { useEffect, useRef, useState } from 'react'
import './TasklistForm.css';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import {
    addTagRequest,
    getAllTagsRequset
} from '../../../../../../../../../configuration/ConfigurationApiActions';
import {
    getAllMilestoneRequest,
    getAllParticipantsRequest,
    getAllProjectCheckListRequest,
    updateTaskListRequest,
    addTaskListRequest
} from '../../../TasksApiActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import SearchableDropDown from '../../../../../../../../../../utilities/SearchableDropDown/SearchableDropDown';
import CustomTheme from '../../../../../../../../../../utilities/theme/theme.json'
import MessageModal from '../../../../../../../../../../utilities/MessageModal/MessageModal'
import ChecklistModal from '../../../../../../../../../../utilities/CheckListModalProjectDefination/ChecklistModal';
import ChecklistDropdownSearchable
    from '../../../../../../../../../../utilities/SearchableDropDown/ChecklistDropdownSearchable/ChecklistDropdownSearchable';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';

const TasklistForm = ({
    getAllParticipants, getAllProjectCheckList,
    taskListId, projectId, getAllOnlyMilestones,
    loggedInUser, getAllTags, addTags, setIsDisplayForm, singleTasklistDetails, updateTaskList,
    closeAddUpdateFrom, showTaskDetailsPane, setAddUpdateRes, addTaskList
}) => {
    const modalRef = useRef();
    const [openAddCheckList, setOpenAddCheckList] = useState(false);
    // const [participents, setParticipents] = useState([]);
    const [showApprovalDropdown, setShowApprovalDropdown] = useState(false);
    const [allCheckList, setAllCheckList] = useState([]);
    const [showChecklistDropdown, setShowChecklistDropdown] = useState(false);
    const [allMilestones, setAllMilestones] = useState([]);
    const [showMilestoneDropdown, setShowMilestoneDropdown] = useState(false);
    const [showDueDate,setShowDueDate] = useState(false)
    const [isTitleEditable, setIsTitleEditable] = useState(false);
    const [isDescriptionEditable, setIsDescriptionEditable] = useState(false);
    const [isChipEditable, setIsChipEditable] = useState(false);
    const [allTags, setAllTags] = useState(null)
    const [tagName, setTagName] = useState(null);
    const [isChecklistNewCreating, setIsChecklistNewCreating] = useState(false);
    const [isChangeInUpdate, setIsChangeInUpdate] = useState(false)
    const [tasklistForm, setTasklistForm] = useState({
        approver: null,
        title: null,
        description: null,
        tags: [],
        milestoneId: null,
        checklistId: null,
        dueDate: null
    })
    // useEffect(() => {
    //     // getting all participents
    //     getAllParticipants(projectId).then((res) => {
    //         setParticipents(res);
    //     })

    // }, []);

    useEffect(() => {
        setOpenAddCheckList(false);
        setShowApprovalDropdown(false);
        setShowChecklistDropdown(false);
        setShowMilestoneDropdown(false);
        setIsTitleEditable(false);
        setIsDescriptionEditable(false);
        setIsChipEditable(false);

    }, [])

    useEffect(() => {
        if (showChecklistDropdown) {
            // get all checklist
            getAllProjectCheckList(projectId).then((res) => {
                let result = Object.entries(res).map(([k, v]) => (v));
                let createChecklistObj = { title: 'Create checklist', id: "1" }
                let noneListObj = { title: 'None', id: null }
                result.unshift(createChecklistObj, noneListObj)
                setAllCheckList(result)
            })
        }
    }, [showChecklistDropdown]);

    useEffect(() => {
        if (showMilestoneDropdown) {
            // get all milestone
            getAllOnlyMilestones(projectId).then((res) => {
                setAllMilestones(res)
            })
        }
    }, [showMilestoneDropdown]);

    useEffect(() => {
        if (taskListId) {
            titleHideShowHandler();
            descriptionHideShowHandler();
            let dataObjForStoringValues = {
                approver: null,
                title: null,
                description: null,
                tags: [],
                milestoneId: null,
                checklistId: null,
                dueDate: null
            }
            dataObjForStoringValues.title = singleTasklistDetails.title
            dataObjForStoringValues.description = singleTasklistDetails.description
            if (singleTasklistDetails.tags && singleTasklistDetails.tags.length > 0) {
                chipHideShowHandler();
                dataObjForStoringValues.tags = singleTasklistDetails.tags
            } else {
            }
            if (singleTasklistDetails.approver) {
                setShowApprovalDropdown(true);
                dataObjForStoringValues.approver = singleTasklistDetails.approver
            } else {
            }
            if (singleTasklistDetails.checklist) {
                setShowChecklistDropdown(true);
                getAllProjectCheckList(projectId).then((res) => {
                    res.filter((checklist) => {
                        if (checklist.id === singleTasklistDetails.checklist.id) {
                            if (checklist.checklist === null) {
                                dataObjForStoringValues.checklistId = null
                            } else if (checklist) {
                                dataObjForStoringValues.checklistId = checklist
                            }
                        }
                        return checklist
                    })
                })

            } else {
            }
            if (singleTasklistDetails.dueDate) {
                dataObjForStoringValues.dueDate = singleTasklistDetails.dueDate
            } else {}
            if (singleTasklistDetails.milestoneId) {
                setShowMilestoneDropdown(true);
                getAllOnlyMilestones(projectId).then((res) => {
                    res.map((milestone) => {
                        if (milestone.id === singleTasklistDetails.milestoneId) {
                            dataObjForStoringValues.milestoneId = milestone
                        }
                        return milestone
                    })
                })
            } else {
            }
            setTasklistForm(dataObjForStoringValues);
        } else {
            setTasklistForm({
                approver: null,
                title: null,
                description: null,
                tags: [],
                milestoneId: null,
                checklistId: null,
                dueDate: null
            })
            if (isTitleEditable) {
                titleHideShowHandler();
            } else {
            }
            if (isDescriptionEditable) {
                descriptionHideShowHandler();
            } else {
            }
            if (isChipEditable) {
                chipHideShowHandler();
            } else {
            }
            if (showApprovalDropdown) {
                setShowApprovalDropdown(false);
            } else {
            }
            if (showChecklistDropdown) {
                setShowChecklistDropdown(false);
            } else {
            }
            if (showMilestoneDropdown) {
                setShowMilestoneDropdown(false);
            } else {
            }
            if (showDueDate) {
                setShowDueDate(false);
            } else {
            }
        }
    }, [taskListId])

    useEffect(() => {
        if (tagName && tagName.length >= 3 && tagName.length < 4) {
            getAllTags(tagName, true).then((res) => {
                setAllTags(res)
            }).catch((err) => {
            })
        }
    }, [tagName])


    const handleChangeInput = (event, value) => {
        const data = Object.assign({}, tasklistForm);
        data[event.target.name] = event.target.value;
        if (!isChangeInUpdate) {
            setIsChangeInUpdate(true)
        } else {
        }
        setTasklistForm(data);
    };

    /**
     * Method executes on onChange action of autocomplete form element
     */
    const handleChangeAutocomplete = (elementName, selectedvalue) => {
        let data = Object.assign({}, tasklistForm);
        if (elementName === "approver") {
            data.approver = selectedvalue
            if (!isChangeInUpdate) {
                setIsChangeInUpdate(true)
            } else {
            }
        } else {
        }
        if (elementName === "milestoneId") {
            data.milestoneId = selectedvalue
            if (!isChangeInUpdate) {
                setIsChangeInUpdate(true)
            } else {
            }
        } else {
        }
        if (elementName === "checklistId") {
            data.checklistId = selectedvalue
            if (!isChangeInUpdate) {
                setIsChangeInUpdate(true)
            } else {
            }
        } else {
        }
        if (elementName === "tags") {
            let chips = [];
            selectedvalue.forEach((product) => {
                chips.push(product);
            })
            data.tags = chips
            if (!isChangeInUpdate) {
                setIsChangeInUpdate(true)
            } else {
            }
        } else {
        }
        if (elementName === "dueDate") {
            data.dueDate = moment(selectedvalue).format('YYYY-MM-DD')
            data.showDueDateField = false
            if (!isChangeInUpdate) {
                setIsChangeInUpdate(true)
            } else {
            }
        } else {
        }
        setTasklistForm(data);
    }

    const titleHideShowHandler = () => {
        setIsTitleEditable(!isTitleEditable)
    };

    const descriptionHideShowHandler = () => {
        setIsDescriptionEditable(!isDescriptionEditable)
    };

    const chipHideShowHandler = () => {
        setIsChipEditable(!isChipEditable);
    };

    // add tags
    const addChips = (tags) => {
        tags.map((singleTagName) => {
            let data = {
                name: singleTagName,
                color: singleTagName
            }
            addTags(data).then((res) => {
            })
            return singleTagName
        })

    }

    const clickHandler = () => {
        // addChips(tags)
        let filterArr = []
        getAllTags().then((res) => {
            let arr = tasklistForm.tags && tasklistForm.tags.filter((tag) => {
                return !(res.some((resTag) => resTag.name === tag))
            })
            filterArr.push(arr)
            addChips(filterArr[0])
        })
        let formData = {
            title: tasklistForm.title,
            tags: tasklistForm.tags
        }
        if (tasklistForm.description) {
            formData.description = tasklistForm.description;
        } else {
        }
        if (taskListId) {
            if (tasklistForm.checklistId) {
                if (tasklistForm.checklistId === "None") {
                    formData.checklistId = null
                } else {
                    formData.checklistId = tasklistForm.checklistId.id
                }
            } else {
            }
        } else {
            if (tasklistForm.checklistId) {
                formData.checklistId = tasklistForm.checklistId.id;
            } else {
            }
        }

        if (tasklistForm.approver) {
            formData.isApprovalNeeded = true;
            formData.approver = tasklistForm.approver.id;
        } else {
            formData.isApprovalNeeded = false;
        }
        if (tasklistForm.dueDate) {
            formData.dueDate = tasklistForm.dueDate;
        } else {
        }
        if (tasklistForm.milestoneId) {
            formData.milestoneId = tasklistForm.milestoneId.id;
        } else { }
        if (taskListId) {
            updateTaskList(taskListId, formData).then((res) => {
                // getAllTaskLists(projectId);
                setAddUpdateRes({
                    res: res.id,
                    formName: 'taskList'
                });
                closeAddUpdateFrom();
                showTaskDetailsPane(taskListId, "taskList");
            });
        }
        else {
            if (formData.milestoneId) {
            } else {
                formData.projectId = projectId
            }
            addTaskList(formData).then((res) => {
                // getAllTaskLists(projectId);
                setAddUpdateRes({
                    res: res.id,
                    formName: 'taskList'
                });
                closeAddUpdateFrom();
                showTaskDetailsPane(res.id, "taskList");
            });
        }
    };

    const handleEsc = (e) => {
        if (e.keyCode === 27) {
            //for tasklist
            if (taskListId) {
                if (!isChangeInUpdate) {
                    setIsDisplayForm(false)
                } else {
                    const title = `Close Without Saving?`
                    const message = `Are you sure you want to close this edit tasklist without saving?`
                    modalRef.current.setState({
                        showModal: true,
                        message: message,
                        title: title,
                        showOkButton: true,
                        showCancelButton: true,
                        showNote: false,
                        note: '',
                        handleOk: (note) => {
                            setIsDisplayForm(false)
                        },
                        okText: "Yes",
                        cancelText: 'No'
                    });

                }
            } else {
                if (isChangeInUpdate) {
                    const title = `Close Without Saving?`
                    const message = `Are you sure you want to close this tasklist without saving?`
                    modalRef.current.setState({
                        showModal: true,
                        message: message,
                        title: title,
                        showOkButton: true,
                        showCancelButton: true,
                        showNote: false,
                        note: '',
                        handleOk: (note) => {
                            setIsDisplayForm(false)
                        },
                        okText: "Yes",
                        cancelText: 'No'
                    });

                } else {
                    setIsDisplayForm(false)
                }
            }
        }
    }
    return (
        <>
            <ValidatorForm
                onSubmit={isChecklistNewCreating ? console.log() : clickHandler}
                noValidate
                onError={errors => console.log(errors)}
                onKeyDown={(e) => handleEsc(e)}
            >
                {
                    loggedInUser.userRoles.isTeamLead
                }
                <Grid style={{ borderBottom: `1px dashed ${CustomTheme.primaryColor}` }} item xl={12} lg={12} md={12}
                    sm={12} xs={12} className="project-save-btn-container">
                    <Grid container justify="space-between">
                        <Grid>
                        </Grid>
                        <Grid>
                            <IconButton style={{ color: CustomTheme.primaryColor }} className="form-panel-save-btn"
                                type="submit"><i className="far fa-save"></i></IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                {/* {
                    formName === "milestone" || formName === "taskList"
                        ? (
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Box className="details-header">
                                    <Box className="detail-header-content-for-users-icon margin-top-10">
                                        <Box
                                            className="inline-flex"
                                        >
                                        </Box>

                                        {
                                            showApprovalDropdown
                                                ?
                                                <Box
                                                    className="inline-flex for-width-120"
                                                >
                                                    <SearchableDropDown projectDefination={true} firstLastName={true} title={false} name={false} checklist={false} forNameAttr={"approver"} value={tasklistForm.approver} setValue={handleChangeAutocomplete} label="Select Approver" optionsList={participents} />
                                                </Box>
                                                : (
                                                    <Box
                                                        className="inline-flex"
                                                        onClick={() => setShowApprovalDropdown(true)}
                                                    >
                                                        <Box>
                                                            <img
                                                                src='https://ui-avatars.com/api/?name=A&background=random'
                                                                alt='profile'
                                                                className="task-assignee-image"
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <Typography style={{ color: CustomTheme.fontColor }} variant='body2'>Approver</Typography>
                                                            <Typography style={{ color: CustomTheme.primaryColor }} variant='body2' className='user-name'><i className="fas fa-plus-circle"></i>Add</Typography>
                                                        </Box>
                                                    </Box>
                                                )
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                        )
                        : <></>
                } */}

                <Grid style={{ backgroundColor: CustomTheme.bodyBackground }} item xl={12} lg={12} md={12} sm={12} xs={12}
                    className="form-panel-assign-name-container for-background-color for-height ">
                    <Grid container>
                        {
                            isTitleEditable
                                ?
                                <>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <TextValidator
                                            fullWidth
                                            // required
                                            // variant="outlined"
                                            autoFocus={true}
                                            size="small"
                                            label="Add tasklist title"
                                            onChange={handleChangeInput}
                                            name="title" value={tasklistForm.title || ''}
                                            validators={['required', 'trim', 'maxStringLength:50']}
                                            errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                        />
                                    </Grid>
                                </>
                                :
                                <Grid onClick={titleHideShowHandler}>
                                    <Typography variant="h2" style={{ color: CustomTheme.primaryColor }}
                                        className="add-title-text">
                                        <span><i className="far fa-edit"></i> Click to add  tasklist title</span>
                                    </Typography>
                                </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid style={{ backgroundColor: CustomTheme.bodyBackground }} item xl={12} lg={12} md={12} sm={12} xs={12}
                    className="padding-for-description-container for-background-color">
                    <Grid container>
                        {
                            isDescriptionEditable
                                ?
                                <>
                                    <Grid className="description-input-container" item xl={12} lg={12} md={12} sm={12}
                                        xs={12}>
                                        <TextValidator
                                            fullWidth
                                            // required
                                            // variant="outlined"
                                            autoFocus={true}
                                            size="small"
                                            label="Add tasklist description"
                                            onChange={handleChangeInput}
                                            name="description" value={tasklistForm.description || ''}
                                            validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:250']}
                                            errorMessages={['At least one alphanumeric characters', 'The max length of 250 charachers reached']}
                                        />
                                    </Grid>
                                </>
                                :
                                <Grid onClick={descriptionHideShowHandler}>
                                    <Typography variant="h3" style={{ color: CustomTheme.primaryColor }}
                                        className="add-description-text">
                                        <span><i className="far fa-edit"></i> Click to add a description for this tasklist</span>
                                    </Typography>
                                </Grid>
                        }
                    </Grid>
                </Grid>
                {
                    isChipEditable
                        ?
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <span className="tag-container-icon">
                                <i className='fas fa-tags'></i>
                            </span>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                name="tags"
                                value={tasklistForm.tags}
                                className="tags-contianer"
                                onChange={(event, newValue) => {
                                    // setTags(newValue);
                                    handleChangeAutocomplete("tags", newValue)
                                }}
                                onInputChange={(event, newValue) => {
                                    setTagName(newValue);
                                }}
                                clearOnEscape={true}
                                options={allTags ? Object.keys(allTags).map((option) => allTags[option].name) : []}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="filled" placeholder="Type tag and hit enter" />
                                )}
                            />
                        </Grid>
                        :
                        <Grid onClick={chipHideShowHandler} className="add-tag-text" item xl={12} lg={12} md={12}
                            sm={12} xs={12}>
                            <Grid container>
                                <Grid style={{ color: CustomTheme.fontColor }} className="tag-icon-container">
                                    <i className='fas fa-tags'></i>
                                </Grid>
                                <Grid style={{ color: CustomTheme.primaryColor }} className="add-tag-container-projects">
                                    <i className='fas fa-plus-circle'></i> Add a tag
                                </Grid>
                            </Grid>
                        </Grid>
                }
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                    className="form-panel-assign-name-container">
                    <Grid container>
                        {
                            showMilestoneDropdown
                                ?
                                <Grid className="for-paddong-right" item xl={4} lg={4} md={4} sm={12}
                                    xs={12}>
                                    <Box className="dropdown-margins">
                                        <SearchableDropDown title={true} name={false} checklist={false}
                                            value={tasklistForm.milestoneId}
                                            setValue={handleChangeAutocomplete}
                                            forNameAttr={"milestoneId"}
                                            label="Select milestone"
                                            optionsList={allMilestones} />
                                    </Box>
                                </Grid>
                                :
                                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}
                                    onClick={() => setShowMilestoneDropdown(true)}>
                                    <Typography variant="body2" style={{ color: CustomTheme.fontColor }}
                                        className="form-panel-followers-text"><i
                                            className="fas fa-flag"></i> Milestone</Typography>
                                    <Typography variant="body2" style={{ color: CustomTheme.primaryColor }}
                                        className="form-panel-bold-text"><i
                                            className="fas fa-link"></i> Link milestone</Typography>
                                </Grid>
                        }
                        {
                            showChecklistDropdown
                                ?
                                <Grid className="for-paddong-right" item xl={4} lg={4} md={4} sm={12}
                                    xs={12}>
                                    <Box className="dropdown-margins">
                                        <ChecklistDropdownSearchable project={true}
                                            setIsChecklistNewCreating={setIsChecklistNewCreating}
                                            setValue={handleChangeAutocomplete}
                                            selectedValue={tasklistForm.checklistId}
                                            forNameAttr={"checklistId"}
                                            setOpenAddCheckList={setOpenAddCheckList}
                                            setAllCheckList={setAllCheckList}
                                            optionsList={allCheckList} />
                                    </Box>
                                </Grid>
                                :
                                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}
                                    onClick={() => setShowChecklistDropdown(true)}>
                                    <Typography variant="body2" style={{ color: CustomTheme.fontColor }}
                                        className="form-panel-followers-text"><i
                                            className="far fa-list-alt"></i> Checklist</Typography>
                                    <Typography variant="body2" style={{ color: CustomTheme.primaryColor }}
                                        className="form-panel-bold-text"><i
                                            className="fas fa-link"></i> Add Checklist</Typography>
                                </Grid>
                        }
                        {
                            showDueDate
                                ?
                                <Grid className="for-paddong-right" item xl={4} lg={4} md={4} sm={12} xs={12}>
                                <FormControl className="due-date-margin">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            fullWidth
                                            label='Select Due Date'
                                            format='dd/MM/yyyy'
                                            name="dueDate"
                                            value={tasklistForm.dueDate}
                                            onChange={date => {
                                                handleChangeAutocomplete("dueDate", date)
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                                </Grid>
                                :
                                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}
                                    onClick={() => setShowDueDate(true)}>
                                   <Typography variant="body2" style={{ color: CustomTheme.fontColor }}
                                        className="form-panel-followers-text">
                                        <i className="far fa-calendar-alt"
                                            style={{ paddingRight: '3px' }}></i>
                                        Due Date
                                    </Typography>
                                    <Typography variant="body2" style={{ color: CustomTheme.primaryColor }}
                                        className="form-panel-bold-text">
                                        {
                                            tasklistForm.dueDate
                                                ? moment(tasklistForm.dueDate).format('DD/MM/YYYY')
                                                : (
                                                    <>
                                                        <i className="fas fa-plus-circle"></i> Set Date
                                                    </>
                                                )
                                        }
                                    </Typography>
                                </Grid>
                        }
                    </Grid>
                </Grid>

                {
                    openAddCheckList
                        ?
                        <ChecklistModal nameAtt={"checklistId"} setIsChecklistNewCreating={setIsChecklistNewCreating}
                            projectId={projectId} setAllCheckList={setAllCheckList}
                            setSelectedChecklist={handleChangeAutocomplete}
                            setOpenAddCheckList={setOpenAddCheckList} openAddCheckList={openAddCheckList} />
                        :
                        <></>
                }
            </ValidatorForm>
            <MessageModal
                ref={modalRef} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
        singleTasklistDetails: state.projects.singleTasklistDetails
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllParticipants: (projectId) => {
            return dispatch(getAllParticipantsRequest(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllProjectCheckList: (projectId) => {
            return dispatch(getAllProjectCheckListRequest(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllOnlyMilestones: (projectId) => {
            return dispatch(getAllMilestoneRequest(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllTags: (name, searchTag) => {
            return dispatch(getAllTagsRequset(name, searchTag)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addTags: (data) => {
            return dispatch(addTagRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        updateTaskList: (taskListId, data) => {
            return dispatch(updateTaskListRequest(taskListId, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addTaskList: (data) => {
            return dispatch(addTaskListRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(TasklistForm);
