import {makeStyles} from '@material-ui/core';
import CustomTheme from '../../../../utilities/theme/theme.json'

export const useStyles = makeStyles(theme => {
    return {
        paneContainer: {
            backgroundColor: '#fff',
            marginLeft: '0.5rem',
            borderRadius: '6px',
            boxShadow: '0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%);',
            position: 'sticky',
            top: '90px'
        },
        detailsPane: {
            // padding: '0 10px 0 10px',
            color: '#77797c',
            height: 'calc(100vh - 115px)',
            overflowY: 'auto',
            overflowX: 'hidden'
        },
        detailsActions: {
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            height: '42px',
            background: '#fff',
            padding: '5px 13px 5px 5px',
            borderBottom: `1px dashed ${CustomTheme.primaryColor}`,
            // textAlign: 'right',
        },
        detailsActionsForProjectMember: {
            height: '35px',
            background: '#fff',
            padding: '5px 13px 5px 5px',
            borderBottom: '1px dashed #02ABEE',
            // textAlign: 'right',
        },
        detailsFollowersContainer: {
            marginLeft: '8px',
        },
        Icon: {
            display: 'inline-block',
            fontSize: '20px',
            color: CustomTheme.primaryColor,
            padding: '2px 9px',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        detailsMainHeader: {
            padding: '0 10px 0 10px'
        },
        detailsHeader: {
            padding: '5px',
        },
        detailsHeaderContent: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '101%',
            margin: 'auto',
            alignItems: 'center',
            fontSize: '14px',
        },
        detailsHeaderContentForUsersIcon: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '95%',
            margin: 'auto',
            alignItems: 'center',
            fontSize: '11px',
        },
        taskAssigneeImage: {
            height: '32px',
            width: '32px',
            borderRadius: '50%',
            marginRight: '0.4rem',
        },
        name: {
            color: '#02abeeb3',
            fontWeight: '500',
        },
        taskUsersImage: {
            height: '32px',
            width: '32px',
            borderRadius: '50%',
            padding: '2px',
            marginLeft: '-10px',
            marginBottom: '4px',
            background: '#CCF1FF',
        },
        taskUsersCount: {
            height: '32px',
            width: '32px',
            borderRadius: '50%',
            padding: '2px',
            background: '#CCF1FF',
            display: 'inline-block',
            color: '#000',
            textAlign: 'center',
            margin: '0px 0 4px -10px',
            verticalAlign: 'middle',
            borderStyle: 'none',
            lineHeight: '32px',
        },
        taskAddUser: {
            // verticalAlign: 'middle',
            // borderStyle: 'none',

            color: '#02ABEE',
            border: '2px solid #02ABEE',
            fontSize: '14px',
            marginLeft: '5px',
            background: '#fff',
            height: '32px',
            width: '32px',
            borderRadius: '50%',
            textAlign: 'center',
            lineHeight: '32px',
            display: 'inline-block',
            fontWeight: 600,
        },
        teamLead: {
            display: 'flex',
            alignItems: 'center',
        },
        inlineFlex: {
            display: 'inline-flex',
        },
        taskStatus: {
            color: 'orange',
        },
        infoContainer: {
            backgroundColor: '#f2f6fa',
            marginLeft: '-10px',
            paddingLeft: '10px',
            marginRight: '-10px',
            paddingRight: '-10px',
        },
        detailsTitle: {
            padding: '10px 5px 2px 5px',
            color: CustomTheme.projectTaskTitle,
            display: 'block',
            // backgroundColor:'#ccf1fd66'
        },
        detailsBy: {
            padding: '0px 5px',
            // backgroundColor:'#ccf1fd66'
        },
        detailsDescription: {
            display: 'block',
            padding: '10px 5px 10px 5px',
            // backgroundColor:'#ccf1fd66'
        },
        chipInput: {
            backgroundColor: '#dbe8f6',
        },
        inputRoot: {
            borderRadius: '0px',
            paddingTop: '0px!important',
            height: '47px',
        },
        input: {
            marginTop: '3px!important',
            paddingTop: '3px!important',
            marginBottom: '4px!important',
            paddingBottom: '4px!important',
            paddingLeft: '0.4rem',
        },
        chip: {
            margin: '7px 7px',
            marginRight: '0px',
            border: '1px solid #fff',
            borderRadius: '3px',
            backgroundColor: '#5784BA',
            color: '#fff',
        },
        detailsContainer: {
            marginTop: '1rem',
            paddingLeft: '5px',
            borderBottom: '1px dashed #77797c',
            paddingBottom: '1rem',
        },
        info: {
            fontWeight: '600',
        },
        tagsContaianer: {
            position: 'relative',
            backgroundColor: '#dbe8f6',
            border: 'none',
            borderRadius: 'none',
            marginLeft: '-10px',
            marginRight: '-10px',
        },
        tagIcon: {
            display: 'block',
            position: 'absolute',
            left: '0px',
            margin: '0.5rem',
            zIndex: '1',
            marginLeft: '1.1rem',
            marginTop: '0.7rem',
        },
        chipInputContainer: {
            marginLeft: '45px',
        },
        TabListRoot: {
            justifyContent: 'space-evenly',
        },
        TabRoot: {
            fontWeight: '550',
            textTransform: 'capitalize',
            fontSize: '1rem',
            minWidth: '0px',
        },
        TabsIndicator: {
            backgroundColor: CustomTheme.projectTaskTitle,
            height: '3px',
        },
        selected: {
            color: CustomTheme.projectTaskTitle,
        },
        notSelected: {
            color: CustomTheme.linkColor,
        },
        TabWrapper: {
            alignItems: 'center',
        },
        tabPanelRoot: {
            padding: '0px!important',
            paddingTop: '0.5rem!important',
        },
        tabsOuterContainer: {
            width: '100%',
            margin: 'auto',
        },
        orange: {
            color: '#f5b01e',
        },
        grey: {
            color: '#d0d0d0',
        },
        detailsStatusContainer: {
            borderBottom: '1px dashed #77797c',
            padding: '10px 5px',
        },
        detailsStatusContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
        },
        detailsStatusItemContainer: {
            textAlign: 'center',
            height: '61px',
            padding: '5px',
            margin: '5px',
            borderRadius: '5px',
            minWidth: '130px',
        },
        statusHeading: {},
        statusText: {
            fontSize: '24px',
            fontWeight: '700',
        },
        statusGreen: {
            backgroundColor: '#D2FDBB',
        },
        statusOrange: {
            backgroundColor: '#FFDAC1',
        },
        userNameColor: {
            color: CustomTheme.primaryColor
        }
    };
});
