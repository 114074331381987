import {makeStyles} from '@material-ui/core';
import CustomTheme from '../../../../../../../../../../../../../utilities/theme/theme.json'

export const useStyles = makeStyles(theme => ({
    actionText: {
        color: '#77797c',
        position: 'relative',
        top: '9px'
        // marginRight: '3rem',
    },
    timelineActionIcon: {
        fontSize: '24px',
        marginRight: '0.8rem',
        // cursor:'pointer'
    },
    blue: {
        color: '#5784BA',
    },
    grey: {
        color: '#d0d0d0',
    },
    red: {
        color: CustomTheme.errorColor
    },
    forFlex: {
        display: 'flex'
    },
    forChangeStateLoaderStyle: {
        position: 'relative',
        bottom: '3px'
    },
    activeStatus: {
        color: '#5784BA',
        cursor: 'pointer'
    },
    generalNonActiveStatus: {
        color: '#77797c',
        cursor: 'pointer',
        '&:hover': {
            color: CustomTheme.projectTaskTitle
        },
    },
    nonActiveIcons: {
        color: '#bbb',
        cursor: 'not-allowed'
    }
}));
