import React, {useEffect, useRef, useState} from 'react'
import {connect} from 'react-redux';
import {Divider, Grid, IconButton} from '@material-ui/core';
import {ValidatorForm} from 'react-material-ui-form-validator';
import moment from 'moment';

import CustomTheme from '../../../../../../../../../../utilities/theme/theme.json';
import MessageModal from '../../../../../../../../../../utilities/MessageModal/MessageModal';

import RoleSelection from './components/RoleSelection';
import MetaInfoSection from './components/MetaInfoSection';
import AssignSection from './components/AssignSection';
import EstimateSection from './components/EstimateSection';
import DependsOnSection from './components/DependsOnSection';

import {addTagRequest, getAllTagsRequset} from '../../../../../../../../../configuration/ConfigurationApiActions';
import {addTaskRequest, updateTaskRequest} from '../../../TasksApiActions'
const priority = [
    {name: 'High', value: 1, id: '1'},
    {name: 'Medium', value: 2, id: '2'},
    {name: 'Low', value: 3, id: '3'}
]
let statusArr = [
    {id: '1', title: 'Not Started', value: 'todo', depend: ["todo"]},
    {
        id: '2',
        title: 'In Progress',
        value: 'in_progress',
        depend: ["in_progress", "todo", "stopped", "blocked", "complete", "deferred"]
    },
    {id: '3', title: 'Stopped', value: 'stopped', depend: ["stopped", "in_progress", "stopped"]},
    {id: '4', title: 'Blocked', value: 'blocked', depend: ["blocked", "in_progress"]},
    {id: '5', title: 'Complete', value: 'complete', depend: ["complete", "in_progress"]},
    {id: '6', title: 'Deferred', value: 'deferred', depend: ["deferred", "todo", "in_progress", "stopped", "blocked"]}
]

const TaskForm = React.memo(({
                                 projectId, taskId, setIsDisplayForm,
                                 getAllTags, addTags, singleTaskDetails, taskListId, updateTask, addTask, setAddUpdateRes, closeAddUpdateFrom,
                                  showTaskDetailsPane 
                             }) => {
    const modalRef = useRef();
    const [tagList, setTagList] = useState([]);
    const [formData, setFormData] = useState({
        approver: null,
        functionalRole: [],
        title: null,
        description: null,
        tags: [],
        tasklist: [],
        priority: priority[1],
        checklist: null,
        status: null,
        predecessors: [],
    });
    const [isChangeInUpdate, setIsChangeInUpdate] = useState(false)

    /**
     * Function is triggred when component render first time
     * Trigger the getAllTags API request and update the state value of tagList
     */
    useEffect(() => {
        getAllTags().then((res) => {
            setTagList(res);
        });
    }, []);

    /**
     * Function is triggred when component is received updated value of taskId
     * Update the state value of formData
     */
    useEffect(() => {
        let data = {
            approver: null, functionalRole: [], title: null, description: null, tags: [],
            tasklist: [], priority: priority[1], checklist: null, status: null, predecessors: []
        };
        if (taskId) {
            data.approver =singleTaskDetails && singleTaskDetails.approver ?  singleTaskDetails.approver : null;
            data.functionalRole =singleTaskDetails && singleTaskDetails.assignee && singleTaskDetails.assignee.map((role) => {
                role.name = role.functionalRole.name;
                role.users = role.users;
                delete role.functionalRole;
                role.timeEffort = role.timeEffort ? role.timeEffort : "";
                return role;
            });
            data.title = singleTaskDetails.title;
            data.description =singleTaskDetails && singleTaskDetails.description ? singleTaskDetails.description : null
            if (singleTaskDetails.tags && singleTaskDetails.tags.length > 0) {
                data.tags = singleTaskDetails.tags;
            } else {
            }
            data.tasklist = null;
            data.tasklistId =singleTaskDetails && singleTaskDetails.tasklistId ? singleTaskDetails.tasklistId : null
            if (singleTaskDetails.priority) {
                data.priority = priority.find((priority) => priority.value === singleTaskDetails.priority);
            } else {
            }
            data.checklist = singleTaskDetails.checklist ? singleTaskDetails.checklist : null;
            if (singleTaskDetails.status) {
                let newStatusArr = statusArr.filter((status) => {
                    return (
                        status.depend.find((title) => title === singleTaskDetails.status)
                    )
                })
                let arrObj = newStatusArr.find((data) => data.value === singleTaskDetails.status)
                data.status = arrObj;
            } else {
            }
            if (singleTaskDetails.predecessors) {
                data.predecessors = singleTaskDetails.predecessors
            } else {
            }

        } else {
        }
        setFormData(data);
    }, [taskId])

    /**
     * Function is triggred on onChange action of autocomplete form element
     */
    const handleChangeAutocomplete = (elementName, selectedvalue) => {
        let data = Object.assign({}, formData);

        data[elementName] = selectedvalue;
        if (!isChangeInUpdate) {
            setIsChangeInUpdate(true);
        } else {
        }

        if (elementName === "dueDate") {
            data.showDueDateField = false
        } else {
        }

        setFormData(data);
    }

    /**
     * Function is triggred on onChange action of input form element
     */
    const handleChangeInput = (event, value) => {
        const data = Object.assign({}, formData);
        data[event.target.name] = event.target.value;
        if (!isChangeInUpdate) {
            setIsChangeInUpdate(true)
        } else {
        }
        setFormData(data);
    };

    /**
     * Function is used for add new tags
     */
    const addChips = (tags) => {
        const newTags = tags.filter((tag) => {
            return !(tagList.some((singleTag) => singleTag.name === tag));
        });
        newTags.map((singleTagName) => {
            let data = {name: singleTagName, color: singleTagName};
            addTags(data);
        });
    };

    /**
     * Function executes on submit form action
     */
    const handleSubmit = () => {
        if (formData.tags.length > 0) {
            addChips(formData.tags);
        } else {
        }
        let data = {
            title: formData.title,
            tags: formData.tags
        };
        if (formData.description) {
            data.description = formData.description;
        } else {
        }
        if (taskId) {
            if (formData.checklist) {
                if (formData.checklist === "None") {
                    data.checklistId = null
                } else {
                    data.checklistId = formData.checklist.id
                }
            } else {
            }
            if (formData.status) {
                data.status = formData.status.value
            } else {
            }
            if (formData.tasklist) {
                data.tasklistId = formData.tasklist.id
            }
        } else {
            if (formData.checklist) {
                data.checklistId = formData.checklist.id;
            } else {
            }
        }
        if (formData.approver) {
            data.isApprovalNeeded = true;
            data.approver = formData.approver.id;
        } else {
            data.isApprovalNeeded = false;
        }

        if (formData.functionalRole) {
            const roleData = Object.assign([], formData.functionalRole);
            const roles = roleData.map((item) => {
                let itemRole = Object.assign({}, item);
                delete itemRole.name;
                let arr = []
                itemRole.users.map((user) => {
                    if (user.id) {
                        arr.push(user.id)
                    } else {
                        arr.push(user)
                    }
                    itemRole.users = arr;
                })
                itemRole.noOfResources = itemRole.users.length;
                if (!itemRole.timeEffort) {
                    delete itemRole.timeEffort;
                } else {
                }
                return itemRole;
            });
            const roleWithoutUser = roles.filter((role) => role.users.length === 0 && role.timeEffort);
            const roleWithUsers = roles.filter((role) => role.users.length > 0);
            data.assignee = roleWithUsers.concat(roleWithoutUser);
        }
        if (formData.dueDate) {
            data.dueDate = moment(formData.dueDate).format('YYYY-MM-DD');
        } else {
        }
        data.priority = formData.priority.value;
        if (formData.predecessors) {
            let arrayPredecessor = [];
            formData.predecessors.map((task) => {
                arrayPredecessor.push(task.id)
            })
            data.predecessors = arrayPredecessor;
        } else {
        }
        if (taskId) {
            const id = taskId;
            updateTask(id, data, false, "Task updated successfully").then((res) => {
                let resData = {
                    id : res.id,
                    tasklistId : res.tasklistId,
                    formType: "edit"
                }
                setAddUpdateRes({
                    res: resData,
                    formName: 'task',
                });
                // getAllTaskLists(projectId);
                closeAddUpdateFrom();
                showTaskDetailsPane(id, "task");
            });
        } else {
            data.tasklistId = taskListId;
            addTask(data).then((res) => {
                let resData = {
                    id : res.id,
                    tasklistId : res.tasklistId,
                    formType: "add"
                }
                setAddUpdateRes({
                    res: resData,
                    formName: 'task'
                });
                // getAllTaskLists(projectId);
                closeAddUpdateFrom();
                showTaskDetailsPane(res.id, "task");
            });
        }
    }

    /**
     * Function executes on click of Esc key
     * Note: Show the dialog of Close Without Saving?, if user perform any action using form element
     */
    const handleEsc = (e) => {
        if (e.keyCode === 27) {

            if (isChangeInUpdate) {
                let title = `Close Without Saving?`
                let message = taskId ? `Are you sure you want to close this edit task without saving?` : `Are you sure you want to close this task without saving?`;
                modalRef.current.setState({
                    showModal: true,
                    message: message,
                    title: title,
                    showOkButton: true,
                    showCancelButton: true,
                    showNote: false,
                    note: '',
                    handleOk: (note) => {
                        setIsDisplayForm(false)
                    },
                    okText: "Yes",
                    cancelText: 'No'
                })
            } else {
                setIsDisplayForm(false)
            }
        }
    };

    /**
     * Function is used for remove selected predecessors
     */
    const removeTaskFromSelection = (id) => {
        let data = Object.assign([], formData);
        data.predecessors = data.predecessors.filter((item) => item.id !== id);
        setFormData(data);
    };

    return (
        <>
            <ValidatorForm
                onSubmit={handleSubmit}
                noValidate
                onError={errors => console.log(errors)}
                onKeyDown={(e) => handleEsc(e)}
            >
                <Grid style={{borderBottom: `1px dashed ${CustomTheme.primaryColor}`}} item xl={12} lg={12} md={12}
                      sm={12} xs={12} className="project-save-btn-container">
                    <Grid container justify="space-between">
                        <Grid>
                        </Grid>
                        <Grid>
                            <IconButton style={{color: CustomTheme.primaryColor}} className="form-panel-save-btn"
                                        type="submit"><i className="far fa-save"></i></IconButton>
                        </Grid>
                    </Grid>
                </Grid>

                <RoleSelection
                    formData={formData}
                    projectId={projectId}
                    taskId={taskId}
                    handleChangeAutocomplete={handleChangeAutocomplete}
                />
                <MetaInfoSection
                    formData={formData}
                    taskId={taskId}
                    tagList={tagList}
                    handleChangeInput={handleChangeInput}
                    handleChangeAutocomplete={handleChangeAutocomplete}
                />

                <AssignSection
                    formData={formData}
                    singleTaskDetails={singleTaskDetails}
                    priorityList={priority}
                    statusArr={statusArr}
                    projectId={projectId}
                    taskId={taskId}
                    handleChangeAutocomplete={handleChangeAutocomplete}
                    taskListId={taskListId}
                />

                <Divider className="divider-style"/>

                <EstimateSection
                    formData={formData}
                    taskId={taskId}
                    handleChangeAutocomplete={handleChangeAutocomplete}
                />

                <Divider className="divider-style"/>

                <DependsOnSection
                    formData={formData}
                    projectId={projectId}
                    taskId={taskId}
                    handleChangeAutocomplete={handleChangeAutocomplete}
                    removeTaskFromSelection={removeTaskFromSelection}
                />
            </ValidatorForm>
            <MessageModal
                ref={modalRef}/>
        </>
    )

});

/**
 * Used for selecting the part of the data from the redux store that the connected component needs and pass it as a props
 * It is called every time when update the state of redux store
 * @param state
 */
const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
        singleTaskDetails: state.projects.singleTaskDetails
    }
};

/**
 * Used to specify the which actions your component might need to dispatch and pass it to connected component as a props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getAllTags: (name, searchTag) => {
            return dispatch(getAllTagsRequset(name, searchTag)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addTags: (data) => {
            return dispatch(addTagRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        updateTask: (id, data, forAssigneeStatus, message) => {
            return dispatch(updateTaskRequest(id, data, forAssigneeStatus, message)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addTask: (data) => {
            return dispatch(addTaskRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    };
};

/**
 * Used to connects a react component to a redux store
 */
export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);
