import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import './Milestones.css'
import { Box, Card, Grid } from '@material-ui/core';
import AppHeader from '../userDashboard/components/AppHeader/AppHeader';
import ProjectHeader from '../projects/components/details/Project/components/ProjectHeader/ProjectHeader';
import MilestoneList from './components/MilestoneList/MilestoneList';
import TaskMilestoneDetails from './components/TaskMilestoneDetails/TaskMilestoneDetails';
import MilestoneForm from './components/MilestoneForm/MilestoneForm';
import CustomTheme from '../../utilities/theme/theme.json'
import MessageModal from '../../utilities/MessageModal/MessageModal';

const Milestones = ({
    handleDrawerOpenClose, history, match,
    taskList,
}) => {
    const modalRef = useRef();
    const projectId = match.params.id;
    const [milestoneId, setMilestoneId] = useState(null)
    const [rightPane, setRightPane] = useState(null);
    // formpanel
    const [isDisplayForm, setIsDisplayForm] = useState(false);
    // Formpanel states End //
    const [addUpdateRes, setAddUpdateRes] = useState({
        res: null,
        formName: ''
    });
    /*
    function for showing add and edit milestone form
     @param id , rightPaneName , taskListId
    */
    const showAddUpdateForm = (id, rightPaneName, taskListId = null) => {
        setRightPane(rightPaneName);
        setIsDisplayForm(true);
        if (id) {
            setMilestoneId(id);
        } else {
            setMilestoneId(null);
        }
    }

    /*
     function for close the add and edit form on addition and updation of milestone
     */
    const closeAddUpdateFrom = () => {
        setMilestoneId(null);
        setRightPane(null);
        setIsDisplayForm(false);
    }

    /*
    function for when milestone add/edit then fetching details of that add/edit milestone and showing into  TaskMilestoneDetails componenet
   */
    const showTaskDetailsPane = (id, rightPaneName) => {
        setIsDisplayForm(false);
        setMilestoneId(id);
        setRightPane(rightPaneName);
    }

    const renderMilestoneDetails = () => {
        return (
            <TaskMilestoneDetails
                setAddUpdateRes={setAddUpdateRes}
                setRightPane={setRightPane}
                match={match}
                showAddUpdateForm={showAddUpdateForm}
                milestoneId={milestoneId}
            />
        )
    }

    const milestoneFormDisplayHandler = () => {
        return (
            <MilestoneForm
                milestoneIdForUpdateView={milestoneId}
                projectId={projectId}
                showAddUpdateForm={showAddUpdateForm}
                setIsDisplayForm={setIsDisplayForm}
                showTaskDetailsPane={showTaskDetailsPane}
                closeAddUpdateFrom={closeAddUpdateFrom}
                setAddUpdateRes={setAddUpdateRes}
            />
        )
    }



    return (
        <>
            <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                <ProjectHeader history={history} match={match} />
            </AppHeader>

            <Box>
                <Grid container>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className="table-view-tasklist-container">
                        {
                            taskList && taskList.status === "draft"
                                ?
                                <Card style={{ color: CustomTheme.fontColor }} className="draft-status-container">
                                    <Box className="draft-message-span">Project creation is in progress. Please come
                                        back in a few minutes.</Box>
                                </Card>
                                :
                                <MilestoneList
                                    history={history}
                                    showTaskDetailsPane={showTaskDetailsPane}
                                    showAddUpdateForm={showAddUpdateForm}
                                    closeAddUpdateFrom={closeAddUpdateFrom}
                                    addUpdateRes={addUpdateRes}
                                />
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        {
                            isDisplayForm
                                ?
                                <Card className="add-update-form-onclick for-card-shadow">
                                    {rightPane !== "" && milestoneFormDisplayHandler()}
                                </Card>
                                :
                                (
                                    rightPane === ""
                                        ?
                                        <>
                                        </>
                                        :
                                        <>
                                            {milestoneId && rightPane === "milestone" && renderMilestoneDetails()}
                                        </>
                                )
                        }

                    </Grid>
                </Grid>
            </Box>
            <MessageModal
                ref={modalRef} />
        </>
    );
};

const mapStateToProps = (state) => {

    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Milestones);
