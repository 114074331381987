import {makeStyles} from '@material-ui/core';
import CustomTheme from '../../../../../../../../../utilities/theme/theme.json'

export const useStyles = makeStyles(theme => {
    return {
        profileContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        profileImage: {
            height: '32px',
            width: '32px',
            borderRadius: '100%',
            marginRight: '5px',
        },
        labelText: {
            fontWeight: '500',
        },
        removeIcon: {
            color: theme.palette.error.main,
        },
        green: {
            color: CustomTheme.primaryColor,
        },
        blue: {
            color: '#428bca',
        },
        orange: {
            color: CustomTheme.warningColor,
        },
        backIcon: {
            fontSize: '20px !important',
            cursor: 'pointer',
            color: CustomTheme.primaryColor
        },
        participantTableHeader: {
            backgroundColor: '#fff',
            borderTopRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderBottom: `1px dashed ${CustomTheme.primaryColor}`,
            height:'57px'
        },
        backButtonGrid: {
            padding: '14px 16px 5px 8px'
        },
        tableContainerStyle: {
            boxShadow: '0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%)',
            borderRadius: '6px'
        },
        newMemberIcon: {
            color: CustomTheme.fontColor
        },
        forFontWeightNormal: {
            fontWeight: 'normal'
        },
        newMemberInputContainer : {
            marginTop: '1px'
        },
        participantsTableCoulnmHeaders:{
            fontWeight:500,
            color:'black'
        },
        newMemberTitle: {
            marginLeft: '5px',
            marginTop:'2px'
        }
    };
});
