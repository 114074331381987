import React from 'react';
import {connect} from 'react-redux';
import './AppLoader.css';
import {CircularProgress} from '@material-ui/core';

class AppLoader extends React.Component {

    render() {
        if (this.props.showLoader || this.props.loader) {
            return (
                // <div className={`${(this.props.showLoader || this.props.loader)?'loader-container display-loader':"hide-loader"}`}>
                //   <div id="lottie"></div>
                // </div>
                <div className="loader-container">
                    <CircularProgress className="circular-progress-app-loader"/>
                </div>
            )
        } else {
            return <></>
        }

    }
}

const mapStateToProps = state => {
    return {
        showLoader: state.showLoader.showLoader
    }
};

AppLoader = connect(
    mapStateToProps
)(AppLoader);

export default AppLoader;
