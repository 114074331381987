import React from 'react';
import AppHeader from '../../../userDashboard/components/AppHeader/AppHeader';
import ProjectHeader from '../ProjectHeader/ProjectHeader';
import AddProjectStepper from './components/AddProjectStepper/AddProjectStepper';

const AddProjectSee = ({handleDrawerOpenClose, history}) => {
    return (
        <>
            <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                <ProjectHeader history={history}/>
            </AppHeader>

            <AddProjectStepper history={history}/>
        </>
    );
};

export default AddProjectSee;
