import React, {useEffect, useState} from 'react';
import './ChangePassword.css';
import {connect} from 'react-redux';

import {Button, Card, Grid} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';

import {signInRequest} from '../AuthenticationApiActions';

import {IS_PASSWORD_MATCH, ONLY_BLANK_SPACES, VALIDATE_PASSWORD} from '../../../utilities/Constants';
import UtilHelper from "../../../utilities/UtilHelper";

const ChangePassword = React.memo(({history, signIn, changePassword}) => {

    const [formData, setFormData] = useState({
        currentPassword: '', newPassword: '', confirmPassword: ''
    });

    useEffect(() => {
        ValidatorForm.addValidationRule(ONLY_BLANK_SPACES, UtilHelper.validateBlankSpaces);
        ValidatorForm.addValidationRule(VALIDATE_PASSWORD, UtilHelper.validatePassword);
        ValidatorForm.addValidationRule(IS_PASSWORD_MATCH, (value) => {
            return UtilHelper.isPasswordMatch(formData.newPassword, formData.confirmPassword);
        });

        return () => {
            ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
            ValidatorForm.removeValidationRule(VALIDATE_PASSWORD);
            ValidatorForm.removeValidationRule(IS_PASSWORD_MATCH);
        }
    }, [formData]);

    const handleChange = (event) => {
        let data = Object.assign({}, formData);

        data[event.target.name] = event.target.value;
        setFormData(data);
    };

    const handleSubmit = () => {
        changePassword(formData.newPassword, formData.currentPassword)
            .then(res => {
                navigateBack();
            });
    };

    /**
     * Navigate to the previous page
     */
    const navigateBack = () => {
        history.goBack();
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Card className="change-password-card-container">
                    <ValidatorForm
                        onSubmit={handleSubmit}
                    >
                        <TextValidator
                            autoFocus
                            margin="dense"
                            id="currentPassword"
                            name="currentPassword"
                            label="Current password"
                            type={'password'}
                            onChange={handleChange}
                            value={formData.currentPassword}
                            fullWidth
                            validators={['required', ONLY_BLANK_SPACES]}
                            errorMessages={["This field is required", "Blank spaces not allowed"]}
                        />

                        <TextValidator
                            className="margin-top-20"
                            margin="dense"
                            id="newPassword"
                            name="newPassword"
                            label="New password"
                            type={'password'}
                            onChange={handleChange}
                            value={formData.newPassword}
                            fullWidth
                            validators={['required', ONLY_BLANK_SPACES, VALIDATE_PASSWORD]}
                            errorMessages={["This field is required", "Blank spaces not allowed", "Password should have minimum 1 uppercase, 1 lowercase, 1 numeric, 1 special characters"]}
                        />
                        <TextValidator
                            margin="dense"
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Confirm password"
                            type={'password'}
                            onChange={handleChange}
                            value={formData.confirmPassword}
                            fullWidth
                            validators={['required', IS_PASSWORD_MATCH]}
                            errorMessages={["This field is required", "Password mismatch"]}
                        />

                        <div className="margin-top-20">
                            <Button size="small" variant="contained" type="submit" color="secondary"
                                    className="change-password-form-button">
                                Change password
                            </Button>
                            <Button size="small" className="change-password-cancel-button" onClick={navigateBack}>
                                Cancel
                            </Button>
                        </div>
                    </ValidatorForm>
                </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>

        </Grid>
    )
});

const mapStateToProps = state => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        changePassword: (data) => {
            return dispatch(signInRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });

        }
    }
};

const ChangePasswordConnect = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePassword);

export default ChangePasswordConnect;
