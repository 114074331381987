import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper,} from '@material-ui/core';
import Draggable from 'react-draggable';
import {useStyles} from './styles';

function PaperComponent(props) {
    return (
        <Draggable
            handle='#draggable-dialog-title'
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const TestModal = ({open, handleClose}) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            disableScrollLock={true}
            PaperComponent={PaperComponent}
        >
            <DialogTitle id='draggable-dialog-title' className={classes.dialogTitle}>
                Use Google's location service?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Let Google help apps determine location. This means sending anonymous
                    location data to Google, even when no apps are running.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    Disagree
                </Button>
                <Button onClick={handleClose} color='primary'>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TestModal;
