import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux';
import moment from 'moment';
import CustomTheme from '../../../../../../../../../../../utilities/theme/theme.json';

import {Box, FormControl, Grid, Typography} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

import {getAllProjectCheckListRequest, getAllTaskListsRequest} from '../../../../TasksApiActions';

import SearchableDropDown from '../../../../../../../../../../../utilities/SearchableDropDown/SearchableDropDown';
import ChecklistDropdownSearchable
    from '../../../../../../../../../../../utilities/SearchableDropDown/ChecklistDropdownSearchable/ChecklistDropdownSearchable';
import ChecklistModal from '../../../../../../../../../../../utilities/CheckListModalProjectDefination/ChecklistModal';

const AssignSection = React.memo(({
                                      handleChangeAutocomplete,
                                      priorityList,
                                      statusArr,
                                      formData,
                                      taskId,
                                      singleTaskDetails,
                                      projectId,
                                      getAllOnlyTaskLists,
                                      getAllProjectCheckList,
                                      taskListId
                                  }) => {
    const [allTaskList, setAllTaskList] = useState([]);
    const [showPriorityDropdown, setShowPriorityDropdown] = useState(false);
    const [allCheckList, setAllCheckList] = useState([]);
    const [showChecklistDropdown, setShowChecklistDropdown] = useState(false);
    const [isChecklistNewCreating, setIsChecklistNewCreating] = useState(false);
    const [openAddCheckList, setOpenAddCheckList] = useState(false);
    const [showDueDateField, setShowDueDateField] = useState(false);
    const [isStatusShow, setIsStatusShow] = useState(false);
    const [tasklistName, setTasklistName] = useState(null)
    /**
     * Function is triggred when component render first time
     * Trigger the getAllOnlyTaskLists and getAllProjectCheckList API request and update the state value of allTaskList and allCheckList
     */
    useEffect(() => {
        getAllOnlyTaskLists(projectId, false).then((res) => {
            setAllTaskList(res);
            let selectedTasklistObj = res && res.filter((tasklist)=>tasklist.id===taskListId)
            setTasklistName(selectedTasklistObj[0].title)
        });
        getAllProjectCheckList(projectId).then((res) => {
            let result = Object.entries(res).map(([k, v]) => (v));
            let createChecklistObj = {title: 'Create checklist', id: "1"}
            let noneListObj = {title: 'None', id: null}
            result.unshift(createChecklistObj, noneListObj)
            setAllCheckList(result)
        });
    }, [taskListId]);

    /**
     * Function is triggred when component is received updated value of taskId
     * Update the state value of showPriorityDropdown, showChecklistDropdown and showDueDateField based on formData
     */
    useEffect(() => {
        if (taskId) {
            setShowPriorityDropdown(formData.priority ? true : false);
            setShowChecklistDropdown(formData.checklist ? true : false);
            setShowDueDateField(formData.dueDate ? true : false);
        } else {
            setShowPriorityDropdown(formData.priority ? true : false);
            setShowChecklistDropdown(formData.priority ? true : false);
            setShowDueDateField(formData.priority ? true : false);
        }
    }, [taskId, formData]);

    /**
     * Function return the priority string from selected priority value
     */
    const renderPriority = (priority) => {
        let priorityValue = "";
        switch (priority.value) {
            case 1:
                priorityValue = "High";
                break;
            case 2:
                priorityValue = "Medium";
                break;
            case 3:
                priorityValue = "Low";
                break;
            default:
            // code block
        }
        return priorityValue;
    }

    /**
     * Below code is for display the selected tasklist while update
     */
    let selectedTask = formData.tasklist;
    if (formData.tasklistId !== undefined && formData.tasklist === null) {
        if (allTaskList.length > 0 && formData.tasklistId) {
            let tasklist = allTaskList.filter((task) => task.id === formData.tasklistId);
            selectedTask = tasklist[0];
        }
    } else {
    }
    
    return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="form-panel-assign-name-container">
            <Grid>
                <Box className="task-items-list ">
                    {
                        taskId
                            ?
                            <Box className="dropdown-margins-task-form">
                                <SearchableDropDown
                                    title={true} name={false}
                                    checklist={false} forNameAttr={"tasklist"}
                                    value={selectedTask ? selectedTask : []}
                                    setValue={handleChangeAutocomplete}
                                    label="Select tasklist" optionsList={allTaskList}
                                />
                            </Box>
                            :
                            <Box
                            >
                                <Typography variant="body2" style={{color: CustomTheme.fontColor}}
                                            className="form-panel-followers-text"><i
                                    className="far fa-list-alt"></i> Tasklist</Typography>
                                <Typography variant="body2" style={{color: CustomTheme.primaryColor}}
                                            className="form-panel-bold-text">
                                    {tasklistName ? tasklistName : "-"}
                                </Typography>
                            </Box>
                    }
                    {
                        showPriorityDropdown
                            ?
                            <Box className="dropdown-margins-task-form">
                                <SearchableDropDown
                                    title={false} name={true}
                                    checklist={false} forNameAttr={"priority"}
                                    value={formData.priority}
                                    setValue={handleChangeAutocomplete}
                                    label="Select priority" optionsList={priorityList}
                                />
                            </Box>
                            :
                            <Box onClick={() => setShowPriorityDropdown(true)}>
                                <Typography variant="body2" style={{color: CustomTheme.fontColor}}
                                            className="form-panel-followers-text"><i
                                    className="fas fa-angle-double-up"></i> Priority</Typography>
                                <Typography variant="body2" style={{color: CustomTheme.primaryColor}}
                                            className="form-panel-bold-text">
                                    {
                                        formData.priority
                                            ? renderPriority(formData.priority)
                                            : (
                                                <>
                                                    <i className="far fa-edit"></i> Set Priority
                                                </>
                                            )
                                    }
                                </Typography>
                            </Box>
                    }
                    {
                        showChecklistDropdown
                            ?
                            <Box className="dropdown-margins-task-form">
                                <ChecklistDropdownSearchable
                                    project={true}
                                    setIsChecklistNewCreating={setIsChecklistNewCreating}
                                    forNameAttr={"checklist"} setValue={handleChangeAutocomplete}
                                    selectedValue={formData.checklist}
                                    setOpenAddCheckList={setOpenAddCheckList}
                                    setAllCheckList={setAllCheckList} optionsList={allCheckList}/>
                            </Box>
                            :
                            <Box onClick={() => setShowChecklistDropdown(true)}>
                                <Typography variant="body2" style={{color: CustomTheme.fontColor}}
                                            className="form-panel-followers-text"><i
                                    className="far fa-list-alt"></i> Checklist</Typography>
                                <Typography variant="body2" style={{color: CustomTheme.primaryColor}}
                                            className="form-panel-bold-text"><i className="fas fa-link"></i> Add
                                    Checklist</Typography>
                            </Box>
                    }
                    {
                        showDueDateField
                            ?
                            <FormControl className="dropdown-margins-task-form">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        fullWidth
                                        label='Select Due Date'
                                        format='dd/MM/yyyy'
                                        name="dueDate"
                                        value={formData.dueDate}
                                        onChange={date => {
                                            handleChangeAutocomplete("dueDate", date);
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                            :
                            <Box onClick={() => setShowDueDateField(true)}>
                                <Typography variant="body2" style={{color: CustomTheme.fontColor}}
                                            className="form-panel-followers-text">
                                    <i className="far fa-calendar-alt" style={{paddingRight: '3px'}}></i>
                                    Due Date
                                </Typography>
                                <Typography variant="body2" style={{color: CustomTheme.primaryColor}}
                                            className="form-panel-bold-text">
                                    {
                                        formData.dueDate
                                            ? moment(formData.dueDate).format('DD/MM/YYYY')
                                            : (
                                                <>
                                                    <i className="fas fa-plus-circle"></i> Set Date
                                                </>
                                            )
                                    }
                                </Typography>
                            </Box>
                    }
                    {
                        taskId && isStatusShow
                            ?
                            <Box className="dropdown-margins-task-form">
                                <SearchableDropDown
                                    title={true} name={false} checklist={false}
                                    forNameAttr={"status"} value={formData.status}
                                    setValue={handleChangeAutocomplete}
                                    label="Select status" optionsList={statusArr}
                                />
                            </Box>
                            :
                            <>
                                {
                                    taskId
                                        ?
                                        <Box onClick={() => setIsStatusShow(true)}>
                                            <Typography variant="body2" style={{color: CustomTheme.fontColor}}
                                                        className="form-panel-followers-text"><i
                                                className="far fa-list-alt"></i> Status</Typography>
                                            <Typography variant="body2" style={{color: CustomTheme.primaryColor}}
                                                        className="form-panel-bold-text"><i className="fas fa-link"></i> Status</Typography>
                                        </Box>
                                        :
                                        <></>
                                }
                            </>
                    }
                </Box>
            </Grid>
            {
                openAddCheckList && (
                    <ChecklistModal
                        nameAtt={"checklist"} setIsChecklistNewCreating={setIsChecklistNewCreating}
                        projectId={projectId} setAllCheckList={setAllCheckList}
                        setSelectedChecklist={handleChangeAutocomplete}
                        setOpenAddCheckList={setOpenAddCheckList} openAddCheckList={openAddCheckList}
                    />
                )
            }
        </Grid>
    )

});

/**
 * Used for selecting the part of the data from the redux store that the connected component needs and pass it as a props
 * It is called every time when update the state of redux store
 * @param state
 */
const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

/**
 * Used to specify the which actions your component might need to dispatch and pass it to connected component as a props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getAllOnlyTaskLists: (projectId, isResStoringInRedux) => {
            return dispatch(getAllTaskListsRequest(projectId, isResStoringInRedux)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllProjectCheckList: (projectId) => {
            return dispatch(getAllProjectCheckListRequest(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    };
};

/**
 * Used to connects a react component to a redux store
 */
export default connect(mapStateToProps, mapDispatchToProps)(AssignSection);
