import React, {useRef} from 'react';
import {Box, Typography} from '@material-ui/core';
import {TimelineContent, TimelineItem} from '@material-ui/lab';
import {useStyles} from './styles';
import {connect} from 'react-redux';
import CustomTheme from '../../../../../../../../../../../../../utilities/theme/theme.json'
import moment from 'moment';
import Switch from '@material-ui/core/Switch';
import {withStyles} from "@material-ui/core/styles";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MessageModal from '../../../../../../../../../../../../../utilities/MessageModal/MessageModal';
import {deleteTimelogRequestProject} from '../../../../../../TasksApiActions'

const CustomSwitch = withStyles({
    colorSecondary: {
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "green"
        }
    },
    // track: {
    //   backgroundColor: "blue"
    // }
})(Switch);
const EffortItem = ({
                        timelog,
                        onEditTimeLog,
                        getAllTimelogList,
                        deleteTimelog
                    }) => {
    const classes = useStyles();
    const modalRef = useRef();

    return (
        <TimelineItem
            classes={{
                missingOppositeContent: classes.oppositeContent,
            }}
        >
            <TimelineContent classes={{root: classes.contentContainer}}>
                <Box className={classes.contentInnerContainer}>
                    <Box className={classes.headingContainer}>
                        <Box className={classes.profileContainer}>
                            <Box>
                                <img
                                    src={`https://ui-avatars.com/api/?name=${timelog.user.firstName}&background=random`}
                                    alt='profile'
                                    className={classes.profileImage}
                                />
                            </Box>
                            <Box>
                                <Typography variant="body1" classes={{root: classes.nameText}}>
                                    <span>{`${timelog.user.firstName} ${timelog.user.lastName}`}</span>
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Typography variant="body2" classes={{root: classes.timeText}}>
                                {moment(timelog.createdAt).format('llll')}
                            </Typography>
                        </Box>
                        <Box>
              <span
                  style={{
                      color: CustomTheme.primaryColor,
                      position: 'relative',
                      left: '-8px',
                      bottom: '5px',
                      fontSize: '19px',
                      cursor: 'pointer'
                  }}
                  onClick={() => {
                      const title = `Delete Timelog`
                      const message = `Are you sure,you want to delete timelog?`
                      modalRef.current.setState({
                          showModal: true,
                          message: message,
                          title: title,
                          showOkButton: true,
                          showCancelButton: true,
                          showNote: false,
                          note: '',
                          handleOk: (note) => {
                              deleteTimelog(timelog.id).then((res) => {
                                  getAllTimelogList()
                              })
                          },
                          okText: "Yes",
                          cancelText: 'No'
                      });

                  }}
              >
                <i className="far fa-trash-alt"></i>
              </span>
                            <span
                                style={{
                                    color: CustomTheme.primaryColor,
                                    position: 'relative',
                                    left: '-1px',
                                    bottom: '5px',
                                    fontSize: '19px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => onEditTimeLog(timelog)}
                            >
                <i className="far fa-edit"></i>
              </span>
                            {/* <Button
              onClick={()=>onEditTimeLog(timelog)}
              color="primary"
              type="submit"
              className={classes.saveBtn}
              style={{color:CustomTheme.primaryColor,position:'relative',left:'21px',bottom:'5px'}}
              >
                  <i className="far fa-edit"></i>
              </Button> */}
                        </Box>
                    </Box>

                    <Box className={classes.taskInfoContainer}>
                        <Box className={`${classes.taskIconContainerForTimelogItem} switch-btn-container `}>
              <span
                  className={`${classes.iconFirTimeLogItem}`}
              >
                <i className='fas fa-rupee-sign'></i>
              </span>
                            <span className="custome-switch" style={{position: 'relative', top: '-11px'}}>
                <CustomSwitch
                    /**
                     * TODO: Replace inline css with external or internal, please check it in throughout the system.
                     */
                    style={
                        {
                            color: '#ffff',
                        }
                    }
                    checked={timelog.isBillable}
                    checkedIcon={
                        <div className={classes.checkedIconDivStyle}>
                      <span>
                        <CheckRoundedIcon style={{color: 'green', fontSize: '15px', marginTop: '-18px'}}/>
                      </span>
                        </div>
                    }
                    icon={
                        <div className={classes.notCheckedIconDivStyle}>
                    <span>
                      <CloseRoundedIcon style={{color: 'red', fontSize: '15px', marginTop: '-18px'}}/>
                    </span>
                        </div>
                    }
                    value="checkedA"
                    inputProps={{"aria-label": "secondary checkbox"}}
                />
              </span>
                        </Box>

                        <Box className={classes.hoursContainerForTimelogItem}>
                            <Typography classes={{root: classes.hoursCountText}}>
                                {(timelog.durationInMinutes / 60).toFixed(2)}
                            </Typography>
                            <Typography style={{position: 'relative', top: '-4px'}} variant="subtitle2"
                                        classes={{root: classes.hoursLabel}}>
                                HRS
                            </Typography>
                        </Box>

                        <Box className={classes.taskTextContainer}>
                            <Typography variant="h5" classes={{root: classes.taskStatusText}}>
                                {/**
                                 * TODO: Why we use different different moment function for display one date?
                                 */}
                                {moment(timelog.fromDateTime).format('MMMM Do YYYY » h:mm A')} - {moment(timelog.toDateTime).format('h:mm A')}
                            </Typography>

                            <Typography variant="body2" classes={{root: classes.taskStatusDescription}}>
                                {timelog.description}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </TimelineContent>
            <MessageModal
                ref={modalRef}/>
        </TimelineItem>
    );
};
const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteTimelog: (timelogId) => {
            return dispatch(deleteTimelogRequestProject(timelogId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(EffortItem);
