import {makeStyles} from '@material-ui/core';
import CustomTheme from '../../../../../../../../../../../../../utilities/theme/theme.json'

export const useStyles = makeStyles(theme => ({
    oppositeContent: {
        '&:before': {
            content: '""',
            flex: 0,
            padding: '0px',
        },
    },
    profileContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '-32px',
    },
    profileImage: {
        height: '35px',
        width: '35px',
        borderRadius: '50%',
        marginLeft: '-10px',
        backgroundColor: '#fff',
        padding: '2px',
    },
    contentContainer: {
        padding: '4px 6px',
        marginLeft: '5px',
    },
    contentInnerContainer: {
        backgroundColor: '#CCF1FF',
        color: 'darkslategrey',
        borderRadius: '7px',
        marginBottom: '1rem',
        padding: '0.5rem',
    },
    headingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: '18px',
        marginTop: '5px',
        marginRight: '5px',
    },
    nameText: {
        marginLeft: '5px',
        // fontVariant: 'small-caps',
    },
    timeText: {},
    taskInfoContainer: {
        display: 'flex',
        // marginTop: '8px',
    },
    taskInfoContainerForForm: {
        display: 'flex',
        // marginTop: '5px',
    },
    taskIconContainer: {
        borderRight: '1px dashed #77797c',
        height: '100px',
        width: '77px',
        textAlign: 'center',
    },
    taskIconContainerForTimelogItem: {
        borderRight: '1px dashed #77797c',
        height: '72px',
        width: '77px',
        textAlign: 'center',
    },
    // icon: {
    //   fontSize: '32px',
    // },
    taskTextContainer: {
        marginLeft: '0.7rem',
        width: '100%'
    },
    taskStatusText: {},
    taskStatusDescription: {},
    hoursContainer: {
        height: '100px',
        borderRight: '1px dashed #77797c',
        width: '65px',
        textAlign: 'center',
    },
    hoursContainerForTimelogItem: {
        height: '72px',
        borderRight: '1px dashed #77797c',
        width: '65px',
        textAlign: 'center',
    },
    hoursCountText: {
        fontSize: '25px',
        lineHeight: '54px',
        fontWeight: '600',
        paddingLeft: '2px',
        paddingRight: '2px'
    },
    hoursLabel: {
        textTransform: 'uppercase',
    },
    grey: {
        color: '#d0d0d0',
    },
    plusSignForTimelog: {
        height: '33px',
        width: '33px',
        borderRadius: '50%',
        marginLeft: '-10px',
        backgroundColor: '#de6b1af0',
        padding: '2px',
    },
    saveBtn: {
        fontSize: '19px'
    },
    saveBtnBox: {
        marginTop: '-10px'
    },
    pickerContainer: {
        position: 'relative',
    },
    formIcon: {
        position: 'absolute',
        top: '4px',
        left: '5px',
        fontSize: '20px',
    },
    pickerInput: {
        paddingLeft: '1rem',
    },
    formContainer: {
        marginLeft: '-20px',
    },
    upperFormContainer: {
        marginBottom: '0.8rem',
    },
    buttonRoot: {
        minWidth: '0px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        fontSize: '32px',
        color: 'darkslategrey',
        position: 'relative',
        top: '9px'
    },
    iconFirTimeLogItem: {
        fontSize: '32px',
        color: 'darkslategrey',
        position: 'relative',
        top: '2px'
    },
    logIcon: {
        display: 'block',
        marginTop: '3px',
    },
    contentInnerContainerForForm: {
        backgroundColor: CustomTheme.bodyBackground,
        color: 'darkslategrey',
        borderRadius: '7px',
        marginBottom: '1rem',
        padding: '0.5rem',
    },
    hoursCountTextForForm: {
        fontSize: '25px',
        lineHeight: '54px',
        fontWeight: '600',
        position: 'relative',
        top: '9px'
    },
    hoursLabelForForm: {
        textTransform: 'uppercase',
        position: 'relative',
        top: '15px'
    },
    checkedIconDivStyle: {
        backgroundColor: '#ffff',
        borderRadius: '50%',
        height: '19px',
        marginTop: '4px',
        marginLeft: '1px'
    },
    notCheckedIconDivStyle: {
        backgroundColor: '#ffff',
        borderRadius: '50%',
        height: '19px',
        marginTop: '4px',
        marginLeft: '5px'
    }
}));
