import React, { useEffect, useRef, useState } from 'react';
import MaterialTable, { MTableBodyRow } from 'material-table';
import { connect } from 'react-redux';
import moment from 'moment';
import { reOrderTaskRowUsingRedux, getAllTasksRequest, taskOrderRequestProject, getMyAllTasksOfLoggedInUser, getAllTaskListsRequest } from '../../TasksApiActions';
import Button from '@material-ui/core/Button';
import { useStyles } from './styles';
import './TaskList.css';
import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import LoaderForSaveAndEdit from '../../../../../../../../../utilities/AppLoader/LoaderForSaveAndEdit'
import CustomTheme from '../../../../../../../../../utilities/theme/theme.json'
import { useParams } from 'react-router';
import ImportTasklisDialog from './ImportTasklistDialog/ImportTasklistDialog'
import ImportTasklistMenu from './TasklistImportMenu/TasklistImportMenu'
const TaskList = ({
    history,
    loggedInUser,
    showTaskDetailsPane,
    getAllTasks,
    showAddUpdateForm,
    addUpdateRes,
    setAddUpdateRes,
    taskOrderRequest,
    getAllTasksOfUser,
    getAllTaskLists,
    taskLists,
    reOrderTaskUsingRedux
}) => {
    const classes = useStyles();
    const { id } = useParams();
    const projectId = id;

    const [isImportTasklistOpenDialog, setIsImportTasklistOpenDialog] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const anchorRef = useRef(null);
    const [showedImportMemu, setShowedImportMemu] = useState(false)
    const [isShowLoaderFotFetchTasks, setIsShowLoaderFotFetchTasks] = useState(false)
    const [isRowDrag, setIsRowDrag] = useState(false)
    const [idForDsiplalyLoader, setIdForDisplayLoader] = useState(null);
    const [myTasksVisible, setMyTasksVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openedTasklists,setOpenedTasklists] = useState([])

    const dragState = { row: -1, dropIndex: -1 };

    useEffect(() => {
        if (myTasksVisible) {
            getAllTasksOfUser(projectId, loggedInUser.id).then((res) => { })
        }
        else {
            getAllTaskLists(projectId, true).then((res) => {
            });
        }
        setOpenedTasklists([]);
    }, [myTasksVisible]);

    useEffect(() => {
        if (addUpdateRes.res && addUpdateRes.formName === "task") {
            if (addUpdateRes.res.formType==="edit") {
                setSelectedRow(addUpdateRes.res.id)
            } else {
                setMyTasksVisible(false)
                if (openedTasklists.includes(addUpdateRes.res.tasklistId)) {
                    setSelectedRow(addUpdateRes.res.id)
                }
                else {
                    setSelectedRow(addUpdateRes.res.id)
                    let data = Object.assign([], openedTasklists)
                    data.push(addUpdateRes.res.tasklistId)
                    setOpenedTasklists(data)
                }
            }
        } else if (addUpdateRes.formName === "taskList") {
            setSelectedRow(addUpdateRes.res)
            setMyTasksVisible(false)
        } else {
        }
    }, [addUpdateRes])

    const showTaskHandler = (event) => {
        event.stopPropagation();
    }


    const offsetIndex = (from, to, arr = []) => {
        if (from < to) {
            let start = arr.slice(0, from),
                between = arr.slice(from + 1, to + 1),
                end = arr.slice(to + 1);
            if (taskLists[from].isSubTask || taskLists[to].isSubTask) {
                orderingRowHandler("task", taskLists[from], taskLists[to], false)
            }
            return [...start, ...between, arr[from], ...end];
        }
        if (from > to) {
            let start = arr.slice(0, to),
                between = arr.slice(to, from),
                end = arr.slice(from + 1);
            let modifiedArray = [...start, arr[from], ...between, ...end];
            let findIndex = modifiedArray.findIndex((task) => task.title === taskLists[from].title)
            let belowIndex = findIndex - 1
            if (taskLists[from].isSubTask || taskLists[to].isSubTask) {
                orderingRowHandler("task", taskLists[from], taskLists[belowIndex], false)
            }
            return [...start, arr[from], ...between, ...end];
        }
        return arr;
    };

    const offsetIndexIfTasklistNotOpen = (from, to, arr = []) => {
        if (from < to) {
            let start = arr.slice(0, from),
                between = arr.slice(from + 1, to + 1),
                end = arr.slice(to + 1);
            let data = {
                tasklistId: taskLists[to].id,
                taskId: taskLists[from].id,
                belowTaskId: null
            }
            taskOrderRequest(data);
            return [...start, ...between, ...end];
        }
        if (from > to) {
            let start = arr.slice(0, to + 1),
                between = arr.slice(to + 1, from),
                end = arr.slice(from + 1);
            let data = {
                tasklistId: taskLists[to].id,
                taskId: taskLists[from].id,
                belowTaskId: null,
            }
            taskOrderRequest(data);
            return [...start, ...between, ...end];
        }
        return arr;
    }

    const reOrderRow = (from, to) => {
        let newtableData;
        if (!taskLists[to].isSubTask && openedTasklists.includes(taskLists[to].id)===false) {
            setIdForDisplayLoader(taskLists[to].id);
            setIsShowLoaderFotFetchTasks(true);
            newtableData = offsetIndexIfTasklistNotOpen(from, to, taskLists);
            getAllTasks(taskLists[to].id, "forReOrder", taskLists[from], newtableData).then((res) => {
                setSelectedRow(taskLists[from].id)
                setIdForDisplayLoader(null);
                setIsShowLoaderFotFetchTasks(false);
                let data = Object.assign([], openedTasklists)
                data.push(taskLists[to].id)
                setOpenedTasklists(data)
                
            });

        } else {
            newtableData = offsetIndex(from, to, taskLists);
        }

        //Update react state
        if (taskLists[from].isSubTask && taskLists[to].isSubTask) {
            reOrderTaskUsingRedux(newtableData)
        } else {
            if (myTasksVisible) {} 
            else {
                reOrderTaskUsingRedux(newtableData)
            }
        }
        setIsRowDrag(false)
    };


    // reordering task
    const orderingRowHandler = (listName, taskObj, overlapTaskObj, isTasklistOpen) => {
        if (listName === "task") {
            if (isTasklistOpen) {

            } else {
                if (taskObj && overlapTaskObj && taskObj.tasklistId && overlapTaskObj.tasklistId && taskObj.tasklistId === overlapTaskObj.tasklistId) {
                    let data = {
                        tasklistId: taskObj.tasklistId,
                        taskId: taskObj.id,
                        belowTaskId: overlapTaskObj.id
                    }
                    taskOrderRequest(data)
                } else if (taskObj && overlapTaskObj && taskObj.tasklistId && overlapTaskObj.tasklistId && taskObj.tasklistId !== overlapTaskObj.tasklistId) {
                    let data = {
                        tasklistId: overlapTaskObj.tasklistId,
                        taskId: taskObj.id,
                        belowTaskId: overlapTaskObj.id
                    }
                    taskOrderRequest(data)
                } else if (taskObj && overlapTaskObj && !overlapTaskObj.tasklistId && overlapTaskObj.id === taskObj.tasklistId) {
                    let data = {
                        tasklistId: taskObj.tasklistId,
                        taskId: taskObj.id,
                        belowTaskId: null
                    }
                    taskOrderRequest(data)
                } else if (taskObj && overlapTaskObj && !overlapTaskObj.tasklistId && overlapTaskObj.id !== taskObj.tasklistId) {
                    let data = {
                        tasklistId: overlapTaskObj.id,
                        taskId: taskObj.id,
                        belowTaskId: null
                    }
                    taskOrderRequest(data)
                }
            }
        }

    }

    /*
    for icon  status wise rendering
    @param status
   */
    const renderIconsAsPerStatus = (status) => {
        let icon;
        switch (status) {
            case "todo":
                icon = (<span className="for-padding-right for-font-size" style={{ color: CustomTheme.not_statred_status }}>
                    <i className='far fa-circle' title='Not Started'></i>
                </span>)
                break;
            case "in_progress":
                icon = (<span className="for-padding-right for-font-size" style={{ color: CustomTheme.in_progress_status }}>
                    <i className='far fa-play-circle' title='In progress'></i>
                </span>)
                break;
            case "stopped":
                icon = (<span className="for-padding-right for-font-size" style={{ color: CustomTheme.stoppped_status }}>
                    <i className='far fa-pause-circle' title='Stopped'></i>
                </span>)
                break;
            case "blocked":
                icon = (<span className="for-padding-right for-font-size" style={{ color: CustomTheme.blockked_status }}>
                    <i className='fas fa-ban' title='Blocked'></i>
                </span>)
                break;
            case "complete":
                icon = (<span className="for-padding-right for-font-size" style={{ color: CustomTheme.complete_status }} >
                    <i className='far fa-check-circle' title='Complete'></i>
                </span>)
                break;
            case "deferred":
                icon = (<span className="for-padding-right for-font-size" style={{ color: CustomTheme.deferred_status }}>
                    <i className='fas fa-stop-circle' title='Deferred'></i>
                </span>)
                break;
            case "ready_for_review":
                icon = (<span className="for-padding-right for-font-size" style={{ color: CustomTheme.ready_for_review }}>
                    <i className='fas fa-check-double' title='Ready for Review'></i>
                </span>)
                break;
            default:
            // code block
        }
        return icon
    }

    const columns = [

        {
            field: 'task',
            title: 'Task',
            filterPlaceholder: 'Task',
            cellStyle: {
                width: '30%',
            },
            headerStyle: {
                borderRadius: '5px 0rem 0rem 0rem',
            },
            render: ({ milestoneId, milestone, isTasklist, title, color, id, isSubTask, status }) => {
                return (
                    <Box className={classes.taskContainer}>
                        {renderColorDot(color)}
                        <>
                            {
                                isSubTask && isSubTask
                                    ?
                                    <>
                                        {
                                            myTasksVisible
                                                ?
                                                <></>
                                                :
                                                <span className="padding-right-5 visibility-hidden">
                                                    <i className='fas fa-flag' />
                                                </span>
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            milestoneId && milestoneId
                                                ?
                                                <span
                                                    className={`${isShowLoaderFotFetchTasks && idForDsiplalyLoader === id ? title.length > 18 ? '' : 'padding-top-8' : ''} milestone-flag-icon`}
                                                >
                                                    <Tooltip title={milestone && milestone.title ? milestone.title : ''}
                                                        placement="top">
                                                        <span className="padding-right-5">
                                                            <i className='fas fa-flag' />
                                                        </span>
                                                    </Tooltip>
                                                </span>
                                                :
                                                <span className="padding-right-5 visibility-hidden">
                                                    <i className='fas fa-flag' />
                                                </span>
                                        }
                                    </>
                            }
                        </>
                        {
                            !myTasksVisible
                                ?
                                <div className="for-display-flex">
                                    <Tooltip title={title.length > 49 ? title : ''} placement="top">
                                        <Typography
                                            className={`${isShowLoaderFotFetchTasks && idForDsiplalyLoader === id ? title.length > 18 ? '' : 'padding-top-8' : ''} for-display-flex `}
                                            variant="body1"
                                        >
                                            {renderIconsAsPerStatus(status)}
                                            <span className="overflow-styling">{title}</span>
                                        </Typography>
                                    </Tooltip>
                                    <span className="save-and-edit-loader">
                                        {
                                            isShowLoaderFotFetchTasks && idForDsiplalyLoader === id && (
                                                <LoaderForSaveAndEdit />
                                            )
                                        }
                                    </span>
                                </div>
                                :
                                <div className="for-display-flex">
                                    <Tooltip title={title.length > 49 ? title : ''} placement="top">
                                        <Typography
                                            variant="body1"
                                            className="for-display-flex"
                                        >
                                            {renderIconsAsPerStatus(status)}
                                            <span className="overflow-styling">{title}</span>
                                        </Typography>
                                    </Tooltip>
                                </div>
                        }

                    </Box>
                );
            },
        },
        {
            field: 'assignee',
            title: 'Assignee',
            filterPlaceholder: 'Assignee',
            cellStyle: {
                width: '17%',
            },
            render: ({ assignee }) => {
                return (
                    <>
                        {myTasksVisible === false && assignee && assignee.length >= 3 ? (
                            <Box>
                                {assignee && assignee.map(user => {
                                    return (
                                        <>
                                            <img
                                                src='https://ui-avatars.com/api/?name=A&background=random'
                                                alt='profile'
                                                className='task-user-image'
                                            />
                                        </>
                                    );
                                })}
                                {assignee.length > 2 ? (
                                    <Typography variant='h5' className='user-count-assignee'>
                                        2
                                    </Typography>
                                ) : (
                                    <></>
                                )}
                            </Box>
                        ) : assignee && assignee.length > 1 && assignee.length <= 2 ? (
                            <Box className='user-if-one'>
                                {assignee.map(user => {
                                    return (
                                        <img
                                            src='https://ui-avatars.com/api/?name=A&background=random'
                                            alt='profile'
                                            className='task-user-image'
                                        />
                                    );
                                })}
                            </Box>
                        ) : assignee && assignee.length === 1 ? (
                            <Box className='user-if-one'>
                                {assignee.map(user => {
                                    return (
                                        <>
                                            <img
                                                src='https://ui-avatars.com/api/?name=A&background=random'
                                                alt='profile'
                                                className='task-user-image'
                                            />
                                            <span
                                                className='user-name-in-assignee margin-left-3'
                                            >
                                                {user.userName}
                                            </span>
                                        </>
                                    );
                                })}
                            </Box>
                        ) : (
                            <></>
                        )}
                    </>
                );
            },
        },
        {
            field: 'priority',
            title: 'Priority',
            filterPlaceholder: 'Priority',
            cellStyle: {
                width: '14%',
            },
            render: ({ priority }) => {
                switch (priority) {
                    case 1:
                        return <span><i className='fas fa-angle-up' /></span>;

                    case 2:
                        return <span><i className='fas fa-angle-double-up' /></span>;

                    case 3:
                        return <span><i className='fas fa-angle-double-up' /></span>;

                    default:
                        return <span></span>;

                }
            },
        },
        {
            field: 'dueDate',
            title: 'Due Date',
            filterPlaceholder: 'Due Date',
            cellStyle: {
                width: '14%',
            },
            render: ({ dueDate }) => {
                return (
                    <>
                        {dueDate && dueDate ? <Typography variant='body1'>{moment(dueDate).format('DD/MM/YYYY')}</Typography> :
                            <Typography variant='body1'></Typography>}
                    </>
                );
            },
        },
        {
            field: 'status',
            title: 'Status',
            filterPlaceholder: 'Status',
            cellStyle: {
                width: loggedInUser.userRoles.isProjectMember ? '16%' : '11%',
            },
            headerStyle: {
                borderRadius: '0px 5px 0px 0px',
            },
            render: ({ status }) => {
                return (
                    <Typography
                        variant="body1"
                        className={
                            status === 'Pending'
                                ? classes.statusGreen
                                : status === 'Done'
                                    ? classes.statusOrange
                                    : status === 'todo'
                                        ? classes.statusUnknown
                                        : ''
                        }
                    >
                        <i className='fas fa-circle' />
                    </Typography>
                );
            },
        },
        {
            field: '',
            title: '',
            cellStyle: {
                width: '5%',
            },
            hidden: !loggedInUser.userRoles.isSystemSuperAdmin && !loggedInUser.userRoles.isTeamLead,
            headerStyle: {
                borderRadius: '0px 5px 0px 0px',
            },
            render: rowData => {
                if (rowData.isSubTask) {
                    return <></>;
                } else {
                    return (
                        rowData && !myTasksVisible ?
                            <span
                                className="table-status-add-icon"
                                style={{ color: CustomTheme.primaryColor }}
                                onClick={(event) => {
                                    showTaskHandler(event);
                                    showAddUpdateForm(null, 'task', rowData.id);
                                    setSelectedRow(null)
                                }}
                            >
                                <i className='fas fa-plus-circle' />
                            </span>
                            :
                            <></>

                    );
                }
            },
        },
    ];


    if (!myTasksVisible) {
        columns.splice(0, 0,
            {
                field: '',
                title: '',
                cellStyle: {
                    width: '8%',
                },
                headerStyle: {
                    borderRadius: '5px 0rem 0rem 0rem',
                },
                render: rowData => {
                    if (rowData.isSubTask) {
                        return (
                            <>
                                {
                                    !myTasksVisible
                                        ?
                                        <div className="drag-icon-div">
                                            <span className="drag-icon" onMouseDown={() => setIsRowDrag(true)}><i
                                                className="fas fa-grip-vertical"></i></span>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                        )
                    } else {
                        return (
                            <div className="for-display-flex">
                                {/* {
                                    hoveringOver===rowData.tableData.id
                                    ?
                                    <span onMouseDown={()=>setIsRowDrag(true)} style={{paddingRight:'5px'}}><i className="fas fa-grip-vertical"></i></span>
                                    :
                                    ''
                                } */}
                                {
                                    !myTasksVisible ? (
                                        openedTasklists.includes(rowData.id)? (
                                            <span>
                                                <i className='fas fa-caret-down' />
                                            </span>
                                        ) : (
                                            <span>
                                                <i className='fas fa-caret-right' />
                                            </span>
                                        )
                                    ) : (
                                        <span>
                                            <i className='fas fa-flag' />
                                        </span>
                                    )
                                }
                            </div>
                        );
                    }
                },
            },
        )
    } else {
    }

    const renderColorDot = (color) => {
        switch (color) {
            case 'green':
                return (
                    <span className={`${classes.taskIcon} ${classes.green}`}>
                        <i className='far fa-check-circle' />
                    </span>
                );

            case 'red':
                return (
                    <span className={`${classes.taskIcon} ${classes.red}`}>
                        <i className='far fa-stop-circle' />
                    </span>
                );

            case 'blue':
                return (
                    <span className={`${classes.taskIcon} ${classes.blue}`}>
                        <i className='far fa-play-circle' />
                    </span>
                );

            case 'yellow':
                return (
                    <span className={`${classes.taskIcon} ${classes.orange}`}>
                        <i className='far fa-pause-circle' />
                    </span>
                );

            case 'blank':
                return (
                    <span className={`${classes.taskIcon}`}>
                        <i className='far fa-circle' />
                    </span>
                );

            default:
                return <span></span>;
        }
    }


    const redirectToProjectList = () => {
        history.replace('/projects/teams');
    }

    const handleToggle = (e) => {
        e.stopPropagation()
        setIsMenuOpen((prevOpen) => !prevOpen);
        setShowedImportMemu(!showedImportMemu)
    };

    const tableTitle = () => {
        return (
            <Grid style={{ borderBottom: `1px dashed ${CustomTheme.primaryColor}` }} container spacing={0}
                className='table-title'>
                <Grid className="width-100-per">
                    <Box className="margin-top-5">
                        <Tooltip title="Back">
                            <span style={{ color: CustomTheme.primaryColor }}
                                className="template-back-icon tasklist-back-icon"
                                onClick={redirectToProjectList}> <span className="back-btn-styling-forvertical-align"><i
                                    style={{ fontSize: '20px' }} className="far fa-arrow-alt-circle-left" /></span> </span>
                        </Tooltip>
                        <span style={{ color: CustomTheme.primaryColor, fontWeight: 300 }}
                            className={` ${myTasksVisible ? 'active-tasks-tab' : ''} mytask-style`}
                            onClick={() => {
                                setMyTasksVisible(true)
                                setSelectedRow('')
                            }}
                        >
                            My Tasks
                        </span>
                        <span style={{ color: CustomTheme.primaryColor, fontWeight: 300 }}
                            className={` ${!myTasksVisible ? 'active-tasks-tab' : ''} all-style`}
                            onClick={() => {
                                setMyTasksVisible(false)
                                setSelectedRow('')
                            }}
                        >
                            All Tasks
                        </span>
                        {
                            loggedInUser.userRoles.isSystemSuperAdmin || loggedInUser.userRoles.isTeamLead
                                ? (
                                    <>
                                        <Button size="small" variant="outlined" color="primary"
                                            onClick={() => {
                                                showAddUpdateForm(null, 'taskList');
                                            }}
                                            className="project-blank-page-button-tasklist float-right"
                                        >
                                            <span className="style-for-table-header-icons">
                                                <i className="fas fa-list-alt" />
                                            </span>
                                            <span style={{ borderRight: `1px solid ${CustomTheme.primaryColor}` }}
                                                className="btn-margin-left for-border-right">
                                                <span className="table-header-icon-title for-padding-right">
                                                    +Tasklist
                                                </span>
                                            </span>
                                            {
                                                showedImportMemu
                                                    ?
                                                    <span
                                                        ref={anchorRef}
                                                        className="clickbale-icon"
                                                        aria-controls={isMenuOpen ? "menu-list-grow" : undefined}
                                                        aria-haspopup="true"
                                                        onClick={(e) => handleToggle(e)}
                                                    >
                                                        <i className="fas fa-angle-up" />
                                                    </span>
                                                    :
                                                    <span
                                                        ref={anchorRef}
                                                        className="clickbale-icon"
                                                        aria-controls={isMenuOpen ? "menu-list-grow" : undefined}
                                                        aria-haspopup="true"
                                                        onClick={(e) => handleToggle(e)}
                                                    >
                                                        <i className="fas fa-angle-down" />
                                                    </span>
                                            }
                                        </Button>
                                    </>
                                )
                                : <></>
                        }
                    </Box>
                </Grid>
                <Grid>
                </Grid>
            </Grid>
        );
    };



    return (
        <TableContainer className="for-box-shadow">
            {tableTitle()}
            <MaterialTable
                icons={{ Filter: () => <div /> }}
                columns={columns}
                data={taskLists || []}
                options={{
                    padding: 'dense',
                    header: false,
                    toolbar: false,
                    filtering: true,
                    paging: false,
                    sorting: false,
                    emptyRowsWhenPaging: false,
                    draggable: false,
                    detailPanelColumnStyle: {
                        display: 'none',
                    },
                    headerStyle: {
                        borderBottom: '1px dashed #c5e2ff',
                        fontSize: '14px',
                        fontWeight: '600',
                        height: '40px',
                    },
                    rowStyle: rowData => {
                        return {
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#77797c',
                            borderBottom: '1px solid #f2f6fa',
                            padding: '10px',
                            lineHeight: '24px',
                            height: '50px',
                            backgroundColor: (selectedRow === rowData.id) ? '#0faeec17' : '#FFF'
                        };
                    },
                }}
                style={{
                    boxShadow: 'none',
                    borderRadius: '5px',
                    paddingBottom: '0.8rem',
                    color: '#77797c',
                }}
                components={{
                    Row: props => {
                        return (
                            <MTableBodyRow
                                {...props}
                                draggable={isRowDrag ? "true" : 'false'}
                                onDragStart={(e) => {
                                    dragState.row = props.data.tableData.id;
                                }}
                                onDragEnter={e => {
                                    e.preventDefault();
                                    if (props.data.tableData.id !== dragState.row) {
                                        dragState.dropIndex = props.data.tableData.id;
                                    }
                                }}
                                onDragEnd={(e) => {
                                    if (dragState.dropIndex !== -1) {
                                        reOrderRow(dragState.row, dragState.dropIndex);
                                    }
                                    dragState.row = -1;
                                    dragState.dropIndex = -1;
                                }}
                            />
                        )
                    },
                }}
                onRowClick={(e, rowData) => {
                    if (rowData.isSubTask) {
                        setSelectedRow(rowData.id)
                        showTaskDetailsPane(rowData.id, "task");
                    } else {
                        setSelectedRow(rowData.id)
                        showTaskDetailsPane(rowData.id, myTasksVisible ? "" : "taskList");

                        if (!myTasksVisible) {
                            if (openedTasklists.includes(rowData.id) && openedTasklists.includes(rowData.id) === true) {
                                setIdForDisplayLoader(rowData.id)
                                setIsShowLoaderFotFetchTasks(true)

                                getAllTasks(rowData.id, "forTasklistClose").then((res) => {
                                    setIdForDisplayLoader(null);
                                    setIsShowLoaderFotFetchTasks(false);
                                    let data = Object.assign([], openedTasklists)
                                    let filterArrayForFindIndex = data.findIndex((id)=>id === rowData.id)
                                    data.splice(filterArrayForFindIndex, 1)
                                    setOpenedTasklists(data)
                                });
                            } else {
                                setIdForDisplayLoader(rowData.id)
                                setIsShowLoaderFotFetchTasks(true)

                                getAllTasks(rowData.id, "forTasklistOpen").then((res) => {
                                    setIdForDisplayLoader(null);
                                    setIsShowLoaderFotFetchTasks(false);
                                    if (res.length > 0) {
                                        let data = Object.assign([], openedTasklists)
                                        data.push(rowData.id)
                                        setOpenedTasklists(data)
                                    }
                                });
                            }
                        } else {
                        }
                    }
                }}
            />
            {isImportTasklistOpenDialog &&
                <ImportTasklisDialog
                    openDialog={isImportTasklistOpenDialog}
                    closeDialog={setIsImportTasklistOpenDialog}
                    setAddUpdateRes={setAddUpdateRes}
                    setMyTasksVisible={setMyTasksVisible}
                    showTaskDetailsPane={showTaskDetailsPane}
                />
            }
            {isMenuOpen &&
                <ImportTasklistMenu
                    setShowedImportMemu={setShowedImportMemu}
                    openMenu={isMenuOpen}
                    closeMenu={setIsMenuOpen}
                    anchorRef={anchorRef}
                    showedImportMemu={showedImportMemu}
                    openImportDialog={setIsImportTasklistOpenDialog}
                />
            }

        </TableContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
        taskLists: state.tasks.taskLists
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllTasks: (taskId, type, from, newtableData) => {
            return dispatch(getAllTasksRequest(taskId, type, from, newtableData)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        taskOrderRequest: (data) => {
            return dispatch(taskOrderRequestProject(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllTasksOfUser: (projectId, loggedInUserId) => {
            return dispatch(getMyAllTasksOfLoggedInUser(projectId, loggedInUserId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllTaskLists: (projectId, isResStoringInRedux, data) => {
            return dispatch(getAllTaskListsRequest(projectId, isResStoringInRedux, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        reOrderTaskUsingRedux: (data) => {
            return dispatch(reOrderTaskRowUsingRedux(data))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskList);
