import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {connect} from 'react-redux';
import {Box, Radio, Typography} from '@material-ui/core';
import MaterialTable from 'material-table';
import {useStyles} from './styles';

import {getAllProjectTemplatesRequest} from '../../../../../../configuration/ConfigurationApiActions';

const TemplateForm = forwardRef((props, ref) => {
    const classes = useStyles();
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateList, setTemplateList] = useState([]);
    useEffect(() => {
        props.getAllProjectTemplates().then((res) => {
            setTemplateList(res);
        })
    }, []);

    const columns = [
        {
            field: 'name',
            title: 'Name',
            cellStyle: {
                width: '40%',
            },
            render: ({name}) => {
                return (
                    <Typography variant="body1">{name}</Typography>
                );
            },
            headerStyle: {},
        },
        {
            field: 'metaInfo',
            title: 'Description',
            cellStyle: {
                width: '50%',
            },
            render: ({metaInfo}) => {
                return (
                    <Typography variant="body1">{metaInfo}</Typography>
                );
            },
            headerStyle: {},
        },
        {
            field: '',
            title: 'Select',
            cellStyle: {
                width: '10%',
            },
            headerStyle: {},
            render: ({id}) => {
                return (
                    <>
                        <Radio
                            onClick={() => {
                                if (id === selectedTemplate) {
                                    setSelectedTemplate(null);
                                }
                            }}
                            onChange={() => {
                                if (id !== selectedTemplate) {
                                    setSelectedTemplate(id);
                                }
                            }}
                            checked={id === selectedTemplate}
                        />
                    </>
                );
            },
        },
    ];

    useImperativeHandle(ref, () => ({
        isValidated: () => {
            return true;
        },
        state: {
            selectedTemplate,
        },
    }));
    return (
        <>
            <Box className={classes.titleContainer}>
                <Typography variant='h2' className={classes.titleLabel}>
                    Let's choose a project template
                </Typography>
            </Box>

            <Box className={classes.templateListContainer}>
                <MaterialTable
                    columns={columns}
                    data={templateList}
                    options={{
                        toolbar: false,
                        paging: false,
                        sorting: false,
                        emptyRowsWhenPaging: false,
                        draggable: false,
                        detailPanelColumnStyle: {
                            display: 'none',
                        },
                        headerStyle: {
                            borderBottom: '1px dashed #77797c',
                            fontSize: '16px',
                            fontWeight: '600',
                            height: '40px',
                        },
                        rowStyle: {
                            fontSize: '14px',
                            color: '#77797c',
                        },
                    }}
                    style={{
                        boxShadow: 'none',
                        borderRadius: '5px',
                        paddingBottom: '0.8rem',
                    }}
                />
            </Box>
        </>
    );
});

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllProjectTemplates: () => {
            return dispatch(getAllProjectTemplatesRequest()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef: true}
)(TemplateForm);
