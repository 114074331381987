import React from 'react';
import { Box, Typography, Card, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { useStyles } from './styles';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';

const ActionStationLists = ({setSelctedCard, selectedCard, dayList }) => {
    const classes = useStyles();
    return (
        <>
            {
                dayList && dayList.tasks && dayList.tasks.map((listData, i) => {
                    return (
                        <Card className={classes.cardStyle} key={i} onClick={() => setSelctedCard(listData.task.id)} style={{ backgroundColor: selectedCard === listData.task.id ? '#ffff5294' : '' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Grid container>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                        <Box className={classes.forDisplayFlex}>
                                            <span className={classes.forPaddingRight5}><Avatar className={classes.projectNameAvatar}>{listData.projectName.charAt(0).toUpperCase()}</Avatar> </span>
                                            <Typography variant="h4" className={classes.projectName}> {listData.projectName}</Typography>
                                        </Box>
                                        <Box className={`${classes.forDisplayFlex} ${classes.forMarginTopSecondLine}`}>
                                            <span className={classes.duedateIcon} > <i className="fas fa-calendar-alt"></i></span>
                                            <Typography className={classes.paddingLeft5} variant="h4"> Due: {moment(listData.task.dueDate).format('DD/MM/YYYY')}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                        <Box className={classes.forDisplayFlex}>
                                            <span className={classes.iconColor} ><i className="fas fa-tasks" /></span><Typography className={`${classes.paddingTop2} ${classes.paddingLeft5}`} variant="h4"> Task</Typography>
                                        </Box>
                                        <Box className={`${classes.forDisplayFlex} ${classes.forMarginTopSecondLine}`}>
                                            <span className={`${classes.tasklistIconTitle} ${classes.iconColor}`}><i className="fas fa-list" /></span><Typography className={classes.paddingLeft5} variant="h4"> Tasklist</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.titlesContainer}>
                                        <Box>
                                            <Typography variant="h4" className={classes.listName}> {listData.task.title}</Typography>
                                        </Box>
                                        <Box className={classes.fromListNameMarginTop}>
                                            <Typography className={`${classes.paddingLeft5} ${classes.paddingTop4}`} variant="h4">{listData.tasklistName}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={`${classes.forDisplayFlex} ${selectedCard === listData.task.id ? `${classes.actionIconContainer} ${classes.actionContainerBorderLeft}` : classes.actionIconContainer}`}>
                                        <span className={`${classes.actionIcon} ${classes.checkCircleIconColor}`}><i className="far fa-check-circle" /></span>
                                        <span className={`${classes.actionIcon} ${classes.paddingLeft10}  ${selectedCard === listData.task.id ? classes.snoozeIconColorGray : classes.snoozeIcon}`}><i className="far fa-clock" /></span>
                                        <span className={`${classes.actionIcon} ${classes.paddingLeft10} ${classes.userIcon}`}><i className="far fa-user-circle" /></span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    )
                })
            }
            {
                dayList && dayList.milestones && dayList.milestones.map((listData, i) => {
                    return (
                        <Card className={classes.cardStyle} key={i} onClick={() => setSelctedCard(listData.milestone.id)} style={{ backgroundColor: selectedCard === listData.milestone.id ? '#ffff5294' : '' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Grid container>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                        <Box className={classes.forDisplayFlex}>
                                            <span className={classes.forPaddingRight5}><Avatar className={classes.projectNameAvatar}>{listData.projectName.charAt(0).toUpperCase()}</Avatar> </span>
                                            <Typography variant="h4" className={classes.projectName}> {listData.projectName}</Typography>
                                        </Box>
                                        <Box className={`${classes.forDisplayFlex} ${classes.forMarginTopSecondLine}`}>
                                            <span className={classes.duedateIcon} > <i className="fas fa-calendar-alt"></i></span>
                                            <Typography className={classes.paddingLeft5} variant="h4"> Due: {moment(listData.milestone.dueDate).format('DD/MM/YYYY')}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                        <Box className={classes.forDisplayFlex}>
                                            <span className={classes.iconColor} ><i className="fas fa-flag" /></span><Typography className={`${classes.paddingLeft5} ${classes.paddingTop2} ${classes.marginTop1}`} variant="h4"> Milestone</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.titlesContainer}>
                                        <Box>
                                            <Typography variant="h4" className={classes.listName}> {listData.milestone.title}</Typography>
                                        </Box>
                                        {/* <Box className={classes.fromListNameMarginTop}>
                                            <Typography className={classes.paddingLeft5} variant="h4"> {listData.listDescription}</Typography>
                                        </Box> */}
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={`${classes.forDisplayFlex} ${selectedCard === listData.milestone.id ? `${classes.actionIconContainer} ${classes.actionContainerBorderLeft}` : classes.actionIconContainer}`}>
                                        <span className={`${classes.actionIcon} ${classes.checkCircleIconColor}`}><i className="far fa-check-circle" /></span>
                                        <span className={`${classes.actionIcon} ${classes.paddingLeft10}  ${selectedCard === listData.milestone.id ? classes.snoozeIconColorGray : classes.snoozeIcon}`}><i className="far fa-clock" /></span>
                                        <span className={`${classes.actionIcon} ${classes.paddingLeft10} ${classes.userIcon}`}><i className="far fa-user-circle" /></span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    )
                })
            }
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionStationLists);