import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useStyles } from './styles';
import AppHeader from '../../../userDashboard/components/AppHeader/AppHeader';
import ActionStationHeader from '../ActionStationHeader/ActionStationHeader'
import ActionStationLists from './components/ActionStationLists/ActionStationLists'
import { Box, Grid, Typography, Card } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import DateRangePickerDailog from './components/DateRangPickerDialog/DateRangePickerDailog';
import {
    getMyTasksOfUserInActionStation
} from '../../ActionStationApiActions'
import { getAllProjectsRequest, getProjectOfUserRequest } from '../../../projects/ProjectsApiActions';
import { getAllMilestoneRequest } from '../../../milestones/MilestonesApiActions'
import moment from 'moment';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import NoDataFound from './components/NoDataFound/NoDataFound'
const MyTasks = ({
    loggedInUser, handleDrawerOpenClose, history, match, getTasksOfUser,
    tasksOfUser, getAllProjects, getProjectsOfUser, projectsOfUser, getAllMilestones
}) => {
    const classes = useStyles();

    const [selectedCard, setSelctedCard] = useState(null)
    const [selectedProjectMilestoneList, setSelectedProjectMilestoneList] = useState([])

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDateRangeBox, setOpenDateRangeBox] = useState(false);
    const [filterParams, setFilterParams] = useState({
        startDate: null,
        endDate: null,
        project: null,
        milestone: null
    })

    useEffect(() => {
        getTasksOfUser(loggedInUser.id, filterParams).then((res) => {
            setSelctedCard(null)
            setOpenDateRangeBox(false)
            getAllProjects(false);
            getProjectsOfUser(false)
            if (filterParams.project !== null) {
                getAllMilestones(filterParams.project.id).then((res) => {
                    setSelectedProjectMilestoneList(res)
                })
            } else { }
        })
    }, [filterParams])

    // useEffect(()=>{
    //     getAllProjects(false);
    //     getProjectsOfUser(false)
    // },[])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenDateRangeBox(!openDateRangeBox);
    };

    const titleHandler = (title) => {
        switch (title) {
            case 'today':
                return title.toUpperCase()

            case 'tomorrow':
                return title.toUpperCase()

            case 'week':
                return title.toUpperCase()

            default:
                return title
        }
    }

    return (
        <>
            <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                <ActionStationHeader history={history} />
            </AppHeader>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                <ValidatorForm
                    onSubmit={console.log()}
                    noValidate
                    onError={errors => console.log(errors)}
                >
                    <Box className={`${classes.filterBox}`}>
                        <Typography className={classes.filterTasksBy} variant="h4" >Filter tasks by</Typography>
                        <Box ref={anchorEl} className={classes.filterBorder} onClick={(e) => handleClick(e)} >
                            <Typography className={classes.dateRangeBoxValue} >
                                {filterParams && filterParams.startDate ? `${moment(filterParams.startDate).format('DD/MM/YYYY')} to ${moment(filterParams.endDate).format('DD/MM/YYYY')}` : <span className={classes.dateRangeTitleColor}>Date Range</span>}
                                {filterParams && filterParams.startDate && <span><CloseRoundedIcon className={classes.closeIcon} onClick={(e) => {
                                    e.stopPropagation()
                                    setOpenDateRangeBox(false)
                                    setFilterParams({ ...filterParams, startDate: null, endDate: null })
                                }}
                                />
                                </span>
                                }
                            </Typography>
                        </Box>
                        <Box className={classes.filterBorderForTasklist}>
                            <Autocomplete
                                fullWidth
                                className={classes.forPaddingLeft}
                                value={filterParams.project}
                                onChange={(e, value) => {
                                    setFilterParams({
                                        ...filterParams,
                                        project: value,
                                    })
                                }}
                                options={projectsOfUser || []}
                                getOptionLabel={option => option.name}
                                getOptionSelected={(option, value) =>
                                    option.id === value.id
                                }
                                renderInput={params => (
                                    <TextValidator placeholder="Project" {...params} />
                                )}
                            />
                        </Box>
                        <Box className={classes.filterBorderForMilestone}>
                            <Autocomplete
                                fullWidth
                                disabled={filterParams.project !== null ? false : true}
                                className={classes.forPaddingLeft}
                                value={filterParams.milestone || ''}
                                onChange={(e, value) => {
                                    setFilterParams({
                                        ...filterParams,
                                        milestone: value
                                    })
                                }}
                                options={selectedProjectMilestoneList || []}
                                getOptionLabel={option => option.title}
                                getOptionSelected={(option, value) =>
                                    option.id === value.id
                                }
                                renderInput={params => (
                                    <TextValidator placeholder="Milestone" {...params} />
                                )}
                            />
                        </Box>
                    </Box>
                    <Button type="submit" ></Button>
                </ValidatorForm>
            </Grid>
            {
                Object.entries(tasksOfUser).map(listOfTasks => {
                    return (
                        <>
                            <Typography variant="body2" className={classes.listTitle}>{titleHandler(listOfTasks[0])}</Typography>
                            <Box>
                                {
                                    listOfTasks[1].milestones.length === 0 && listOfTasks[1].tasks.length === 0
                                        ?
                                        <Card className={classes.cardStyle} ><Typography variant="h4" >No tasks found</Typography></Card>
                                        :
                                        <ActionStationLists selectedCard={selectedCard} setSelctedCard={setSelctedCard} dayList={listOfTasks[1]} />
                                }
                            </Box>
                        </>
                    )
                })
            }
            {
                openDateRangeBox
                    ?
                    <DateRangePickerDailog popperOpen={openDateRangeBox} anchorEl={anchorEl} filterParams={filterParams} setFilterParams={setFilterParams} />
                    :
                    <></>
            }
        </>
    );
};
const mapStateToProps = (state) => {
    let teamDetails = state.projects.projects && state.projects.projects.map((teamInfo) => {
        return {
            ...teamInfo, projects: teamInfo.projects.filter((projectInfo) => {
                return state.projects.projectsOfTeamLeadAndProjectMember && state.projects.projectsOfTeamLeadAndProjectMember.find((id) => {
                    return projectInfo.id === id
                })
            })
        }
    })
    let userProjects = []
    teamDetails && Object.entries(teamDetails).map((details) => {
        details[1].projects && details[1].projects.map((project) => {
            userProjects.push({ id: project.id, name: project.name })
        })
    })
    return {
        loggedInUser: state.auth.loggedInUser,
        tasksOfUser: state.actionStation.myTasksOfUser,
        projectsOfUser: userProjects
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTasksOfUser: (userId, filterParams) => {
            return dispatch(getMyTasksOfUserInActionStation(userId, filterParams)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllProjects: (isLoaderShow) => {
            return dispatch(getAllProjectsRequest(isLoaderShow)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getProjectsOfUser: (isLoaderShow) => {
            return dispatch(getProjectOfUserRequest(isLoaderShow)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllMilestones: (projectId) => {
            return dispatch(getAllMilestoneRequest(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyTasks);