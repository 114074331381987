import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import ChipInput from 'material-ui-chip-input';
import moment from 'moment';
import { Box, Grid, Tab, Tooltip, Typography, Card } from '@material-ui/core';
import { useStyles } from './styles';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import TimeLine from './components/TimeLine/TimeLine';
// import Conversations from './components/Conversations/Conversations';
// import Documents from './components/Documents/Documents';
import Effort from './components/Effort/Effort';
import './TaskDetails.css';
import {
    getTaskDetailsByIdRequest,
    getTaskListDetailsByIdRequest,
    updateTaskRequest,
    deleteTaskRequestProject
} from '../../TasksApiActions';
import ChecklistItemsDraggableDialog
    from '../../../../../../../../../utilities/ChecklistItemsDraggableDialog/ChecklistItemsDraggableDialog';
import CustomTheme from '../../../../../../../../../utilities/theme/theme.json'
import LoaderForSaveAndEdit from '../../../../../../../../../utilities/AppLoader/LoaderForSaveAndEdit'
import MessageModal from '../../../../../../../../../utilities/MessageModal/MessageModal'
const TaskDetails = ({
    updateTask,
    setRightPane,
    taskId,
    showAddUpdateForm,
    loggedInUser,
    getTaskListDetailsById,
    getTaskDetailsById,
    deleteTaskRequest
}) => {
    const classes = useStyles();
    const modalRef = useRef();
    const [openChecklistItemsDialog, setOpenChecklistItemsDialog] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [tasklist, setTasklist] = useState(null);
    // const [task, setTask] = useState(null);
    const [timeEffort, setTimeEffort] = useState(null);
    const [taskDetailsList, setTaskDetailsList] = useState()
    const [isLoaderShowing, setIsLoaderShowing] = useState(false);

    useEffect(() => {
        setIsLoaderShowing(true)
        getTaskDetailsById(taskId).then((res) => {
            setTaskDetailsList(res)
            setIsLoaderShowing(false)
        })
    }, [taskId])
    useEffect(() => {
        if (taskDetailsList && taskDetailsList.assignee && taskDetailsList.assignee.length > 0) {
            let timeEffort = null;
            taskDetailsList && taskDetailsList.assignee.forEach((role) => {
                if (role.timeEffort) {
                    timeEffort = timeEffort + parseFloat(role.timeEffort);
                }
            });
            setTimeEffort(timeEffort);
        }
    }, [taskDetailsList]);

    const renderPriority = (priority) => {
        let priorityValue = "";
        switch (priority) {
            case 1:
                priorityValue = "High";
                break;
            case 2:
                priorityValue = "Medium";
                break;
            case 3:
                priorityValue = "Low";
                break;
            default:
            // code block
        }
        return priorityValue;
    }

    useEffect(() => {
        if (taskDetailsList && taskDetailsList.tasklistId) {
            getTaskListDetailsById(taskDetailsList.tasklistId).then((res) => {
                setTasklist(res);
            })
        } else {
        }
        if (taskDetailsList && taskDetailsList.predecessorId) {
            getTaskDetailsById(taskDetailsList.predecessorId).then((res) => {
                // setTask(res);
            })
        } else {
        }
    }, [taskDetailsList])

    const updateTaskFromReadView = (formData, message) => {
        updateTask(taskDetailsList.id, formData, true, message).then((res) => {
            getTaskDetailsById(res.id).then((taskRes) => {
                // setTaskDetailsList(taskRes)
                setTaskDetailsList(taskRes)
            })
        })
    }
    /**
     * for showing color to the status
     * @param status
     */
    const forStatusColorhandler = (status) => {
        switch (status) {
            case "todo":
                return CustomTheme.not_statred_status
            case "in_progress":
                return CustomTheme.in_progress_status
            case "stopped":
                return CustomTheme.stoppped_status
            case "blocked":
                return CustomTheme.blockked_status
            case "complete":
                return CustomTheme.complete_status
            case "deferred":
                return CustomTheme.deferred_status
            case "ready_for_review":
                return CustomTheme.ready_for_review
            default:
            // code block
        }
    }

    // for deleting task
    const deleteForm = (id) => {
        const title = `Delete task`
        const message = `Deleting this task will also delete all the linked conversations, timelogs and activity details. Are you sure you want to proceed?`
        modalRef.current.setState({
            showModal: true,
            message: message,
            title: title,
            showOkButton: true,
            showCancelButton: true,
            showNote: false,
            note: '',
            handleOk: (note) => {
                deleteTaskRequest(id).then((res) => {
                    setRightPane("")
                })

            },
            okText: "Yes",
            cancelText: 'No'
        });
    }

    return (
        <>
            {
                isLoaderShowing
                    ?
                    <Card className="add-update-form for-card-shadow">
                        <span className="loader-for-save-and-edit"><LoaderForSaveAndEdit /></span>
                    </Card>
                    :
                    <Box className={classes.paneContainer}>
                        <Box className={classes.detailsPane}>
                            <Box
                                className={loggedInUser.userRoles.isProjectMember ? classes.detailsActionsForProjectMember : classes.detailsActions}>
                                <Box className={classes.detailsHeaderContent}>
                                    <Box className={classes.detailsFollowersContainer}>
                                        {/* <Typography variant='body2'>Followers</Typography>
              <img
                src='https://ui-avatars.com/api/?name=A&background=random'
                alt='profile'
                className={classes.taskUsersImage}
              />
              <img
                src='https://ui-avatars.com/api/?name=B&background=random'
                alt='profile'
                className={classes.taskUsersImage}
              />
              <img
                src='https://ui-avatars.com/api/?name=C&background=random'
                alt='profile'
                className={classes.taskUsersImage}
              />
              <Typography variant='h5' className={classes.taskUsersCount}>
                5
              </Typography> */}
                                    </Box>
                                    <Box>
                                        {
                                            loggedInUser.userRoles.isSystemSuperAdmin || loggedInUser.userRoles.isTeamLead
                                                ? (
                                                    <>
                                                        <Tooltip title='Delete task' placement='top' arrow>
                                                            <span className={classes.Icon} onClick={() => deleteForm(taskDetailsList.id)}>
                                                                <i className='far fa-trash-alt'></i>
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip title='Edit task' placement='top' arrow>
                                                            <span className={classes.Icon}
                                                                onClick={() => showAddUpdateForm(taskDetailsList.id, 'task', taskDetailsList.tasklistId)}>
                                                                <i className='far fa-edit'></i>
                                                            </span>
                                                        </Tooltip>
                                                    </>
                                                )
                                                : <></>
                                        }
                                        {/* <Tooltip title='Link to Task' placement='top' arrow>
                <span className={classes.Icon}>
                  <i className='far fa-clone'></i>
                </span>
              </Tooltip>
              <Tooltip title='Add subtask' placement='top' arrow>
                <span className={classes.Icon}>
                  <i className='far fa-trash-alt'></i>
                </span>
              </Tooltip>
              <Tooltip title='Edit task' placement='top' arrow>
                <span className={classes.Icon}>
                  <i className='far fa-bell'></i>
                </span>
              </Tooltip>
              <Tooltip title='Make Recurring' placement='top' arrow>
                <span className={classes.Icon}>
                  <i className='far fa-eye'></i>
                </span>
              </Tooltip> */}
                                    </Box>
                                </Box>
                            </Box>

                            <Box className={classes.detailsMainHeader}>
                                <Box className={classes.detailsHeader}>
                                    <Box className={classes.detailsHeaderContentForUsersIcon}>
                                        {
                                            taskDetailsList && taskDetailsList.assignee && taskDetailsList.assignee.length > 0 && taskDetailsList.assignee.map((role, index) => (
                                                <Box
                                                    key={index}
                                                    className={`${classes.inlineFlex} ${classes.boxPadding}`}
                                                >
                                                    <Box>
                                                        <img
                                                            src={`https://ui-avatars.com/api/?name=${role && role.functionalRole && role.functionalRole.name ? role.functionalRole.name : ''}&background=random`}
                                                            alt='profile'
                                                            className={classes.taskAssigneeImage}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Typography variant='body2'>
                                                            {role && role.functionalRole && role.functionalRole.name ? role.functionalRole.name : ""}
                                                        </Typography>
                                                        <Typography variant='h6'
                                                            className={`user-name ${classes.userNameColor}`}>
                                                            {
                                                                role && role.users && role.users.length > 0
                                                                    ? role.users.map((user, ind) => {
                                                                        return <div key={ind}>
                                                                            {user.firstName + " " + user.lastName}
                                                                        </div>
                                                                    })
                                                                    : <></>
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            ))
                                        }
                                        <Box
                                            //  className={classes.teamLead}
                                            className={classes.inlineFlex}
                                        >
                                            <Box className={classes.boxPadding}>
                                                <img
                                                    src='https://ui-avatars.com/api/?name=c&background=random'
                                                    alt='profile'
                                                    className={classes.taskAssigneeImage}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant='body2'>Approver</Typography>
                                                <Typography variant='h6' className={`user-name ${classes.userNameColor}`}>
                                                    {taskDetailsList && taskDetailsList.approver ? taskDetailsList.approver.firstName + " " + taskDetailsList.approver.lastName : "-"}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className={classes.infoContainer}>
                                    <Typography variant='h1' classes={{ root: classes.detailsTitle }}>
                                        {taskDetailsList && taskDetailsList.title ? taskDetailsList.title : ''}
                                    </Typography>
                                    {/* <Typography variant='body2' classes={{ root: classes.detailsBy }}>
              Added by Jeetal C. on March 15, 2021 at 9:32 AM
            </Typography> */}
                                    <Typography
                                        variant='h3'
                                        classes={{ root: classes.detailsDescription }}
                                    >
                                        {taskDetailsList && taskDetailsList.description ? taskDetailsList.description : ''}
                                    </Typography>
                                </Box>

                                <Box className={classes.tagsContaianer}>
                                    <span className={classes.tagIcon}>
                                        <i className='fas fa-tags'></i>
                                    </span>
                                    <Box className={classes.chipInputContainer}>
                                        <ChipInput
                                            disableUnderline={true}
                                            fullWidth
                                            disabled
                                            value={taskDetailsList && taskDetailsList.tags ? taskDetailsList.tags : []}
                                            classes={{
                                                root: classes.chipInput,
                                                inputRoot: classes.inputRoot,
                                                input: classes.input,
                                                chip: classes.chip,
                                                chipContainer: classes.chipContainer,
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box className={classes.detailsContainer}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Box>
                                                <Typography variant='body2'>
                                                    <span>
                                                        <i className='far fa-list-alt'></i>
                                                    </span>{' '}
                                                    Tasklist
                                                </Typography>{' '}
                                                <Typography variant='h6' classes={{ root: classes.info }}>
                                                    {taskDetailsList && taskDetailsList.tasklistId && tasklist ? tasklist.title : "-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Box>
                                                <Typography variant='body2'>
                                                    <span>
                                                        <i className='fas fa-angle-double-up'></i>
                                                    </span>{' '}
                                                    Priority
                                                </Typography>{' '}
                                                <Typography variant='h6' classes={{ root: classes.info }}>
                                                    {taskDetailsList && taskDetailsList.priority ? renderPriority(taskDetailsList.priority) : "-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Box>
                                                <Typography variant='body2'>
                                                    <span>
                                                        <i className='fas fa-clipboard-list'></i>
                                                    </span>{' '}
                                                    Checklist
                                                </Typography>{' '}
                                                {
                                                    taskDetailsList && taskDetailsList.checklist
                                                        ? (
                                                            <Typography
                                                                variant='h6'
                                                                classes={{ root: classes.info }}
                                                                className="cursor-pointer"
                                                                onClick={() => setOpenChecklistItemsDialog(true)}
                                                            >
                                                                {taskDetailsList.checklist.title}
                                                            </Typography>
                                                        )
                                                        : "-"
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box className={classes.detailsContainer}>
                                    <Typography variant='h4' classes={{ root: classes.dependentLabel }}>
                                        <i className="far fa-hourglass" style={{ paddingRight: '3px' }}></i>
                                        Estimated Effort
                                    </Typography>
                                    <Box className={classes.detailsHeader}>
                                        <Box className={classes.detailsHeaderContentForUsersIcon}>
                                            {
                                                taskDetailsList && taskDetailsList.assignee && taskDetailsList.assignee.length > 0 && taskDetailsList.assignee.map((role, index) => (
                                                    <Box
                                                        key={index}
                                                        className={`${classes.inlineFlex} ${classes.boxPadding}`}
                                                    >
                                                        <Box>
                                                            <img
                                                                src={`https://ui-avatars.com/api/?name=${role && role.functionalRole && role.functionalRole.name ? role.functionalRole.name : ''}&background=random`}
                                                                alt='profile'
                                                                className={classes.taskAssigneeImage}
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <Typography variant='body2'>
                                                                {role && role.functionalRole && role.functionalRole.name ? role.functionalRole.name : ''}
                                                            </Typography>
                                                            <Typography variant="h6"
                                                                className={`user-name ${classes.userNameColor}`}>
                                                                {role && role.timeEffort ? role.timeEffort : ''}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className={classes.dependentContainer}>
                                    <Typography variant='h4' classes={{ root: classes.dependentLabel }}>
                                        Dependent Tasks
                                    </Typography>
                                    {
                                        taskDetailsList && taskDetailsList.predecessors && Object.keys(taskDetailsList.predecessors).map((list) => {
                                            return (
                                                <Typography variant="h4" key={list} className='concept-dependent'>
                                                    <span className={`blocked ${classes.forErrorColor}`}>
                                                        <i className='fas fa-share-alt'></i> Blocked
                                                    </span>{' '}
                                                    {taskDetailsList && taskDetailsList.predecessors[list] ? taskDetailsList.predecessors[list].title : "-"}
                                                </Typography>
                                            )
                                        })
                                        // taskDetails.predecessorId && (
                                        //   <Typography className='concept-dependent'>
                                        //     <span className='blocked'>
                                        //       <i className='fas fa-share-alt'></i> Blocked
                                        //     </span>{' '}
                                        //     {task?task.title:"-"}
                                        //   </Typography>
                                        // )
                                    }
                                    <Typography className='final-iteration-of-presentation'>
                                        {/* <span className='blocking'>
                <i className='fas fa-share-alt'></i> Blocking
              </span>{' '}
              Final Iteration of presentation{' '} */}
                                    </Typography>
                                </Box>
                                <Box className={classes.detailsStatusContainer}>
                                    <Box className={classes.detailsStatusContent}>
                                        <Box
                                            className={`${classes.detailsStatusItemContainer} ${classes.statusGreen}`}
                                        >
                                            <Typography
                                                variant='body2'
                                                classes={{ root: classes.statusHeading }}
                                            >
                                                Due
                                            </Typography>
                                            <Typography variant="h3" classes={{ root: classes.statusText }}>
                                                {taskDetailsList && taskDetailsList.dueDate ? moment(taskDetailsList.dueDate).format('DD/MM/YYYY') :
                                                    <Typography variant='h6' classes={{ root: classes.info }}>-</Typography>}
                                                {/* 28/03/2021 */}
                                            </Typography>
                                        </Box>

                                        <Box
                                            className={`${classes.detailsStatusItemContainer} ${classes.statusGreen}`}
                                        >
                                            <Typography
                                                variant='body2'
                                                classes={{ root: classes.statusHeading }}
                                            >
                                                State
                                            </Typography>
                                            <Typography variant="h3" classes={{ root: classes.statusText }} style={{ color: taskDetailsList && taskDetailsList.status ? forStatusColorhandler(taskDetailsList.status) : '' }} >
                                                {
                                                    taskDetailsList && taskDetailsList.status === "todo"
                                                        ?
                                                        "Not Started"
                                                        :
                                                        taskDetailsList && taskDetailsList.status === "in_progress"
                                                            ?
                                                            "In Progress"
                                                            :
                                                            taskDetailsList && taskDetailsList.status === "stopped"
                                                                ?
                                                                "Stopped"
                                                                :
                                                                taskDetailsList && taskDetailsList.status === "blocked"
                                                                    ?
                                                                    "Blocked"
                                                                    :
                                                                    taskDetailsList && taskDetailsList.status === "complete"
                                                                        ?
                                                                        "Complete"
                                                                        :
                                                                        taskDetailsList && taskDetailsList.status === "deferred"
                                                                            ?
                                                                            "Deferred"
                                                                            :
                                                                            taskDetailsList && taskDetailsList.status === "ready_for_review"
                                                                                ?
                                                                                "Ready for Review"
                                                                                :
                                                                                ""
                                                }
                                            </Typography>
                                        </Box>

                                        {
                                            timeEffort
                                                ? (
                                                    <Box
                                                        className={`${classes.detailsStatusItemContainer} ${classes.statusOrange}`}
                                                    >
                                                        <Typography
                                                            variant='body2'
                                                            classes={{ root: classes.statusHeading }}
                                                        >
                                                            Effort Hours
                                                        </Typography>
                                                        <Typography variant="h3"
                                                            classes={{ root: classes.statusText }}>{timeEffort}</Typography>
                                                    </Box>
                                                )
                                                : <></>
                                        }
                                    </Box>
                                </Box>

                                <Box className={classes.tabsOuterContainer}>
                                    <TabContext value={activeTab}>
                                        <TabList
                                            classes={{
                                                // flexContainer: classes.TabListRoot,
                                                indicator: classes.TabsIndicator,
                                            }}
                                            onChange={(e, newValue) => setActiveTab(newValue)}
                                        >
                                            <Tab
                                                // label='Progress'
                                                label={
                                                    <Typography
                                                        className={activeTab === "1" ? classes.selected : classes.notSelected}
                                                        variant="h4">Progress</Typography>
                                                }
                                                value='1'
                                                classes={{
                                                    wrapper: classes.TabWrapper,
                                                    root: classes.TabRoot,
                                                    selected: classes.selected,
                                                }}
                                            />
                                            <Tab
                                                // label='Progress'
                                                label={
                                                    <Typography
                                                        className={activeTab === "2" ? classes.selected : classes.notSelected}
                                                        variant="h4">Time</Typography>
                                                }
                                                value='2'
                                                classes={{
                                                    wrapper: classes.TabWrapper,
                                                    root: classes.TabRoot,
                                                    selected: classes.selected,
                                                }}
                                            />
                                            {/* <Tab
                  // label='Conversations'
                  label={
                    <Typography className={activeTab==="2"?classes.selected:classes.notSelected} variant="h4" >Conversations</Typography>
                  }
                  value='2'
                  classes={{
                    wrapper: classes.TabWrapper,
                    root: classes.TabRoot,
                    selected: classes.selected,
                  }}
                />
                <Tab
                  // label='Documents'
                  label={
                    <Typography className={activeTab==="3"?classes.selected:classes.notSelected} variant="h4" >Documents</Typography>
                  }
                  value='3'
                  classes={{
                    wrapper: classes.TabWrapper,
                    root: classes.TabRoot,
                    selected: classes.selected,
                  }}
                />
                <Tab
                  label={
                    <Typography className={activeTab==="4"?classes.selected:classes.notSelected} variant="h4" >Time</Typography>
                  }
                  value='4'
                  classes={{
                    wrapper: classes.TabWrapper,
                    root: classes.TabRoot,
                    selected: classes.selected,
                  }}
                /> */}
                                        </TabList>

                                        <TabPanel
                                            value='4'
                                            classes={{
                                                root: classes.tabPanelRoot,
                                            }}
                                        >
                                            {/* <TimeLine /> */}
                                        </TabPanel>
                                        <TabPanel
                                            value='1'
                                            classes={{
                                                root: classes.tabPanelRoot,
                                            }}
                                        >
                                            <TimeLine taskDetails={taskDetailsList ? taskDetailsList : ''}
                                                updateTaskFromReadView={updateTaskFromReadView} />
                                        </TabPanel>
                                        <TabPanel
                                            value='3'
                                            classes={{
                                                root: classes.tabPanelRoot,
                                            }}
                                        >
                                            {/* <Documents /> */}
                                        </TabPanel>
                                        <TabPanel
                                            value='2'
                                            classes={{
                                                root: classes.tabPanelRoot,
                                            }}
                                        >
                                            <Effort taskId={taskDetailsList ? taskDetailsList.id : ""} />
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </Box>
                        </Box>
                        {
                            openChecklistItemsDialog
                                ?
                                <ChecklistItemsDraggableDialog panel={"task"} updateTaskFromReadView={updateTaskFromReadView}
                                    taskDetails={taskDetailsList} project={true} template={false}
                                    title={taskDetailsList && taskDetailsList.checklist ? taskDetailsList.checklist.title : ''}
                                    open={openChecklistItemsDialog}
                                    setOpenChecklistItemsDialog={setOpenChecklistItemsDialog}
                                    checklistItemsArray={taskDetailsList && taskDetailsList.checklist ? taskDetailsList.checklist : []} />
                                :
                                <></>
                        }
                    </Box>
            }
            <MessageModal
                ref={modalRef} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTaskListDetailsById: (id) => {
            return dispatch(getTaskListDetailsByIdRequest(id)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getTaskDetailsById: (id) => {
            return dispatch(getTaskDetailsByIdRequest(id)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        updateTask: (id, data, forAssigneeStatus, message) => {
            return dispatch(updateTaskRequest(id, data, forAssigneeStatus, message)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        deleteTaskRequest: (taskId) => {
            return dispatch(deleteTaskRequestProject(taskId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDetails);
