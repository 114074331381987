import React, {useEffect, useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import {connect} from 'react-redux';

import {addParticipantsRequest, getAllParticipantsRequest} from '../../../../../ProjectsApiActions';

import AppHeader from '../../../../../../userDashboard/components/AppHeader/AppHeader';
import ProjectHeader from '../ProjectHeader/ProjectHeader';
import ParticipantsList from './components/ParticipantsList/ParticipantsList';
// import ParticipantDetailsPane from './components/ParticipantDetailsPane/ParticipantDetailsPane';
// import Blanck from './components/Blanck/Blanck';
import {getAllAuthUsersRequest} from '../../../../../../authentication/AuthenticationApiActions'

const Participants = ({
                          handleDrawerOpenClose,
                          history,
                          match,
                          getAllParticipants,
                          participantList,
                          getAllAuthUsers,
                          addParticipants
                      }) => {
    const projectId = match.params.id;
    // const [userId, setUserId] = useState(null);
    // const [participantsDetails, setParticipantsDetails] = useState(null);
    const [allUsers, setAllUsers] = useState(null);
    const [newMemberId, setNewMemberId] = useState(null)
    const [value, setValue] = useState(null)

    useEffect(() => {
        getAllParticipants(projectId).then((res) => {
            getAllAuthUsers(true).then((userRes) => {

                let userObj = userRes && userRes.data.filter((user) => {
                    return !(res && res.find((resUser) => {
                        return user.id === resUser.id
                    }))
                })

                setAllUsers(userObj)
            })
        })
    }, []);

    // useEffect(()=>{
    //   getAllAuthUsers().then((res)=>{

    //   })
    // },[])

    const showParticipantsDetailsPane = (id, data) => {
        // setUserId(id);
        // setParticipantsDetails(data);
    }

    // const hideTaskDetailsPane = () => {
    //   setUserId(null);
    //   setParticipantsDetails(null);
    // }

    const addMemberInParticipentsHandler = () => {
        let data = [
            {
                id: newMemberId,
                memberType: 'non_team',
            }
        ]
        addParticipants(projectId, data).then((res) => {
            setValue(null)
            getAllParticipants(projectId).then((res) => {
                getAllAuthUsers(true).then((userRes) => {

                    let userObj = userRes && userRes.data.filter((user) => {
                        return !(res && res.find((resUser) => {
                            return user.id === resUser.id
                        }))
                    })

                    setAllUsers(userObj)
                })
            })

        }).catch((err) => {
        })
    }

    return (
        <>
            <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                <ProjectHeader history={history} match={match} isParticipantsView={true}/>
            </AppHeader>

            <Box>
                <Grid container>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <ParticipantsList setValue={setValue} value={value} newMemberId={newMemberId}
                                          setNewMemberId={setNewMemberId}
                                          addMemberInParticipentsHandler={addMemberInParticipentsHandler}
                                          allUsers={allUsers} history={history} participantList={participantList}
                                          showParticipantsDetailsPane={showParticipantsDetailsPane}/>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            {userId && participantsDetails ? <ParticipantDetailsPane /> : <Blanck />}
          </Grid> */}
                </Grid>
            </Box>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        participantList: state.projects.participants
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllParticipants: (projectId) => {
            return dispatch(getAllParticipantsRequest(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllAuthUsers: (isLoaderShowing) => {
            return dispatch(getAllAuthUsersRequest(isLoaderShowing)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addParticipants: (projectId, data) => {
            return dispatch(addParticipantsRequest(projectId, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Participants);
