const MuiButton = {
    root: {
        textTransform: 'none',
        height: '44px',
        fontSize: '16px',
        fontWeight: '500',
        "&$sizeSmall": {
            height: '32px'
        }
    }
};

export default MuiButton;
