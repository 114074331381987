import {
    GET_ALL_MILESTONE_LIST,
    GET_ALL_PARTICIPANTS,
    GET_ALL_TASK_LISTS,
    GET_MILESTONE_DETAILS,
    ADD_MILESTONE,
    UPDATE_MILESTONE,
    DELETE_MILESTONE,
    TASKLISTS_UNDER_MILESTONE
} from './MilestonesActions';

const initialState = {
    projects: null,
    taskLists: null,
    milestoneList: null,
    tasks: null,
    participants: null,
    projectsOfTeamLeadAndProjectMember: null,
    singleTasklistDetails: null,
    singleMilestoneDetails: null,
};

/**
 * MilestoneReducer reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */

const MilestoneReducer = (state = initialState, action) => {

    switch (action.type) {

        //Get list of taskLists to be shown
        case GET_ALL_TASK_LISTS:
            return Object.assign({}, state, {
                taskLists: action.taskLists
            });
        //Get list of milestone to be shown
        case GET_ALL_MILESTONE_LIST:
            return Object.assign({}, state, {
                milestoneList: action.milestoneList
            });

        //Get list of participants to be shown
        case GET_ALL_PARTICIPANTS:
            return Object.assign({}, state, {
                participants: action.participants
            });

        //Get details of single milestone
        case GET_MILESTONE_DETAILS:
            return Object.assign({}, state, {
                singleMilestoneDetails: action.milestoneDetails
            });

        //add milestone in the list of all milestones
        case ADD_MILESTONE:
            let tempMilestoneListForAddMilestone = Object.assign([], state.milestoneList)
            tempMilestoneListForAddMilestone.unshift(action.milestoneRes)
            return Object.assign({}, state, {
                milestoneList: tempMilestoneListForAddMilestone
            });

        //update milestone in the list of all milestones
        case UPDATE_MILESTONE:
            let tempMilestoneListForUpdateMilestone = Object.assign([], state.milestoneList)
            let findIndex = tempMilestoneListForUpdateMilestone && tempMilestoneListForUpdateMilestone.findIndex((milestoneList) => milestoneList.id === action.updateMilestoneRes.id)
            tempMilestoneListForUpdateMilestone[findIndex] = action.updateMilestoneRes
            return Object.assign({}, state, {
                milestoneList: tempMilestoneListForUpdateMilestone
            });

        //add / remove tasklists under milestone
        case TASKLISTS_UNDER_MILESTONE:
            let forAddTasklistsUnderMilestoneTempTasklists = Object.assign([], state.milestoneList)
            if(action.typeOfMilestone === "milestoneOpen")
            {
                let list;
                    list = action.tasklistsUnderMilestone.filter(one => {
                        one.isSubTask = true;
                        return true;
                    });
                let index = forAddTasklistsUnderMilestoneTempTasklists.findIndex(one => one.id === action.milestoneId);
                list && list.forEach(one => {
                    forAddTasklistsUnderMilestoneTempTasklists.splice(index + 1, 0, one);
                    index++;
                });
            }  
            else if (action.typeOfMilestone === "milestoneClose")
            {
                 let findIndex = forAddTasklistsUnderMilestoneTempTasklists && forAddTasklistsUnderMilestoneTempTasklists.findIndex((task) => task.id === action.milestoneId)
                 forAddTasklistsUnderMilestoneTempTasklists.splice(findIndex+1, action.tasklistsUnderMilestone.length);
            }    
            else 
            {

            } 
            return Object.assign({}, state, {
                milestoneList: forAddTasklistsUnderMilestoneTempTasklists
            });

        //delete milestone in the list of all milestones
        case DELETE_MILESTONE:
            let tempMilestoneListForDeleteMilestone = Object.assign([], state.milestoneList)
            let findIndexOfMilestone = tempMilestoneListForDeleteMilestone && tempMilestoneListForDeleteMilestone.findIndex((task) => task.id === action.deleteMilestoneRes.deleteId)
            let findSubTasklist = tempMilestoneListForDeleteMilestone && tempMilestoneListForDeleteMilestone.filter((tasklist) => tasklist.isSubTask === true);
            let milestoneTasklist = findSubTasklist && findSubTasklist.filter((tasklist) => tasklist.milestoneId === action.deleteMilestoneRes.deleteId)
            let totalLength = 1 + milestoneTasklist.length
            tempMilestoneListForDeleteMilestone.splice(findIndexOfMilestone, totalLength);
            return Object.assign({}, state, {
                milestoneList: tempMilestoneListForDeleteMilestone
            });

        default:
            return state;

    }
};

export default MilestoneReducer;
