import React from 'react'
import './TeamList.css';
import {Box, Typography} from '@material-ui/core';
import MaterialTable from 'material-table';
import TableContainer from '@material-ui/core/TableContainer';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import {connect} from 'react-redux';

const TeamList = ({setRowIndex, setSelectedRow, selectedRow, history, match, getAllTeams, allTeams}) => {

    const columns = [
        {
            field: '',
            title: '',
            cellStyle: {
                width: '5%',
            },
            headerStyle: {
                display: 'none'
            },
        },
        {
            field: 'name',
            title: 'name',
            cellStyle: {
                width: '30%',
            },
            headerStyle: {
                display: 'none'
            },
            render: rowData => {
                return (
                    <div className="">
                        <Typography variant="body1">{rowData.name}</Typography>
                    </div>
                )
            },
        },

        {
            field: '',
            title: '',
            cellStyle: {
                width: '40%',
            },
            headerStyle: {
                display: 'none'
            },
            render: rowData => {
                let count = 0;
                return (
                    <Box className='user-if-one'>
                        {
                            rowData.members && Object.keys(rowData.members).length
                                ?
                                <>
                                    {
                                        rowData.members && Object.keys(rowData.members).map((member, memberIndex) => {
                                            let profileText = rowData.members[member].firstName
                                            count++
                                            if (count < 5) {
                                                return (
                                                    <Tooltip key={memberIndex}
                                                             title={`${rowData.members[member].firstName} ${rowData.members[member].lastName}`}
                                                             placement="top">
                                                        <img
                                                            src={`https://ui-avatars.com/api/?name=${profileText.charAt(0)}&background=random`}
                                                            alt='profile'
                                                            className="task-user-image"
                                                        />
                                                    </Tooltip>
                                                )
                                            } else {
                                                return 0
                                            }
                                        })
                                    }
                                </>
                                :
                                <></>
                        }
                        {
                            rowData.members && Object.keys(rowData.members).length > 4
                                ?
                                <Avatar
                                    className="team-list-count-assignee">{Object.keys(rowData.members).length - 4}</Avatar>
                                :
                                <></>
                        }

                    </Box>
                )
            },
        },
        {
            field: 'actions',
            title: 'Actions',
            cellStyle: {
                width: '10%',
            },
            headerStyle: {
                display: 'none'
            },
            // render: rowData => {
            //     return (
            //         <span onClick={(event)=>{

            //             onClickDelete(event);
            //             setRowIndex(rowData.tableData.id)
            //         }} className="team-detail-panel-delete-memeber-icon"><i className="far fa-trash-alt"></i></span>
            //     )
            // },
        }
    ]

    return (
        <TableContainer>
            <MaterialTable
                columns={columns}
                className="for-hover-styling"
                data={allTeams || []}
                options={{
                    padding: 'dense',
                    toolbar: false,
                    paging: false,
                    sorting: false,
                    emptyRowsWhenPaging: false,
                    draggable: false,
                    rowStyle: rowData => ({
                        backgroundColor: (selectedRow === rowData.id) ? '#0faeec17' : '#FFF',
                        color: '#77797c',
                        borderBottom: 'none',
                        hover: {
                            backgroundColor: '#0faeec17 !important'
                        }
                    })
                }}
                onRowClick={(e, rowData) => {
                    setRowIndex(rowData.id);
                    setSelectedRow(rowData.id)
                }}
            />
        </TableContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        allTeams: state.configuration.teams
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamList);
