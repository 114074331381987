import HttpRequest from '../../utilities/HttpRequest';

import { API_ACTIONS, SNACKBAR_VARIAINT } from '../../utilities/Constants';
import { showSnackbar } from '../../utilities/AppLoader/AppLoaderActions'

import {
    getAllMilestone,
    getAllParticipantLists,
    getAllProjects,
    getAllProjectsOfTeamLeadAndProjectMember,
    getAllTaskLists,
    getSingleTaskList,
    getSingleTaskDetails,
    addTasklist,
    updateTasklist,
    deleteTasklist,
    getAllTasks,
    addTask,
    updateTask,
    deleteTask,
    reorderTask,
    myTasks
} from "./ProjectsActions";

/**
 * Server API call to get all projects
 * @returns {function(*)}
 */
export const getAllProjectsRequest = (isLoaderShow) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.PROJECTS}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, isLoaderShow)
            .then(res => {
                let projects = [];
                for (const [key, value] of Object.entries(res.data)) {
                    let obj = {
                        id: key,
                        name: value.team.name,
                        headName: value.team.head ? value.team.head.firstName + " " + value.team.head.lastName : "", //DETAILS NOT FOUND
                        projects: value.projects,
                        members: value.team && value.team.members ? value.team.members : []
                    }
                    projects.push(obj);
                }
                dispatch(getAllProjects(projects));
                return Promise.resolve(projects);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get project details by id
 * @returns {function(*)}
 */
export const getProjectDetailsByIdRequest = (projectId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.PROJECTS}`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add new project
 * @returns {function(*)}
 */
export const addProjectRequest = (data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.PROJECTS}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Project added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all taskLists
 * @returns {function(*)}
 */
export const getAllTaskListsRequest = (projectId, isResStoringInRedux) => {
    return (dispatch) => {
        let url = `${API_ACTIONS.PROJECTS}/${projectId}/tasklists`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                if (isResStoringInRedux) {
                    dispatch(getAllTaskLists(res.data));
                }
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all un associated tasklist
 * @returns {function(*)}
 */
export const getAllUnAssociatedTasklistOfProject = (projectId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.UNASSOCIATED_TASKLIST_OF_PROJECT}?projectId=${projectId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all milestone
 * @returns {function(*)}
 */
export const getAllMilestoneRequest = (projectId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.PROJECTS}/${projectId}/milestones`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                dispatch(getAllMilestone(res.data));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add new tasklist
 * @returns {function(*)}
 */
export const addTaskListRequest = (data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.TASKSLIST}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(addTasklist(res.data))
                dispatch(showSnackbar("Tasklist added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to update tasklist
 * @returns {function(*)}
 */
export const updateTaskListRequest = (taskListId, data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.TASKSLIST}/${taskListId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(updateTasklist(res.data))
                dispatch(showSnackbar("Tasklist updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add new milestone
 * @returns {function(*)}
 */
export const addMilestoneRequest = (projectId, data) => {

    return (dispatch) => {
        const url = `/milestones`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Milestone added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to update milestone
 * @returns {function(*)}
 */
export const updateMilestoneRequest = (milestoneId, data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.MILESTONES}/${milestoneId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Milestone updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add new task
 * @returns {function(*)}
 */
export const addTaskRequest = (data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.TASKS}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                res.data.isSubTask = true;
                dispatch(addTask(res.data))
                dispatch(showSnackbar("Task added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to update task
 * @returns {function(*)}
 */
export const updateTaskRequest = (taskId, data, forAssigneeStatus, message) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.TASKS}/${taskId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send(null, forAssigneeStatus)
            .then(res => {
                res.data.isSubTask = true;
                dispatch(updateTask(res.data))
                dispatch(showSnackbar(message, SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all tasks
 * @returns {function(*)}
 */
export const getAllTasksRequest = (taskId, type, reOrderFromIndex, newtableData) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_ALL_TASKS}/${taskId}/tasks`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                dispatch(getAllTasks(res.data, type, taskId, reOrderFromIndex, newtableData));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get task list details
 * @returns {function(*)}
 */
export const getTaskListDetailsByIdRequest = (taskId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.TASKSLIST}/${taskId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                dispatch(getSingleTaskList(res.data))
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get milestone details
 * @returns {function(*)}
 */
export const getMilestoneDetailsByIdRequest = (milestoneId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.MILESTONES}/${milestoneId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get task details
 * @returns {function(*)}
 */
export const getTaskDetailsByIdRequest = (taskId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.TASKS}/${taskId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                dispatch(getSingleTaskDetails(res.data))
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all taskLists
 * @returns {function(*)}
 */
export const getAllParticipantsRequest = (projectId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.PROJECTS}/${projectId}/participants`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                dispatch(getAllParticipantLists(res.data));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all project types
 * @returns {function(*)}
 */
export const getAllProjectTypesRequest = () => {

    return (dispatch) => {
        const url = `/config/projectsConfig`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to import task list
 * @returns {function(*)}
 */
export const importTaskListRequest = (projectId, data) => {

    return (dispatch) => {
        const url = `/projects/${projectId}/import`;
        return new HttpRequest(dispatch, url, 'patch', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Tasklist imported successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all predecessor task
 * @returns {function(*)}
 */
export const getPredecessorRequest = (projectId) => {

    return (dispatch) => {
        const url = `/projects/${projectId}/child-tasks`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
}

/**
 * Server API call to get all Tasks of loggedInUser
 * @returns {function(*)}
 */
export const getMyAllTasksOfLoggedInUser = (projectId, loggedInUserId) => {

    return (dispatch) => {
        const url = `/projects/${projectId}/child-tasks`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                dispatch(myTasks(res.data, loggedInUserId))
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
}

/**
 * Server API call to get all project checklist
 * @returns {function(*)}
 */
export const getAllProjectCheckListRequest = (projectId) => {

    return (dispatch) => {
        const url = `/projects/${projectId}/checklist`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add project check list
 * @returns {function(*)}
 */
export const addProjectCheckListRequest = (data) => {

    return (dispatch) => {
        const url = `/projects/checklist`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all only taskLists
 * @returns {function(*)}
 */
export const getAllOnlyTaskListsRequest = (projectId) => {

    return (dispatch) => {
        const url = `/projects/${projectId}/tasks`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all only milestone
 * @returns {function(*)}
 */
export const getAllOnlyMilestonesRequest = (projectId) => {

    return (dispatch) => {
        const url = `/milestones/projects/${projectId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all tasklist by milestone id
 * @returns {function(*)}
 */
export const getAllTasklistByMilestoneIdRequest = (projectId) => {

    return (dispatch) => {
        const url = `/projects/${projectId}/tasks`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all checklist of project
 * @returns {function(*)}
 */
export const getAllChecklistInProjectRequest = (projectId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_ALL_CHECKLIST_FOR_PROJECT}/${projectId}/checklist/current`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add checklist in project
 * @returns {function(*)}
 */
export const addChecklistInProjectRequest = (data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.ADD_CHECKLIST_FOR_PROJECT}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Checklist added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add items in checklist
 * @returns {function(*)}
 */
export const addItemsForChecklistInProject = (checklistId, data) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.ADD_ITEMS_IN_CHECKLIST_FOR_PROJECT}/${checklistId}`;
        return new HttpRequest(dispatch, url, 'patch', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Items added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get single checklist details of project
 * @returns {function(*)}
 */
export const getSingleChecklistInProjectRequest = (projectId, checklistId, isLoaderShowing) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_SINGLE_CHECKLIST_PROJECT}/${projectId}/checklist/${checklistId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, isLoaderShowing)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to edit checklist  project.
 * @returns {function(*)}
 */
export const editChecklistProjectRequest = (checklistId, data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.EDIT_CHECKLIST_PROJECT}/${checklistId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Checklist edited successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get projects of user
 * @returns {function(*)}
 */
export const getProjectOfUserRequest = (isLoaderShow) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.GET_POROJECTS_OF_USER}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, isLoaderShow)
            .then(res => {
                let projects = []
                res.data.map((projectInfo) => {
                    projects.push(projectInfo.id)
                    return projectInfo
                })
                dispatch(getAllProjectsOfTeamLeadAndProjectMember(projects));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add participants
 * @returns {function(*)}
 */
export const addParticipantsRequest = (projectId, data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.ADD_PARTICIPANTS}/${projectId}/members`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Participants added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to edit  project
 * @returns {function(*)}
 */
export const editProjectRequest = (data, projectId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.EDIT_PROJECT}/${projectId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Project edited successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add time log in task
 * @returns {function(*)}
 */
export const addTimeLogInTask = (data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.ADD_TIMELOG_IN_TASK}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send(null, true)
            .then(res => {
                dispatch(showSnackbar("Timelog  added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call fetch all timelog for task
 * @returns {function(*)}
 */
export const getAllTimeLogRequest = (taskId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_ALL_TIMELOG}/${taskId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to edit  timelog of task
 * @returns {function(*)}
 */
export const editTimelogOfTaskRequest = (data, taskId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.EDIT_TIMELOG}/${taskId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send(null, true)
            .then(res => {
                dispatch(showSnackbar("Timelog edited successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to delete task
 * @returns {function(*)}
 */
export const deleteTaskRequestProject = (taskId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.DELETE_TASK_PROJECT}/${taskId}`;
        return new HttpRequest(dispatch, url, 'delete')
            .send(null, true)
            .then(res => {
                res.data = taskId
                dispatch(deleteTask(res.data))
                dispatch(showSnackbar("Task deleted successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to task ordering
 * @returns {function(*)}
 */
export const taskOrderRequestProject = (data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.TASK_ORDER_WITHIN_TASKLIST}`;
        return new HttpRequest(dispatch, url, 'patch', data)
            .send(null, true)
            .then(res => {
                dispatch(showSnackbar("Re order successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to delete tasklist
 * @returns {function(*)}
 */
export const deleteTasklistRequestProject = (tasklistId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.DELETE_TASKLIST_PROJECT}/${tasklistId}`;
        return new HttpRequest(dispatch, url, 'delete')
            .send(null, true)
            .then(res => {
                res.data.id = tasklistId
                dispatch(deleteTasklist(res.data))
                dispatch(showSnackbar("Tasklist deleted successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to delete timelog
 * @returns {function(*)}
 */
export const deleteTimelogRequestProject = (timelogId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.DELETE_TIMELOG}/${timelogId}`;
        return new HttpRequest(dispatch, url, 'delete')
            .send(null, true)
            .then(res => {
                dispatch(showSnackbar("Timelog deleted successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to delete milestone
 * @returns {function(*)}
 */
export const deleteMilestoneRequestProject = (milestoneId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.DELETE_MILESTONE_PROJECT}/${milestoneId}`;
        return new HttpRequest(dispatch, url, 'delete')
            .send(null, true)
            .then(res => {
                dispatch(showSnackbar("Milestone deleted successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

// for re order  task using redux
export const reOrderTaskRowUsingRedux = (data) => {
    return dispatch => {
        dispatch(reorderTask(data));
    };
};

/**
 * Server API call to delete project
 * @returns {function(*)}
 */
 export const deleteProject = (projectId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.DELETE_PROJECT}/${projectId}`;
        return new HttpRequest(dispatch, url, 'delete')
            .send()
            .then(res => {
                dispatch(showSnackbar("Project deleted successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};
