import React from 'react'
import './VendorTableView.css';
import { Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import TableContainer from '@material-ui/core/TableContainer';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
const VendorTableView = ({
    setRowIndex,
    setSelectedRow,
    selectedRow,
    allVendors
}) => {

    const columns = [
        {
            field: '',
            title: '',
            cellStyle: {
                width: '1%',
            },
            headerStyle: {
                display: 'none'
            },
            render: rowData => {
                let profileName = rowData && rowData.contactPerson ? rowData.contactPerson.charAt(0).toUpperCase() : ''
                let url = `https://ui-avatars.com/api/?name=${profileName !== "" ? profileName : ''}&background=random`
                return (
                    <Tooltip title={rowData.contactPerson} placement="top">
                        <img
                            src={url}
                            alt='profile'
                            className="user-image"
                        />
                    </Tooltip>
                )
            },
        },
        {
            field: 'name',
            title: 'name',
            cellStyle: {
                width: '30%',
            },
            headerStyle: {
                display: 'none'
            },
            render: rowData => {
                return (
                    <div className="client-vendor-profile-image">
                        <Typography variant="body1">{rowData.name}</Typography>
                    </div>
                )
            },
        },
        {
            field: 'contactPerson',
            title: 'contactPerson',
            cellStyle: {
                width: '25%',
            },
            headerStyle: {
                display: 'none'
            },
            render: rowData => {
                return (
                    <Typography variant="body1">{rowData.contactPerson}</Typography>
                )
            },
        },
        {
            field: 'actions',
            title: 'Actions',
            cellStyle: {
                width: '5%',
            },
            headerStyle: {
                display: 'none'
            },
            // render: rowData => {
            //     return (
            //         <span onClick={(event)=>{

            //             onClickDelete(event,rowData.tableData.id);
            //             // setDeleteRowIndex(rowData.tableData.id)
            //         }} className="team-detail-panel-delete-memeber-icon"><i className="far fa-trash-alt"></i></span>
            //     )
            // },
        }
    ]

    return (
        <TableContainer>
            <MaterialTable
                columns={columns}
                data={allVendors || []}
                options={{
                    padding: 'dense',
                    toolbar: false,
                    paging: false,
                    sorting: false,
                    emptyRowsWhenPaging: false,
                    draggable: false,
                    rowStyle: rowData => ({
                        backgroundColor: (selectedRow === rowData.id) ? '#0faeec17' : '#FFF',
                        color: '#77797c',
                        borderBottom: 'none',
                        hover: {
                            backgroundColor: '#0faeec17 !important'
                        }
                    })
                }}
                onRowClick={(e, rowData) => {
                    setRowIndex(rowData.id);
                    setSelectedRow(rowData.id)
                }}
            />
        </TableContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        allVendors: state.configuration.vendors
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorTableView);
