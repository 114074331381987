import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import UserDashboard from '../modules/userDashboard/UserDashboard';
import Home from '../modules/Home/Home';
import Login from '../modules/authentication/Login/Login';
import NotFound from '../modules/notFound/NotFound';

import {ROUTER_ACTIONS} from '../utilities/Constants';

function AppRoutes() {
    return (
        <>
            <Router>
                <Switch>
                    <PrivateRoute exact path='/' component={Home}/>

                    <PrivateRoute exact path={ROUTER_ACTIONS.ACTION_STATION}
                                  component={(props) => <UserDashboard {...props} />}/>

                    <PrivateRoute exact path={ROUTER_ACTIONS.ACTION_STATION_MY_TASKS}
                                  component={(props) => <UserDashboard {...props} />}/>         

                    {/* projects menu routes */}
                    <PrivateRoute exact path={ROUTER_ACTIONS.PROJECTS_TEAMS}
                                  component={(props) => <UserDashboard {...props} />}/>
                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.PROJECTS_CLIENTS}
                        component={(props) => <UserDashboard {...props} />}
                    />
                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.PROJECTS_ADD}
                        component={(props) => <UserDashboard {...props} />}
                    />
                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.PROJECTS_EDIT}
                        component={(props) => <UserDashboard {...props} />}
                    />
                    <PrivateRoute exact path={ROUTER_ACTIONS.PROJECT_TASK}
                                  component={(props) => <UserDashboard {...props} />}/>

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.PROJECT_PARTICIPANTS}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.PROJECT_CHECKLISTS}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.PROJECT_CHECKLIST_DETAILS}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.PROJECT_PROGRESS}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_ORGANIZATION}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_ROLES}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_TASKS}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_PROJECTS}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_CHECKLISTS}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_TEAMS}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_TEAM_ADD}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_CLIENTS}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_CLIENT_ADD}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_VENDORS}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_VENDOR_ADD}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_TEMPLATE}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_TEMPLATE_EDIT}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_TEMPLATE_ADD}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_TEMPLATE_CHECKLIST}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_TASKLIST_VIEW}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_CHECKLIST_VIEW}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_TEMPLATE_CHECKLIST_EDIT}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_USERS}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.CONFIGURATION_USER_ADD}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <PrivateRoute
                        exact
                        path={ROUTER_ACTIONS.PROJECT_MILESTONES}
                        component={(props) => <UserDashboard {...props} />}
                    />

                    <Route exact path={ROUTER_ACTIONS.SIGNIN} component={(props) => <Login {...props} />}/>
                    <Route exact path={ROUTER_ACTIONS.CHANGE_PASSWORD}
                           component={(props) => <UserDashboard {...props} />}/>
                    <Route component={NotFound}/>
                </Switch>
            </Router>
        </>
    );
}

export default AppRoutes;
