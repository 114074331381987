import React from 'react';
import {connect} from 'react-redux';
import {Box, Typography} from '@material-ui/core';
import mkdmLogo from '../../../../../../assets/mkdm-logo.png';

import TestModal from '../../../../../tasks/components/addTask/components/TestModal/TestModal';

import {useStyles} from './styles';


const EditProjectHeader = ({history, loggedInUser}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    // const onProjectClick = () => {
    //   history.push('/actionStation');
    // };

    return (
        <>
            <Box className={classes.outerContainer}>
                <Box className={classes.mainContainer}>
                    <Box className={classes.imageContainer}>
                        <img src={mkdmLogo} alt='logo' className={classes.image}/>
                    </Box>
                    <Box className={classes.contentContainer}>
                        <Box> <Typography className={classes.heading} variant="h2">Edit Project</Typography> </Box>
                    </Box>
                </Box>
            </Box>

            <TestModal open={open} handleClose={handleClose}/>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProjectHeader);
