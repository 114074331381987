import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import moment from 'moment';
import Button from '@material-ui/core/Button';

import { useStyles } from './styles';
import './MilestoneList.css';
import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import CustomTheme from '../../../../utilities/theme/theme.json'
import { getAllMilestoneRequest, showTasklistUnderMilestones } from '../../MilestonesApiActions'
import { useParams } from 'react-router';

const MilestoneList = ({
    history,
    loggedInUser,
    showTaskDetailsPane,
    showAddUpdateForm,
    addUpdateRes,
    getAllMilestones,
    allMilestonesList,
    getTasklistsUnderMilestone
}) => {
    const classes = useStyles();
    const { id } = useParams();
    const projectId = id;
    const [openedMilestones, setOpenedMilestones] = useState([])
    //for highlighting row
    const [selectedRow, setSelectedRow] = useState(null);
    /*
    UseEffect for storing the list of milestone in mergedTasks state
   */
    useEffect(() => {
        getAllMilestones(projectId)
    }, []);

    /*
    UseEffect for storing the res add/edit in mergedTasks state and delete milstone row
   */
    useEffect(() => {
        if(addUpdateRes && addUpdateRes.res)
        {
            setSelectedRow(addUpdateRes.res)
        } else {}
    }, [addUpdateRes])

    /*
    for rendering status wise icons
   */
    const renderIconsAsPerStatus = (status) => {
        let icon;
        switch (status) {
            case "todo":
                icon = (<span className="for-padding-right for-font-size" style={{ color: CustomTheme.not_statred_status }}>
                    <i className='far fa-circle' title='Not Started'></i>
                </span>)
                break;
            case "in_progress":
                icon = (<span className="for-padding-right for-font-size" style={{ color: CustomTheme.in_progress_status }}>
                    <i className='far fa-play-circle' title='In progress'></i>
                </span>)
                break;
            case "stopped":
                icon = (<span className="for-padding-right for-font-size" style={{ color: CustomTheme.stoppped_status }}>
                    <i className='far fa-pause-circle' title='Stopped'></i>
                </span>)
                break;
            case "blocked":
                icon = (<span className="for-padding-right for-font-size" style={{ color: CustomTheme.blockked_status }}>
                    <i className='fas fa-ban' title='Blocked'></i>
                </span>)
                break;
            case "complete":
                icon = (<span className="for-padding-right for-font-size" style={{ color: CustomTheme.complete_status }}>
                    <i className='far fa-check-circle' title='Complete'></i>
                </span>)
                break;
            case "deferred":
                icon = (<span className="for-padding-right for-font-size" style={{ color: CustomTheme.deferred_status }}>
                    <i className='fas fa-stop-circle' title='Deferred'></i>
                </span>)
                break;
            case "ready_for_review":
                icon = (<span className="for-padding-right for-font-size" style={{ color: CustomTheme.ready_for_review }}>
                    <i className='fas fa-check-double' title='Ready for Review'></i>
                </span>)
                break;
            default:
            // code block
        }
        return icon
    }

    /*
    for icon  status wise rendering
    @param status
   */
    const columns = [
        {
            field: '',
            title: '',
            cellStyle: {
                width: '8%',
            },
            headerStyle: {
                borderRadius: '5px 0rem 0rem 0rem',
            },
            render: rowData => {
                if (rowData.isSubTask) {
                    return (
                        <></>
                    )
                } else {
                    return (
                        <span>
                            <i className='fas fa-flag' />
                        </span>
                    );
                }
            },
        },
        {
            field: 'task',
            title: 'Task',
            filterPlaceholder: 'Milestone',
            cellStyle: {
                width: '30%',
            },
            headerStyle: {
                borderRadius: '5px 0rem 0rem 0rem',
            },
            render: ({ title, color, id, status }) => {
                return (
                    <Box className={classes.taskContainer}>
                        {renderColorDot(color)}
                        <div className="for-display-flex">
                            <Tooltip title={title.length > 49 ? title : ''} placement="top">
                                <Typography
                                    variant="body1"
                                    className="for-display-flex"
                                >
                                    {renderIconsAsPerStatus(status)}
                                    <span className="overflow-styling">{title}</span>
                                </Typography>
                            </Tooltip>
                        </div>
                    </Box>
                );
            },
        },
        {
            field: 'assignee',
            title: 'Assignee',
            filterPlaceholder: 'Assignee',
            cellStyle: {
                width: '17%',
            },
            render: ({ assignee }) => {
                return (
                    <>
                        {
                            assignee && assignee.firstName
                                ?
                                <Box className='user-if-one'>
                                    <img
                                        src={`https://ui-avatars.com/api/?name=${assignee.firstName.charAt(0)}&background=random`}
                                        alt='profile'
                                        className='task-user-image'
                                    />
                                </Box>
                                :
                                <></>
                        }

                    </>
                );
            },
        },
        {
            field: 'priority',
            title: 'Priority',
            filterPlaceholder: 'Priority',
            cellStyle: {
                width: '14%',
            },
            render: ({ priority }) => {
                switch (priority) {
                    case 1:
                        return <span><i className='fas fa-angle-up' /></span>;

                    case 2:
                        return <span><i className='fas fa-angle-double-up' /></span>;

                    case 3:
                        return <span><i className='fas fa-angle-double-up' /></span>;

                    default:
                        return <span></span>;

                }
            },
        },
        {
            field: 'dueDate',
            title: 'Due Date',
            filterPlaceholder: 'Due Date',
            cellStyle: {
                width: '14%',
            },
            render: ({ dueDate }) => {
                return (
                    <>
                        {dueDate && dueDate ? <Typography variant='body1'>{moment(dueDate).format('DD/MM/YYYY')}</Typography> :
                            <Typography variant='body1'></Typography>}
                    </>
                );
            },
        },
        {
            field: 'status',
            title: 'Status',
            filterPlaceholder: 'Status',
            cellStyle: {
                width: loggedInUser.userRoles.isProjectMember ? '16%' : '11%',
            },
            headerStyle: {
                borderRadius: '0px 5px 0px 0px',
            },
            render: ({ status }) => {
                return (
                    <Typography
                        variant="body1"
                        className={
                            status === 'Pending'
                                ? classes.statusGreen
                                : status === 'Done'
                                    ? classes.statusOrange
                                    : status === 'todo'
                                        ? classes.statusUnknown
                                        : ''
                        }
                    >
                        <i className='fas fa-circle' />
                    </Typography>
                );
            },
        },
    ];

    /*
        function showing  color
        @param color
   */
    const renderColorDot = (color) => {
        switch (color) {
            case 'green':
                return (
                    <span className={`${classes.taskIcon} ${classes.green}`}>
                        <i className='far fa-check-circle' />
                    </span>
                );

            case 'red':
                return (
                    <span className={`${classes.taskIcon} ${classes.red}`}>
                        <i className='far fa-stop-circle' />
                    </span>
                );

            case 'blue':
                return (
                    <span className={`${classes.taskIcon} ${classes.blue}`}>
                        <i className='far fa-play-circle' />
                    </span>
                );

            case 'yellow':
                return (
                    <span className={`${classes.taskIcon} ${classes.orange}`}>
                        <i className='far fa-pause-circle' />
                    </span>
                );

            case 'blank':
                return (
                    <span className={`${classes.taskIcon}`}>
                        <i className='far fa-circle' />
                    </span>
                );

            default:
                return <span></span>;
        }
    }


    /*
        function for redirecting page
   */
    const redirectToProjectList = () => {
        history.goBack()
    }


    /*
        function for showing the header action buttons
   */
    const tableTitle = () => {
        return (
            <Grid style={{ borderBottom: `1px dashed ${CustomTheme.primaryColor}` }} container spacing={0}
                className='table-title'>
                <Grid className="width-100-per">
                    <Box className="margin-top-5">
                        <Tooltip title="Back">
                            <span style={{ color: CustomTheme.primaryColor }}
                                className="template-back-icon tasklist-back-icon"
                                onClick={redirectToProjectList}> <span className="back-btn-styling-forvertical-align"><i
                                    style={{ fontSize: '20px' }} className="far fa-arrow-alt-circle-left" /></span> </span>
                        </Tooltip>
                        {
                            loggedInUser.userRoles.isSystemSuperAdmin || loggedInUser.userRoles.isTeamLead
                                ? (
                                    <>
                                        <Button size="small" variant="outlined" color="primary"
                                            onClick={() => {
                                                showAddUpdateForm(null, 'milestone');
                                            }}
                                            className="project-blank-page-button-milestone float-right"><span
                                                className="style-for-table-header-icons"><i className="fas fa-flag" /></span>
                                            <span className="btn-margin-left"><span
                                                className="table-header-icon-title">+Milestone</span></span>
                                        </Button>
                                    </>
                                )
                                : <></>
                        }
                    </Box>
                </Grid>
                <Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <TableContainer className="for-box-shadow">
            {tableTitle()}
            <MaterialTable
                icons={{ Filter: () => <div /> }}
                columns={columns}
                data={allMilestonesList || []}
                options={{
                    padding: 'dense',
                    header: false,
                    toolbar: false,
                    filtering: true,
                    paging: false,
                    sorting: false,
                    emptyRowsWhenPaging: false,
                    draggable: false,
                    detailPanelColumnStyle: {
                        display: 'none',
                    },
                    headerStyle: {
                        borderBottom: '1px dashed #c5e2ff',
                        fontSize: '14px',
                        fontWeight: '600',
                        height: '40px',
                    },
                    rowStyle: rowData => {
                        return {
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#77797c',
                            borderBottom: '1px solid #f2f6fa',
                            padding: '10px',
                            lineHeight: '24px',
                            height: '50px',
                            backgroundColor: (selectedRow === rowData.id) ? '#0faeec17' : '#FFF'
                        };
                    }
                }}
                style={{
                    boxShadow: 'none',
                    borderRadius: '5px',
                    paddingBottom: '0.8rem',
                    color: '#77797c',
                }}
                onRowClick={(e, rowData) => {
                    if (rowData.isSubTask) {
                    } else {
                        setSelectedRow(rowData.id)
                        showTaskDetailsPane(rowData.id, "milestone");

                        if (openedMilestones.includes(rowData.id)) {
                            setSelectedRow(rowData.id);
                            let data = Object.assign([], openedMilestones)
                            let filterArrayForFindIndex = data.findIndex((id) => id === rowData.id)
                            data.splice(filterArrayForFindIndex, 1)
                            setOpenedMilestones(data)
                            getTasklistsUnderMilestone(rowData.tasklists, "milestoneClose", rowData.id)
                        } else {
                            setSelectedRow(rowData.id);
                            if (rowData && rowData.tasklists) {
                                getTasklistsUnderMilestone(rowData.tasklists, "milestoneOpen", rowData.id)
                                let data = Object.assign([], openedMilestones)
                                data.push(rowData.id)
                                setOpenedMilestones(data)
                            }
                        }
                    }
                }}
            />
        </TableContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
        allMilestonesList: state.milestones.milestoneList
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllMilestones: (projectId) => {
            return dispatch(getAllMilestoneRequest(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getTasklistsUnderMilestone: (data, type, milestoneId) => {
            return dispatch(showTasklistUnderMilestones(data, type, milestoneId))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MilestoneList);
