import moment from 'moment';

export const matchPassword = (value, password) => {
    return value === password;
};

export const validateFromAndToTime = (fromDateTime, value) => {
    const toDateAndTime = moment(value).subtract('00:02:00').format();
    return !moment(toDateAndTime).isBefore(fromDateTime);
};
