import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import Auth from '../../utilities/Auth';
import {Redirect} from 'react-router-dom';

import clsx from 'clsx';
import {Box, Snackbar, Typography} from '@material-ui/core';
import {Alert} from "@material-ui/lab";
import AppHeader from './components/AppHeader/AppHeader';
import AppDrawer from './components/AppDrawer/AppDrawer';
import UserDashboardRoutes from './UserDashboardRoutes';
import {useStyles} from './styles';

import AppLoader from '../../utilities/AppLoader/AppLoader';
import MessageModal from '../../utilities/MessageModal/MessageModal';
import {sessionExpiredHideMessage} from "../authentication//AuthenticationActions";
import {clearSnackbar} from "../../utilities/AppLoader/AppLoaderActions";

const UserDashboard = React.memo(({
                                      loggedInUser,
                                      clearSnackbarAction,
                                      showSnackbar,
                                      snackbarVariant,
                                      snackbarMessage,
                                      showSessionExpiredModal,
                                      sessionExpiredError,
                                      hideAuthenticationModal
                                  }) => {
    const classes = useStyles();
    const modalRef = useRef();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpenClose = () => {
        setOpen(!open);
    };

    const logoutUser = () => {
        Auth.logoutAndNavigateToLogin();
    }

    /**
     * Method used to close the snackbar
     */
    const handleCloseSnackbar = () => {
        clearSnackbarAction();
    };

    /**
     * Called when component received updated value of showSessionExpiredModal
     * Used to show the session expired modal
     */
    useEffect(() => {
        if (showSessionExpiredModal) {
            modalRef.current.setState({
                showModal: showSessionExpiredModal,
                message: sessionExpiredError,
                title: "Session Expired",
                showOkButton: true,
                showCancelButton: false,
                handleOk: () => {
                    hideAuthenticationModal();
                    logoutUser();
                },
                okText: "Ok",
            });
        }
    }, [showSessionExpiredModal]);

    if (!loggedInUser) {
        return <Redirect to="/"/>
    } else {
        return (
            <>
                <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                    <Typography className={classes.heading} variant="h2">Dashboard</Typography>
                </AppHeader>

                <AppDrawer logoutUser={logoutUser} open={open}/>

                <Box
                    className={clsx(classes.content, {
                        [classes.contentShift]: open,
                    })}
                >
                    <div className={classes.toolbar}/>
                    <UserDashboardRoutes
                        handleDrawerOpenClose={handleDrawerOpenClose}
                    />
                </Box>

                <MessageModal
                    ref={modalRef}/>

                <AppLoader/>

                <Snackbar open={showSnackbar} autoHideDuration={5000}
                          onClose={() => handleCloseSnackbar()}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <Alert severity={snackbarVariant ? snackbarVariant : "info"}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </>
        );
    }
});

const mapStateToProps = state => {
    return {
        loggedInUser: state.auth.loggedInUser,
        showSnackbar: state.showLoader.showSnackbar,
        snackbarVariant: state.showLoader.snackbarVariant,
        snackbarMessage: state.showLoader.snackbarMessage,

        showSessionExpiredModal: state.auth.showSessionExpiredModal,
        sessionExpiredError: state.auth.sessionExpiredError
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        clearSnackbarAction: () => {
            dispatch(clearSnackbar())
        },
        hideAuthenticationModal: () => {
            dispatch(sessionExpiredHideMessage());
        }
    }
};

const UserDashboardConnect = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserDashboard);

export default UserDashboardConnect;
