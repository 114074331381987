// Export Constants (Action names)
export const GET_ALL_TASK_LISTS = 'GET_ALL_TASK_LISTS';
export const GET_ALL_TASKS = 'GET_ALL_TASKS';
export const GET_ALL_PARTICIPANTS = 'GET_ALL_PARTICIPANTS';
export const GET_ALL_MILESTONE_LIST = 'GET_ALL_MILESTONE_LIST'
export const GET_SINGLE_TASK_LIST = 'GET_SINGLE_TASK_LIST';
export const GET_SINGLE_TASK_DETAILS = 'GET_SINGLE_TASK_DETAILS';
export const ADD_TASKLIST = 'ADD_TASKLIST';
export const UPDATE_TASKLIST = 'UPDATE_TASKLIST';
export const DELETE_TASKLIST = 'DELETE_TASKLIST';
export const ADD_TASK = 'ADD_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const REORDER_TASK_ROW = 'REORDER_TASK_ROW';
export const MY_TASKS = 'MY_TASKS';
export const ADD_IMPORTED_TASKLIST = 'ADD_IMPORTED_TASKLIST';

/**
 * Action called after get all task lists
 * @param taskLists
 */
export const getAllTaskLists = (taskLists) => {
    return {
        type: GET_ALL_TASK_LISTS,
        taskLists: taskLists
    };
};


/**
 * Action called after get all milestone lists
 * @param milestoneList
 */
export const getAllMilestone = (milestoneList) => {
    return {
        type: GET_ALL_MILESTONE_LIST,
        milestoneList: milestoneList
    };
};

/**
 * Action called after get all tasks
 * @param tasks
 */
export const getAllTasks = (tasks, typeOfTask, rowId, from, newtableData) => {
    return {
        type: GET_ALL_TASKS,
        tasks: tasks,
        typeOfTask: typeOfTask,
        rowId: rowId,
        from: from,
        newtableData: newtableData
    };
};

/**
 * Action called after get all participant lists
 * @param participants
 */
export const getAllParticipantLists = (participants) => {
    return {
        type: GET_ALL_PARTICIPANTS,
        participants: participants
    };
};

/**
 * Action called after get single task list detials by Id
 * @param singleTasklistDetails
 */
export const getSingleTaskList = (singleTasklistDetails) => {
    return {
        type: GET_SINGLE_TASK_LIST,
        singleTasklistDetails: singleTasklistDetails
    };
};

/**
 * Action called after get single task  detials by Id
 * @param singleTaskDetails
 */
export const getSingleTaskDetails = (singleTaskDetails) => {
    return {
        type: GET_SINGLE_TASK_DETAILS,
        singleTaskDetails: singleTaskDetails
    };
};

/**
 * Action called after addtasklist
 * @param addedTasklist
 */
export const addTasklist = (addedTasklist) => {
    return {
        type: ADD_TASKLIST,
        addedTasklist: addedTasklist
    };
};

/**
 * Action called after updatedtasklist
 * @param updatedTasklist
 */
export const updateTasklist = (updatedTasklist) => {
    return {
        type: UPDATE_TASKLIST,
        updatedTasklist: updatedTasklist
    };
};

/**
 * Action called after deletedTasklist
 * @param deletedTasklist
 */
export const deleteTasklist = (deletedTasklist) => {
    return {
        type: DELETE_TASKLIST,
        deletedTasklist: deletedTasklist
    };
};


/**
 * Action called after add Task
 * @param addedTask
 */
export const addTask = (addedTask) => {
    return {
        type: ADD_TASK,
        addedTask: addedTask
    };
};

/**
 * Action called after edit Task
 * @param updatedTask
 */
export const updateTask = (updatedTask) => {
    return {
        type: UPDATE_TASK,
        updatedTask: updatedTask
    };
};

/**
 * Action called after deleted Task
 * @param deletedTask
 */
export const deleteTask = (deletedTask) => {
    return {
        type: DELETE_TASK,
        deletedTask: deletedTask
    };
};

/**
 * for reorder task row
 * @param reorderTaskData
 */
export const reorderTask = (reorderTaskData) => {
    return {
        type: REORDER_TASK_ROW,
        reorderTaskData: reorderTaskData
    };
};

/**
 * for my Tasks
 * @param myTasksData
 */
export const myTasks = (myTasksData, loggedInUserId) => {
    return {
        type: MY_TASKS,
        myTasksData: myTasksData,
        loggedInUserId: loggedInUserId
    };
};

/**
 * Action called after imported tasklist 
 * @param importedTasklist
 */
 export const importedTasklist = (importedTasklist) => {
    return {
        type: ADD_IMPORTED_TASKLIST,
        importedTasklist: importedTasklist
    };
};