import React, {useEffect, useState} from 'react';
import "./Projects.css";
import {Card, Grid, Typography} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {useStyles} from '../Tasks/styles';
import {connect} from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import AddProjectRole from './components/addprojectrole/AddProjectRole'
import {getFunctionalRoleRequest, getProjectTypeRequest} from '../../ConfigurationApiActions'
import TableAndForm from './components/TableAndForm/TableAndForm';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchableDropDown from '../../../../utilities/SearchableDropDown/SearchableDropDown';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import CustomTheme from '../../../../utilities/theme/theme.json'
import {useHistory} from "react-router-dom";

const Projects = ({
                      templates,
                      setTagName,
                      allTags,
                      handleDrawerOpenClose,
                      history,
                      match,
                      getFunctionalRoles,
                      basicTitle,
                      setBasicTitle,
                      basicDescription,
                      setBasicDescription,
                      chips,
                      setChips,
                      setSelectedProjectType,
                      selectedProjectType,
                      setSelectedProjectRole,
                      selectedProjectRole,
                      openProjectRoleDialog,
                      setOpenProjectRoleDialog,
                      setShrinkProjectTitle,
                      shrinkProjectTitle,
                      editTemplate,
                      handleSubmit,
                      setOnSuccessHide,
                      onSuccessHide,
                      projectTypes,
                      setProjectTypes,
                      getProjectType,
                      setEditTemplate,
                      templateId,
                      getTemplateDetails,
                      setEditProjectTemplateId
                  }) => {


    const [fetchedFunctionalRoles, setFetchedFunctionalRoles] = useState(null)
    const [editType, setEditType] = useState(null);
    let historyForGoBack = useHistory();

    const classes = useStyles();
    useEffect(() => {
        getFunctionalRoles().then((res) => {
            let result = Object.entries(res).map(([k, v]) => (v));
            let createRole = {name: 'Create template role', id: "1"}
            result.unshift(createRole)
            setFetchedFunctionalRoles(result)

            if (templateId) {
                let findTemplate = templates.filter((template) => template.id === templateId)
                let roles = [];
                if (findTemplate[0] && findTemplate[0].functionalRoles && findTemplate[0].functionalRoles.length > 0) {
                    findTemplate[0].functionalRoles.forEach((role) => {
                        const findRole = result.find(item => item.id === role)
                        if (findRole) {
                            roles.push(findRole)
                        }
                    })
                } else {
                }
                if (findTemplate[0]) {
                    setBasicTitle(findTemplate[0].name)
                    setBasicDescription(findTemplate[0].metaInfo)
                    setChips(findTemplate[0].tags)
                    // let projectTypeArray=projectTypes&&projectTypes.find((type)=>type.id===findTemplate[0].project.type)
                    // console.log("projectTypeArray",projectTypeArray)
                    // setSelectedProjectType(projectTypeArray)
                    setEditType(findTemplate[0].type)
                    setSelectedProjectRole(roles)
                    setEditProjectTemplateId(findTemplate[0].id)
                }
            }
        })

    }, [templates])

    useEffect(() => {
        getProjectType().then((res) => {
            if (editType) {
                setSelectedProjectType(res && res.find((type) => type.id === editType))
            } else {
                setProjectTypes(res)
            }
        })
    }, [editType])

    return (
        <>
            {
                onSuccessHide
                    ?
                    <TableAndForm handleDrawerOpenClose={handleDrawerOpenClose} match={match} history={history}
                                       setEditTemplate={setEditTemplate} setOnSuccessHide={setOnSuccessHide}/>
                    :
                    <ValidatorForm
                        onSubmit={handleSubmit}
                    >
                        <Card className="projects-card-container">
                            <Tooltip title="Back">
                        <span style={{color: CustomTheme.primaryColor}}
                              className="template-add-edit-back-button"
                              onClick={() => historyForGoBack.goBack()}
                        >
                            <i style={{fontSize: '20px'}} className="far fa-arrow-alt-circle-left"/>
                        </span>
                            </Tooltip>
                            <Grid className="projects-card-title-container">
                                <Typography variant="h2" className="projects-card-title">
                                    {
                                        editTemplate
                                            ?
                                            'Edit Project Template'
                                            :
                                            'Add Project Template'
                                    }
                                </Typography>
                                {
                                    editTemplate
                                        ?
                                        <></>
                                        :
                                        <Typography variant="h4" className="projects-card-description">
                                            Let's create a new project template
                                        </Typography>
                                }
                            </Grid>
                            {
                                editTemplate
                                    ?
                                    <></>
                                    :
                                    <Grid className="projects-basic-title-container">
                                        <Typography variant="h3" className="projects-basic-title">
                                            Let's start with the basics
                                        </Typography>
                                    </Grid>
                            }

                            <Grid className="basic-margin-left-container" item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container>
                                    <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                        <Typography className="task-title" variant="body1">Enter a title for this
                                            template</Typography>
                                    </Grid>
                                    <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                        <TextValidator
                                            fullWidth
                                            required
                                            // variant="outlined"
                                            size="small"
                                            // label="Title"
                                            onChange={(e) => setBasicTitle(e.target.value)}
                                            name="name" value={basicTitle || ''}
                                            validators={['required', 'trim', 'maxStringLength:50']}
                                            errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached.']}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="basic-description-input-title" item xl={12} lg={12} md={12} sm={12}
                                  xs={12}>
                                <Typography variant="body1">Enter a brief description</Typography>
                            </Grid>
                            <Grid className="basic-margin-description margin-bott0m-in-basic" item xl={8} lg={8} md={8}
                                  sm={8} xs={8}>
                                <TextValidator
                                    fullWidth
                                    size="small"
                                    value={basicDescription || ''}
                                    onChange={(e) => setBasicDescription(e.target.value)}
                                    name="description"
                                    validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:250']}
                                    errorMessages={['At least one alphanumeric characters', 'The max length of 250 charachers reached']}
                                />
                            </Grid>
                            <Grid className="margin-bott0m-in-basic forpadding" item xl={12} lg={12} md={12} sm={12}
                                  xs={12}>
                                <span className="tag-icon-span-on-template">
                                    <i className='fas fa-tags'></i>
                                </span>
                                <Autocomplete
                                    multiple
                                    id="tags-filled"
                                    value={chips}
                                    className="tags-contianer"
                                    onChange={(event, newValue) => {
                                        setChips(newValue);
                                        // alert(newValue)
                                        // addChips(event,newValue);
                                    }}
                                    onInputChange={(event, newValue) => {
                                        setTagName(newValue);
                                    }}
                                    clearOnEscape={true}
                                    options={allTags ? Object.keys(allTags).map((option) => allTags[option].name) : []}
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip key={index} variant="outlined"
                                                  label={option} {...getTagProps({index})} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} variant="filled" placeholder="Type tag and hit enter"/>
                                    )}
                                />
                            </Grid>
                            <Grid className="margin-bott0m-in-basic forpadding" item xl={12} lg={12} md={12} sm={12}
                                  xs={12}>
                                <Grid container>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                        <Typography variant="body1"><span><i className="fas fa-folder"></i></span> What
                                            is the type of this project?</Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.dropDownContainer}>
                                        <SearchableDropDown errorMessages={['This field is required']}
                                                            validators={['required']} type={true} title={false}
                                                            name={false} checklist={false} value={selectedProjectType}
                                                            setValue={setSelectedProjectType}
                                                            label="Select project type" optionsList={projectTypes}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="margin-bott0m-in-basic forpadding" item xl={12} lg={12} md={12} sm={12}
                                  xs={12}>
                                <Grid container>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                        <Typography variant="body1"><span><i
                                            className="fas fa-user-tag"></i></span> Which roles will be a part of this
                                            template?</Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.dropDownContainer}>
                                        <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            className="project-role-dropdown"
                                            options={fetchedFunctionalRoles ? fetchedFunctionalRoles : []}
                                            getOptionLabel={(option) => option ? option.name : ''}
                                            value={selectedProjectRole ? selectedProjectRole : {}}
                                            onChange={(event, newRole) => {


                                                const findCreateNewRole = newRole.find((role) => role ? role.name === "Create template role" : '')
                                                if (findCreateNewRole) {
                                                    setOpenProjectRoleDialog(true)
                                                } else {


                                                    setSelectedProjectRole(newRole)
                                                }

                                            }}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    variant="standard"
                                                    label={
                                                        <Typography> Template Role </Typography>
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                className="new-template-btn btn-alignment"
                                variant="outlined"
                                size="small"
                                color="primary"
                            >
                                SAVE
                            </Button>
                        </Card>
                    </ValidatorForm>
            }
            {
                openProjectRoleDialog
                    ?
                    <AddProjectRole selectedProjectRole={selectedProjectRole} open={openProjectRoleDialog}
                                    setOpenProjectRoleDialog={setOpenProjectRoleDialog}
                                    setSelectedProjectRole={setSelectedProjectRole}
                                    setFetchedFunctionalRoles={setFetchedFunctionalRoles}
                                    setShrinkProjectTitle={setShrinkProjectTitle}/>
                    :
                    <></>
            }
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        templates: state.configuration.templates
        // loggedInUser: state.auth.loggedInUser,
        // post: state.postDetails.post,
        // comments:state.postDetails.comments
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFunctionalRoles: () => {
            return dispatch(getFunctionalRoleRequest()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getProjectType: () => {
            return dispatch(getProjectTypeRequest()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        // getTemplateDetails: (templateId) => {
        //     return dispatch(getAllTasklistAndMilestones(templateId)).then((res) => {
        //         return Promise.resolve(res);
        //     }, (error) => {
        //         return Promise.reject(error);
        //     });
        // },

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Projects);
