// Export Constants (Action names)
export const GET_ALL_TASK_LISTS = 'GET_ALL_TASK_LISTS';
export const GET_ALL_PARTICIPANTS = 'GET_ALL_PARTICIPANTS';
export const GET_ALL_MILESTONE_LIST = 'GET_ALL_MILESTONE_LIST'
export const GET_MILESTONE_DETAILS = 'GET_MILESTONE_DETAILS'
export const ADD_MILESTONE = 'ADD_MILESTONE';
export const UPDATE_MILESTONE = 'UPDATE_MILESTONE';
export const DELETE_MILESTONE = 'DELETE_MILESTONE';
export const TASKLISTS_UNDER_MILESTONE = 'TASKLISTS_UNDER_MILESTONE';

/**
 * Action called after get all task lists
 * @param taskLists
 */
export const getAllTaskLists = (taskLists) => {
    return {
        type: GET_ALL_TASK_LISTS,
        taskLists: taskLists
    };
};


/**
 * Action called after get all milestone lists
 * @param milestoneList
 */
export const getAllMilestone = (milestoneList) => {
    return {
        type: GET_ALL_MILESTONE_LIST,
        milestoneList: milestoneList
    };
};

/**
 * Action called after get all participant lists
 * @param participants
 */
export const getAllParticipantLists = (participants) => {
    return {
        type: GET_ALL_PARTICIPANTS,
        participants: participants
    };
};

/**
 * Action called after get  milestone details
 * @param milestoneDetails
 */
 export const getMilestoneDetails = (milestoneDetails) => {
    return {
        type: GET_MILESTONE_DETAILS,
        milestoneDetails: milestoneDetails
    };
};

/**
 * Action called after add milestone
 * @param milestoneRes
 */
 export const addMilestone = (milestoneRes) => {
    return {
        type: ADD_MILESTONE,
        milestoneRes: milestoneRes
    };
};

/**
 * Action called after update milestone
 * @param updateMilestoneRes
 */
 export const updateMilestone = (updateMilestoneRes) => {
    return {
        type: UPDATE_MILESTONE,
        updateMilestoneRes: updateMilestoneRes
    };
};

/**
 * Action called after delete milestone
 * @param deleteMilestoneRes
 */
 export const deleteMilestone = (deleteMilestoneRes) => {
    return {
        type: DELETE_MILESTONE,
        deleteMilestoneRes: deleteMilestoneRes
    };
};


/**
 * Action called after delete milestone
 * @param tasklistsUnderMilestone
 */
 export const showTasklists = (tasklistsUnderMilestone,typeOfMilestone, milestoneId) => {
    return {
        type: TASKLISTS_UNDER_MILESTONE,
        tasklistsUnderMilestone: tasklistsUnderMilestone,
        typeOfMilestone: typeOfMilestone,
        milestoneId: milestoneId
    };
};