import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import clsx from 'clsx';
import {Drawer, List, ListItem} from '@material-ui/core';
import {useStyles} from './styles';
import {ROUTER_ACTIONS} from '../../../../utilities/Constants';
import Tooltip from '@material-ui/core/Tooltip';
import MessageModal from '../../../../utilities/MessageModal/MessageModal'
import SetPasswordModal from '../../../../utilities/SetPasswordModal/SetPasswordModal';

const AppDrawer = ({open, loggedInUser, logoutUser}) => {
    const classes = useStyles();
    const modalRef = useRef();

    const [openSetPasswordDialog, setOpenSetPasswordDialog] = useState(false);
    let routePath = window.location.pathname
    let menuName = routePath.substring(1, 4)
    return (
        <Drawer
            variant='permanent'
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx(classes.paper, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <List className={classes.removePadding}>
                <ListItem
          button
          className={classes.button}
          component={NavLink}
          to={ROUTER_ACTIONS.ACTION_STATION_MY_TASKS}
          activeClassName={classes.activeMenu}
        >
          <span className={classes.icon}>
            <i className='fas fa-tasks' />
          </span>
          <span className={(classes.menuName, clsx(!open && classes.hide))}>
            Action Station
          </span>
        </ListItem>
                <Tooltip title={open === true ? '' : "Projects"} placement="top">
                    <ListItem
                        button
                        className={classes.button}
                        component={NavLink}
                        to={ROUTER_ACTIONS.PROJECTS_TEAMS}
                        activeClassName={classes.activeMenu}
                    >
          <span className={classes.icon}>
            <span className={menuName === "pro" ? classes.activeColor : null}>
              <i className='far fa-folder'/>
            </span>
          </span>
                        <span className={(classes.menuName, clsx(!open && classes.hide))}>
            <span className={menuName === "pro" ? classes.activeColor : null}>
              Projects
            </span>
          </span>
                    </ListItem>
                </Tooltip>
                {/* <ListItem button className={classes.button}>
          <span className={classes.icon}>
            <i className='far fa-hourglass' />
          </span>
          <span className={(classes.menuName, clsx(!open && classes.hide))}>
            Time Tracking
          </span>
        </ListItem>
        <ListItem button className={classes.button}>
          <span className={classes.icon}>
            <i className='far fa-file-alt' />
          </span>
          <span className={(classes.menuName, clsx(!open && classes.hide))}>
            Files
          </span>
        </ListItem>
        <ListItem button className={classes.button}>
          <span className={classes.icon}>
            <i className='fas fa-tachometer-alt' />
          </span>
          <span className={(classes.menuName, clsx(!open && classes.hide))}>
            Reports
          </span>
        </ListItem> */}
                {/* <ListItem button className={classes.button}>
          <span className={classes.icon}>
            <i className='far fa-comments' />
          </span>
          <span className={(classes.menuName, clsx(!open && classes.hide))}>
            Messages
          </span>
        </ListItem> */}
            </List>


            <List className={`${classes.removePadding} ${classes.profileContainer}`}>
                <ListItem button className={classes.button}
                          onClick={() => setOpenSetPasswordDialog(true)}
                >

          <span className={classes.profileImageContainer}>
            <Tooltip title={open === true ? '' : `${loggedInUser.firstName} ${loggedInUser.lastName}`} placement="top">
              <img
                  src={`https://ui-avatars.com/api/?name=${loggedInUser.firstName.charAt(0)}&background=random`}
                  alt='profile'
                  className={classes.profileImage}
              />
            </Tooltip>
          </span>
                    <span className={(classes.menuName, clsx(!open && classes.hide))}>
            {`${loggedInUser.firstName} ${loggedInUser.lastName}`}
          </span>
                </ListItem>
                {
                    loggedInUser.userRoles.isSystemSuperAdmin
                        ? (
                            <Tooltip title={open === true ? '' : "System Configuration"} placement="top">
                                <ListItem
                                    button
                                    component={NavLink}
                                    to={ROUTER_ACTIONS.CONFIGURATION_USERS}
                                    className={classes.button}
                                    activeClassName={classes.activeMenu}
                                >
              <span className={classes.icon}>
                <span className={menuName === "con" ? classes.activeColor : null}>
                  <i className="fas fa-cog"></i>
                </span>
              </span>
                                    <span className={(classes.menuName, clsx(!open && classes.hide))}>
                <span className={menuName === "con" ? classes.activeColor : null}>
                  System Configuration
                </span>
              </span>
                                </ListItem>
                            </Tooltip>
                        )
                        : <></>
                }

                {/* <ListItem button className={classes.button}>
          <span className={classes.icon}>
            <i className='far fa-question-circle' />
          </span>
          <span className={(classes.menuName, clsx(!open && classes.hide))}>
            Help
          </span>
        </ListItem> */}
                <Tooltip title={open === true ? '' : "Sign Out"} placement="top">
                    <ListItem button className={classes.button}
                              onClick={() => {
                                  const message = `Are you sure you want to logout now?`
                                  modalRef.current.setState({
                                      showModal: true,
                                      message: message,
                                      showOkButton: true,
                                      showCancelButton: true,
                                      showNote: false,
                                      note: '',
                                      handleOk: (note) => {
                                          logoutUser();
                                      },
                                      okText: "Yes",
                                      cancelText: 'No'
                                  });
                              }}>
          <span className={classes.icon}>
            <i className='fas fa-sign-out-alt'/>
          </span>
                        <span className={(classes.menuName, clsx(!open && classes.hide))}>
            Sign Out
          </span>
                    </ListItem>
                </Tooltip>
            </List>

            <SetPasswordModal
                openSetPasswordDialog={openSetPasswordDialog}
                closeSetPasswordDialog={() => {
                    setOpenSetPasswordDialog(false);
                }}
            />
            <MessageModal
                ref={modalRef}/>
        </Drawer>
    );
};

const mapStateToProps = state => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {}
};

const AppDrawerConnect = connect(
    mapStateToProps,
    mapDispatchToProps
)(AppDrawer);

export default AppDrawerConnect;
