import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux';
import CustomTheme from '../../../../../../../../../../../utilities/theme/theme.json';

import {Box, Grid, Tooltip, Typography} from '@material-ui/core';

import {getFunctionalRoleRequest} from '../../../../../../../../../../configuration/ConfigurationApiActions';
import {getAllParticipantsRequest} from '../../../../TasksApiActions';
import SearchableDropDown from '../../../../../../../../../../../utilities/SearchableDropDown/SearchableDropDown';

const RoleSelectionSection = React.memo(({
                                             handleChangeAutocomplete, projectId, formData, taskId,
                                             getFunctionalRoles, getAllParticipants
                                         }) => {
    const [allFunctionalRoleList, setAllFunctionalRoleList] = useState([]);
    const [showFunctionalRoleDropDown, setShowFunctionalRoleDropDown] = useState(false);
    const [participents, setParticipents] = useState([]);
    const [roleIndexForUserDropdown, setRoleIndexForUserDropdown] = useState(null)
    const [roleUserIndexForUserDropdown, setRoleUserIndexForUserDropdown] = useState(null)
    const [showApprovalDropdown, setShowApprovalDropdown] = useState(false);

    /**
     * Trigger the getFunctionalRoles and getAllParticipants API request and update the state value of allFunctionalRoleList and participents
     */
    const getData = () => {
        // getting all roles
        getFunctionalRoles(projectId).then((res) => {
            setAllFunctionalRoleList(res);
        });
        // getting all participents
        getAllParticipants(projectId).then((res) => {
            setParticipents(res);
        });
    };

    /**
     * Function is triggred when component render first time
     */
    useEffect(() => {
        setTimeout(() => {
            getData();
        }, 0)
    }, []);

    /**
     * Function is triggred when component is received updated value of taskId
     * Update the state value of showApprovalDropdown
     */
    useEffect(() => {
        if (taskId) {
            setShowApprovalDropdown(true);
        } else {
            setShowApprovalDropdown(false);
        }
    }, [taskId]);

    /**
     * Function return the UI of selected roles and its users
     */
    const selectedUserRender = (userId) => {
        let id = taskId ? userId.id : userId.id
        if (id) {
            const findUser = participents && participents.find((user) => user.id === id);
            if (findUser) {
                const userName = findUser.firstName + " " + findUser.lastName;
                return (
                    <Tooltip title={userName}>
                        <div className="text-overflow-ellipsis">{userName}</div>
                    </Tooltip>
                );
            } else {
                return "";
            }
        } else {
            return "";
        }
    };

    return (
        <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box className="details-header">
                    <Box className="detail-header-content-for-users-icon margin-top-10">
                        {
                            showApprovalDropdown
                                ?
                                <Box
                                    className="inline-flex for-width-120"
                                >
                                    <SearchableDropDown
                                        projectDefination={true} firstLastName={true}
                                        title={false} name={false}
                                        checklist={false} forNameAttr={"approver"}
                                        value={formData.approver}
                                        setValue={handleChangeAutocomplete}
                                        label="Select Approver"
                                        optionsList={participents}/>
                                </Box>
                                : (
                                    <Box
                                        className="inline-flex"
                                        onClick={() => setShowApprovalDropdown(true)}
                                    >
                                        <Box>
                                            <img
                                                src='https://ui-avatars.com/api/?name=A&background=random'
                                                alt='profile'
                                                className="task-assignee-image"
                                            />
                                        </Box>
                                        <Box>
                                            <Typography style={{color: CustomTheme.fontColor}}
                                                        variant='body2'>Approver</Typography>
                                            <Typography style={{color: CustomTheme.primaryColor}} variant='body2'
                                                        className='user-name'><i
                                                className="fas fa-plus-circle"></i> Add</Typography>
                                        </Box>
                                    </Box>
                                )
                        }
                        {
                            allFunctionalRoleList.length > 0 && (
                                <>
                                    {
                                        showFunctionalRoleDropDown
                                            ? (
                                                <Box
                                                    className="inline-flex for-width-120"
                                                >
                                                    <SearchableDropDown
                                                        projectDefination={true} name={true}
                                                        forNameAttr={"functionalRole"}
                                                        selectedFunctionalRole={formData.functionalRole}
                                                        setShowFunctionalRoleDropDown={setShowFunctionalRoleDropDown}
                                                        setSelectedFunctionalRole={handleChangeAutocomplete}
                                                        label="Select Role"
                                                        optionsList={allFunctionalRoleList}/>
                                                </Box>
                                            )
                                            : (
                                                <Box
                                                    className="inline-flex"
                                                    onClick={() => setShowFunctionalRoleDropDown(true)}
                                                >
                                                    <Box>
                                                        <img
                                                            src='https://ui-avatars.com/api/?name=R&background=random'
                                                            alt='profile'
                                                            className="task-assignee-image"
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Typography style={{color: CustomTheme.fontColor}} variant='body2'>New
                                                            Role</Typography>
                                                        <Typography style={{color: CustomTheme.primaryColor}}
                                                                    variant='body2' className='user-name'><i
                                                            className="fas fa-plus-circle"></i> Add</Typography>
                                                    </Box>
                                                </Box>
                                            )
                                    }
                                </>
                            )
                        }
                    </Box>
                </Box>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box className="details-header">
                    <Box className="detail-header-content-for-functional-role">
                        {
                            formData && formData.functionalRole && formData.functionalRole.map((selectedRole, roleIndex) => (
                                <div key={roleIndex} className="inline-flex box-padding">
                                    {
                                        selectedRole.users && selectedRole.users.length > 0 && selectedRole.users.map((user, userIndex) => (
                                            <div key={userIndex} className="for-width-200">
                                                {
                                                    roleIndexForUserDropdown === roleIndex && roleUserIndexForUserDropdown === userIndex
                                                        ? (
                                                            <SearchableDropDown
                                                                isUpdate={taskId} userIndex={userIndex}
                                                                selectedRole={selectedRole} projectDefination={true}
                                                                firstLastName={true} forNameAttr={"functionalRole"}
                                                                selectedFunctionalRole={formData.functionalRole}
                                                                value={user ? user : null}
                                                                setValue={handleChangeAutocomplete}
                                                                setRoleIndexForUserDropdown={setRoleIndexForUserDropdown}
                                                                setRoleUserIndexForUserDropdown={setRoleUserIndexForUserDropdown}
                                                                label={selectedRole.name} optionsList={participents}/>
                                                        )
                                                        : (
                                                            <Box
                                                                className="inline-flex box-padding"
                                                                onClick={() => {
                                                                    setRoleIndexForUserDropdown(roleIndex);
                                                                    setRoleUserIndexForUserDropdown(userIndex);
                                                                }}
                                                            >
                                                                <Box>
                                                                    <img
                                                                        src={`https://ui-avatars.com/api/?name=${selectedRole.name}&background=random`}
                                                                        alt='profile'
                                                                        className="task-assignee-image"
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <Typography style={{color: CustomTheme.fontColor}}
                                                                                variant='body2'>{selectedRole.name}</Typography>
                                                                    <Typography style={{color: CustomTheme.primaryColor}}
                                                                                className='user-name'>
                                                                        {
                                                                            user
                                                                                ? selectedUserRender(user)
                                                                                : <Typography variant="body2"
                                                                                              style={{color: CustomTheme.primaryColor}}><i
                                                                                    className="fas fa-plus-circle"></i> Assign</Typography>
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        )
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
});

/**
 * Used for selecting the part of the data from the redux store that the connected component needs and pass it as a props
 * It is called every time when update the state of redux store
 * @param state
 */
const mapStateToProps = (state) => {
    return {}
};

/**
 * Used to specify the which actions your component might need to dispatch and pass it to connected component as a props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getFunctionalRoles: (projectId) => {
            console.log("getFunctionalRoles req");
            return dispatch(getFunctionalRoleRequest(projectId)).then((res) => {
                console.log("getFunctionalRoles res");
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllParticipants: (projectId) => {
            console.log("getAllParticipants req");
            return dispatch(getAllParticipantsRequest(projectId)).then((res) => {
                console.log("getAllParticipants res");
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        }
    };
};

/**
 * Used to connects a react component to a redux store
 */
export default connect(mapStateToProps, mapDispatchToProps)(RoleSelectionSection);
