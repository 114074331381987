import React, { useEffect, useRef, useState } from 'react'
import './UsersAddEditForm.css'
import { Grid, Typography } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import MobileNumber from '../../../../../utilities/MobileNumber/MobileNumber';
import { deleteMemberForShowingMessage } from '../../../ConfigurationApiActions'
import { connect } from 'react-redux';
import CustomTheme from '../../../../../utilities/theme/theme.json'
import MessageModal from '../../../../../utilities/MessageModal/MessageModal'
import LoaderForSaveAndEdit from '../../../../../utilities/AppLoader/LoaderForSaveAndEdit'
import SearchableDropDown from '../../../../../utilities/SearchableDropDown/SearchableDropDown'
import { addAuthUserRequest, editAuthUserRequest, getAllAuthUsersRequest, getSingleAuthUserDetailsRequest } from '../../../../authentication/AuthenticationApiActions'
const roles = [
    { id: "1", name: 'Organisation super admin' },
    { id: "2", name: 'Project Member' },
    { id: "3", name: 'Team Lead' },
    { id: "4", name: 'Limited Access Users' }
]
const UsersAddEditForm = ({
    setSelectedRow,
    setAddNewUser,
    addNewUser,
    singleUserDetails,
    addAuthUser,
    editAuthUser,
    rowIndex,
    setRowIndex,
    loggedInUser,
    getAllAuthUsers,
    getSingleAuthUserDetails
}) => {
    const modalRef = useRef();

    const [showFirstName, setShowFirstName] = useState(false)
    const [showMiddleName, setShowMiddleName] = useState(false);
    const [showLastName, setShowLastName] = useState(false)
    const [showContactPhone, setShowContactPhone] = useState(false);
    const [showContactEmail, setShowContactEmail] = useState(false);
    const [showRole, setShowRole] = useState(false);

    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false)
    const [phoneValidationMessage, setPhoneValidationMessage] = useState(null)

    const [isUserFormUpdate, setIsUserFormUpdate] = useState(false)
    const [addEditButtonShow, setEditButtonShow] = useState(false)
    const [addEditRes, setAddEditRes] = useState(null)
    const [isShowLoader, setIsShowLoader] = useState(false);

    const [userFormData, setUserFormData] = useState({
        firstName: null,
        middleName: null,
        lastName: null,
        mobile: null,
        email: null,
        roles: []
    })

    useEffect(() => {
        if (addNewUser) {
            setUserFormData({
                firstName: null,
                middleName: null,
                lastName: null,
                mobile: null,
                email: null,
                roles: []
            })
            setShowFirstName(false)
            setShowMiddleName(false)
            setShowContactPhone(false)
            setShowContactEmail(false);
            setShowLastName(false);
            setShowRole(false);
            setPhoneValidationMessage('')
            setEditButtonShow(false)
        }
    }, [addNewUser])

    useEffect(() => {
        if (rowIndex) {
            setIsShowLoader(true)
            getSingleAuthUserDetails(rowIndex).then((res) => {
                setIsShowLoader(false)
                let userData = Object.assign({}, userFormData)
                if (res && res.roles && res.roles[0]) {
                    let roleName = res.roles[0]
                    roles.forEach((role) => {
                        if (role.name === roleName) {
                            userData.roles = role
                        }
                    })
                }
                userData.firstName = res.firstName
                userData.middleName = res.middleName
                userData.lastName = res.lastName
                userData.email = res.email
                userData.mobile = res.mobile
                setUserFormData(userData)
                setShowFirstName(false)
                setShowLastName(false)
                setShowMiddleName(false)
                setShowContactPhone(false)
                setShowContactEmail(false);
                setShowRole(false);
                setEditButtonShow(true)
                setAddNewUser(false)
            })
        } else {
        }

    }, [rowIndex, addEditRes])

    const clickHandler = () => {
        const data = {
            firstName: userFormData.firstName,
        }
        if (userFormData.lastName) {
            data.lastName = userFormData.lastName
        } else {
                data.lastName = ""
        }
        if (userFormData.middleName) {
            data.middleName = userFormData.middleName
        } else {
                data.middleName = ""
        }
        if (userFormData.mobile) {
            data.mobile = userFormData.mobile
        } else {
            if (singleUserDetails) {
                // data.mobile = ""
            } else { }
        }
        if (userFormData.email) {
            data.email = userFormData.email
        } else {
            if (singleUserDetails) {
                // data.email = ""
            } else { }
        }
        if (userFormData.roles) {
            data.roles = [userFormData.roles.name]
        } else {
            if (singleUserDetails) {
                data.roles = []
            } else { }
        }
        if (userFormData.mobile) {
            if (userFormData.mobile.length < 13) {
                setPhoneValidationMessage("Contact phone should 10 digit")
                return false;
            } else {
                submitForm(data);
                setPhoneValidationMessage('')
            }
        } else {
            submitForm(data);
            setPhoneValidationMessage('')
        }
    }
    const updateMobileNumber = (phone, validPhoneNumber) => {
        handleChangeAutocomplete("mobile", phone)
        setIsPhoneNumberValid(validPhoneNumber)
        if (validPhoneNumber || phone.length === 3) {
            setIsPhoneNumberValid(false)
            setPhoneValidationMessage('')
        } else {
            setIsPhoneNumberValid(true)
            setPhoneValidationMessage('Contact phone should 10 digit')
        }
    }

    const showAllValuesInFormHandler = () => {
        setEditButtonShow(false)
            setShowFirstName(true)
            setShowLastName(true)
            setShowMiddleName(true)
            setShowContactPhone(true)
            setShowContactEmail(true);
            setShowRole(true);
            setAddNewUser(false)
    }

    const submitForm = (data) => {
        if (rowIndex) {
            data.organizationId = loggedInUser.organizationId
            editAuthUser(rowIndex, data).then((res) => {

                getAllAuthUsers(true)

                setRowIndex(res.id)
                setSelectedRow(res.id)
                setEditButtonShow(true)
                setAddEditRes(res)
            })
        }
        else {
            data.organizationId = loggedInUser.organizationId
            addAuthUser(data).then((res) => {

                getAllAuthUsers(true)

                setRowIndex(res.id)
                setSelectedRow(res.id)
                setEditButtonShow(true)
                setAddEditRes(res)
            })
        }
    }

    const handleChangeInput = (event, value) => {
        const data = Object.assign({}, userFormData);
        data[event.target.name] = event.target.value;
        if (!isUserFormUpdate) {
            setIsUserFormUpdate(true)
        } else {
        }
        setUserFormData(data)
    }

    /**
  * Method executes on onChange action of autocomplete form element
  */
    const handleChangeAutocomplete = (elementName, selectedvalue) => {
        let data = Object.assign({}, userFormData);
        if (elementName === "mobile") {
            data.mobile = selectedvalue
            if (!isUserFormUpdate) {
                setIsUserFormUpdate(true)
            } else {
            }
        }
        else if (elementName === "roles") {
            data.roles = selectedvalue
            if (!isUserFormUpdate) {
                setIsUserFormUpdate(true)
            } else {
            }
        }
        else { }
        setUserFormData(data)
    }

    const handleEsc = (e) => {
        if (e.keyCode === 27) {
            if (rowIndex) {
                if (!isUserFormUpdate) {
                    setAddNewUser(false)
                    setSelectedRow(null)
                }
                else {

                    const title = `User`
                    const message = `Changes that you made in edit user may not be saved.`
                    modalRef.current.setState({
                        showModal: true,
                        message: message,
                        title: title,
                        showOkButton: true,
                        showCancelButton: true,
                        showNote: false,
                        note: '',
                        handleOk: (note) => {
                            setAddNewUser(false)
                            setSelectedRow(null)
                        },
                        okText: "Yes",
                        cancelText: 'No'
                    });
                }
            }
            else {
                if (isUserFormUpdate) {
                    const title = `User`
                    const message = `Changes that you made in user may not be saved.`
                    modalRef.current.setState({
                        showModal: true,
                        message: message,
                        title: title,
                        showOkButton: true,
                        showCancelButton: true,
                        showNote: false,
                        note: '',
                        handleOk: (note) => {
                            setAddNewUser(false)
                            setSelectedRow(null)
                        },
                        okText: "Yes",
                        cancelText: 'No'
                    });
                }
                else {
                    setAddNewUser(false)
                }
            }
        }
    }

    return (
        <>
            {
                isShowLoader
                    ?
                    <span className="loader-for-save-and-edit"><LoaderForSaveAndEdit /></span>
                    :
                    <>
                        <ValidatorForm
                            onSubmit={() => clickHandler()}
                            onKeyDown={(e) => handleEsc(e)}
                        >
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                style={{ borderBottom: `1px dashed ${CustomTheme.primaryColor}` }}
                                className="users-detail-panel-padding">
                                <Grid container justify="space-between">
                                    <Grid>
                                    </Grid>
                                    {
                                        addEditButtonShow
                                            ?
                                            <Grid onClick={() => {
                                                showAllValuesInFormHandler();
                                            }} className="team-detail-panel-save-btn"
                                                style={{ color: CustomTheme.primaryColor }}
                                            >
                                                <i className="far fa-edit"></i>
                                            </Grid>
                                            :
                                            <Grid className="save-btn-position-style">
                                                <Button size="small"
                                                    type="submit"
                                                    className="form-panel-save-btn submit-btn"
                                                    style={{ color: CustomTheme.primaryColor }}
                                                >
                                                    <i className="far fa-save"></i>
                                                </Button>
                                            </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                className="padding-to-grid client-vendor-background-color">
                                <Grid container>
                                    <Grid xl={10} lg={10} md={10} sm={10} xs={10}>
                                        {
                                            showFirstName
                                                ?
                                                <Grid>
                                                    <TextValidator
                                                        fullWidth
                                                        autoFocus
                                                        size="small"
                                                        label="First Name"
                                                        onChange={handleChangeInput}
                                                        name="firstName"
                                                        value={userFormData.firstName || ''}
                                                        validators={['required', 'trim', 'maxStringLength:50']}
                                                        errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                                    />
                                                </Grid>
                                                :
                                                <>
                                                    {
                                                        rowIndex
                                                            ?
                                                            <Grid>
                                                                <Typography variant="h1"
                                                                    style={{ color: CustomTheme.primaryColor }}
                                                                    className="add-title-text">
                                                                    <span>
                                                                        {userFormData.firstName}
                                                                    </span>
                                                                </Typography>
                                                            </Grid>
                                                            :
                                                            <Grid onClick={() => setShowFirstName(true)}>
                                                                <Typography variant="h2"
                                                                    style={{ color: CustomTheme.primaryColor }}
                                                                    className="add-title-text">
                                                                    <span><i className="far fa-edit"></i>   Click to add First name</span>
                                                                </Typography>
                                                            </Grid>
                                                    }
                                                </>
                                        }
                                        {
                                            showMiddleName
                                                ?
                                                <Grid>
                                                    <TextValidator
                                                        fullWidth
                                                        autoFocus
                                                        // required
                                                        // variant="outlined"
                                                        size="small"
                                                        label="Middle Name"
                                                        onChange={handleChangeInput}
                                                        name="middleName"
                                                        value={userFormData.middleName || ''}
                                                        validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:50']}
                                                        errorMessages={['At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                                    />
                                                </Grid>
                                                :
                                                <>
                                                    {
                                                        rowIndex
                                                            ?
                                                            <Grid>
                                                                <Typography variant="h1"
                                                                    style={{ color: CustomTheme.primaryColor }}
                                                                    className="add-title-text">
                                                                    <span>
                                                                        {userFormData.middleName}
                                                                    </span>
                                                                </Typography>
                                                            </Grid>
                                                            :
                                                            <Grid onClick={() => setShowMiddleName(true)}>
                                                                <Typography variant="h2"
                                                                    style={{ color: CustomTheme.primaryColor }}
                                                                    className="add-title-text">
                                                                    <span><i className="far fa-edit"></i>   Click to add Middle name</span>
                                                                </Typography>
                                                            </Grid>
                                                    }
                                                </>
                                        }
                                        {
                                            showLastName
                                                ?
                                                <Grid>
                                                    <TextValidator
                                                        fullWidth
                                                        autoFocus
                                                        // required
                                                        // variant="outlined"
                                                        size="small"
                                                        label="Last Name"
                                                        onChange={handleChangeInput}
                                                        name="lastName"
                                                        value={userFormData.lastName || ''}
                                                        validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:50']}
                                                        errorMessages={['At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                                    />
                                                </Grid>
                                                :
                                                <>
                                                    {
                                                        rowIndex
                                                            ?
                                                            <Grid>
                                                                <Typography variant="h1"
                                                                    style={{ color: CustomTheme.primaryColor }}
                                                                    className="add-title-text">
                                                                    <span>
                                                                        {userFormData.lastName}
                                                                    </span>
                                                                </Typography>
                                                            </Grid>
                                                            :
                                                            <Grid onClick={() => setShowLastName(true)}>
                                                                <Typography variant="h2"
                                                                    style={{ color: CustomTheme.primaryColor }}
                                                                    className="add-title-text">
                                                                    <span><i className="far fa-edit"></i>   Click to add Last name</span>
                                                                </Typography>
                                                            </Grid>
                                                    }
                                                </>
                                        }
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="padding-to-grid">
                                <Grid container>
                                    <Grid style={{ color: CustomTheme.fontColor, paddingTop: '6px' }} item xl={1} lg={1} md={1} sm={12}
                                        xs={12}>
                                        <span className="client-vendor-contact-icons"><i
                                            className="fas fa-phone-alt"></i></span>
                                    </Grid>
                                    {
                                        showContactPhone
                                            ?
                                            <Grid className="client-vendor-input-grid-for-contact-phone" item xl={10}
                                                lg={10} md={10} sm={12} xs={12}>
                                                <MobileNumber updateMobileNumber={updateMobileNumber}
                                                    specialLabel="Contact Phone" phone={userFormData.mobile} />
                                                {
                                                    isPhoneNumberValid
                                                        ?
                                                        <Typography
                                                            className="error-message-phone">{phoneValidationMessage}</Typography>
                                                        :
                                                        <></>
                                                }
                                            </Grid>
                                            :
                                            <>
                                                {
                                                    rowIndex
                                                        ?
                                                        <Grid className="client-vendor-contact-text-grid">
                                                            <Grid>
                                                                <Typography variant="body2" style={{
                                                                    color: CustomTheme.fontColor,
                                                                    paddingBottom: '4px'
                                                                }} className="client-vendor-contact-person">Contact
                                                                    Phone</Typography>
                                                            </Grid>
                                                            <Grid style={{ color: CustomTheme.primaryColor }}
                                                                className="client-vendor-contact-person-phone-email">
                                                                <Typography variant="body1"
                                                                    style={{ color: CustomTheme.primaryColor }}><span
                                                                        className="edit-icon-small"></span>{userFormData.mobile}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        <Grid className="client-vendor-click-to-add-text">
                                                            <Typography variant="h3"
                                                                onClick={() => setShowContactPhone(true)}
                                                                style={{ color: CustomTheme.primaryColor }}
                                                                className="add-description-text">
                                                                <i className="far fa-edit"></i><span style={{ color: CustomTheme.primaryColor }} className="edit-icon-small"> Click to add a contact phone for this User</span>
                                                            </Typography>
                                                        </Grid>
                                                }
                                            </>

                                    }

                                </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="padding-to-grid">
                                <Grid container>
                                    <Grid style={{ color: CustomTheme.fontColor, paddingTop: '6px' }} item xl={1} lg={1} md={1} sm={12}
                                        xs={12}>
                                        <span className="client-vendor-contact-icons"><i
                                            className="far fa-envelope"></i></span>
                                    </Grid>
                                    {
                                        showContactEmail
                                            ?
                                            <Grid className="client-vendor-input-grid" item xl={10} lg={10} md={10}
                                                sm={12} xs={12}>
                                                <TextValidator
                                                    fullWidth
                                                    autoFocus
                                                    // required
                                                    // variant="outlined"
                                                    size="small"
                                                    label="Contact Email"
                                                    onChange={handleChangeInput}
                                                    name="email"
                                                    value={userFormData.email || ''}
                                                    validators={['required', 'isEmail']}
                                                    errorMessages={['This field is required', 'Email is not valid']}
                                                />
                                            </Grid>
                                            :
                                            <>
                                                {
                                                    rowIndex
                                                        ?
                                                        <Grid className="client-vendor-contact-text-grid">
                                                            <Grid>
                                                                <Typography variant="body2" style={{
                                                                    color: CustomTheme.fontColor,
                                                                    paddingBottom: '3px'
                                                                }} className="client-vendor-contact-person">Contact
                                                                    Email</Typography>
                                                            </Grid>
                                                            <Grid style={{ color: CustomTheme.primaryColor }}
                                                                className="client-vendor-contact-person-phone-email">
                                                                <Typography variant="body1"
                                                                    style={{ color: CustomTheme.primaryColor }}><span
                                                                        className="edit-icon-small"></span>{userFormData.email}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        <Grid className="client-vendor-click-to-add-text">
                                                            <Typography variant="h3"
                                                                onClick={() => setShowContactEmail(true)}
                                                                style={{ color: CustomTheme.primaryColor }}
                                                                className="add-description-text">
                                                                <span style={{ color: CustomTheme.primaryColor }}
                                                                    className="edit-icon-small">
                                                                    <i className="far fa-edit"></i>   Click to add a contact email for this User
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                }
                                            </>

                                    }
                                </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="padding-to-grid">
                                <Grid container>
                                    <Grid style={{ color: CustomTheme.fontColor, paddingTop: '4px' }} item xl={1} lg={1} md={1} sm={12}
                                        xs={12}>
                                        <span className="client-vendor-contact-icons"><i
                                            className="fas fa-user-tag"></i></span>
                                    </Grid>
                                    {
                                        showRole
                                            ?
                                            <Grid className="client-vendor-input-grid" item xl={10} lg={10} md={10}
                                                sm={12} xs={12}>
                                                <SearchableDropDown title={false} name={true} checklist={false}
                                                    value={userFormData.roles} setValue={handleChangeAutocomplete} label="System Role" forNameAttr={"roles"}
                                                    optionsList={roles} />
                                            </Grid>
                                            :
                                            <>
                                                {
                                                    rowIndex
                                                        ?
                                                        <Grid className="client-vendor-contact-text-grid">
                                                            <Grid>
                                                                <Typography variant="body2" style={{
                                                                    color: CustomTheme.fontColor,
                                                                    paddingBottom: '3px'
                                                                }}
                                                                    className="client-vendor-contact-person">Role</Typography>
                                                            </Grid>
                                                            <Grid style={{ color: CustomTheme.primaryColor }}
                                                                className="client-vendor-contact-person-phone-email">
                                                                <Typography variant="body1"
                                                                    style={{ color: CustomTheme.primaryColor }}><span
                                                                        className="edit-icon-small"></span>{userFormData && userFormData.roles ? userFormData.roles.name : ''}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        <Grid className="client-vendor-click-to-add-text">
                                                            <Typography variant="h3" onClick={() => setShowRole(true)}
                                                                style={{ color: CustomTheme.primaryColor }}
                                                                className="add-description-text">
                                                                <span style={{ color: CustomTheme.primaryColor }}
                                                                    className="edit-icon-small">
                                                                    <i className="far fa-edit"></i>   Click to add a role for this User </span>
                                                            </Typography>
                                                        </Grid>
                                                }
                                            </>

                                    }
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                        <MessageModal
                            ref={modalRef}
                        />
                    </>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        singleUserDetails: state.auth.singleUserDetails,
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addAuthUser: (data) => {
            return dispatch(addAuthUserRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllAuthUsers: (isLoaderShowing) => {
            return dispatch(getAllAuthUsersRequest(isLoaderShowing)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getSingleAuthUserDetails: (id) => {
            return dispatch(getSingleAuthUserDetailsRequest(id)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        editAuthUser: (id, data) => {
            return dispatch(editAuthUserRequest(id, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        deleteMember: () => {
            return dispatch(deleteMemberForShowingMessage())
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersAddEditForm);
