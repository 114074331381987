import HttpRequest from '../../utilities/HttpRequest';

import {API_ACTIONS, SNACKBAR_VARIAINT} from '../../utilities/Constants';
import {showSnackbar} from '../../utilities/AppLoader/AppLoaderActions'

import {getMyTasksOfActionStation} from "./ActionStationActions";


/**
 * Server API call to get my tasks of user in action station
 * @returns {function(*)}
 */
export const getMyTasksOfUserInActionStation = (userId, filterParams) => {
    let url;
    let searchData = {
        startDate:filterParams.startDate,
        endDate:filterParams.endDate
    }
    let searchDataForProjec = {
        projectId:filterParams && filterParams.project!==null ? filterParams.project.id :''
    }
    let searchDataForMilestone = {
        milestoneId: filterParams && filterParams.milestone!==null ? filterParams.milestone.id :''
    }
    let searchDataDateRangeAndProject = {
        startDate:filterParams.startDate,
        endDate:filterParams.endDate,
        projectId:filterParams && filterParams.project!==null ? filterParams.project.id :''
    }
    let searchDataRangeAndMilestone = {
        startDate:filterParams.startDate,
        endDate:filterParams.endDate,
        milestoneId: filterParams && filterParams.milestone!==null ? filterParams.milestone.id :''
    }
    return (dispatch) => {
        
        if (filterParams.startDate!==null && filterParams.milestone !==null &&  filterParams.project !==null)
        {
            url = `${API_ACTIONS.ACTION_STATION_MY_TASKS}/${userId}?searchData=${JSON.stringify(searchDataRangeAndMilestone)}`;
        }
        else if (filterParams.startDate!==null && filterParams.project !==null && filterParams.milestone ==null)
        {
            url = `${API_ACTIONS.ACTION_STATION_MY_TASKS}/${userId}?searchData=${JSON.stringify(searchDataDateRangeAndProject)}`;
        }
        else if(filterParams.startDate!==null)
        {
            url = `${API_ACTIONS.ACTION_STATION_MY_TASKS}/${userId}?searchData=${JSON.stringify(searchData)}`;
        }
        else if (filterParams.project !==null && filterParams.milestone ===null)
        {
            url = `${API_ACTIONS.ACTION_STATION_MY_TASKS}/${userId}?searchData=${JSON.stringify(searchDataForProjec)}`;
        }
        else if (filterParams.milestone !==null ) 
        {
            url = `${API_ACTIONS.ACTION_STATION_MY_TASKS}/${userId}?searchData=${JSON.stringify(searchDataForMilestone)}`;
        }
        else 
        {
            url = `${API_ACTIONS.ACTION_STATION_MY_TASKS}/${userId}`;
        }
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                dispatch(getMyTasksOfActionStation(res.data));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};
