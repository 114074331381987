// Export Constants (Action names)
export const ACTION_STATION_MY_TASKS_LIST = 'ACTION_STATION_MY_TASKS_LIST';

/**
 * Action called get my tasks of user in action sattion
 * @param myTasksList
 */
export const getMyTasksOfActionStation = (myTasksList) => {
    return {
        type: ACTION_STATION_MY_TASKS_LIST,
        myTasksList: myTasksList
    };
};

