import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Box, Card } from '@material-ui/core';
import AppHeader from '../userDashboard/components/AppHeader/AppHeader';
import ProjectHeader from '../projects/components/details/Project/components/ProjectHeader/ProjectHeader'
import { useStyles } from './styles';
import TrackCountCard from './components/TrackCountCard/TrackCountCard'
import FilterProgressActivity from './components/FilterProgressActivity/FilterProgressActivity';
import ProgressTasksActivityList from './components/ProgressTasksActivityList/ProgressTasksActivityList';
const Progress = ({
    handleDrawerOpenClose, history, match, loggedInUser
}) => {
    const classes = useStyles();
    const projectId = match.params.id;
    const [filterParams, setFilterParams] = useState({
        user: null,
        event: null,
        task: null,
        state: null
    })
    return (
        <>
            <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                <ProjectHeader history={history} match={match} />
            </AppHeader>
            <TrackCountCard />
            <Card className={classes.progressFilterAndActivityListCard}>
                {
                    loggedInUser.userRoles.isProjectMember
                        ?
                        <></>
                        :
                        <Box className={classes.filterTasksContainer}>
                            <FilterProgressActivity filterParams={filterParams} setFilterParams={setFilterParams} />
                        </Box>
                }
                <Box>
                    <ProgressTasksActivityList projectId={projectId} filterParams={filterParams}/>
                </Box>
            </Card>
        </>
    )
}
const mapStateToProps = (state) => {

    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Progress);