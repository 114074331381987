import React, { useEffect, useState } from 'react';
import { Box, Card, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import '../Teams/Teams.css';
import VendorAddEditForm from './VendorAddEditForm/VendorAddEditForm';
import VendorTableView from './VendorTableView/VendorTableView';
import { connect } from 'react-redux';
import CustomTheme from '../../../../utilities/theme/theme.json'
import { getAllVendorRequset } from '../../../configuration/ConfigurationApiActions'
import NoVendorFound from './NoVendorFound/NoVendorFound';
const Vendors = ({ getAllVendors, allVendors }) => {

    const [showNewVendorForm, setShowNewVendorForm] = useState(false)
    const [rowIndex, setRowIndex] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [addNewVendor, setAddNewVendor] = useState(false)

    useEffect(() => {
        getAllVendors().then((res) => {
            if (res.length !== 0) {
                setShowNewVendorForm(true)
            } else { }
        })
    }, [])

    return (
        <>
            {
                allVendors.length !== 0 || showNewVendorForm
                    ?
                    <Box>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                <Card className="new-team-list">
                                    <Grid container style={{ borderBottom: `1px dashed ${CustomTheme.primaryColor}` }}
                                        justify="space-between" spacing={0} className='table-title-style'>
                                        <Grid>
                                        </Grid>
                                        <Grid>
                                            <Box className="header-buttons-in-list">
                                                <Button size="small"
                                                    onClick={() => {
                                                        setAddNewVendor(true)
                                                        setSelectedRow(null)
                                                        setRowIndex(null);
                                                    }}
                                                    variant="outlined"
                                                    color="primary"
                                                    className="blank-page-button-milestone margin-top-team-btn"><i
                                                        className="fas fa-user"></i> <span className="btn-margin-left"> + VENDOR</span>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <VendorTableView
                                        setSelectedRow={setSelectedRow}
                                        selectedRow={selectedRow}
                                        rowIndex={rowIndex}
                                        setRowIndex={setRowIndex}
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                {
                                    selectedRow != null || addNewVendor
                                        ?
                                        <Card className="new-team-detail-panel">
                                            <VendorAddEditForm
                                                setRowIndex={setRowIndex}
                                                rowIndex={rowIndex}
                                                addNewVendor={addNewVendor}
                                                setAddNewVendor={setAddNewVendor}
                                                setSelectedRow={setSelectedRow}
                                            />
                                        </Card>
                                        :
                                        <></>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <NoVendorFound setShowNewVendorForm={setShowNewVendorForm} setAddNewVendor={setAddNewVendor} />
            }
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        allVendors: state.configuration.vendors
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllVendors: () => {
            return dispatch(getAllVendorRequset()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Vendors);
