import React from 'react';
import {Box} from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
import {ValidatorComponent} from 'react-material-ui-form-validator';

class DropzoneAreaValidator extends ValidatorComponent {
    renderValidatorComponent() {
        const {
            value,
            errorMessages,
            validators,
            validatorListener,
            ...rest
        } = this.props;

        return (
            <Box>
                <DropzoneArea {...rest} />
                {this.errorText()}
            </Box>
        );
    }

    errorText() {
        const {isValid} = this.state;

        if (isValid) {
            return null;
        }

        return (
            <Box
                style={{
                    color: 'red',
                    fontSize: '0.75rem',
                    marginLeft: '14px',
                    marginTop: '5px',
                }}
            >
                {this.getErrorMessage()}
            </Box>
        );
    }
}

export default DropzoneAreaValidator;
