import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        titleContainer: {
            textAlign: 'center',
            marginBottom: '2rem',
        },
        titleLabel: {
            fontSize: '1.3rem',
            opacity: '0.7',
        },
        errorText: {
            color: 'red',
        },
        cardsContainer: {
            width: '90%',
            margin: 'auto',
        },
        iconText: {
            fontSize: '0.8rem',
            marginTop: '1rem',
        },
        labelText: {
            color: 'rgba(0, 0, 0, 0.54) !important',
        },
        inputField: {
            '&:after': {
                borderBottom: '2px solid red !important',
            },
        },
    };
});
