import {makeStyles} from '@material-ui/core';
import CustomTheme from '../../../../../../../../../utilities/theme/theme.json'

export const useStyles = makeStyles(theme => {
    return {
        taskContainer: {
            display: 'flex',
            alignItems: 'flex-start',
        },
        taskIcon: {
            fontSize: '17px',
            marginRight: '5px',
            display: 'block',
            marginTop: '1px',
        },
        taskLable: {
            fontSize: '14px',
            fontWeight: '400',
        },
        subtaskTaskHeading: {
            // fontWeight: 'bold',
        },
        statusGreen: {
            color: CustomTheme.successColor,
        },
        statusOrange: {
            color: 'orange',
        },
        statusUnknown: {
            color: '#bbb',
        },
        green: {
            color: 'limegreen',
        },
        orange: {
            color: '#f5b01e',
        },
        red: {
            color: '#e21414',
        },
        blue: {
            color: 'blue',
        },
        iconColumn: {
            display: 'flex',
        },
        colorGreen: {
            color: 'limegreen',
            marginLeft: '19px',
            fontSize: '19px',
            marginTop: '-12px',
        },
        colorRed: {
            color: 'red',
            marginLeft: '19px',
            fontSize: '19px',
            marginTop: '-12px',
        },
        colorYellow: {
            color: 'yellow',
            marginLeft: '19px',
            fontSize: '19px',
            marginTop: '-12px',
        },
        colorblue: {
            color: 'blue',
            marginLeft: '19px',
            fontSize: '19px',
            marginTop: '-12px',
        },
        colorBlank: {
            color: '#77797c',
            marginLeft: '19px',
            fontSize: '19px',
            marginTop: '-12px',
        },
    };
});
