import {makeStyles} from '@material-ui/core';
import CustomTheme from '../../../../../../utilities/theme/theme.json'

export const useStyles = makeStyles(theme => {
    return {
        cardStyle:{
            padding:'10px',
            marginTop:'10px',
            boxShadow:'0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%)',
            cursor:'pointer',
            borderRadius:'6px'
        },
        projectNameAvatar:{
            width:'25px',
            height:'25px',
        },
        forDisplayFlex: {
            display:'flex'
        },
        projectName: {
            marginTop:'3px'
        },
        listName: {
            fontWeight:500,
            marginTop:'3px'
        },
        forPaddingRight5: {
            paddingRight:'5px'
        },
        duedateIcon: {
            color: CustomTheme.fontColor,
            marginTop:'-3px',
            marginLeft:'5px'
        },
        paddingLeft5: {
            paddingLeft:'5px'
        },
        forMarginTopSecondLine: {
            marginTop:'10px'
        },
        iconColor: {
            color: CustomTheme.fontColor,
        },
        fromListNameMarginTop: {
            marginTop:'8px'
        },
        paddingLeft10: {
            paddingLeft:'10px'
        },
        actionIcon: {
            fontSize:'25px',
            marginTop:'7px'
        },
        checkCircleIconColor: {
            color: CustomTheme.complete_status
        },
        snoozeIcon: {
            color: CustomTheme.stoppped_status
        },
        snoozeIconColorGray: {
            color:'#77797ca3'
        },
        userIcon: {
            color: CustomTheme.linkColor
        },
        titlesContainer: {
            marginLeft:'25px'
        },
        actionIconContainer: {
           marginLeft:'61px',
           paddingLeft:'10px',
           paddingTop: '3px'
        },
        actionContainerBorderLeft:{
            borderLeft:'1px solid #ffffff',
        },
        tasklistIconTitle: {
            marginTop:'-2px'
        },
        paddingTop2: {
            paddingTop:'2px'
        },
        paddingTop4: {
            paddingTop:'4px'
        },
        marginTop1: {
            marginTop: '1px'
        }
    };
});
