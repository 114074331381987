import React, {useEffect, useState} from 'react';
import {Box, Grid, TableContainer, Tooltip, Typography} from '@material-ui/core';
import MaterialTable from 'material-table';
import {useStyles} from './styles';
// import RemoveIcon from '@material-ui/icons/HighlightOffOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import { InputAdornment } from "@material-ui/core";

const ParticipantList = ({
                             loggedInUser,
                             setValue,
                             value,
                             history,
                             showParticipantsDetailsPane,
                             participantList,
                             allUsers,
                             addMemberInParticipentsHandler,
                             setNewMemberId,
                             newMemberId
                         }) => {
    const classes = useStyles();
    const [participants, setParticipants] = useState(null);

    useEffect(() => {
        setParticipants(participantList);
    }, [participantList]);
    const columns = [
        {
            field: 'memberType',
            title: '',
            cellStyle: {
                width: '5%',
            },
            render: ({memberType}) => {
                return (
                    <>
                        <AccountCircleOutlinedIcon
                            classes={{
                                root:
                                    memberType === 'team'
                                        ? classes.green
                                        : memberType === 'non_team'
                                        ? classes.blue
                                        : memberType === 'client' || memberType === 'vendor'
                                            ? classes.orange
                                            : '',
                            }}
                        />
                    </>
                );
            },
        },
        {
            field: 'firstName',
            title: (<Typography className={classes.participantsTableCoulnmHeaders} variant="h4">Assignee</Typography>),
            cellStyle: {},
            headerStyle: {
                borderRadius: '5px 0rem 0rem 0rem',
            },
            render: ({firstName, lastName}) => {
                return (
                    <Box className={classes.profileContainer}>
                        <Box>
                            <img
                                src={`https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=random`}
                                alt={firstName}
                                className={classes.profileImage}
                            />
                        </Box>
                        <Box>
                            <Typography
                             variant='body1' 
                            //  classes={{root: classes.labelText}}
                             >
                                {firstName}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'functionalRoles',
            title: (<Typography className={classes.participantsTableCoulnmHeaders}  variant="h4">Project Role</Typography>),
            cellStyle: {},
            render: ({functionalRoles}) => {
                if (functionalRoles && functionalRoles.length > 0) {
                    let roleNames = [];
                    functionalRoles.forEach((role) => {
                        roleNames.push(role.name);
                    });
                    return <Typography variant="body1">{roleNames.join(", ")}</Typography>
                } else {
                    return <></>;
                }
            },
        },
        {
            field: 'completedTasks',
            title: (<Typography className={classes.participantsTableCoulnmHeaders}  variant="h4">Complete</Typography>),
            cellStyle: {
                width: '10%',
            },
            render: ({completedTasks}) => {
                return <Typography variant="body1">{completedTasks}</Typography>
            },
        },
        {
            field: 'openTasks',
            title: (<Typography className={classes.participantsTableCoulnmHeaders}  variant="h4">Open</Typography>),
            cellStyle: {
                width: '10%',
            },
            render: ({openTasks}) => {
                return <Typography variant="body1">{openTasks}</Typography>
            },
        },
        {
            field: 'overdueTasks',
            title: (<Typography className={classes.participantsTableCoulnmHeaders}  variant="h4">Overdue</Typography>),
            cellStyle: {
                width: '15%',
            },
            headerStyle: {
                borderRadius: '0px 5px 0px 0px',
            },
            render: ({overdueTasks}) => {
                return <Typography variant="body1">{overdueTasks}</Typography>
            },
        },
        // {
        //   field: '-',
        //   title: '',
        //   cellStyle: {
        //     width: '5%',
        //   },
        //   headerStyle: {
        //     borderRadius: '0px 5px 0px 0px',
        //   },
        //   render: rowData => {
        //     return (
        //       <>
        //       {
        //         rowData.memberType === 'team'
        //         ?
        //         <></>
        //         :
        //         <>
        //         {
        //           rowData.memberType === 'non_team'||rowData.memberType === 'client'||rowData.memberType === 'vendor'
        //           ?
        //           <RemoveIcon
        //           classes={{ root: classes.removeIcon }}
        //           onClick={() => {
        //           }}
        //           />
        //           :
        //           <></>
        //         }
        //         </>
        //       }

        //       </>
        //     );
        //   },
        // },
    ];

    const redirectToProjectList = () => {
        history.replace('/projects/teams');
    }

    const defaultProps = {
        options: allUsers || [],
        getOptionLabel: (option) => `${option.firstName} ${option.lastName}`,
    };

    const tableTitle = () => {

        return (
            <ValidatorForm
                action='#'
                method='post'
                onSubmit={() => value ? addMemberInParticipentsHandler() : ''}
            >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={0} className={classes.participantTableHeader}>
                        <Grid className={classes.backButtonGrid} item xl={4} lg={4} md={4} sm={4} xs={4}>
                            <Tooltip title="Back">
                                <span className={`template-back-icon ${classes.backIcon}`}
                                      onClick={redirectToProjectList}><i
                                    className="far fa-arrow-alt-circle-left"></i></span>
                            </Tooltip>
                        </Grid>
                        {
                            loggedInUser.userRoles.isSystemSuperAdmin || loggedInUser.userRoles.isTeamLead
                                ?
                                <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                          className="form-panel-assign-name-container team-detail-panel-memeber-container">
                                        <Grid container>
                                            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                                <Box className="team-detail-panel-add-member-box">
                                                </Box>
                                            </Grid>
                                            <Grid item xl={7} lg={7} md={7} sm={12} xs={12} className={classes.newMemberInputContainer}>
                                                <Autocomplete
                                                    {...defaultProps}
                                                    className="team-detail-panel-inputbase"
                                                    value={value}
                                                    onChange={(event, newValue) => {
                                                        setValue(newValue);
                                                        if (newValue && newValue.id) {
                                                            setNewMemberId(newValue.id)
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextValidator
                                                        {...params} 
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                              <InputAdornment position="start">
                                                                <i className="fas fa-user-plus"/>
                                                                {/* <span className={classes.newMemberTitle}>New Member</span> */}
                                                              </InputAdornment>
                                                            )
                                                          }}
                                                          placeholder=" New Member"
                                                        />}
                                                />
                                            </Grid>
                                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}
                                                  className="team-detail-panel-add-member-btn">
                                                <Button size="small"
                                                        type="submit"
                                                        variant="outlined"
                                                        color="primary"
                                                        className={value != null ? "blank-page-button-tasklist btn-margin-left" : "disable-add-btn blank-page-button-tasklist btn-margin-left"}><span
                                                    className="btn-margin-left">ADD</span>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                <></>
                        }
                    </Grid>
                </Grid>
            </ValidatorForm>
        )

    }

    return (
        <TableContainer className={`${classes.tableContainerStyle} participants-table-container`}>
            {tableTitle()}
            <MaterialTable
                columns={columns}
                data={participants || []}
                options={{
                    toolbar: false,
                    paging: false,
                    sorting: false,
                    emptyRowsWhenPaging: false,
                    draggable: false,
                    detailPanelColumnStyle: {
                        display: 'none',
                    },
                    headerStyle: {
                        borderBottom: '1px dashed #77797c',
                        color: '#77797c',
                        fontSize: '16px',
                        fontWeight: 'normal',
                        height: '40px',
                    },
                    rowStyle: {
                        fontSize: '14px',
                        color: '#77797c',
                    },
                }}
                style={{
                    boxShadow: 'none',
                    borderRadius: '5px',
                    paddingBottom: '0.8rem',
                }}
                onRowClick={(e, rowData) => {
                    showParticipantsDetailsPane(rowData.id, rowData);
                }}
            />
        </TableContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantList);
