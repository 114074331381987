import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        titleContainer: {
            textAlign: 'center',
            marginBottom: '2rem',
        },
        titleLabel: {
            opacity: '0.7',
        },
        errorText: {
            color: 'red',
        },
        labelText: {
            color: 'rgba(0, 0, 0, 0.54) !important',
        },
        inputField: {
            '&:after': {
                borderBottom: '2px solid red !important',
            },
        },
    };
});
