import React from 'react';
import {NavLink} from 'react-router-dom';
import {Box, Typography} from '@material-ui/core';
import mkdmLogo from '../../../../assets/mkdm-logo.png';

import {useStyles} from './styles';

import {ROUTER_ACTIONS} from '../../../../utilities/Constants'

const ConfigurationHeader = () => {
    const classes = useStyles();
    let routePath = window.location.pathname
    let menuName = routePath.substr(15, 4)
    return (
        <Box className={classes.mainContainer}>
            <Box className={classes.imageContainer}>
                <img src={mkdmLogo} alt='logo' className={classes.image}/>
            </Box>
            <Box className={classes.contentContainer}>
                <Box><Typography className={classes.heading} variant="h2">SYSTEM CONFIGURATION</Typography> </Box>
                <Box className={classes.bottomLabelContainer}>
                    {/* <NavLink to={ROUTER_ACTIONS.CONFIGURATION_ORGANIZATION} className={classes.bottomLabel} activeClassName={classes.active}>
            Organization
          </NavLink> */}
                    {/* <NavLink to={ROUTER_ACTIONS.CONFIGURATION_ROLES} className={classes.bottomLabel} activeClassName={classes.active}>
            Roles
          </NavLink> */}
                    <NavLink to={ROUTER_ACTIONS.CONFIGURATION_USERS} className={classes.bottomLabel}
                             activeClassName={classes.active}>
                        Users
                    </NavLink>
                    <NavLink to={ROUTER_ACTIONS.CONFIGURATION_TEAMS} className={classes.bottomLabel}
                             activeClassName={classes.active}>
                        Teams
                    </NavLink>
                    <NavLink to={ROUTER_ACTIONS.CONFIGURATION_CLIENTS} className={classes.bottomLabel}
                             activeClassName={classes.active}>
                        Clients
                    </NavLink>
                    <NavLink to={ROUTER_ACTIONS.CONFIGURATION_VENDORS} className={classes.bottomLabel}
                             activeClassName={classes.active}>
                        Vendors
                    </NavLink>
                    <NavLink to={ROUTER_ACTIONS.CONFIGURATION_TASKS} className={classes.bottomLabel}
                             activeClassName={classes.active}>
                        Tasks
                    </NavLink>
                    <NavLink to={ROUTER_ACTIONS.CONFIGURATION_CHECKLISTS} className={classes.bottomLabel}
                             activeClassName={classes.active}>
                        Checklists
                    </NavLink>
                    <NavLink to={ROUTER_ACTIONS.CONFIGURATION_PROJECTS}
                             className={menuName === "temp" ? classes.forAddEditTemplateHighlight : classes.bottomLabel}
                             activeClassName={classes.active}>
                        Templates
                    </NavLink>
                </Box>
            </Box>
        </Box>
    );
};

export default ConfigurationHeader;
