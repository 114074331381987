import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux';
import CustomTheme from '../../../../../../../../../../../utilities/theme/theme.json';

import {Chip, Grid, TextField, Typography} from '@material-ui/core';
import {TextValidator} from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {getAllTagsRequset} from '../../../../../../../../../../configuration/ConfigurationApiActions';

const MetaInfoSection = React.memo(({
                                        handleChangeInput, handleChangeAutocomplete, formData, taskId, tagList,
                                        getAllTags
                                    }) => {
    const [isTitleEditable, setIsTitleEditable] = useState(false);
    const [isDescriptionEditable, setIsDescriptionEditable] = useState(false);
    const [isChipEditable, setIsChipEditable] = useState(false);
    const [allTags, setAllTags] = useState(null)
    const [tagName, setTagName] = useState(null);

    /**
     * Function is triggred when component is received updated value of taskId
     * Update the state value of isTitleEditable, isDescriptionEditable and isChipEditable
     */
    useEffect(() => {
        if (taskId) {
            setIsTitleEditable(true);
            setIsDescriptionEditable(true);
            setIsChipEditable(true);
        } else {
            setIsTitleEditable(false);
            setIsDescriptionEditable(false);
            setIsChipEditable(false);
        }
    }, [taskId]);

    /**
     * Function is triggred when component is received updated value of tagName and filter the allTags using searchString
     * Update the state value of showApprovalDropdown based on approver
     */
    useEffect(() => {
        if (tagName && tagName.length >= 3 && tagName.length < 4) {
            const searchString = new RegExp(tagName, 'i'); // prepare a regex object
            const filterArray = tagList && tagList.length > 0 ? tagList.filter(item => searchString.test(item.name)) : [];
            setAllTags(filterArray)
            // getAllTags(tagName, true).then((res) => {
            //     setAllTags(res)
            // }).catch((err) => {
            // })
        }
    }, [tagName]);

    return (
        <>
            <Grid style={{backgroundColor: CustomTheme.bodyBackground}} item xl={12} lg={12} md={12} sm={12} xs={12}
                  className="form-panel-assign-name-container for-background-color for-height ">
                <Grid container>
                    {
                        isTitleEditable
                            ?
                            <>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <TextValidator
                                        fullWidth
                                        // required
                                        // variant="outlined"
                                        autoFocus={true}
                                        size="small"
                                        label="Add task title"
                                        onChange={handleChangeInput}
                                        name="title" value={formData.title || ''}
                                        validators={['required', 'trim', 'maxStringLength:50']}
                                        errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                    />
                                </Grid>
                            </>
                            :
                            <Grid onClick={() => {
                                setIsTitleEditable(!isTitleEditable)
                            }}>
                                <Typography variant="h2" style={{color: CustomTheme.primaryColor}}
                                            className="add-title-text">
                                    <span><i className="far fa-edit"></i> Click to add task title</span>
                                </Typography>
                            </Grid>
                    }
                </Grid>
            </Grid>
            <Grid style={{backgroundColor: CustomTheme.bodyBackground}} item xl={12} lg={12} md={12} sm={12} xs={12}
                  className="padding-for-description-container for-background-color">
                <Grid container>
                    {
                        isDescriptionEditable
                            ?
                            <>
                                <Grid className="description-input-container" item xl={12} lg={12} md={12} sm={12}
                                      xs={12}>
                                    <TextValidator
                                        fullWidth
                                        // required
                                        // variant="outlined"
                                        autoFocus={true}
                                        size="small"
                                        label="Add task description"
                                        onChange={handleChangeInput}
                                        name="description" value={formData.description || ''}
                                        validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:250']}
                                        errorMessages={['At least one alphanumeric characters', 'The max length of 250 charachers reached']}
                                    />
                                </Grid>
                            </>
                            :
                            <Grid onClick={() => {
                                setIsDescriptionEditable(!isDescriptionEditable)
                            }}>
                                <Typography variant="h3" style={{color: CustomTheme.primaryColor}}
                                            className="add-description-text">
                                    <span><i className="far fa-edit"></i> Click to add a description for this task</span>
                                </Typography>
                            </Grid>
                    }
                </Grid>
            </Grid>
            {
                isChipEditable
                    ?
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <span className="tag-container-icon">
                            <i className='fas fa-tags'></i>
                        </span>
                        <Autocomplete
                            multiple
                            id="tags-filled"
                            name="tags"
                            value={formData.tags}
                            className="tags-contianer"
                            onChange={(event, newValue) => {
                                // setTags(newValue);
                                handleChangeAutocomplete("tags", newValue)
                            }}
                            onInputChange={(event, newValue) => {
                                setTagName(newValue);
                            }}
                            clearOnEscape={true}
                            options={allTags ? Object.keys(allTags).map((option) => allTags[option].name) : []}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip key={index} variant="outlined" label={option} {...getTagProps({index})} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} variant="filled" placeholder="Type tag and hit enter"/>
                            )}
                        />
                    </Grid>
                    :
                    <Grid onClick={() => {
                        setIsChipEditable(!isChipEditable)
                    }} className="add-tag-text" item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid container>
                            <Grid style={{color: CustomTheme.fontColor}} className="tag-icon-container">
                                <i className='fas fa-tags'></i>
                            </Grid>
                            <Grid style={{color: CustomTheme.primaryColor}} className="add-tag-container-projects">
                                <i className='fas fa-plus-circle'></i> Add a tag
                            </Grid>
                        </Grid>
                    </Grid>
            }
        </>
    )

});

/**
 * Used for selecting the part of the data from the redux store that the connected component needs and pass it as a props
 * It is called every time when update the state of redux store
 * @param state
 */
const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

/**
 * Used to specify the which actions your component might need to dispatch and pass it to connected component as a props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getAllTags: (name, searchTag) => {
            return dispatch(getAllTagsRequset(name, searchTag)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        }
    };
};

/**
 * Used to connects a react component to a redux store
 */
export default connect(mapStateToProps, mapDispatchToProps)(MetaInfoSection);
