import React, { useEffect, useRef, useState } from 'react';
import './ModalDown.css';
import { Dialog, DialogActions, DialogContent, Grid, Typography, } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useStyles } from '../../modules/configuration/components/Tasks/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SearchableDropDown from '../SearchableDropDown/SearchableDropDown';
import {
    addChecklistRequest,
    addTagRequest,
    addTaskListRequest,
    addTaskRequest,
    ediTaskFromConfig,
    getAllChecklist,
    getAllTagsRequset,
    getAllTaskListRequest,
    getFunctionalRoleRequest,
    getPredecessorRequest,
    getTaskDetailsInTasklistConfig,
    getTaskOfTaskListRequest
} from '../../modules/configuration/ConfigurationApiActions'
import CustomTheme from '../../utilities/theme/theme.json'
import MessageModal from '../MessageModal/MessageModal'
import CheckListModal from '../CheckListModal/ChecklistModal'
const approver = [
    { name: 'Yes', value: true, id: '1' },
    { name: 'No', value: false, id: '2' },
]
const priority = [
    { name: 'High', value: 1, id: '1' },
    { name: 'Medium', value: 2, id: '2' },
    { name: 'Low', value: 3, id: '3' }
]
const ModalDown = ({
    editTask,
    setIsEditTask,
    setEditTaskId,
    editTaskId,
    getTaskDetails,
    isEditTask,
    open,
    hideTaskListTemplate,
    setOpen,
    getAllChecklist,
    addTaskList,
    getAllTaskList,
    getTasks,
    addTask,
    selectedTaskList,
    setSelectedTaskList,
    onClickTableRowIdTaskList,
    getAllTags,
    addTags,
    configAllTasks,
    configAllTasklist
}) => {

    const modalRef = useRef();

    const [title, setTitle] = useState(null);
    const [prevTitle, setPrevTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [prevDescription, setPrevDescription] = useState(null);
    const [chips, setChips] = useState([]);
    const [chipsInTask, setChipsInTasks] = useState([]);
    const [prevChipsInTask, setPrevChipsInTasks] = useState([]);
    const [prevTaskSelectedCheckList, setPrevTaskSelectedCheckList] = useState(null)

    const [selectedApprover, setSelectedApprover] = useState(approver[1]);
    const [prevSelectedApprover, setPrevSelectedApprover] = useState(approver[1]);


    const [taskListTemplateTitle, setTaskListTemplateTitle] = useState(undefined);
    const [taskListTemplateDescription, setTaskListTemplateDescription] = useState(undefined)

    const [priorityValue, setPriorityValue] = useState(priority[1])
    const [prevPriorityValue, setPrevPriorityValue] = useState(priority[1])

    const [selecetedPredecessor, setSelecetedPredecessor] = useState(null)
    const [prevSelecetedPredecessor, setPrevSelecetedPredecessor] = useState(null)
    const [openAddCheckList, setOpenAddCheckList] = useState(false);
    const [allCheckList, setAllCheckList] = useState(undefined);// todo: undefined not showing instead of that put null
    const [taskListCheckList, setTaskListCheckList] = useState(null)
    const [taskSelectedCheckList, setTaskSelectedCheckList] = useState(null)


    const [allTags, setAllTags] = useState(null)
    const [tagName, setTagName] = useState(null);
    useEffect(() => {

        // getting all checklist
        getAllChecklist().then((res) => {
            let result = Object.entries(res).map(([k, v]) => (v));
            let createChecklistObj = { title: 'Create checklist', id: "1" }
            let nonelistObj = { title: 'None', id: null }
            result.unshift(createChecklistObj, nonelistObj)
            setAllCheckList(result)
        }).catch((err) => {
        })

        // getting all task list
        getAllTaskList().then((res) => {
        }).catch((err) => {
        })

    }, [])

    useEffect(() => {
        if (isEditTask) {
            editTaskId && getTaskDetails(editTaskId).then((res) => {
                setTitle(res.title);
                setPrevTitle(res.title);
                setDescription(res.description);
                setPrevDescription(res.description);
                setChipsInTasks(res.tags);
                setPrevChipsInTasks(res.tags);
                setSelectedTaskList(res.tasklistId)
                let priorityArray = priority.find((priority) => priority.value === res.priority)
                setPriorityValue(priorityArray)
                setPrevPriorityValue(priorityArray)
                if (res.predecessors) {
                    let predecessorArray = configAllTasks && configAllTasks.find((task) => task.id === res.predecessors[0])
                    setSelecetedPredecessor(predecessorArray)
                    setPrevSelecetedPredecessor(predecessorArray)
                } else {
                    setSelecetedPredecessor(null)
                    setPrevSelecetedPredecessor(null)
                }
                let approverArray = approver.find((approval) => approval.value === res.isApprovalNeeded)
                setSelectedApprover(approverArray)
                setPrevSelectedApprover(approverArray)
                if (res.checklist && res.checklist.checklist === null) {
                    setTaskSelectedCheckList(null)
                    setPrevTaskSelectedCheckList(null)
                } else if (res.checklist && res.checklist) {
                    let checklistArray = allCheckList && allCheckList.find((checklist) => checklist.id === res.checklist.id)
                    setTaskSelectedCheckList(checklistArray)
                    setPrevTaskSelectedCheckList(checklistArray)
                }
            })
        }
    }, [editTaskId])

    useEffect(() => {
        if (tagName && tagName.length >= 3 && tagName.length < 4) {
            getAllTags(tagName, true).then((res) => {
                setAllTags(res)
            }).catch((err) => {
            })
        }
    }, [tagName])

    // add tags
    const addChips = (tags) => {
        tags.map((singleTagName) => {
            let data = {
                name: singleTagName,
                color: singleTagName
            }
            addTags(data).then((res) => {
            })
            return singleTagName
        })

    }

    // on hide state change
    useEffect(() => {

        // getting all tasks of tasklist
        getTasks(onClickTableRowIdTaskList)

    }, [onClickTableRowIdTaskList])


    // for adding task in tasklist
    const handleSaveData = () => {
        // addChips(chipsInTask)
        let filterArr = []
        getAllTags().then((res) => {
            let arr = chipsInTask && chipsInTask.filter((tag) => {
                return !(res.some((resTag) => resTag.name === tag))
            })
            filterArr.push(arr)
            addChips(filterArr[0])
        })
        if (editTaskId) {
            let data = {
                title: title,
                isApprovalNeeded: selectedApprover.name === "Yes",
            }
            if (description) {
                data.description = description
            } else {
            }
            if (selectedTaskList) {
                data.tasklistId = selectedTaskList
            } else {
            }

            if (taskSelectedCheckList) {
                if (taskSelectedCheckList === "None") {
                    data.checklist =
                    {
                        checklist: null
                    }
                } else {
                    data.checklist = taskSelectedCheckList
                }
            } else {
            }
            if (priorityValue) {
                data.priority = priorityValue.value
            } else {
            }
            if (chipsInTask) {
                data.tags = chipsInTask
            } else {
            }
            if (selecetedPredecessor) {
                data.predecessors = [`${selecetedPredecessor.id}`]
            } else {
            }

            editTask(editTaskId, data).then((res) => {

                getTasks(onClickTableRowIdTaskList)

                setOpen(false);
                setSelecetedPredecessor('');
                setTaskSelectedCheckList('');
                setTitle('');
                setDescription('');
                setChipsInTasks([]);
                setEditTaskId(null);

            })
        } else {
            let data = {
                title: title,
                isApprovalNeeded: selectedApprover.name === "Yes",
            }
            if (description) {
                data.description = description
            } else {
            }
            if (selectedTaskList) {
                data.tasklistId = selectedTaskList
            } else {
            }

            if (taskSelectedCheckList) {
                data.checklistId = taskSelectedCheckList.id
            } else {
            }
            if (priorityValue) {
                data.priority = priorityValue.value
            } else {
            }
            if (chipsInTask) {
                data.tags = chipsInTask
            } else {
            }
            if (selecetedPredecessor) {
                data.predecessors = [selecetedPredecessor.id]
            } else {
            }

            addTask(data).then((res) => {

                getTasks(onClickTableRowIdTaskList)

                setOpen(false);
                setTitle('');
                setDescription('');
                setPriorityValue(priority[1]);
                setSelecetedPredecessor('');
                setSelectedApprover(approver[1]);
                setTaskSelectedCheckList('');
                setChipsInTasks([]);


            }).catch((err) => {
            })
        }

    }


    // adding tasklist
    const handleSaveDataTaskTemplate = () => {
        //   addChips(chips)
        let filterArr = []
        getAllTags().then((res) => {
            let arr = chips && chips.filter((tag) => {
                return !(res.some((resTag) => resTag.name === tag))
            })
            filterArr.push(arr)
            addChips(filterArr[0])
        })
        let data = {
            title: taskListTemplateTitle,
            isApprovalNeeded: selectedApprover.name === "Yes",
        }

        if (taskListTemplateDescription) {
            data.description = taskListTemplateDescription
        } else {
        }
        if (chips) {
            data.tags = chips
        } else {
        }
        if (taskListCheckList) {
            data.checklistId = taskListCheckList.id
        } else {
        }

        addTaskList(data).then((res) => {
            setSelectedApprover(approver[1])
            setTaskListTemplateTitle('')
            setTaskListTemplateDescription('')
            setChips([])
            setTaskListCheckList('')
            getAllTaskList()
            setOpen(false);

        }).catch((err) => {
        })
    }

    // closing model on cancel click
    const handleClose = () => {
        setOpen(false)

        //tasklist
        setChips([])
        setTaskListTemplateTitle('')
        setTaskListTemplateDescription('')
        setTaskListCheckList(null)

        //tasks
        setTitle('');
        setDescription('');
        setPriorityValue(priority[1]);
        setSelecetedPredecessor('');
        setSelectedApprover(approver[1]);
        setTaskSelectedCheckList('');
        setChipsInTasks([])

        setEditTaskId(null)
        setIsEditTask(false)
    }
    const classes = useStyles();

    const handleEsc = (e) => {

        if (e.keyCode === 27) {
            if (hideTaskListTemplate) {
                if (editTaskId != null) {
                    if (title === prevTitle && description === prevDescription && chipsInTask === prevChipsInTask && priorityValue === prevPriorityValue && selecetedPredecessor === prevSelecetedPredecessor && selectedApprover === prevSelectedApprover && taskSelectedCheckList === prevTaskSelectedCheckList) {
                        handleClose()
                    } else {
                        const title = `Task`
                        const message = `Changes that you made in edit task may not be saved.`
                        modalRef.current.setState({
                            showModal: true,
                            message: message,
                            title: title,
                            showOkButton: true,
                            showCancelButton: true,
                            showNote: false,
                            note: '',
                            handleOk: (note) => {
                                handleClose()
                            },
                            okText: "Yes",
                            cancelText: 'No'
                        });

                    }
                } else {
                    if (title != null || description != null || taskSelectedCheckList != null || chipsInTask.length > 0 || selecetedPredecessor != null) {
                        const title = `Task`
                        const message = `Changes that you made in task may not be saved.`
                        modalRef.current.setState({
                            showModal: true,
                            message: message,
                            title: title,
                            showOkButton: true,
                            showCancelButton: true,
                            showNote: false,
                            note: '',
                            handleOk: (note) => {
                                handleClose()
                            },
                            okText: "Yes",
                            cancelText: 'No'
                        });

                    } else {
                        handleClose()
                    }
                }
            } else {
                if (taskListTemplateTitle != null || taskListTemplateDescription != null || chips.length > 0 || taskListCheckList != null) {
                    const title = `Tasklist`
                    const message = `Changes that you made in tasklist may not be saved.`
                    modalRef.current.setState({
                        showModal: true,
                        message: message,
                        title: title,
                        showOkButton: true,
                        showCancelButton: true,
                        showNote: false,
                        note: '',
                        handleOk: (note) => {
                            handleClose()
                        },
                        okText: "Yes",
                        cancelText: 'No'
                    });
                } else {
                    handleClose()
                }
            }
        }
    }


    return (
        <div className="tasklist-template">
            <Dialog
                open={open}
                // TransitionComponent={Transition}
                className="dailog-box-for-task-add"
            >
                <DialogContent>
                    <Typography variant="h2" style={{ color: CustomTheme.fontColor }}
                        className="block-titles margin-top-20">
                        {
                            hideTaskListTemplate ? 'New Task' : 'New Tasklist'
                        }
                    </Typography>
                    <ValidatorForm
                        onSubmit={hideTaskListTemplate ? handleSaveData : handleSaveDataTaskTemplate}
                        onKeyDown={(e) => handleEsc(e)}
                    >
                        <Grid container spacing={3}>
                            {
                                hideTaskListTemplate ?
                                    <>
                                        <Grid className="marginTop" item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Grid container>
                                                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                                    <Typography className="task-title" variant="body1">Enter a title for
                                                        this task</Typography>
                                                </Grid>
                                                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                                    <TextValidator
                                                        fullWidth
                                                        autoFocus={true}
                                                        // variant="outlined"
                                                        size="small"
                                                        // label="Title"
                                                        onChange={(e) => setTitle(e.target.value)}
                                                        name="name" value={title || ''}
                                                        validators={['required', 'trim', 'maxStringLength:50']}
                                                        errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant="body1">Enter a brief description</Typography>
                                        </Grid>
                                        <Grid className="description-container" item xl={12} lg={12} md={12} sm={12}
                                            xs={12}>
                                            <TextValidator
                                                fullWidth
                                                // variant="outlined"
                                                size="small"
                                                // label="Description"
                                                onChange={(e) => setDescription(e.target.value)}
                                                name="description" value={description || ''}
                                                validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:250']}
                                                errorMessages={['At least one alphanumeric characters', 'The max length of 250 charachers reached']}
                                            />
                                        </Grid>
                                        {/*
                            In Task Chip
                        */}
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <span className="tag-icon-span">
                                                <i className='fas fa-tags'></i>
                                            </span>
                                            <Autocomplete
                                                multiple
                                                id="tags-filled"
                                                value={chipsInTask}
                                                className="tags-contianer"
                                                onChange={(event, newValue) => {
                                                    setChipsInTasks(newValue)
                                                    // addChips(event,newValue);
                                                }}
                                                onInputChange={(event, newValue) => {
                                                    setTagName(newValue);
                                                }}
                                                clearOnEscape={true}
                                                options={allTags ? Object.keys(allTags).map((option) => allTags[option].name) : []}
                                                freeSolo
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip key={index} variant="outlined"
                                                            label={option} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="filled"
                                                        placeholder="Type tag and hit enter" />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Grid container>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                    <Typography variant="body1"><span><i
                                                        className="fas fa-list-alt"></i></span> Attach to
                                                        tasklist</Typography>
                                                </Grid>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                                                    className={classes.dropDownContainer}>
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel variant="body1" shrink={true}>Select
                                                            tasklist</InputLabel>
                                                        <Select

                                                            id="demo-simple-select-required"
                                                            value={selectedTaskList}
                                                            fullWidth
                                                            readOnly={true}
                                                            onChange={(e) => setSelectedTaskList(e.target.value)}
                                                        // className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            {
                                                                configAllTasklist && Object.keys(configAllTasklist).map((item, index) => {
                                                                    return (
                                                                        <MenuItem key={item}
                                                                            value={configAllTasklist[item].id}>{configAllTasklist[item].title}</MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Grid container>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                    <Typography variant="body1"><span><i
                                                        className="fas fa-angle-double-up"></i></span> What is the
                                                        priority of this task?</Typography>
                                                </Grid>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                                                    className={classes.dropDownContainer}>
                                                    <SearchableDropDown title={false} name={true} checklist={false}
                                                        value={priorityValue}
                                                        setValue={setPriorityValue}
                                                        label="Select priority" optionsList={priority} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Grid container>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                    <Typography variant="body1"><span><i
                                                        className="fas fa-share-alt"></i></span> Does this task depend
                                                        on another?</Typography>
                                                </Grid>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                                                    className={classes.dropDownContainer}>
                                                    <SearchableDropDown title={true} name={false} checklist={false}
                                                        value={selecetedPredecessor}
                                                        setValue={setSelecetedPredecessor}
                                                        label="Select predecessor"
                                                        optionsList={configAllTasks} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Grid container>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                    <Typography variant="body1"><span><i
                                                        className="fas fa-check-double"></i></span> Does this task
                                                        require approval?</Typography>
                                                </Grid>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                                                    className={classes.dropDownContainer}>
                                                    <SearchableDropDown errorMessages={['This field is required']}
                                                        validators={['required']} title={false}
                                                        name={true} checklist={false}
                                                        value={selectedApprover}
                                                        setValue={setSelectedApprover}
                                                        label="Select option" optionsList={approver} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Grid container>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                    <Typography variant="body1"><span><i
                                                        className="fas fa-clipboard-list"></i></span> Attach a checklist
                                                        (or create new):</Typography>
                                                </Grid>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                                                    className={classes.dropDownContainer}>
                                                    <SearchableDropDown title={true} name={true} checklist={true}
                                                        setOpenAddCheckList={setOpenAddCheckList}
                                                        setOpen={setOpen} value={taskSelectedCheckList}
                                                        setValue={setTaskSelectedCheckList}
                                                        label="Select checklist"
                                                        optionsList={allCheckList} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid className="marginTop" item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Grid container>
                                                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                                    <Typography className="task-title" variant="body1">Enter a title for
                                                        this tasklist</Typography>
                                                </Grid>
                                                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                                    <TextValidator
                                                        fullWidth
                                                        autoFocus={true}
                                                        size="small"
                                                        onChange={(e) => setTaskListTemplateTitle(e.target.value)}
                                                        name="name" value={taskListTemplateTitle || ''}
                                                        validators={['required', 'trim', 'maxStringLength:50']}
                                                        errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant="body1">Enter a brief description</Typography>
                                        </Grid>
                                        <Grid className="description-container" item xl={12} lg={12} md={12} sm={12}
                                            xs={12}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                onChange={(e) => setTaskListTemplateDescription(e.target.value)}
                                                name="description" value={taskListTemplateDescription || ''}
                                                validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:250']}
                                                errorMessages={['At least one alphanumeric characters', 'The max length of 250 charachers reached']}
                                            />
                                        </Grid>
                                        {/*
                            In Tasklist Chip
                        */}
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <span className="tag-icon-span">
                                                <i className='fas fa-tags'></i>
                                            </span>
                                            <Autocomplete
                                                multiple
                                                id="tags-filled"
                                                value={chips}
                                                className="tags-contianer"
                                                onChange={(event, newValue) => {
                                                    setChips(newValue);
                                                }}
                                                onInputChange={(event, newValue) => {
                                                    setTagName(newValue);
                                                }}
                                                clearOnEscape={true}
                                                options={allTags ? Object.keys(allTags).map((option) => allTags[option].name) : []}
                                                freeSolo
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip key={index} variant="outlined"
                                                            label={option} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="filled"
                                                        placeholder="Type tag and hit enter" />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Grid container>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                    <Typography variant="body1">
                                                        <span><i className="fas fa-check-double"></i></span> Does this
                                                        tasklist require approval?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                                                    className={classes.dropDownContainer}>
                                                    <SearchableDropDown title={false} name={true} checklist={false}
                                                        value={selectedApprover}
                                                        setValue={setSelectedApprover}
                                                        label="Select option" optionsList={approver} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Grid container>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                    <Typography variant="body1">
                                                        <span><i className="fas fa-clipboard-list"></i></span> Attached
                                                        or create checklist
                                                    </Typography>
                                                </Grid>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                                                    className={classes.dropDownContainer}>
                                                    <SearchableDropDown title={true} name={true} checklist={true}
                                                        setOpenAddCheckList={setOpenAddCheckList}
                                                        setOpen={setOpen} value={taskListCheckList}
                                                        setValue={setTaskListCheckList}
                                                        label="Select checklist"
                                                        optionsList={allCheckList} />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </>
                            }

                        </Grid>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                color="secondary"
                                className="reset-dialog-button"
                                size="small"
                                onClick={handleClose}
                            >
                                CANCEL
                            </Button>
                            <Button
                                type="submit"
                                className="save-dialog-button"
                                variant="outlined"
                                color="primary"
                                size="small"
                            >
                                SAVE
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
            <MessageModal
                ref={modalRef}
            />
            {
                openAddCheckList ?
                    <CheckListModal
                        setAllCheckList={setAllCheckList}
                        setTaskListCheckList={setTaskListCheckList}
                        setTaskSelectedCheckList={setTaskSelectedCheckList}
                        setOpen={setOpen}
                        setOpenAddCheckList={setOpenAddCheckList}
                        openAddCheckList={openAddCheckList}
                    />

                    :
                    <></>
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        configAllTasks: state.configuration.configTasks,
        configAllTasklist: state.configuration.configTasklist
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllChecklist: () => {
            return dispatch(getAllChecklist()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addTaskList: (data) => {
            return dispatch(addTaskListRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addCheckList: (data) => {
            return dispatch(addChecklistRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllTaskList: () => {
            return dispatch(getAllTaskListRequest()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getPredecessorTask: (tasklListId) => {
            return dispatch(getPredecessorRequest(tasklListId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getTasks: (tasklListId) => {
            return dispatch(getTaskOfTaskListRequest(tasklListId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getFunctionalRoles: () => {
            return dispatch(getFunctionalRoleRequest()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addTask: (data) => {
            return dispatch(addTaskRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllTags: (name, searchTag) => {
            return dispatch(getAllTagsRequset(name, searchTag)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addTags: (data) => {
            return dispatch(addTagRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getTaskDetails: (taskId) => {
            return dispatch(getTaskDetailsInTasklistConfig(taskId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        editTask: (taskId, data) => {
            return dispatch(ediTaskFromConfig(taskId, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ModalDown);
