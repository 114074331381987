import {makeStyles} from '@material-ui/core';
import CustomTheme from '../../../../utilities/theme/theme.json';

export const useStyles = makeStyles(theme => {
    return {
        filterBox:{
            float:'right',
            display:'flex'
        },
        filterBorder: {
            border:'1px solid black',
            marginLeft:'5px',
            borderRadius:'5px',
            backgroundColor:'#ffffff'
        },
        startDateStyle: {
            width:'82px',
            paddingLeft:'2px'
        },
        endDateStyle:{
            width:'82px',
            paddingLeft:'2px'
        },
        filterBorderForTasklist: {
            border:'1px solid black',
            marginLeft:'5px',
            borderRadius:'5px',
            backgroundColor:'#ffffff',
            width:'120px'
        },
        forPaddingLeft:{
            paddingLeft:'2px'
        },
        filterBorderForMilestone: {
            border:'1px solid black',
            marginLeft:'5px',
            borderRadius:'5px',
            backgroundColor:'#ffffff',
            width:'130px'
        },
        filterTasksBy:{
            marginTop:'6px'
        },
        todayHeaderBox:{
            backgroundColor:CustomTheme.fontColor,
            border:`1px solid ${CustomTheme.fontColor}`,
            borderRadius:'5px',
            width:'50px',
            color:'#ffffff',
            padding:'3px',
            marginBottom:'30px',
            marginTop:'20px',
            // position:'relative',
            // top:'-25px'
        },
        tomorrowHeaderBox:{
            backgroundColor:CustomTheme.fontColor,
            border:`1px solid ${CustomTheme.fontColor}`,
            borderRadius:'5px',
            width:'75px',
            color:'#ffffff',
            padding:'3px',
            position:'relative',
            top:'-33px'
        },
        thisWeekHeaderBox:{
            backgroundColor:CustomTheme.fontColor,
            border:`1px solid ${CustomTheme.fontColor}`,
            borderRadius:'5px',
            width:'75px',
            color:'#ffffff',
            padding:'3px',
            position:'relative',
            top:'-33px'
        },
        tomorrowSectionBox: {
            marginTop:'55px'
        },
        thisWeekSectionBox: {
            marginTop:'55px'
        },
        dateRangeBoxValue: {
            padding:'4px'
        },
        closeIcon: {
            fontSize:'19px',
            cursor:'pointer',
            color:"#77797c",
            marginTop:'-3px'
        },
        dateRangeTitleColor: {
            color:"#77797c",
            fontWeight:300
        },
        listTitle: {
            backgroundColor:CustomTheme.fontColor,
            border:`1px solid ${CustomTheme.fontColor}`,
            width: 'fit-content',
            marginTop: '28px',
            marginBottom: '18px',
            padding:'3px',
            borderRadius:'5px',
            color:'#ffffff',
        },
        cardStyle:{
            padding:'10px',
            marginTop:'10px',
            boxShadow:'0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%)',
            cursor:'pointer',
            borderRadius:'6px'
        },
    };
});
