import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import ChipInput from 'material-ui-chip-input';
import { Box, Divider, Grid, Tab, Tooltip, Typography, Card } from '@material-ui/core';
import { useStyles } from './styles';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
// import TimeLine from './components/TimeLine/TimeLine';
// import Conversations from './components/Conversations/Conversations';
// import Documents from './components/Documents/Documents';
// import Effort from './components/Effort/Effort';
import { getMilestoneDetailsByIdRequest, getTaskListDetailsByIdRequest, deleteTasklistRequestProject } from '../../TasksApiActions';
import ChecklistItemsDraggableDialog
    from '../../../../../../../../../utilities/ChecklistItemsDraggableDialog/ChecklistItemsDraggableDialog'
import CustomTheme from '../../../../../../../../../utilities/theme/theme.json'
import LoaderForSaveAndEdit from '../../../../../../../../../utilities/AppLoader/LoaderForSaveAndEdit'
import MessageModal from '../../../../../../../../../utilities/MessageModal/MessageModal'
const TaskListDetails = ({ setRightPane, deleteTasklistRequest, tasklistId, getTaskListDetailsById, showAddUpdateForm, loggedInUser, getMilestoneDetailsById }) => {
    const classes = useStyles();
    const modalRef = useRef();
    const [openChecklistItemsDialog, setOpenChecklistItemsDialog] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [milestoneDetails, setMilestoneDetails] = useState(null);
    const [isLoaderShowing, setIsLoaderShowing] = useState(false);
    const [tasklistDetails, setTasklistDetails] = useState([])

    useEffect(() => {
        setIsLoaderShowing(true)
        getTaskListDetailsById(tasklistId).then((res) => {
            setIsLoaderShowing(false)
            setTasklistDetails(res)
            if (res && res.milestoneId) {
                getMilestoneDetailsById(res.milestoneId).then((res) => {
                    setMilestoneDetails(res);
                })
            }
        })

    }, [tasklistId]);
    /**
     * for showing color to the status
     * @param status
     */
    const forStatusColorhandler = (status) => {
        switch (status) {
            case "todo":
                return CustomTheme.not_statred_status
            case "in_progress":
                return CustomTheme.in_progress_status
            case "stopped":
                return CustomTheme.stoppped_status
            case "blocked":
                return CustomTheme.blockked_status
            case "complete":
                return CustomTheme.complete_status
            case "deferred":
                return CustomTheme.deferred_status
            case "ready_for_review":
                return CustomTheme.ready_for_review
            default:
            // code block
        }
    }

    // for deleting tasklist
    const deleteForm = (id) => {
        const title = `Delete tasklist`
        const message = `Deleting this tasklist will also delete all the linked tasks with corresponding conversations, timelogs and activity details. Are you sure you want to proceed?`
        modalRef.current.setState({
            showModal: true,
            message: message,
            title: title,
            showOkButton: true,
            showCancelButton: true,
            showNote: false,
            note: '',
            handleOk: (note) => {
                deleteTasklistRequest(id).then((res) => {
                    setRightPane("")
                })

            },
            okText: "Yes",
            cancelText: 'No'
        });
    }

    return (
        <>
            {
                isLoaderShowing
                    ?
                    <Card className="add-update-form for-card-shadow">
                        <span
                            className="loader-for-save-and-edit"><LoaderForSaveAndEdit /></span>
                    </Card>
                    :
                    <Box className={classes.paneContainer}>
                        <Box className={classes.detailsPane}>
                            <Box
                                className={loggedInUser.userRoles.isProjectMember ? classes.detailsActionsForProjectMember : classes.detailsActions}>
                                <Box className={classes.detailsHeaderContent}>
                                    <Box>
                                        {/* <Tooltip title='Permissions' placement='top' arrow>
                <span className={classes.Icon}>
                  <i className='far fa-plus-square'></i>
                </span>
              </Tooltip> */}
                                        {
                                            loggedInUser.userRoles.isSystemSuperAdmin || loggedInUser.userRoles.isTeamLead
                                                ? (
                                                    <>
                                                        <Tooltip title='Delete tasklist' placement='top' arrow>
                                                            <span className={classes.Icon} onClick={() => deleteForm(tasklistDetails.id)}>
                                                                <i className='far fa-trash-alt'></i>
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip title='Edit tasklist' placement='top' arrow>
                                                            <span className={classes.Icon} onClick={() => showAddUpdateForm(tasklistDetails.id, 'taskList')}>
                                                                <i className='far fa-edit'></i>
                                                            </span>
                                                        </Tooltip>
                                                    </>
                                                )
                                                : <></>
                                        }
                                        {/* <Tooltip title='Link to Task' placement='top' arrow>
                <span className={classes.Icon}>
                  <i className='far fa-clone'></i>
                </span>
              </Tooltip>
              <Tooltip title='Add subtask' placement='top' arrow>
                <span className={classes.Icon}>
                  <i className='far fa-trash-alt'></i>
                </span>
              </Tooltip>
              <Tooltip title='Edit task' placement='top' arrow>
                <span className={classes.Icon}>
                  <i className='far fa-bell'></i>
                </span>
              </Tooltip>
              <Tooltip title='Make Recurring' placement='top' arrow>
                <span className={classes.Icon}>
                  <i className='far fa-eye'></i>
                </span>
              </Tooltip> */}
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={classes.detailsMainHeader}>
                                {/* <Box className={classes.detailsHeader}>
            <Box className={classes.detailsHeaderContentForUsersIcon}>
              <Box>
              </Box>
              <Box className={`inline-flex ${classes.forFloatRight}`}>
                <Box>
                    <img
                      src={`https://ui-avatars.com/api/?name=${tasklistDetails&&tasklistDetails.approver?tasklistDetails.approver.firstName:""}&background=random`}
                      alt='profile'
                      className="task-assignee-image"
                    />
                </Box>
                <Box>
                    <Typography variant='body2'>Approver</Typography>
                    <Typography variant='h6' className={`user-name ${classes.userNameColor}`}>
                    {tasklistDetails&&tasklistDetails.approver?tasklistDetails.approver.firstName+" "+tasklistDetails.approver.lastName:"-"}
                    </Typography>
                </Box>
              </Box>
            </Box>
          </Box> */}

                                <Box className={classes.infoContainer}>
                                    <Typography variant='h1' classes={{ root: classes.detailsTitle }}>
                                        {tasklistDetails && tasklistDetails.title ? tasklistDetails.title : ''}
                                    </Typography>
                                    {/* <Typography variant='body2' classes={{ root: classes.detailsBy }}>
              Added by Jeetal C. on March 15, 2021 at 9:32 AM
            </Typography> */}
                                    <Typography
                                        variant='h3'
                                        classes={{ root: classes.detailsDescription }}
                                    >
                                        {tasklistDetails && tasklistDetails.description ? tasklistDetails.description : ''}
                                    </Typography>
                                </Box>

                                <Box className={classes.tagsContaianer}>
                                    <span className={classes.tagIcon}>
                                        <i className='fas fa-tags'></i>
                                    </span>
                                    <Box className={classes.chipInputContainer}>
                                        <ChipInput
                                            disableUnderline={true}
                                            fullWidth
                                            disabled
                                            value={tasklistDetails && tasklistDetails.tags ? tasklistDetails.tags : []}
                                            classes={{
                                                root: classes.chipInput,
                                                inputRoot: classes.inputRoot,
                                                input: classes.input,
                                                chip: classes.chip,
                                                chipContainer: classes.chipContainer,
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box className={classes.detailsContainer}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Box>
                                                <Typography variant='body2'>
                                                    <span>
                                                        <i className='far fa-list-alt'></i>
                                                    </span>{' '}
                                                    Milestone
                                                </Typography>{' '}
                                                <Typography variant='h6' classes={{ root: classes.info }}>
                                                    {
                                                        milestoneDetails
                                                            ? milestoneDetails.title
                                                            : "-"
                                                    }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            {/* <Box>
                                                <Typography variant='body2'>
                                                    <span>
                                                        <i className='fas fa-check-double'></i>
                                                    </span>{' '}
                                                    Approval
                                                </Typography>{' '}
                                                <Typography variant='h6' classes={{ root: classes.info }}>
                                                    Pending
                                                </Typography>
                                            </Box> */}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Box>
                                                <Typography variant='body2'>
                                                    <span>
                                                        <i className='fas fa-clipboard-list'></i>
                                                    </span>{' '}
                                                    Checklist
                                                </Typography>{' '}
                                                {
                                                    tasklistDetails && tasklistDetails.checklist
                                                        ? (
                                                            <Typography
                                                                variant='h6'
                                                                classes={{ root: classes.info }}
                                                                className="cursor-pointer"
                                                                onClick={() => setOpenChecklistItemsDialog(true)}
                                                            >
                                                                {tasklistDetails.checklist.title}
                                                            </Typography>
                                                        )
                                                        : "-"
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box className={classes.detailsStatusContainer}>
                                    <Box className={classes.detailsStatusContent}>
                                        <Box
                                            className={`${classes.detailsStatusItemContainer} ${classes.statusGreen}`}
                                        >
                                            <Typography
                                                variant='body2'
                                                classes={{ root: classes.statusHeading }}
                                            >
                                                Due Date
                                            </Typography>
                                            <Typography variant="h3" classes={{ root: classes.statusText }}>
                                                {tasklistDetails && tasklistDetails.dueDate ? moment(tasklistDetails.dueDate).format('DD/MM/YYYY') :
                                                    <Typography variant='h6' classes={{ root: classes.info }}>-</Typography>}
                                            </Typography>
                                        </Box>

                                        <Box
                                            className={`${classes.detailsStatusItemContainer} ${classes.statusGreen}`}
                                        >
                                            <Typography
                                                variant='body2'
                                                classes={{ root: classes.statusHeading }}
                                            >
                                                Current State
                                            </Typography>
                                            <Typography variant="h3" classes={{ root: classes.statusText }} style={{ color: tasklistDetails && tasklistDetails.status ? forStatusColorhandler(tasklistDetails.status) : '' }}>
                                                {
                                                    tasklistDetails && tasklistDetails.status === "todo"
                                                        ?
                                                        "Not Started"
                                                        :
                                                        tasklistDetails && tasklistDetails.status === "in_progress"
                                                            ?
                                                            "In Progress"
                                                            :
                                                            tasklistDetails && tasklistDetails.status === "stopped"
                                                                ?
                                                                "Stopped"
                                                                :
                                                                tasklistDetails && tasklistDetails.status === "blocked"
                                                                    ?
                                                                    "Blocked"
                                                                    :
                                                                    tasklistDetails && tasklistDetails.status === "complete"
                                                                        ?
                                                                        "Complete"
                                                                        :
                                                                        tasklistDetails && tasklistDetails.status === "deferred"
                                                                            ?
                                                                            "Deferred"
                                                                            :
                                                                            tasklistDetails && tasklistDetails.status === "ready_for_review"
                                                                                ?
                                                                                "Ready for Review"
                                                                                :
                                                                                ""
                                                }
                                            </Typography>
                                        </Box>

                                        {/* <Box
                className={`${classes.detailsStatusItemContainer} ${classes.statusOrange}`}
              >
                <Typography
                  variant='body2'
                  classes={{ root: classes.statusHeading }}
                >
                  Effort Hours
                </Typography>
                <Typography classes={{ root: classes.statusText }}>36</Typography>
              </Box> */}
                                    </Box>
                                </Box>

                                <Box className={classes.tabsOuterContainer}>
                                    <TabContext value={activeTab}>
                                        <TabList
                                            classes={{
                                                flexContainer: classes.TabListRoot,
                                                indicator: classes.TabsIndicator,
                                            }}
                                            onChange={(e, newValue) => setActiveTab(newValue)}
                                        >
                                            <Tab
                                                // label='Progress'
                                                label={
                                                    <Typography
                                                        className={activeTab === "1" ? classes.selected : classes.notSelected}
                                                        variant="h4">Progress</Typography>
                                                }
                                                value='1'
                                                classes={{
                                                    wrapper: classes.TabWrapper,
                                                    root: classes.TabRoot,
                                                    selected: classes.selected,
                                                }}
                                            />
                                            <Tab
                                                // label='Conversations'
                                                label={
                                                    <Typography
                                                        className={activeTab === "2" ? classes.selected : classes.notSelected}
                                                        variant="h4">Conversations</Typography>
                                                }
                                                value='2'
                                                classes={{
                                                    wrapper: classes.TabWrapper,
                                                    root: classes.TabRoot,
                                                    selected: classes.selected,
                                                }}
                                            />
                                            <Tab
                                                // label='Documents'
                                                label={
                                                    <Typography
                                                        className={activeTab === "3" ? classes.selected : classes.notSelected}
                                                        variant="h4">Documents</Typography>
                                                }
                                                value='3'
                                                classes={{
                                                    wrapper: classes.TabWrapper,
                                                    root: classes.TabRoot,
                                                    selected: classes.selected,
                                                }}
                                            />
                                            <Tab
                                                // label='Time'
                                                label={
                                                    <Typography
                                                        className={activeTab === "4" ? classes.selected : classes.notSelected}
                                                        variant="h4">Time</Typography>
                                                }
                                                value='4'
                                                classes={{
                                                    wrapper: classes.TabWrapper,
                                                    root: classes.TabRoot,
                                                    selected: classes.selected,
                                                }}
                                            />
                                        </TabList>

                                        <Divider />

                                        <TabPanel
                                            value='1'
                                            classes={{
                                                root: classes.tabPanelRoot,
                                            }}
                                        >
                                            {/* <TimeLine /> */}
                                        </TabPanel>
                                        <TabPanel
                                            value='2'
                                            classes={{
                                                root: classes.tabPanelRoot,
                                            }}
                                        >
                                            {/* <Conversations /> */}
                                        </TabPanel>
                                        <TabPanel
                                            value='3'
                                            classes={{
                                                root: classes.tabPanelRoot,
                                            }}
                                        >
                                            {/* <Documents /> */}
                                        </TabPanel>
                                        <TabPanel
                                            value='4'
                                            classes={{
                                                root: classes.tabPanelRoot,
                                            }}
                                        >
                                            {/* <Effort /> */}
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </Box>
                        </Box>
                        {
                            openChecklistItemsDialog
                                ?
                                <ChecklistItemsDraggableDialog panel={"tasklist"} project={true} template={false}
                                    title={tasklistDetails && tasklistDetails.checklist ? tasklistDetails.checklist.title : ''}
                                    open={openChecklistItemsDialog}
                                    setOpenChecklistItemsDialog={setOpenChecklistItemsDialog}
                                    checklistItemsArray={tasklistDetails && tasklistDetails.checklist ? tasklistDetails.checklist : []} />
                                :
                                <></>
                        }
                    </Box>
            }
            <MessageModal
                ref={modalRef} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMilestoneDetailsById: (id) => {
            return dispatch(getMilestoneDetailsByIdRequest(id)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getTaskListDetailsById: (data) => {
            return dispatch(getTaskListDetailsByIdRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        deleteTasklistRequest: (tasklistId) => {
            return dispatch(deleteTasklistRequestProject(tasklistId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskListDetails);
