import React, {useEffect, useState} from 'react';
import "./Checklists.css";

import {Card, CardContent, Grid, Typography} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import ChecklistAdd from './projectChecklistAdd/ProjectChecklistAdd'
import {
    addItemsForChecklistInProject,
    editChecklistProjectRequest,
    getAllChecklistInProjectRequest,
    getSingleChecklistInProjectRequest
} from '../../../../../ProjectsApiActions'
import {useParams} from 'react-router';
import AppHeader from '../../../../../../userDashboard/components/AppHeader/AppHeader';
import ProjectHeader from '../ProjectHeader/ProjectHeader';
import {deleteItemsChecklistForShowingMessage} from '../../../../../../configuration/ConfigurationApiActions'
import CustomTheme from '../../../../../../../utilities/theme/theme.json'

const Checklists = ({
                        history,
                        match,
                        handleDrawerOpenClose,
                        getAllChecklistForProject,
                        getSingleChecklist,
                        editChecklist,
                        loggedInUser,
                        showingDeleteItemMessageHandler
                    }) => {

    const {id, singleChecklistId} = useParams()

    const [item, setItem] = useState(["Proposal Presentation", "Checklist 2", "Checklist 3"])

    const [openAddCheckList, setOpenAddCheckList] = useState(false)
    const [hide, setHide] = useState(false);
    const [allCheckList, setAllCheckList] = useState(null);
    const [checklistTitle, setChecklistTitle] = useState(null);
    const [checkListDescriptiion, setCheckListDescriptiion] = useState(null);
    const [checklistId, setChecklistId] = useState(null)
    const [oldChecklists, setOldChecklists] = useState([])


    // add items
    const [itemList, setItemList] = useState([
        {title: "", isCompleted: false}
    ])


    useEffect(() => {
        if (singleChecklistId != null) {
            getSingleChecklist(id, singleChecklistId).then((res) => {
                setHide(true)
                // setSingleCheckListItems(res.items)
                let data = Object.assign([], oldChecklists);
                res.items && Object.keys(res.items).map((checklist, checklistIndex) => {
                    const newRoleObj = {title: res.items[checklist].title, isCompleted: false};
                    data.push(newRoleObj);
                    return checklist
                })
                setOldChecklists(data);
                setChecklistTitle(res.title)
                setCheckListDescriptiion(res.description)
                setChecklistId(res.id)
            })
        }
    }, [singleChecklistId])

    useEffect(() => {
        getAllChecklistForProject(id).then((res) => {
            setAllCheckList(res);
        })

    }, [id])

    //edit checklist
    const editHandler = () => {
        let newItemList = []
        newItemList = itemList && itemList.filter((item) => item.title !== "")
        let newChecklist = oldChecklists.concat(newItemList)
        let data = {
            title: checklistTitle,
            note: ''
        }
        if (checkListDescriptiion) {
            data.description = checkListDescriptiion
        } else {
        }
        if (itemList) {
            data.items = newItemList != null ? newChecklist : oldChecklists
        } else {
        }
        editChecklist(checklistId, data).then((res) => {
            let data = Object.assign([], oldChecklists);
            res.items && Object.keys(res.items).map((checklist, checklistIndex) => {
                const newRoleObj = {title: res.items[checklist].title, isCompleted: false};
                data.push(newRoleObj);
                return checklist
            })
            setOldChecklists(res.items);
            // setSingleCheckListItems(res.items)
            setItemList([{title: "", isCompleted: false}])
        })

    }

    /**
     * Function executes on onChange action of form element
     */
    // const handleChangeInput = (event, roleIndex) => {
    //         const data = Object.assign({}, roles);
    //         data[event.target.name] = event.target.value;
    //         setRoles(data);
    // };

    /**
     * Function executes on submit form action
     */
        // const handleSubmit = () => {

        // };

    const handleClickOpen = () => {
            if (hide) {
            } else {
                setOpenAddCheckList(true);
            }

        }

    // const handleClose= () => {
    //     setOpen(false)
    // }

    // const  hideHandler= () => {
    //     setHide(true)
    // }

    // const handleSaveData= () => {
    //     const data = Object.assign([], itemDescriptionList);
    //     data.push(itemDescription);
    //     setItemDescriptionList(data);
    // }

    const deleteHandler = (rowIndex) => {

        // code for delete item
        const data = Object.assign([], item);
        data.splice(rowIndex, 1);
        setItem(data);

        // code for delete item description
        const dataForItems = Object.assign([], oldChecklists);
        dataForItems.splice(rowIndex, 1);
        // setSingleCheckListItems(dataForItems);
        setOldChecklists(dataForItems)
        showingDeleteItemMessageHandler();
    }

    // const handleResetBtn= () => {
    //     setItemDescription('')
    // }


    // const saveChecklistItemshandler= () => {
    //     const data={
    //         items:itemList
    //     }
    //     addItems(singleChecklistId,data).then((res)=>{
    //         setSingleCheckListItems(res.items)
    //         setItemList([{title:""}])
    //     })
    // }

    // storing values of item list in checklist
    const handleChangeInputListItem = (event, itemIndex) => {
        const data = Object.assign([], itemList);
        data[itemIndex] = {title: event.target.value, isCompleted: false};
        setItemList(data);
    }

    // for onclick Add icon add new row in checklist item list
    const addList = () => {
        const data = Object.assign([], itemList);
        const newRoleObj = {title: '', isCompleted: false};
        data.push(newRoleObj);
        setItemList(data);
    }


    // for onclick of cancel Button remove respected item list from cehcklist item list
    const removeItem = (itemIndex) => {
        const data = Object.assign([], itemList);
        data.splice(itemIndex, 1);
        setItemList(data);
    }


    return (
        <>
            <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                <ProjectHeader history={history} match={match} isParticipantsView={true}/>
            </AppHeader>

            <Card>
                <ValidatorForm
                    onSubmit={editHandler}
                    // onError={errors => console.log(errors)}
                >
                    <CardContent>
                        {
                            hide ?
                                <>
                                    <Grid style={{borderBottom: `1px dashed ${CustomTheme.primaryColor}`}} item xl={12}
                                          lg={12} md={12} sm={12} xs={12} className="header-actions-btns">
                                        <Grid container justify="space-between">
                                            <Grid onClick={() => {
                                                history.goBack()
                                                // setHide(false)
                                            }}>
                                                <span style={{color: CustomTheme.primaryColor}}
                                                      className="header-icons-styling"><i
                                                    className="far fa-arrow-alt-circle-left"></i></span>
                                            </Grid>
                                            {
                                                loggedInUser.userRoles.isProjectMember
                                                    ?
                                                    <></>
                                                    :
                                                    <Grid>
                                                        <Button size="small"
                                                                type="submit"
                                                                className="form-panel-save-btn submit-btn"
                                                                style={{color: CustomTheme.primaryColor}}
                                                        >
                                                            <i className="far fa-save"></i>
                                                        </Button>
                                                    </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Typography variant="h1" style={{color: CustomTheme.fontColor}}
                                                className="block-titles margin-top-20">Checklist</Typography>
                                    <Typography variant="body1">
                                        Click to edit the details of this checklist. That you can use in this project by
                                        linking it with a task.
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                            <TextValidator
                                                fullWidth
                                                required
                                                // variant="outlined"
                                                size="small"
                                                autoFocus
                                                label="Checklist title"
                                                InputProps={{
                                                    readOnly: loggedInUser.userRoles.isProjectMember,
                                                }}
                                                onChange={(e) => setChecklistTitle(e.target.value)}
                                                name="name"
                                                value={checklistTitle || ""}
                                                validators={['required', 'trim', 'maxStringLength:50']}
                                                errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                            />
                                        </Grid>
                                        <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                                            <TextValidator
                                                fullWidth
                                                InputProps={{
                                                    readOnly: loggedInUser.userRoles.isProjectMember,
                                                }}
                                                // variant="outlined"
                                                size="small"
                                                label="Description"
                                                onChange={(e) => setCheckListDescriptiion(e.target.value)}
                                                name="Checklist description"
                                                value={checkListDescriptiion || ""}
                                                validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:250']}
                                                errorMessages={['At least one alphanumeric characters', 'The max length of 250 charachers reached']}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Typography variant="h1" style={{color: CustomTheme.fontColor}}
                                                className="task-info-title">Project Checklists</Typography>
                                    <Typography variant="body1">
                                        These are all the checklists used in this project, along with the tasks they are
                                        linked to. You can edit them or create new ones. If you have used a checklist
                                        from the company templates, any edits you make will be limited to the project,
                                        and will not affect the overall template.
                                    </Typography>
                                </>
                        }
                        <Grid container>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container justify="space-between">
                                    <Grid>
                                        <Typography variant="h2" style={{color: CustomTheme.fontColor}}
                                                    className="block-titles margin-top-20">
                                            {
                                                hide
                                                    ?
                                                    'Add Checklist Items'
                                                    :
                                                    'Add Checklist'
                                            }
                                        </Typography>
                                    </Grid>
                                    {
                                        hide
                                            ?
                                            <></>
                                            :
                                            <>
                                                {
                                                    loggedInUser.userRoles.isProjectMember
                                                        ?
                                                        <></>
                                                        :
                                                        <Grid>
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={handleClickOpen}
                                                                className="add-task-button"
                                                                startIcon={<i className="fas fa-plus-circle"/>}
                                                            >
                                                                ADD
                                                            </Button>
                                                        </Grid>
                                                }
                                            </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography variant="body1">
                            {
                                hide
                                    ?
                                    'Click to add or modify checklist items'
                                    :
                                    'Create a new checklist that you can use in this project by linking it with a task. You can also create a new checklist from a task directly. '
                            }
                        </Typography>
                        {
                            hide
                                ?
                                <>
                                    {
                                        loggedInUser.userRoles.isProjectMember
                                            ?
                                            <></>
                                            :
                                            <Grid className="add-project-role-margin-top-container" item xl={12} lg={12}
                                                  md={12} sm={12} xs={12}>
                                                {
                                                    itemList.length > 0 && itemList.map((item, itemIndex) => (
                                                        <Grid container spacing={3} className="role-details-container"
                                                              key={itemIndex}>
                                                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                                                <TextValidator
                                                                    fullWidth
                                                                    autoFocus={itemList.length > 1 ? true : false}
                                                                    className="list-of-item"
                                                                    // variant="outlined"
                                                                    size="small"
                                                                    // label="List Item"
                                                                    onChange={(e) => handleChangeInputListItem(e, itemIndex)}
                                                                    name="listTitle"
                                                                    value={item.title || ""}
                                                                    validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:50']}
                                                                    errorMessages={['At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                                                />
                                                            </Grid>
                                                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                                                {
                                                                    (itemIndex === (itemList.length - 1))
                                                                        ? (
                                                                            <i
                                                                                className="add-role-icon fas fa-plus-circle"
                                                                                aria-hidden="true"
                                                                                onClick={addList}
                                                                            ></i>
                                                                        )
                                                                        : (
                                                                            <i
                                                                                className="remove-role-icon far fa-times-circle"
                                                                                onClick={() => removeItem(itemIndex)}
                                                                            ></i>
                                                                        )
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                    }

                                    {/* <Grid className="add-items-btn-grid" item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid container>
                            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>

                            </Grid>
                            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                className="additem-btn"
                                onClick={saveChecklistItemshandler}
                                startIcon={<i className="fas fa-plus-circle"/>}
                                >
                                ADD
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid> */}
                                </>
                                :
                                <></>
                        }

                    </CardContent>
                    {
                        hide ?
                            oldChecklists && Object.keys(oldChecklists).map((checklistItems, checklistItemsIndex) => {
                                return (
                                    <Grid container key={oldChecklists[checklistItems].id}>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Grid style={{color: CustomTheme.fontColor}}
                                                  className="checklist-item-description-list" container
                                                  justify="space-between">
                                                <Grid className="item-description-column">
                                                    {/* <span>{oldChecklists[checklistItems].title}</span> */}
                                                    <Typography
                                                        variant="body1">{oldChecklists[checklistItems].title}</Typography>
                                                </Grid>
                                                {
                                                    loggedInUser.userRoles.isSystemSuperAdmin || loggedInUser.userRoles.isTeamLead
                                                        ?
                                                        <Grid onClick={() => deleteHandler(checklistItemsIndex)}
                                                              className="trash-icon-description-list">
                                                            <span className="icon"><i
                                                                className="fas fa-trash-alt"></i></span>
                                                        </Grid>
                                                        :
                                                        <></>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })
                            :
                            <>
                                {
                                    allCheckList && Object.keys(allCheckList).length > 0
                                        ?
                                        <>
                                            {
                                                allCheckList && Object.keys(allCheckList).map((item, itemIndex) => {
                                                    return (
                                                        <div key={allCheckList[item].id}>
                                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                <Grid
                                                                    style={{paddingLeft: '15px', paddingRight: '15px'}}
                                                                    className="checklist-item-list" container>
                                                                    <Grid item xl={8} lg={8} md={8} sm={8} xs={8}
                                                                          style={{
                                                                              color: CustomTheme.linkColor,
                                                                              cursor: 'pointer'
                                                                          }} onClick={() => {
                                                                        history.push(`/project/${id}/checklists/${allCheckList[item].id}`)
                                                                    }}>
                                                                        <Typography
                                                                            variant="body1">{allCheckList[item].title}</Typography>
                                                                    </Grid>
                                                                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                                                        <Typography variant="body1">
                                                                            {
                                                                                allCheckList && allCheckList[item].milestone
                                                                                    ?
                                                                                    <span><i
                                                                                        className="fas fa-flag"></i> {allCheckList[item].milestone.title}</span>
                                                                                    :
                                                                                    allCheckList && allCheckList[item].tasklist
                                                                                        ?
                                                                                        <span><i
                                                                                            className="fas fa-list"></i> {allCheckList[item].tasklist.title}</span>
                                                                                        :
                                                                                        allCheckList && allCheckList[item].task
                                                                                            ?
                                                                                            <span><i
                                                                                                className="fas fa-tasks"></i> {allCheckList[item].task.title}</span>
                                                                                            :
                                                                                            '---'
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                    {/* <Grid item xl={1} lg={1} md={1} sm={1} xs={1} onClick={()=>deleteHandler(itemIndex)} className="trash-icon" >
                                                        <span className="icon"><i className="fas fa-trash-alt"></i></span>
                                                    </Grid> */}
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        <>
                                            <Grid container>
                                                <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>

                                                </Grid>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                    <Grid className="checklist-item-list" container>
                                                        <Grid className="no-records-found">
                                                            <span>No records to display</span>
                                                        </Grid>
                                                        {/* <Grid onClick={()=>deleteHandler(itemIndex)} className="trash-icon" >
                                            <span className="icon"><i className="fas fa-trash-alt"></i></span>
                                        </Grid> */}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                }
                            </>

                    }
                    <Grid container>
                        {/* <Grid item item xl={12} lg={12} md={12} sm={12} xs={12} >
                        <Grid container >
                            <Grid item item xl={10} lg={10} md={10} sm={10} xs={10} >

                            </Grid>
                            <Grid item item xl={2} lg={2} md={2} sm={2} xs={2} >
                                <Button
                                variant="contained"
                                size="medium"
                                className="nextbuttontasks"
                                >
                                NEXT
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    </Grid>
                </ValidatorForm>
            </Card>
            {
                openAddCheckList
                    ?
                    <ChecklistAdd projectId={id} setAllCheckList={setAllCheckList}
                                  setOpenAddCheckList={setOpenAddCheckList} openAddCheckList={openAddCheckList}/>
                    :
                    <></>
            }
        </>
    )

}


const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
        // post: state.postDetails.post,
        // comments:state.postDetails.comments
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllChecklistForProject: (id) => {
            return dispatch(getAllChecklistInProjectRequest(id)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getSingleChecklist: (id, checklistId) => {
            return dispatch(getSingleChecklistInProjectRequest(id, checklistId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addItems: (checklistId, data) => {
            return dispatch(addItemsForChecklistInProject(checklistId, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        editChecklist: (checklistId, data) => {
            return dispatch(editChecklistProjectRequest(checklistId, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        showingDeleteItemMessageHandler: () => {
            return dispatch(deleteItemsChecklistForShowingMessage())
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Checklists);
