import {makeStyles} from '@material-ui/core';
import ThemeOne from '../../../../utilities/theme/theme.json';
import CustomeTheme from '../../../../utilities/theme/theme.json';

const drawerWidth = 240;
export const useStyles = makeStyles(theme => {
    return {
        paper: {
            borderRight: '1px solid #e0e0e0',
            boxShadow: '0 4px 8px 0 rgb(32 33 36 / 28%)',
            top: '65px',
            background: ThemeOne.leftMenuBackground
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            overflowX: 'hidden',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        icon: {
            fontSize: '22px',
            width: '30px',
            height: '30px',
            marginRight: '15px',
        },
        menuName: {
            fontSize: '15px',
            margin: '0px',
        },
        button: {
            color: CustomeTheme.leftTopMenuIconButton,
            padding: '10px 8px 10px 20px',
            '&:hover': {
                color: CustomeTheme.activeLinkColor,
            },
        },
        hide: {
            display: 'none',
        },
        activeMenu: {
            padding: '10px 20px',
            color: CustomeTheme.activeLinkColor,
        },
        activeColor: {
            color: CustomeTheme.activeLinkColor,
        },
        removePadding: {
            padding: '0px',
        },
        profileContainer: {
            position: 'fixed',
            bottom: '0',
            // width: '240px',
        },
        profileImageContainer: {
            marginRight: '13px',
        },
        profileImage: {
            borderRadius: '100%',
            width: '40px',
            height: '40px',
            marginLeft: '-0.4rem',
        },
    };
});
