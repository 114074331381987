import axios from 'axios';
import {sessionExpired} from "../modules/authentication/AuthenticationActions";
import Auth from "./Auth";
import {SNACKBAR_VARIAINT} from "./Constants";
import {hideLoader, showLoader, showSnackbar} from "./AppLoader/AppLoaderActions";

/**
 * Used to communicate with server
 */
class HttpRequest {

    /**
     *
     * @param {*} url Resource URL
     * @param {*} method HTTP method(GET | POST | PUT | PATCH | DELETE)
     * @param {*} headers HTTP request headers
     * @param {*} data HTTP request data (If applicable)
     */
    constructor(dispatch, url, method = 'get', data = {}, headers = {}, options) {
        this.url = url;
        this.method = method;
        this.data = data;
        this.headers = headers;
        this.options = options;
        this.dispatch = dispatch;
    };

    showHideLoader = (dispatch) => {
        let numberOfAjaxCAllPending = 0;
        // Add a request interceptor
        axios.interceptors.request.use((config) => {
            if (!config.isHideLoader) {
                numberOfAjaxCAllPending++;
                // show loader
                dispatch(showLoader());
            } else {
            }
            return config;
        }, function (error) {
            return Promise.reject(error);
        });
        // Add a response interceptor
        axios.interceptors.response.use((response) => {
            numberOfAjaxCAllPending--;
            if (numberOfAjaxCAllPending === 0) {
                //hide loader
                dispatch(hideLoader());
            }
            return response;
        }, function (error) {
            return Promise.reject(error);
        });
    };

    /**
     * Send http request to server to write data to / read data from server
     * axios library provides promise implementation to send request to server
     * Here we are using axios library for requesting a resource
     */
    send = (accessToken = null, isHideLoader = false) => {
        // Content-Type and Authorization are default headers. If we want to send additional headers then add it to object
        let headers = {...this.headers, 'Content-Type': 'application/json'};
        const token = accessToken === null ? Auth.getToken() : accessToken;

        if (token) {
            headers['access-token'] = `Bearer ${token}`;
        }

        return new Promise((resolve, reject) => {
            this.showHideLoader(this.dispatch);
            // Make server request using axios
            axios({
                baseURL: process.env.REACT_APP_SERVER_API_URL,
                // withCredentials: true,
                url: this.url,
                method: this.method,
                headers: headers,
                data: JSON.stringify(this.data),
                isHideLoader: isHideLoader
            }).then(response => {
                resolve(response);
            }).catch(error => {
                this.dispatch(hideLoader());
                // Users signature(JWT token) has been expired. Redirect user to Login page
                if (error.response === undefined) {
                    this.dispatch(showSnackbar("Something went wrong. Please try again.", SNACKBAR_VARIAINT.ERROR));
                } else if (error.response.status === 401) {
                    if (this.dispatch) {
                        this.dispatch(sessionExpired(error.response.data.error.message));
                    }
                } else if (error.response.status === 428) {

                } else if (error.response.status === 500) {
                    this.dispatch(showSnackbar(error.response.data.error.message, SNACKBAR_VARIAINT.ERROR));
                } else if (error.response.status === 400) {
                    this.dispatch(showSnackbar(error.response.data.error.message, SNACKBAR_VARIAINT.ERROR));
                } else {
                    this.dispatch(showSnackbar(error.response.data.error.message, SNACKBAR_VARIAINT.ERROR));
                }
                reject(error);
            });
        });
    };

    upload = () => {
        // Content-Type and Authorization are default headers. If we want to send additional headers then add it to object
        let headers = {...this.headers, 'Content-Type': 'multipart/form-data'};

        return new Promise((resolve, reject) => {
            this.showHideLoader(this.dispatch);
            // Make server request using axios
            axios({
                baseURL: process.env.REACT_APP_SERVER_API_URL,
                withCredentials: true,
                url: this.url,
                method: this.method,
                headers: headers,
                data: this.data
            }).then(response => {
                resolve(response);
            }).catch(error => {
                this.dispatch(hideLoader());
                // Users signature(JWT token) has been expired. Redirect user to Login page
                if (error.response === undefined) {
                    this.dispatch(showSnackbar("Something went wrong. Please try again.", SNACKBAR_VARIAINT.ERROR));
                } else if (error.response.status === 401) {
                    if (this.dispatch)
                        this.dispatch(sessionExpired(error.response.data.error.message));
                } else {
                    this.dispatch(showSnackbar(error.response.data.error.message, SNACKBAR_VARIAINT.ERROR));
                }
                reject(error);
            });
        });
    };

    s3Upload = () => {
        // Content-Type and Authorization are default headers. If we want to send additional headers then add it to object
        let headers = {...this.headers, 'Content-Type': 'multipart/form-data'};

        return new Promise((resolve, reject) => {
            this.showHideLoader(this.dispatch);
            // Make server request using axios
            axios({
                withCredentials: true,
                url: this.url,
                method: this.method,
                headers: headers,
                data: this.data
            }).then(response => {
                resolve(response);
            }).catch(error => {
                this.dispatch(hideLoader());
                if (error.response === undefined) {
                    this.dispatch(showSnackbar("Something went wrong. Please try again.", SNACKBAR_VARIAINT.ERROR));
                } else if (error.response.status === 401) {
                    if (this.dispatch)
                        this.dispatch(sessionExpired(error.response.data.error.message));
                } else if (error.response.status === 428) {

                } else {
                    this.dispatch(showSnackbar(error.response.data.error.message, SNACKBAR_VARIAINT.ERROR));
                }
                reject(error);
            });
        });
    };
}

export default HttpRequest;
