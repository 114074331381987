/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * Used to manage the cookies of the application
 */
class Auth {

    static login(user) {
        cookies.set('user', user, {path: '/'});
    };

    static setToken(token) {
        localStorage.setItem('access_token', token);
    }

    /**
     * Remove all cookies so that user session will be expired
     */
    static logout() {
        cookies.remove('user', {path: '/'});
        localStorage.removeItem('access_token');
    };

    /**
     * Remove all cookies so that user session will be expired
     */
    static logoutAndNavigateToLogin() {
        cookies.remove('user', {path: '/'});
        localStorage.removeItem('access_token');
        window.location = '/';
    }

    /**
     * Check if authentication JWT token exists in cookies
     * If it exists then return true
     */
    static isLoggedIn() {
        const user = cookies.get('user', {path: '/'});
        return !!user;
    };

    static getToken() {
        return localStorage.getItem('access_token');
    }

    /**
     * Get logged in user details
     */
    static getUserDetails() {
        return cookies.get('user', {path: '/'});
    }

    static setSystemGeneratedPassword(isSystemGeneratedPassword) {
        const user = cookies.get('user', {path: '/'});
        cookies.set('user', {...user, isSystemGeneratedPassword: isSystemGeneratedPassword}, {path: '/'});
    };

}

export default Auth;
