import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {connect} from 'react-redux';
import {Box, FormHelperText, Grid, TextField, Typography,} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useStyles} from './styles';

import {getAllProjectTypesRequest} from '../../../../../ProjectsApiActions';
import {getAllTeamsRequset} from '../../../../../../configuration/ConfigurationApiActions';

const BasicsForm = forwardRef((props, ref) => {
    const classes = useStyles();

    // const accessList = [
    //   { title: 'Private to only me' },
    //   { title: 'Private to a team' },
    //   { title: 'Public to the organization' },
    // ];

    const [name, setName] = useState('');
    const [projectType, setProjectType] = useState(null);
    const [projectTypes, setProjectTypes] = useState(null);
    const [projectTypeError, setProjectTypeError] = useState('');
    const [teamList, setTeamList] = useState(null);
    const [team, setTeam] = useState(null);
    const [ais, setAis] = useState('');
    const [description, setDescription] = useState('');
    const [projectObjective, setProjectObjective] = useState('');
    // const [access, setAccess] = useState(accessList[0]);

    const [nameError, setNameError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [teamError, setTeamError] = useState('');
    const [areaInSqftError,setAreaInSqftError] = useState('')

    useEffect(() => {
        props.getAllProjectTypes().then((res) => {
            setProjectTypes(res);
        });
        props.getAllTeams().then((res) => {
            setTeamList(res);
        });
    }, []);

    const validateForm = () => {
        if (
            name === '' ||
            description === '' ||
            projectType === null ||
            team === null
        ) {
            if (name === '') {
                setNameError('Name is required');
            }
            if (description === '') {
                setDescriptionError('Description is required');
            }
            if (projectType === null) {
                setProjectTypeError('Project yype is required');
            }
            if (team === null) {
                setTeamError('Team is required');
            }
            if (ais < 0) {
                setAreaInSqftError('Area in sqft must positive number')
            }
            return false;
        } else {
            return true;
        }
    };

    const isValidated = () => {
        return validateForm();
    };

    useImperativeHandle(ref, () => ({
        isValidated: () => {
            return isValidated();
        },
        state: {
            name,
            projectType,
            team,
            ais,
            description,
            projectObjective,
        },
    }));
    return (
        <Box>
            <Box className={classes.titleContainer}>
                <Typography variant='h2' className={classes.titleLabel}>
                    Let's start with the basics
                </Typography>
            </Box>
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            // label='Name'
                            label={
                                <Typography variant="body1"> Name </Typography>
                            }
                            value={name}
                            onChange={e => {
                                setName(e.target.value);
                                setNameError('');
                            }}
                        />
                        {
                            <FormHelperText className={classes.errorText}>
                                {nameError}
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                            fullWidth
                            value={projectType}
                            onChange={(e, value) => {
                                setProjectType(value);
                                setProjectTypeError('')
                            }}
                            options={projectTypes}
                            getOptionLabel={option => option.type}
                            getOptionSelected={(option, value) =>
                                option.id === value.id
                            }
                            renderInput={params => (
                                <TextField {...params}
                                    // label='Project Type'
                                           label={
                                               <Typography variant="body1"> Project Type </Typography>
                                           }
                                />
                            )}
                        />
                        {
                            <FormHelperText className={classes.errorText}>
                                {projectTypeError}
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                            fullWidth
                            value={team}
                            onChange={(e, value) => {
                                setTeam(value);
                                setTeamError('');
                            }}
                            options={teamList}
                            getOptionLabel={option => option.name}
                            getOptionSelected={(option, value) =>
                                option.name === value.name
                            }
                            renderInput={params => (
                                <TextField {...params}
                                    // label='Select Team'
                                           label={
                                               <Typography variant="body1"> Select Team </Typography>
                                           }
                                />
                            )}
                        />
                        {
                            <FormHelperText className={classes.errorText}>
                                {teamError}
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            // label='Area in sqft'
                            label={
                                <Typography variant="body1"> Area in sqft </Typography>
                            }
                            type="number"
                            value={ais}
                            onChange={e => {
                                setAis(e.target.value);
                                setAreaInSqftError('')
                            }}
                        />
                        {
                            <FormHelperText className={classes.errorText}>
                                {areaInSqftError}
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            fullWidth
                            // label='Description'
                            label={
                                <Typography variant="body1"> Description </Typography>
                            }
                            value={description}
                            onChange={e => {
                                setDescription(e.target.value);
                                setDescriptionError('');
                            }}
                        />
                        {
                            <FormHelperText className={classes.errorText}>
                                {descriptionError}
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            fullWidth
                            // label='Project Objective'
                            label={
                                <Typography variant="body1"> Project Objective </Typography>
                            }
                            value={projectObjective}
                            onChange={e => {
                                setProjectObjective(e.target.value);
                            }}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Autocomplete
              fullWidth
              value={access}
              onChange={(e, value) => setAccess(value)}
              options={accessList}
              getOptionLabel={option => option.title}
              getOptionSelected={(option, value) =>
                option.title === value.title
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label='Who should have access to this project'
                />
              )}
            />
          </Grid> */}
                </Grid>
            </Box>
        </Box>
    );
});

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllProjectTypes: (taskId) => {
            return dispatch(getAllProjectTypesRequest(taskId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllTeams: () => {
            return dispatch(getAllTeamsRequset()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef: true}
)(BasicsForm);
