import React, {useEffect, useState} from 'react';
import "./Checklists.css";

import {Card, CardContent, Grid, Typography} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import CheckListModal from '../../../../utilities/CheckListModal/ChecklistModal'
import {
    addItemsInChecklist,
    deleteItemsChecklistForShowingMessage,
    editChecklistRequest,
    getAllChecklist,
    getSingleCheckListRequest
} from '../../ConfigurationApiActions'
import {useParams} from 'react-router';
import CustomTheme from '../../../../utilities/theme/theme.json'

const Checklists = ({
                        history,
                        getAllChecklist,
                        getSingleChecklist,
                        addItems,
                        getSingleChecklistDetails,
                        editChecklist,
                        showingDeleteItemMessageHandler
                    }) => {

    const {singleChecklistId} = useParams()
    const [item, setItem] = useState(["Proposal Presentation", "Checklist 2", "Checklist 3"])


    const [openAddCheckList, setOpenAddCheckList] = useState(false)
    const [hide, setHide] = useState(false);
    const [allCheckListData, setAllCheckListData] = useState(null);
    const [checklistTitle, setChecklistTitle] = useState(null);
    const [checkListDescriptiion, setCheckListDescriptiion] = useState(null);
    const [checklistId, setChecklistId] = useState(null)
    const [oldChecklists, setOldChecklists] = useState([])

    // for add checklist
    // add items
    const [itemList, setItemList] = useState([
        {title: "", isCompleted: false}
    ])

    useEffect(() => {
        getSingleChecklist(singleChecklistId).then((res) => {
            setHide(true)
            let data = Object.assign([], oldChecklists);
            res.items && Object.keys(res.items).map((checklist, checklistIndex) => {
                const newRoleObj = {title: res.items[checklist].title, isCompleted: false};
                data.push(newRoleObj);
                return checklist
            })
            setOldChecklists(data);
            setChecklistTitle(res.title)
            setCheckListDescriptiion(res.description)
            setChecklistId(res.id)
        })
    }, [singleChecklistId])

    useEffect(() => {
        getAllChecklist().then((res) => {
            setAllCheckListData(res);
        })

    }, [])

    //edit checklist
    const editHandler = () => {
        let newItemList = []
        newItemList = itemList && itemList.filter((item) => item.title !== "")
        let newChecklist = oldChecklists.concat(newItemList)
        let data = {
            title: checklistTitle,
            note: ''
        }
        if (checkListDescriptiion) {
            data.description = checkListDescriptiion
        } else {
        }
        if (itemList) {
            data.items = newItemList != null ? newChecklist : oldChecklists
        } else {
        }
        editChecklist(checklistId, data).then((res) => {
            let data = Object.assign([], newChecklist);
            res.items && Object.keys(res.items).map((checklist, checklistIndex) => {
                const newRoleObj = {title: res.items[checklist].title};
                data.push(newRoleObj);
                return checklist
            })
            setOldChecklists(res.items);
            // setSingleCheckListItems(res.items)
            setItemList([{title: "", isCompleted: false}])
        })

    }


    const handleClickOpen = () => {
        setOpenAddCheckList(true);
    }

    const deleteHandler = (rowIndex) => {

        // code for delete item
        const data = Object.assign([], item);
        data.splice(rowIndex, 1);
        setItem(data);

        // code for delete item description
        const dataForItems = Object.assign([], oldChecklists);
        dataForItems.splice(rowIndex, 1);
        // setSingleCheckListItems(dataForItems);
        setOldChecklists(dataForItems)
        showingDeleteItemMessageHandler();
    }


    // storing values of item list in checklist
    const handleChangeInputListItem = (event, itemIndex) => {
        const data = Object.assign([], itemList);
        data[itemIndex] = {title: event.target.value, isCompleted: false};
        setItemList(data);
    }

    // for onclick Add icon add new row in checklist item list
    const addList = () => {
        const data = Object.assign([], itemList);
        const newRoleObj = {title: '', isCompleted: false};
        data.push(newRoleObj);
        setItemList(data);
    }


    // for onclick of cancel Button remove respected item list from cehcklist item list
    const removeItem = (itemIndex) => {
        const data = Object.assign([], itemList);
        data.splice(itemIndex, 1);
        setItemList(data);
    }


    return (
        <>
            <Card>
                <ValidatorForm
                    onSubmit={editHandler}
                    // onError={errors => console.log(errors)}
                >
                    <CardContent>
                        {
                            hide ?
                                <>
                                    <Grid style={{borderBottom: `1px dashed ${CustomTheme.primaryColor}`}} item xl={12}
                                          lg={12} md={12} sm={12} xs={12} className="header-actions-btns">
                                        <Grid container justify="space-between">
                                            <Grid onClick={() => {
                                                history.goBack()
                                            }}>
                                                <span style={{color: CustomTheme.primaryColor}}
                                                      className="header-icons-styling"><i
                                                    className="far fa-arrow-alt-circle-left"></i></span>
                                            </Grid>
                                            <Grid>
                                                <Button size="small"
                                                        type="submit"
                                                        className="form-panel-save-btn submit-btn"
                                                        style={{color: CustomTheme.primaryColor}}
                                                >
                                                    <i className="far fa-save"></i>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Typography variant="h1" style={{color: CustomTheme.fontColor}}
                                                className="block-titles margin-top-20">Checklist</Typography>
                                    <Typography variant="body1">
                                        Click to edit the details of this checklist. This checklist can be used in
                                        multiple templates and projects.
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                label="Checklist title"
                                                onChange={(e) => setChecklistTitle(e.target.value)}
                                                value={checklistTitle || ''}
                                                validators={['required', 'trim', 'maxStringLength:50']}
                                                errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached.']}
                                            />
                                        </Grid>
                                        <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                label="Description"
                                                onChange={(e) => setCheckListDescriptiion(e.target.value)}
                                                value={checkListDescriptiion || ''}
                                                validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:250']}
                                                errorMessages={['At least one alphanumeric characters', 'The max length of 250 charachers reached']}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Typography variant="h1" style={{color: CustomTheme.fontColor}}
                                                className="task-info-title">Checklist Configuration</Typography>
                                    <Typography variant="body1">
                                        You can create a new checklist, or edit an existing checklist
                                    </Typography>
                                </>
                        }
                        <Grid container>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container justify="space-between">
                                    <Grid>
                                        <Typography variant="h2" style={{color: CustomTheme.fontColor}}
                                                    className="block-titles margin-top-20">
                                            {
                                                hide
                                                    ?
                                                    'Add Checklist Items'
                                                    :
                                                    'Add Checklist'
                                            }
                                        </Typography>
                                    </Grid>
                                    {
                                        hide
                                            ?
                                            <></>
                                            :
                                            <Grid>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    size="small"
                                                    onClick={handleClickOpen}
                                                    className="add-task-button"
                                                    startIcon={<i className="fas fa-plus-circle"/>}
                                                >
                                                    ADD
                                                </Button>
                                            </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography variant="body1">
                            {
                                hide
                                    ?
                                    'Click to add or modify checklist items'
                                    :
                                    'You can add new checklist that can be used in multiple templates and projects. '
                            }
                        </Typography>
                        {
                            hide
                                ?
                                <>
                                    <Grid className="add-project-role-margin-top-container" item xl={12} lg={12} md={12}
                                          sm={12} xs={12}>
                                        {
                                            itemList.length > 0 && itemList.map((item, itemIndex) => (
                                                <Grid container spacing={3} className="role-details-container"
                                                      key={itemIndex}>
                                                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                                        <TextValidator
                                                            fullWidth
                                                            autoFocus={itemList.length > 1 ? true : false}
                                                            className="list-of-item"
                                                            // variant="outlined"
                                                            size="small"
                                                            // label="List Item"
                                                            onChange={(e) => handleChangeInputListItem(e, itemIndex)}
                                                            name="listTitle"
                                                            value={item.title || ''}
                                                            validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:50']}
                                                            errorMessages={['At least one alphanumeric characters', 'The max length of 50 charachers reached.']}
                                                        />
                                                    </Grid>
                                                    <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                                        {
                                                            (itemIndex === (itemList.length - 1))
                                                                ? (
                                                                    <i
                                                                        className="add-role-icon fas fa-plus-circle"
                                                                        aria-hidden="true"
                                                                        onClick={addList}
                                                                    ></i>
                                                                )
                                                                : (
                                                                    <i
                                                                        className="remove-role-icon far fa-times-circle"
                                                                        onClick={() => removeItem(itemIndex)}
                                                                    ></i>
                                                                )
                                                        }
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                    {/* <Grid className="add-items-btn-grid" item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid container>
                            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>

                            </Grid>
                            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                className="additem-btn"
                                onClick={saveChecklistItemshandler}
                                startIcon={<i className="fas fa-plus-circle"/>}
                                >
                                ADD
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid> */}
                                </>
                                :
                                <></>
                        }

                    </CardContent>
                    {
                        hide ?
                            oldChecklists && Object.keys(oldChecklists).map((checklistItems, checklistItemsIndex) => {
                                return (
                                    <Grid container key={checklistItemsIndex}>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Grid style={{color: CustomTheme.fontColor}}
                                                  className="checklist-item-description-list" container
                                                  justify="space-between">
                                                <Grid className="item-description-column">
                                                    <Typography
                                                        variant="body1">{oldChecklists && oldChecklists[checklistItems].title}</Typography>
                                                    {/* <span>{oldChecklists&&oldChecklists[checklistItems].title}</span> */}
                                                </Grid>
                                                <Grid onClick={() => deleteHandler(checklistItemsIndex)}
                                                      className="trash-icon-description-list">
                                                    <span style={{color: CustomTheme.fontColor}} className="icon"><i
                                                        className="fas fa-trash-alt"></i></span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })
                            :
                            <>
                                {
                                    allCheckListData && Object.keys(allCheckListData).length > 0
                                        ?
                                        <>
                                            {
                                                allCheckListData && Object.keys(allCheckListData).map((item, itemIndex) => {
                                                    return (
                                                        <Grid container key={itemIndex}>
                                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                <Grid className="checklist-item-list" container
                                                                      justify="space-between">
                                                                    <Grid style={{color: CustomTheme.linkColor}}
                                                                          className="item-name-column" onClick={() => {
                                                                        // hideHandler();
                                                                        history.push(`/configuration/checklists/${allCheckListData[item].id}`)
                                                                        // singleChecklistHandler(allCheckListData[item].title,allCheckListData[item].id,allCheckListData[item].description);
                                                                    }}>
                                                                        {/* <span>{allCheckListData[item].title}</span> */}
                                                                        <Typography
                                                                            variant="body1">{allCheckListData[item].title}</Typography>
                                                                    </Grid>
                                                                    {/* <Grid onClick={()=>deleteHandler(itemIndex)} className="trash-icon" >
                                                        <span className="icon"><i className="fas fa-trash-alt"></i></span>
                                                    </Grid> */}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        <Grid container>
                                            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>

                                            </Grid>
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                <Grid className="checklist-item-list" container>
                                                    <Grid className="no-records-found">
                                                        <span>No records to display</span>
                                                    </Grid>
                                                    {/* <Grid onClick={()=>deleteHandler(itemIndex)} className="trash-icon" >
                                            <span className="icon"><i className="fas fa-trash-alt"></i></span>
                                        </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                }
                            </>

                    }
                    <Grid container>
                        {/* <Grid item item xl={12} lg={12} md={12} sm={12} xs={12} >
                        <Grid container >
                            <Grid item item xl={10} lg={10} md={10} sm={10} xs={10} >

                            </Grid>
                            <Grid item item xl={2} lg={2} md={2} sm={2} xs={2} >
                                <Button
                                variant="contained"
                                size="medium"
                                className="nextbuttontasks"
                                >
                                NEXT
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    </Grid>
                </ValidatorForm>
            </Card>
            {
                openAddCheckList
                    ?
                    <CheckListModal configChecklist={true} setAllCheckListData={setAllCheckListData}
                                    hideTaskListTemplate={false} setOpenAddCheckList={setOpenAddCheckList}
                                    openAddCheckList={openAddCheckList}/>
                    :
                    <></>
            }
        </>
    )

}


const mapStateToProps = (state) => {
    return {
        // loggedInUser: state.auth.loggedInUser,
        // post: state.postDetails.post,
        // comments:state.postDetails.comments
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllChecklist: () => {
            return dispatch(getAllChecklist()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getSingleChecklist: (checklistId) => {
            return dispatch(getSingleCheckListRequest(checklistId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addItems: (checklistId, data) => {
            return dispatch(addItemsInChecklist(checklistId, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        editChecklist: (checklistId, data) => {
            return dispatch(editChecklistRequest(checklistId, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        showingDeleteItemMessageHandler: () => {
            return dispatch(deleteItemsChecklistForShowingMessage())
        },

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Checklists);
