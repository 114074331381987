// Export Constants (Action names)
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const HIDE_SNACK_BAR = 'HIDE_SNACK_BAR';
export const SHOW_SNACK_BAR = 'SHOW_SNACK_BAR';

export const showLoader = () => {
    return {
        type: SHOW_LOADER,
        showLoader: true
    };
};

export const hideLoader = () => {
    return {
        type: HIDE_LOADER,
        showLoader: false
    };
};

export const clearSnackbar = () => {
    return {
        type: HIDE_SNACK_BAR
    };
};

export const showSnackbar = (snackbarMessage, snackbarVariant) => {
    return {
        type: SHOW_SNACK_BAR,
        snackbarMessage,
        snackbarVariant
    };
};
