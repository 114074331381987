import React, { useEffect, useRef, useState } from 'react'
import './MilestoneForm.css';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import { addTagRequest, getAllTagsRequset } from '../../../configuration/ConfigurationApiActions';
import {
    getAllParticipantsRequest,
    getAllProjectCheckListRequest,
    getAllTaskListsRequest,
    getAllUnAssociatedTasklistOfProject,
    updateMilestoneRequest,
    addMilestoneRequest
} from '../../MilestonesApiActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import SearchableDropDown from '../../../../utilities/SearchableDropDown/SearchableDropDown';
import CustomTheme from '../../../../utilities/theme/theme.json'
import MessageModal from '../../../../utilities/MessageModal/MessageModal'
import ChecklistModal from '../../../../utilities/CheckListModalProjectDefination/ChecklistModal';
import ChecklistDropdownSearchable
    from '../../../../utilities/SearchableDropDown/ChecklistDropdownSearchable/ChecklistDropdownSearchable';

const MilestoneForm = ({
    getAllParticipants, getAllProjectCheckList,
    milestoneIdForUpdateView, projectId, getAllOnlyTaskLists,
    loggedInUser, getAllTags, addTags, setIsDisplayForm, getUnAssociatedTasklist,
    updateMilestone, addMilestone, setAddUpdateRes, closeAddUpdateFrom,
    showTaskDetailsPane, milestoneDetails
}) => {
    const modalRef = useRef();
    const [openAddCheckList, setOpenAddCheckList] = useState(false);
    const [participents, setParticipents] = useState([]);

    const [allTaskList, setAllTaskList] = useState([]);
    const [showTasklistDropDown, setShowTasklistDropDown] = useState(false);
    const [showResponsiblePersonDropdown, setShowResponsiblePersonDropdown] = useState(false);
    const [showApprovalDropdown, setShowApprovalDropdown] = useState(false);
    const [allCheckList, setAllCheckList] = useState([]);
    const [showChecklistDropdown, setShowChecklistDropdown] = useState(false);
    const [isTitleEditable, setIsTitleEditable] = useState(false);
    const [isDescriptionEditable, setIsDescriptionEditable] = useState(false);
    const [isChipEditable, setIsChipEditable] = useState(false);
    const [allTags, setAllTags] = useState(null)
    const [tagName, setTagName] = useState(null);
    const [isChecklistNewCreating, setIsChecklistNewCreating] = useState(false);
    const [isStatusShow, setIsStatusShow] = useState(false);
    const [status, setStatus] = useState([
        { id: '1', title: 'Not Started', value: 'todo', depend: ["todo"] },
        {
            id: '2',
            title: 'In Progress',
            value: 'in_progress',
            depend: ["in_progress", "todo", "stopped", "blocked", "complete", "deferred"]
        },
        { id: '3', title: 'Stopped', value: 'stopped', depend: ["stopped", "in_progress", "stopped"] },
        { id: '4', title: 'Blocked', value: 'blocked', depend: ["blocked", "in_progress"] },
        { id: '5', title: 'Complete', value: 'complete', depend: ["complete", "in_progress"] },
        {
            id: '6',
            title: 'Deferred',
            value: 'deferred',
            depend: ["deferred", "todo", "in_progress", "stopped", "blocked"]
        }
    ]);
    const [isChangeInUpdate, setIsChangeInUpdate] = useState(false)
    const [milestoneForm, setMilestoneForm] = useState({
        responsiblePerson: null,
        approver: null,
        title: null,
        description: null,
        tags: [],
        tasklist: [],
        checklist: null,
        status: null,
        dueDate: null,
        showDueDateField: false,
    })

    useEffect(() => {

        /*
            Api function for getting all participents of project
            @param projectId
        */
        getAllParticipants(projectId).then((res) => {
            setParticipents(res);
        })

    }, []);

    /*
        UseEffect for hiding fields of add form
    */
    useEffect(() => {
        setOpenAddCheckList(false);

        setShowApprovalDropdown(false);
        setShowChecklistDropdown(false);
        setShowResponsiblePersonDropdown(false);
        setIsTitleEditable(false);
        setIsDescriptionEditable(false);
        setIsChipEditable(false);
    }, [])

    useEffect(() => {
        if (showChecklistDropdown) {
            /*
            Api function for getting project checklist
            @param projectId
            */
            getAllProjectCheckList(projectId).then((res) => {
                let result = Object.entries(res).map(([k, v]) => (v));
                let createChecklistObj = { title: 'Create checklist', id: "1" }
                let noneListObj = { title: 'None', id: null }
                result.unshift(createChecklistObj, noneListObj)
                setAllCheckList(result)
            })
        }
    }, [showChecklistDropdown]);

    useEffect(() => {
        if (showTasklistDropDown) {
            /*
            Api function for getting tasklist which is not associted with any milestone
            @param projectId
            */
            getUnAssociatedTasklist(projectId).then((res) => {
                setAllTaskList(res)
            })
        }
    }, [showTasklistDropDown]);

    /*
    useEffect for storing values in the update milestone fileds
    */
    useEffect(() => {
        if (milestoneIdForUpdateView) {
            let dataOfMilestoneForm = {
                responsiblePerson: null,
                approver: null,
                title: null,
                description: null,
                tags: [],
                tasklist: [],
                checklist: null,
                status: null,
                dueDate: null,
                showDueDateField: false
            }
            titleHideShowHandler();
            descriptionHideShowHandler();
            dataOfMilestoneForm.title = milestoneDetails.title
            dataOfMilestoneForm.description = milestoneDetails.description
            if (milestoneDetails.tags && milestoneDetails.tags.length > 0) {
                chipHideShowHandler();
                dataOfMilestoneForm.tags = milestoneDetails.tags
            } else {
            }
            if (milestoneDetails.assignee) {
                setShowResponsiblePersonDropdown(true);
                dataOfMilestoneForm.responsiblePerson = milestoneDetails.assignee
            } else {
            }
            if (milestoneDetails.dueDate) {
                dataOfMilestoneForm.dueDate = milestoneDetails.dueDate
            } else {
            }
            if (milestoneDetails.status) {
                let arr = status.filter((status) => {
                    return (
                        status.depend.find((title) => title === milestoneDetails.status)
                    )
                })
                let arrObj = status.find((data) => data.value === milestoneDetails.status)
                dataOfMilestoneForm.status = arrObj
                setStatus(arr)
                setIsStatusShow(true)
            } else {
            }
            if (milestoneDetails.approver) {
                setShowApprovalDropdown(true);
                dataOfMilestoneForm.approver = milestoneDetails.approver
            } else {
            }
            if (milestoneDetails.checklist) {
                setShowChecklistDropdown(true);
                getAllProjectCheckList(projectId).then((res) => {
                    res.filter((checklist) => {
                        if (checklist.id === milestoneDetails.checklist.id) {
                            if (checklist.checklist === null) {
                                dataOfMilestoneForm.checklist = null
                            } else if (checklist) {
                                dataOfMilestoneForm.checklist = checklist
                            }
                        }
                        return checklist
                    })
                })

            } else {
            }
            if (milestoneDetails && milestoneDetails.tasklists && milestoneDetails.tasklists.length > 0) {
                setShowTasklistDropDown(true);
                getAllOnlyTaskLists(projectId, false).then((res) => {
                    // let tasklistArray = res&&res.tasks.filter((tasklist)=>tasklist.isTasklist===true)
                    let array = res.filter((tasklist) => {
                        return (milestoneDetails.tasklists.find((id) => {
                            return id === tasklist.id
                        })
                        )
                    })
                    dataOfMilestoneForm.tasklist = array
                })
            } else {
            }
            setMilestoneForm(dataOfMilestoneForm)
        } else {
            setMilestoneForm({
                responsiblePerson: null,
                approver: null,
                title: null,
                description: null,
                tags: [],
                tasklist: [],
                checklist: null,
                status: null,
                dueDate: null,
                showDueDateField: false
            })
            if (isTitleEditable) {
                titleHideShowHandler();
            } else {
            }
            if (isDescriptionEditable) {
                descriptionHideShowHandler();
            } else {
            }
            if (isChipEditable) {
                chipHideShowHandler();
            } else {
            }
            if (showResponsiblePersonDropdown) {
                setShowResponsiblePersonDropdown(false);
            } else {
            }
            if (showApprovalDropdown) {
                setShowApprovalDropdown(false);
            } else {
            }
            if (showChecklistDropdown) {
                setShowChecklistDropdown(false);
            } else {
            }
            if (showTasklistDropDown) {
                setShowTasklistDropDown(false);
            } else {
            }
        }
    }, [milestoneIdForUpdateView])

    /*
    useEffect for getting tag name or suggesting whenever user type tag name length >= 3 and length < 4
    */
    useEffect(() => {
        if (tagName && tagName.length >= 3 && tagName.length < 4) {
            getAllTags(tagName, true).then((res) => {
                setAllTags(res)
            }).catch((err) => {
            })
        }
    }, [tagName])

    /*
    function for storing values in the milestoneForm state - normal fileds
    */
    const handleChangeInput = (event, value) => {
        const data = Object.assign({}, milestoneForm);
        data[event.target.name] = event.target.value;
        if (!isChangeInUpdate) {
            setIsChangeInUpdate(true)
        } else {
        }
        setMilestoneForm(data);
    };

    /**
     * Method executes on onChange action of autocomplete form element
     */
    const handleChangeAutocomplete = (elementName, selectedvalue) => {
        let data = Object.assign({}, milestoneForm);
        if (elementName === "responsiblePerson") {
            data.responsiblePerson = selectedvalue
            if (!isChangeInUpdate) {
                setIsChangeInUpdate(true)
            } else {
            }
        } else {
        }
        if (elementName === "approver") {
            data.approver = selectedvalue
            if (!isChangeInUpdate) {
                setIsChangeInUpdate(true)
            } else {
            }
        } else {
        }
        if (elementName === "tasklist") {
            let tasklistArr = [];
            selectedvalue.forEach((tasklist) => {
                tasklistArr.push(tasklist);
            })
            data.tasklist = tasklistArr
            if (!isChangeInUpdate) {
                setIsChangeInUpdate(true)
            } else {
            }
        } else {
        }
        if (elementName === "checklist") {
            data.checklist = selectedvalue
            if (!isChangeInUpdate) {
                setIsChangeInUpdate(true)
            } else {
            }
        } else {
        }
        if (elementName === "status") {
            data.status = selectedvalue
            if (!isChangeInUpdate) {
                setIsChangeInUpdate(true)
            } else {
            }
        } else {
        }
        if (elementName === "tags") {
            let chips = [];
            selectedvalue.forEach((tag) => {
                chips.push(tag);
            })
            data.tags = chips
            if (!isChangeInUpdate) {
                setIsChangeInUpdate(true)
            } else {
            }
        } else {
        }
        if (elementName === "dueDate") {
            data.dueDate = moment(selectedvalue).format('YYYY-MM-DD')
            data.showDueDateField = false
            if (!isChangeInUpdate) {
                setIsChangeInUpdate(true)
            } else {
            }
        } else {
        }
        setMilestoneForm(data);
    }
    /*
        function for show and hide tile field
    */
    const titleHideShowHandler = () => {
        setIsTitleEditable(!isTitleEditable)
    };

    /*
        function for show and hide description field
    */
    const descriptionHideShowHandler = () => {
        setIsDescriptionEditable(!isDescriptionEditable)
    };
    /*
        function for show and hide chip/tag field
    */
    const chipHideShowHandler = () => {
        setIsChipEditable(!isChipEditable);
    };

    /*
        function for add tags
        @param tags
    */
    const addChips = (tags) => {
        tags.map((singleTagName) => {
            let data = {
                name: singleTagName,
                color: singleTagName
            }
            addTags(data).then((res) => {
            })
            return singleTagName
        })

    }
    /*
        function for submatting add/edit form of milestone
    */
    const clickHandler = () => {
        // addChips(tags)
        let filterArr = []
        getAllTags().then((res) => {
            let arr = milestoneForm && milestoneForm.tags.filter((tag) => {
                return !(res.some((resTag) => resTag.name === tag))
            })
            filterArr.push(arr)
            addChips(filterArr[0])
        })
        let formData = {
            title: milestoneForm.title,
            tags: milestoneForm.tags
        }
        if (milestoneForm.description) {
            formData.description = milestoneForm.description;
        } else {
        }
        if (milestoneIdForUpdateView) {
            if (milestoneForm.checklist) {
                if (milestoneForm.checklist === "None") {
                    formData.checklistId = null
                } else {
                    formData.checklistId = milestoneForm.checklist.id
                }
            } else {
                formData.checklistId = null
            }
            if (milestoneForm.status) {
                formData.status = milestoneForm.status.value
            } else {
            }
        } else {
            if (milestoneForm.checklist) {
                formData.checklistId = milestoneForm.checklist.id;
            } else {
            }
        }

        if (milestoneForm.approver) {
            formData.isApprovalNeeded = true;
            formData.approver = milestoneForm.approver.id;
        } else {
            formData.isApprovalNeeded = false;
        }
        if (milestoneForm.dueDate) {
            formData.dueDate = milestoneForm.dueDate;
        } else {
        }
        if (milestoneForm.responsiblePerson) {
            formData.assignee = milestoneForm.responsiblePerson.id;
        } else {
        }
        if (milestoneForm.tasklist) {
            if (milestoneForm.tasklist.length > 0) {
                let arr = []
                milestoneForm.tasklist.map((tasklist) => arr.push(tasklist.id))
                formData.tasklists = arr;
            } else {
            }
        } else {
        }
        if (milestoneIdForUpdateView) {
            const milestoneId = milestoneDetails.id;
            updateMilestone(milestoneId, formData).then((res) => {
                setAddUpdateRes({
                    res: res.id,
                    formName: 'milestoneAdd'
                });
                closeAddUpdateFrom();
                showTaskDetailsPane(milestoneId, "milestone");
            });
        } else {
            formData.projectId = projectId
            addMilestone(projectId, formData).then((res) => {
                setAddUpdateRes({
                    res: res.id,
                    formName: 'milestoneEdit'
                });
                closeAddUpdateFrom();
                showTaskDetailsPane(res.id, "milestone");
            });
        }
    };
    /*
        function for esc key functionality
        1. user in add Milestone form if no filds filled and typed esc then close form and if any input filled then gives pop up msg
        2. user in edit Milestone form if no filds filled and typed esc then close form and if any input changes  then gives pop up msg
        @param e
    */
    const handleEsc = (e) => {
        if (e.keyCode === 27) {
            // milestone
            if (milestoneIdForUpdateView) {
                if (!isChangeInUpdate) {
                    setIsDisplayForm(false)
                } else {

                    const title = `Close Without Saving?`
                    const message = `Are you sure you want to close this edit milestone without saving?`
                    modalRef.current.setState({
                        showModal: true,
                        message: message,
                        title: title,
                        showOkButton: true,
                        showCancelButton: true,
                        showNote: false,
                        note: '',
                        handleOk: (note) => {
                            setIsDisplayForm(false)
                        },
                        okText: "Yes",
                        cancelText: 'No'
                    });

                }
            } else {
                if (isChangeInUpdate) {

                    const title = `Close Without Saving?`
                    const message = `Are you sure you want to close this milestone without saving?`
                    modalRef.current.setState({
                        showModal: true,
                        message: message,
                        title: title,
                        showOkButton: true,
                        showCancelButton: true,
                        showNote: false,
                        note: '',
                        handleOk: (note) => {
                            setIsDisplayForm(false)
                        },
                        okText: "Yes",
                        cancelText: 'No'
                    });

                } else {
                    setIsDisplayForm(false)
                }
            }
        }
    }
    return (
        <>
            <ValidatorForm
                onSubmit={isChecklistNewCreating ? console.log() : clickHandler}
                noValidate
                onError={errors => console.log(errors)}
                onKeyDown={(e) => handleEsc(e)}
            >
                {
                    loggedInUser.userRoles.isTeamLead
                }
                <Grid style={{ borderBottom: `1px dashed ${CustomTheme.primaryColor}` }} item xl={12} lg={12} md={12}
                    sm={12} xs={12} className="project-save-btn-container">
                    <Grid container justify="space-between">
                        <Grid>
                        </Grid>
                        <Grid>
                            <IconButton style={{ color: CustomTheme.primaryColor }} className="form-panel-save-btn"
                                type="submit"><i className="far fa-save"></i></IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid container>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className="details-header for-padding-top-20">
                        {
                                showResponsiblePersonDropdown
                                    ? (
                                        <Box
                                        >
                                            <SearchableDropDown projectDefination={true}
                                                firstLastName={true} title={false}
                                                name={false} checklist={false}
                                                forNameAttr={"responsiblePerson"}
                                                value={milestoneForm.responsiblePerson}
                                                setValue={handleChangeAutocomplete}
                                                label="Select Responsible Person"
                                                optionsList={participents} />
                                        </Box>
                                    )
                                    : (
                                        <Box
                                            className="inline-flex"
                                            onClick={() => setShowResponsiblePersonDropdown(true)}
                                        >
                                            <Box>
                                                <img
                                                    src='https://ui-avatars.com/api/?name=A&background=random'
                                                    alt='profile'
                                                    className="task-assignee-image"
                                                />
                                            </Box>
                                            <Box>
                                                <Typography style={{ color: CustomTheme.fontColor }}
                                                    variant='body2'>Responsible
                                                    person</Typography>
                                                <Typography style={{ color: CustomTheme.primaryColor }}
                                                    variant='body2' className='user-name'><i
                                                        className="fas fa-plus-circle"></i> Assign</Typography>
                                            </Box>
                                        </Box>
                                    )
                            }
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2} ></Grid>
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className="details-header for-padding-top-20">
                        {
                                showApprovalDropdown
                                    ?
                                    <Box
                                    >
                                        <SearchableDropDown projectDefination={true} firstLastName={true}
                                            title={false} name={false} checklist={false}
                                            forNameAttr={"approver"}
                                            value={milestoneForm.approver}
                                            setValue={handleChangeAutocomplete}
                                            label="Select Approver" optionsList={participents} />
                                    </Box>
                                    : (
                                        <Box
                                            className="inline-flex"
                                            onClick={() => setShowApprovalDropdown(true)}
                                        >
                                            <Box>
                                                <img
                                                    src='https://ui-avatars.com/api/?name=A&background=random'
                                                    alt='profile'
                                                    className="task-assignee-image"
                                                />
                                            </Box>
                                            <Box>
                                                <Typography style={{ color: CustomTheme.fontColor }}
                                                    variant='body2'>Approver</Typography>
                                                <Typography style={{ color: CustomTheme.primaryColor }}
                                                    variant='body2' className='user-name'><i
                                                        className="fas fa-plus-circle"></i> Add</Typography>
                                            </Box>
                                        </Box>
                                    )
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid style={{ backgroundColor: CustomTheme.bodyBackground }} item xl={12} lg={12} md={12} sm={12} xs={12}
                    className="form-panel-assign-name-container for-background-color for-height ">
                    <Grid container>
                        {
                            isTitleEditable
                                ?
                                <>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <TextValidator
                                            fullWidth
                                            // required
                                            // variant="outlined"
                                            autoFocus={true}
                                            size="small"
                                            label="Add milestone title"
                                            onChange={handleChangeInput}
                                            name="title" value={milestoneForm.title || ''}
                                            validators={['required', 'trim', 'maxStringLength:50']}
                                            errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                        />
                                    </Grid>
                                </>
                                :
                                <Grid onClick={titleHideShowHandler}>
                                    <Typography variant="h2" style={{ color: CustomTheme.primaryColor }}
                                        className="add-title-text">
                                        <span><i className="far fa-edit"></i> Click to add  milestone title</span>
                                    </Typography>
                                </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid style={{ backgroundColor: CustomTheme.bodyBackground }} item xl={12} lg={12} md={12} sm={12} xs={12}
                    className="padding-for-description-container for-background-color">
                    <Grid container>
                        {
                            isDescriptionEditable
                                ?
                                <>
                                    <Grid className="description-input-container" item xl={12} lg={12} md={12} sm={12}
                                        xs={12}>
                                        <TextValidator
                                            fullWidth
                                            // required
                                            // variant="outlined"
                                            autoFocus={true}
                                            size="small"
                                            label="Add milestone description"
                                            onChange={handleChangeInput}
                                            name="description" value={milestoneForm.description || ''}
                                            validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:250']}
                                            errorMessages={['At least one alphanumeric characters', 'The max length of 250 charachers reached']}
                                        />
                                    </Grid>
                                </>
                                :
                                <Grid onClick={descriptionHideShowHandler}>
                                    <Typography variant="h3" style={{ color: CustomTheme.primaryColor }}
                                        className="add-description-text">
                                        <span><i className="far fa-edit"></i> Click to add a description for this milestone</span>
                                    </Typography>
                                </Grid>
                        }
                    </Grid>
                </Grid>
                {
                    isChipEditable
                        ?
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <span className="tag-container-icon">
                                <i className='fas fa-tags'></i>
                            </span>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                value={milestoneForm.tags}
                                name="tags"
                                className="tags-contianer"
                                onChange={(event, newValue) => {
                                    // setTags(newValue);
                                    handleChangeAutocomplete("tags", newValue)
                                }}
                                onInputChange={(event, newValue) => {
                                    setTagName(newValue);
                                }}
                                clearOnEscape={true}
                                options={allTags ? Object.keys(allTags).map((option) => allTags[option].name) : []}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="filled" placeholder="Type tag and hit enter" />
                                )}
                            />
                        </Grid>
                        :
                        <Grid onClick={chipHideShowHandler} className="add-tag-text" item xl={12} lg={12} md={12}
                            sm={12} xs={12}>
                            <Grid container>
                                <Grid style={{ color: CustomTheme.fontColor }} className="tag-icon-container">
                                    <i className='fas fa-tags'></i>
                                </Grid>
                                <Grid style={{ color: CustomTheme.primaryColor }} className="add-tag-container-projects">
                                    <i className='fas fa-plus-circle'></i> Add a tag
                                </Grid>
                            </Grid>
                        </Grid>
                }

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                    className="form-panel-assign-name-container dropdowns-container-margin-top-10">
                    <Box className="task-items-list ">
                        {
                            showTasklistDropDown
                                ?
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    name="tasklist"
                                    className="dropdown-margins"
                                    options={allTaskList || []}
                                    getOptionLabel={(option) => option ? option.title : ''}
                                    value={milestoneForm.tasklist ? milestoneForm.tasklist : {}}
                                    onChange={(event, newRole) => {
                                        handleChangeAutocomplete("tasklist", newRole)
                                    }}
                                    getOptionSelected={(option, value) =>
                                        option.id === value.id
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Select tasklist"
                                        />
                                    )}
                                />
                                :
                                <Box
                                    onClick={() => {
                                        setShowTasklistDropDown(true);
                                    }}
                                >
                                    <Typography variant="body2" style={{ color: CustomTheme.fontColor }}
                                        className="form-panel-followers-text"><i
                                            className="far fa-list-alt"></i> Tasklist</Typography>
                                    <Typography variant="body2" style={{ color: CustomTheme.primaryColor }}
                                        className="form-panel-bold-text"><i
                                            className="fas fa-link"></i> Link tasklist</Typography>
                                </Box>
                        }

                        {
                            showChecklistDropdown
                                ?
                                <Box className="dropdown-margins">
                                    <ChecklistDropdownSearchable project={true}
                                        setIsChecklistNewCreating={setIsChecklistNewCreating}
                                        setValue={handleChangeAutocomplete}
                                        forNameAttr={"checklist"}
                                        selectedValue={milestoneForm.checklist}
                                        setOpenAddCheckList={setOpenAddCheckList}
                                        setAllCheckList={setAllCheckList}
                                        optionsList={allCheckList} />
                                </Box>
                                :
                                <Box onClick={() => setShowChecklistDropdown(true)}>
                                    <Typography variant="body2" style={{ color: CustomTheme.fontColor }}
                                        className="form-panel-followers-text"><i
                                            className="far fa-list-alt"></i> Checklist</Typography>
                                    <Typography variant="body2" style={{ color: CustomTheme.primaryColor }}
                                        className="form-panel-bold-text"><i
                                            className="fas fa-link"></i> Add Checklist</Typography>
                                </Box>
                        }

                        {
                            milestoneForm.showDueDateField
                                ?
                                <FormControl className="due-date-margin">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            fullWidth
                                            label='Select Due Date'
                                            format='dd/MM/yyyy'
                                            name="dueDate"
                                            value={milestoneForm.dueDate}
                                            onChange={date => {
                                                handleChangeAutocomplete("dueDate", date)
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                                :
                                <Box onClick={() => setMilestoneForm({
                                    ...milestoneForm,
                                    showDueDateField: true
                                })}>
                                    <Typography variant="body2" style={{ color: CustomTheme.fontColor }}
                                        className="form-panel-followers-text">
                                        <i className="far fa-calendar-alt"
                                            style={{ paddingRight: '3px' }}></i>
                                        Due Date
                                    </Typography>
                                    <Typography variant="body2" style={{ color: CustomTheme.primaryColor }}
                                        className="form-panel-bold-text">
                                        {
                                            milestoneForm.dueDate
                                                ? moment(milestoneForm.dueDate).format('DD/MM/YYYY')
                                                : (
                                                    <>
                                                        <i className="fas fa-plus-circle"></i> Set Date
                                                    </>
                                                )
                                        }
                                    </Typography>
                                </Box>
                        }

                        {
                            milestoneIdForUpdateView && isStatusShow
                                ?
                                <Box className="dropdown-margins">
                                    <SearchableDropDown title={true} name={false} checklist={false}
                                        forNameAttr={"status"} value={milestoneForm.status}
                                        setValue={handleChangeAutocomplete}
                                        label="Select status" optionsList={status} />
                                </Box>
                                :
                                <>
                                    {
                                        milestoneIdForUpdateView
                                            ?
                                            <Box onClick={() => setIsStatusShow(true)}>
                                                <Typography variant="body2"
                                                    style={{ color: CustomTheme.fontColor }}
                                                    className="form-panel-followers-text"><i
                                                        className="far fa-list-alt"></i> Status</Typography>
                                                <Typography variant="body2"
                                                    style={{ color: CustomTheme.primaryColor }}
                                                    className="form-panel-bold-text"><i
                                                        className="fas fa-link"></i> Status</Typography>
                                            </Box>
                                            :
                                            <></>
                                    }
                                </>
                        }

                    </Box>
                </Grid>
                {
                    openAddCheckList
                        ?
                        <ChecklistModal nameAtt={"checklist"} setIsChecklistNewCreating={setIsChecklistNewCreating}
                            projectId={projectId} setAllCheckList={setAllCheckList}
                            setSelectedChecklist={handleChangeAutocomplete}
                            setOpenAddCheckList={setOpenAddCheckList} openAddCheckList={openAddCheckList} />
                        :
                        <></>
                }
            </ValidatorForm>
            <MessageModal
                ref={modalRef} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
        milestoneDetails: state.milestones.singleMilestoneDetails
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllParticipants: (projectId) => {
            return dispatch(getAllParticipantsRequest(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllProjectCheckList: (projectId) => {
            return dispatch(getAllProjectCheckListRequest(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllOnlyTaskLists: (projectId, isResStoringInRedux) => {
            return dispatch(getAllTaskListsRequest(projectId, isResStoringInRedux)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getUnAssociatedTasklist: (projectId) => {
            return dispatch(getAllUnAssociatedTasklistOfProject(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllTags: (name, searchTag) => {
            return dispatch(getAllTagsRequset(name, searchTag)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addTags: (data) => {
            return dispatch(addTagRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        updateMilestone: (milestoneId, data) => {
            return dispatch(updateMilestoneRequest(milestoneId, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addMilestone: (projectId, data) => {
            return dispatch(addMilestoneRequest(projectId, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(MilestoneForm);
