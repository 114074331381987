import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        wizardContainer: {
            width: '70%',
            margin: 'auto',
            '& .card-header': {
                padding: '1rem 0rem !important',
                borderBottom: 'none !important',
            },
            '& .card-title': {
                fontSize: '1.8em !important',
                lineHeight: '1.4em !important',
                fontWeight: '700 !important',
                textTransform: 'none',
                color: '#3c4858 !important',
                marginTop: '1rem !important',
                marginBottom: '0.5rem !important',
            },
            "& [data-background-color='primary']": {
                backgroundColor: 'white !important',
            },
            '& .description': {
                color: 'black !important',
                fontSize: '1.1rem !important',
                marginBottom: '2rem !important',
                fontWeight: '300 !important',
                opacity: '0.6 !important',
            },
            '& .nav-pills': {
                backgroundColor: 'rgba(200, 200, 200, 0.2) !important',
            },
            '& .nav-item': {
                '&:hover': {
                    cursor: 'pointer',
                },
            },
            '& .moving-tab': {
                color: 'white !important',
                backgroundColor: '#02ABEE !important',
                borderRadius: '5px !important',
                boxShadow:
                    '0 16px 26px -10px #02ABEE,0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px #02ABEE !important',
            },
            '& .tab-content': {
                minHeight: '355px',
                padding: '0px 0 !important',
            },
            '& .card-footer': {
                backgroundColor: 'transparent !important',
                borderTop: 'none !important',
            },
        },
        nextButton: {
            backgroundColor: '#02ABEE !important',
        },
        previousButton: {
            backgroundColor: '##979797 !important',
        },
        finishButton: {
            backgroundColor: '#02ABEE !important',
        },
    };
});
