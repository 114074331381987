import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {useStyles} from './styles';
import {connect} from 'react-redux';

import rocketProjectIcon from '../../../../../../assets/rocketProjectIcon.png';
import {Box, Grid, Typography} from '@material-ui/core';

const ProjectItem = ({history, projectDetails, loggedInUser, deleteProjectHandler}) => {
    const classes = useStyles();
    const [active, handleActive] = useState(false);
    return (
        <div
            className={clsx(
                classes.cardContainer,
                active && classes.cardContainerClicked
            )}
            onClick={() => {
                // handleActive(!active);
                const url = `/project/${projectDetails.id}/tasks`;
                localStorage.setItem('projectName', projectDetails.name);
                history.push(url);
            }}
        >
            <div className={classes.firstDisplay}>
                <p>First Display</p>
            </div>

            <div className={clsx(classes.first, active && classes.firstClicked)}>
                <div className={classes.firstTop}>
                    <Box className={classes.cardFaceContainer}>
                        <Box className={classes.projectHeadingContainer}>
                            <Box className={classes.projectImageContainer}>
                                <img
                                    src={rocketProjectIcon}
                                    alt='project'
                                    className={classes.projectImage}
                                />
                            </Box>
                            <Box className={classes.cardProjectTitleAndClientNameContainer}>
                                <Typography
                                    variant="h4"
                                    className={classes.cardProjectTitle}
                                    component={Link}
                                    to={`/project/${projectDetails.id}/tasks`}
                                >
                                    {projectDetails.name}
                                </Typography>
                                <Typography variant="h4" className={classes.cardProjectDeveloper}>
                                    {projectDetails.client}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.projectDetailsContainer}>
                            <Grid container>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Typography variant="body1" className={classes.detailHeading}>
                                        Lead:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Typography variant="body1" className={classes.detailText}>
                                        {projectDetails.head ? projectDetails.head.firstName + " " + projectDetails.head.lastName : ""}
                                        {/* //DETAILS NOT FOUND */}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Typography variant="body1" className={classes.detailHeading}>
                                        Area in sqft:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Typography variant="body1"
                                                className={classes.detailText}>{projectDetails.metaInfo}</Typography>
                                </Grid>
                                {/* <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography className={classes.detailHeading}>
                    Current Stage:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography variant="body2" className={classes.detailText}>WD-25</Typography>
                </Grid> */}
                                {/* <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography className={classes.detailHeading}>
                    Upcoming Milestone:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography variant="body2" className={classes.detailText}>
                    31 March, 2021
                  </Typography>
                </Grid> */}
                            </Grid>
                        </Box>
                        {
                            loggedInUser.userRoles.isSystemSuperAdmin || loggedInUser.userRoles.isTeamLead
                                ?
                                <>
                                <Box onClick={(e) => {
                                    e.stopPropagation();
                                    history.push(`/projects/editProject/${projectDetails.id}`);
                                }}
                                     className={classes.editContainer}
                                >
                                    <span className={classes.statusBlue}>
                                        <i className='far fa-edit'></i>
                                    </span>
                                </Box>
                                <Box onClick={(e) => {
                                    e.stopPropagation();
                                    deleteProjectHandler(projectDetails.id, e)
                                }}
                                     className={classes.deleteContainer}
                                >
                                    <span className={classes.statusRed}>
                                        <i className='far fa-trash-alt'></i>
                                    </span>
                                </Box>
                                </>
                                :
                                <></>
                        }
                        <Box className={classes.statusContainer}>
              <span className={classes.statusGreen}>
                <i className='fas fa-circle'></i>
              </span>
                        </Box>
                    </Box>
                </div>

                <div className={classes.firstBehind}>
                    <div className={classes.firstBehindDisplay}>
                        <p>Second Display</p>
                    </div>

                    <div
                        className={clsx(classes.second, active && classes.secondClicked)}
                    >
                        <div className={classes.secondTop}/>
                        <div className={classes.secondBehind}>
                            <div className={classes.secondBehindDisplay}>
                                <p>Third Display</p>
                            </div>
                            <div
                                className={clsx(classes.third, active && classes.thirdClicked)}
                            >
                                <div className={classes.thirdTop}/>
                                <div className={classes.secondBehindBottom}>
                                    <p>Fourth Display</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectItem);
