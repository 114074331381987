import React from 'react';
import {connect} from 'react-redux';

import {Box} from '@material-ui/core';
import ReactWizard from 'react-bootstrap-wizard';

import BasicsForm from '../forms/BasicForm/BasicsForm';
import TemplateForm from '../forms/TemplateForm/TemplateForm';
import OptionsForm from '../forms/OptionsForm/OptionsForm';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-wizard/dist/react-wizard.css';

import {addProjectRequest} from '../../../../ProjectsApiActions';

import {useStyles} from './styles';

var steps = [
    {stepName: 'Basics', component: BasicsForm},
    {stepName: 'Template', component: TemplateForm},
    {stepName: 'Options', component: OptionsForm},
];

const AddProjectStepper = ({history, addProject}) => {
    const classes = useStyles();

    const finishButtonClick = allStates => {
        let formData = {
            name: allStates.Basics.name,
            type: allStates.Basics.projectType.id,
            teamId: allStates.Basics.team.id,
            description: allStates.Basics.description,

            isBillable: allStates.Options.billable.title === "Yes",
            enableEstimates: allStates.Options.enableEstimates.title === "Yes",
            startDate: allStates.Options.startDate,
            endDate: allStates.Options.endDate,
        };
        if (allStates.Basics.projectObjective) {
            formData.objective = allStates.Basics.projectObjective;
        } else {
        }
        if (allStates.Basics.ais) {
            formData.metaInfo = allStates.Basics.ais;
        } else {
        }

        if (allStates && allStates.Options && allStates.Options.forClient && allStates.Options.forClient.title === "Yes") {
            formData.client = allStates.Options.client.name;
        } else {
        }
        if (allStates && allStates.Template && allStates.Template.selectedTemplate) {
            formData.projectTemplateId = allStates.Template.selectedTemplate
        } else {
        }
        addProject(formData).then((res) => {
            localStorage.setItem('projectName', res.name);
            history.push(`/project/${res.id}/tasks`);
        });
    };

    return (
        <Box className={classes.wizardContainer}>
            <ReactWizard
                steps={steps}
                navSteps
                title='Add Project'
                description="Let's create a new project"
                headerTextCenter
                validate
                color='primary'
                finishButtonClick={finishButtonClick}
                nextButtonClasses={classes.nextButton}
                previousButtonClasses={classes.previousButton}
                finishButtonClasses={classes.finishButton}
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        addProject: (formData) => {
            return dispatch(addProjectRequest(formData)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddProjectStepper);
