import {SYSTEM_ROLES} from './Constants';
import {Tooltip, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

import _clone from 'lodash/clone';
import _escapeRegExp from 'lodash/escapeRegExp';
import _uniqBy from 'lodash/uniqBy';
import moment from 'moment';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 500,
        width: 500,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
}))(Tooltip);

class UtilHelper {

    static numberToCurrency = (number) => {
        return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    };

    static validateMobileNumber = (rule, value, callback) => {
        const regex = /\d+/g;

        if (value && value.length > 0 && (!regex.test(value) || value.length !== 10)) {
            callback("Please enter valid mobile number");
        }
        callback();
    };

    static validatePassword = (value) => {
        const pattern = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[A-Za-z0-9#?!@$%^&*-]{8,}$");
        return pattern.test(value);
    };

    static validateUserName = (rule, value, callback) => {
        const regex = /^[a-zA-Z '.-]*$/;

        if (value && !regex.test(value)) {
            callback("Please enter valid name");
        } else if (value && value.trim().length === 0) {
            callback("Only spaces are not allowed");
        } else {
            callback();
        }
    };

    static validateOnlyNumber = (rule, value, callback) => {
        const regex = /^[0-9]*$/;

        if (value && !regex.test(value)) {
            callback("Please enter only number");
        } else if (value && value.trim().length === 0) {
            callback("Only spaces are not allowed");
        } else {
            callback();
        }
    };

    static validateString = (value) => {
        const regex = /^[a-zA-Z ]+(([',. -][a-zA-Z ])?[a-zA-Z ]*)*$/;

        if (value && !regex.test(value)) {
            return false;
        } else {

            return true
        }
    };

    static validateBlankSpaces = (value) => {
        if (!Array.isArray(value)) {
            return !(value && value.trim().length === 0);
        } else {
            return true;
        }
    };

    static validateNegativeValue = (rule, value, callback) => {

        if (value && value < 0) {
            callback("Negative number not allowed");
        } else {
            callback();
        }
    };

    static blockSpecialCharacter = (value) => {
        const regex = /^[0-9a-zA-Z '.-]+$/;
        if (value && !regex.test(value)) {
            return false;
        } else {
            return true
        }
    };

    static validAgeInput = (value) => {
        const regex = /^\d+(\.\d)?\d*$/;
        if (value && !regex.test(value)) {
            return false;
        } else {
            return true
        }
    };

    static validateFloatValue = (rule, value, callback) => {

        if (value && value > 0) {
            value.indexOf('.') >= 0 ? callback("Decimal number not allowed") : callback();
        } else {
            callback();
        }
    };

    static isFloat = (n) => {
        return n === +n && n !== (n | 0);
    };

    static stringBeforeDecimal = (n) => {
        return n.split('.')[0];
    };

    static asyncForEach = async (array, callback) => {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    };

    static filterColumnReactTable = (filter, row) => {
        if (!row[filter.id])
            return false;
        return String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
    };

    static getCurrentRole = (user) => {
        let currentRole = {
            isSuperAdmin: false,
            isSurgeon: false
        };

        if (user && user.Roles) {
            user.Roles.forEach((role) => {
                if (role.name === SYSTEM_ROLES.ROLE_SUPER_ADMIN) {
                    currentRole.isSuperAdmin = true;
                } else if (role.name === SYSTEM_ROLES.ROLE_SURGEON) {
                    currentRole.isSurgeon = true;
                }
            })
        }

        return currentRole;
    };

    static sortJsonArray = (array, key) => {
        return array.sort(function (a, b) {
            if (a[key] < b[key]) {
                return -1;
            }
            if (a[key] > b[key]) {
                return 1;
            }
            return 0;
        });
    };

    static validateObject(obj) {
        for (var o in obj)
            if (!obj[o]) return false;

        return true;
    }

    static getBase64(img, callback) {
        var b64Response = btoa(unescape(encodeURIComponent(img)));
        callback('data:image/jpeg;base64,' + b64Response);
    }

    static trimObject = (myObject) => {

        for (var key in myObject) {
            if (typeof myObject[key] == 'string' && myObject[key] !== "") {
                myObject[key] = myObject[key].trim();
            }
        }

        return myObject;
    };

    static isPasswordMatch = (newPassword, confirmPassword) => {
        return newPassword === confirmPassword;
    }

    static ellipsisRender = (string, showCharCount) => {
        let showChar = showCharCount;
        let moretext = "...";
        let returnString = '';
        if (string && string.length > showChar) {

            var c = string.substr(0, showChar);

            returnString = (
                <>
          <span>
            <HtmlTooltip
                interactive
                title={
                    <>
                        <Typography color="inherit">{string}</Typography>
                    </>
                }
            >
              <span className="morelink">{c + moretext}</span>
            </HtmlTooltip>
          </span>
                </>
            )

        } else {
            returnString = string;
        }

        return returnString;
    };

    static checkRoleAvalibility = (roles, roleName) => {
        const isAvailable = roles.find((role) => role === roleName);

        return isAvailable ? true : false;
    };

    static checkAllowedExtension = (fileName, arrayExtensions) => {
        var ext = fileName.split(".");
        ext = `.${ext[ext.length - 1].toLowerCase()}`;
        if (arrayExtensions.lastIndexOf(ext) === -1) {
            return false;
        } else {
            return true;
        }
    }

    static loggedInUserPermissions = (userRoles, resources, rolesWithPermissions) => {
        let permissions = [];
        const roleName = userRoles.isSystemSuperAdmin ? "System Super Admin" : userRoles.isVendorAdmin ? "Vendor Admin" : "Industry Representative";
        const role = rolesWithPermissions.find((role) => role.name === roleName);
        resources.forEach((resource) => {
            let resAction = {
                id: resource.id,
                name: resource.name,
                title: resource.title,
                actions: []
            }

            resource.actions.forEach((resourceAction) => {
                const isFind = role.permission.find((permission) => permission === resourceAction.action);
                if (isFind) {
                    resAction.actions.push(resourceAction.action)
                } else {
                }
            })

            if (resAction.actions.length > 0) {
                permissions.push(resAction);
            }
        })
        return permissions;
    };

    static swapTags = (text) => {
        let displayText = _clone(text)
        const tags = text.match(/@\{\{[^\}]+\}\}/gi) || []
        tags.map(myTag => {
            const tagData = myTag.slice(3, -2)
            const tagDataArray = tagData.split('||')
            const tagDisplayValue = tagDataArray[2]
            displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue)
        })
        return displayText
    }

    static getUsersFromTags = (text) => {
        // let displayText = _clone(text)
        const tags = text.match(/@\{\{[^\}]+\}\}/gi) || []
        const allUserIds = tags.map(myTag => {
            const tagData = myTag.slice(3, -2)
            const tagDataArray = tagData.split('||')
            return {_id: tagDataArray[1], name: tagDataArray[2]}
        })
        return _uniqBy(allUserIds, myUser => myUser._id)
    }

    static getTimeDifference = (date) => {
        const startTime = moment(date);
        const endTime = moment();
        const duration = moment.duration(endTime.diff(startTime));
        const hours = parseInt(duration.asHours());
        let message = '';

        if (hours > 24) {
            const days = parseInt(duration.asDays());
            if (days > 1) {
                message = days + " days";
            } else {
                message = "1 day";
            }
        } else if (hours === 0) {
            const minutes = parseInt(duration.asMinutes());
            if (minutes > 0) {
                message = minutes + " minutes";
            } else {
                message = "Just now";
            }
        } else {
            message = hours + " hours";
        }

        return message;
    }
}

export default UtilHelper;
