import React, {useEffect, useState} from 'react';
// import './CheckListModal.css';
import {Dialog, DialogActions, DialogContent, Grid, Typography} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import {resetPasswordRequest} from '../../modules/authentication/AuthenticationApiActions'

import {IS_PASSWORD_MATCH, ONLY_BLANK_SPACES, VALIDATE_PASSWORD} from '../Constants'
import UtilHelper from '../UtilHelper';
import CustomTheme from '../../utilities/theme/theme.json'

const SetPasswordModal = ({resetPassword, openSetPasswordDialog, closeSetPasswordDialog}) => {

    const [user, setUser] = useState({password: '', confirmPassword: ''});
    // const modalRef = useRef();

    useEffect(() => {
        ValidatorForm.addValidationRule(ONLY_BLANK_SPACES, UtilHelper.validateBlankSpaces);
        ValidatorForm.addValidationRule(VALIDATE_PASSWORD, UtilHelper.validatePassword);
        ValidatorForm.addValidationRule(IS_PASSWORD_MATCH, (value) => {
            return UtilHelper.isPasswordMatch(user.password, user.confirmPassword);
        });

        return () => {
            ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
            ValidatorForm.removeValidationRule(VALIDATE_PASSWORD);
            ValidatorForm.removeValidationRule(IS_PASSWORD_MATCH);
        }
    }, [user]);

    const handleChange = (event) => {
        const data = Object.assign({}, user);
        data[event.target.name] = event.target.value;
        setUser(data);
    };

    const handleSubmit = () => {
        let data = {
            newPIN: user.password
        }
        resetPassword(data).then((res) => {
            onCloseDialog()
        })
        // const {userId, token} = match.params;
        // resetPassword(user.password, userId, token).then((res) => {
        //   modalRef.current.setState({
        //     showModal: true,
        //     message: res.message + I18n.t('reset_password.redirection_to_login_page'),
        //     title: I18n.t('reset_password.reset_password_label'),
        //     showOkButton: true,
        //     handleOk: () => closeSetPasswordDialog()),
        //     okText: 'Ok'
        //   });
        // });
    };

    const onCloseDialog = () => {
        const data = Object.assign({}, user);
        data.password = '';
        data.confirmPassword = '';
        setUser(data);
        closeSetPasswordDialog();
    }
    return (
        <Dialog
            open={openSetPasswordDialog}
            fullWidth={true}
            maxWidth='xs'
            aria-labelledby="max-width-dialog-title"
        >
            <DialogContent>
                <Typography variant="h2" style={{color: CustomTheme.fontColor}} className="block-titles margin-top-20">
                    Set Password
                </Typography>
                <ValidatorForm
                    onSubmit={handleSubmit}
                >
                    <Grid container spacing="3">
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <TextValidator
                                fullWidth
                                label="Password"
                                size="small"
                                type="password"
                                onChange={handleChange}
                                name="password" value={user.password}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <TextValidator
                                fullWidth
                                label="Confirm Password"
                                size="small"
                                type="password"
                                onChange={handleChange}
                                name="confirmPassword" value={user.confirmPassword}
                                validators={[IS_PASSWORD_MATCH, 'required']}
                                errorMessages={['Password mismatch', 'This field is required']}
                            />
                        </Grid>
                    </Grid>
                    <DialogActions className="margin-top-10">
                        <Button
                            variant="outlined"
                            color="secondary"
                            className="reset-dialog-button"
                            size="small"
                            onClick={() => {
                                onCloseDialog();
                            }}
                        >
                            CANCEL
                        </Button>
                        <Button
                            type="submit"
                            className="save-dialog-button"
                            variant="outlined"
                            color="primary"
                            size="small"
                        >
                            SAVE
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword: (data) => {
            return dispatch(resetPasswordRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SetPasswordModal);
