import React, {useEffect, useState} from 'react';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Box, Button, Grid, Typography} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {DateTimePickerValidator} from '../../../../../../../../../../../../../utilities/validators';
import {validateFromAndToTime} from '../../../../../../../../../../../../../utilities/validators/validatorFunctions';
import {useStyles} from '../EffortItem/styles';
import Avatar from '@material-ui/core/Avatar';
import CustomTheme from '../../../../../../../../../../../../../utilities/theme/theme.json'
import Switch from '@material-ui/core/Switch';
import {connect} from 'react-redux';
import {addTimeLogInTask, editTimelogOfTaskRequest} from '../../../../../../TasksApiActions'
import {useParams,} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import './EffectForm.css'
import LoaderForSaveAndEdit from '../../../../../../../../../../../../../utilities/AppLoader/LoaderForSaveAndEdit'

const CustomSwitch = withStyles({
    colorSecondary: {
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "green"
        }
    },
})(Switch);

const DocumentForm = ({
                          addTimeLog,
                          editTimelog,
                          taskId,
                          isEditForm,
                          timeLogDetails,
                          doneEditTimeLog,
                          getAllTimelogList
                      }) => {
    const classes = useStyles();
    let {id} = useParams();

    const [formData, setFormData] = useState({
        fromDateTime: null, toDateTime: null, description: '', isBillable: true
    });

    const [isLoaderShowing, setIsLoaderShowing] = useState(false)

    useEffect(() => {
        ValidatorForm.addValidationRule('isValidTime', value =>
            validateFromAndToTime(formData.fromDateTime, value)
        );
        return () => {
            ValidatorForm.removeValidationRule('isValidTime');
        };
    }, [formData]);

    useEffect(() => {
        if (isEditForm) {
            const data = {
                fromDateTime: timeLogDetails.fromDateTime,
                toDateTime: timeLogDetails.toDateTime,
                description: timeLogDetails.description,
                isBillable: timeLogDetails.isBillable,
            }
            setFormData(data);
        }
    }, [isEditForm]);

    const clearForm = () => {
        const data = {
            fromDateTime: null,
            toDateTime: null,
            description: '',
            isBillable: true,
        }
        setFormData(data);
    }

    const onSubmit = () => {

        let data = Object.assign({}, formData);
        if (!isEditForm) {
            if (id) {
                data.projectId = id
            } else {
            }
            if (taskId) {
                data.taskId = taskId
            } else {
            }

        }

        if (isEditForm) {
            setIsLoaderShowing(true)
            editTimelog(data, timeLogDetails.id).then((res) => {
                clearForm();
                setIsLoaderShowing(false);

                doneEditTimeLog();
            });
        } else {
            setIsLoaderShowing(true)
            addTimeLog(data).then((res) => {
                clearForm();
                setIsLoaderShowing(false);

                getAllTimelogList();
            });
        }

    };

    const onChangeInput = (elementName, value) => {
        const data = Object.assign({}, formData);
        data[elementName] = value;
        setFormData(data);
    }

    let totalTime;
    if (formData.fromDateTime != null && formData.toDateTime != null) {
        let dateFrom = new Date(formData.fromDateTime)
        let dateTo = new Date(formData.toDateTime)
        let diff = (dateFrom.getTime() - dateTo.getTime()) / 1000;
        diff /= (60 * 60);
        let covertMinusToPlusDiff = Math.abs(diff)
        totalTime = covertMinusToPlusDiff
    } else {
    }
    return (
        <ValidatorForm onSubmit={onSubmit}>
            {validateFromAndToTime()}
            <Box className={classes.contentInnerContainerForForm}>
                <Box className={classes.headingContainer}>
                    <Box className={classes.profileContainer}>
                        <Box>
                            <Avatar className={classes.plusSignForTimelog}>+</Avatar>
                        </Box>
                        <Box>
                            <Typography variant="h1" classes={{root: classes.nameText}}>
                                {
                                    isEditForm
                                        ?
                                        <span>Edit Time Log</span>
                                        :
                                        <span>New Time Log</span>
                                }
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        {
                            isLoaderShowing
                                ?
                                <LoaderForSaveAndEdit/>
                                :
                                <Button color="primary" type="submit" className={classes.saveBtn}
                                        style={{color: CustomTheme.primaryColor, position: 'relative', left: '21px'}}>
                                    <i className="far fa-save"></i>
                                </Button>
                        }

                    </Box>
                </Box>

                <Box className={classes.taskInfoContainerForForm}>
                    <Box className={`${classes.taskIconContainer} switch-btn-container `}>
              <span
                  className={`${classes.icon}`}
              >
                <i className='fas fa-rupee-sign'></i>
              </span>
                        <span className="custome-switch">
                <CustomSwitch
                    style={
                        {
                            color: '#ffff',
                        }
                    }
                    checkedIcon={
                        <div className={classes.checkedIconDivStyle}>
                    <span>
                      <CheckRoundedIcon style={{color: 'green', fontSize: '15px', marginTop: '-18px'}}/>
                    </span>
                        </div>
                    }
                    icon={
                        <div className={classes.notCheckedIconDivStyle}>
                    <span>
                      <CloseRoundedIcon style={{color: 'red', fontSize: '15px', marginTop: '-18px'}}/>
                    </span>
                        </div>
                    }
                    checked={formData.isBillable}
                    onChange={(e) => onChangeInput('isBillable', e.target.checked)}
                    value="isBillable"
                    inputProps={{"aria-label": "secondary checkbox"}}
                />
              </span>
                    </Box>

                    <Box className={classes.hoursContainer}>
                        <Typography classes={{root: classes.hoursCountTextForForm}}>
                            {totalTime ? totalTime.toFixed(2) : '0.00'}
                        </Typography>
                        <Typography variant="subtitle2" classes={{root: classes.hoursLabelForForm}}>
                            HRS
                        </Typography>
                    </Box>

                    <Box className={classes.taskTextContainer}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{paddingRight: '5px'}}>
                                    <Grid>
                                        <Typography variant="h5" className={classes.label}>From:</Typography>
                                    </Grid>
                                    <Grid>
                                        <Box className={classes.pickerContainer}>
                        <span className={classes.formIcon}>
                          <i className='far fa-calendar-alt'></i>
                        </span>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DateTimePickerValidator
                                                    fullWidth
                                                    placeholder={moment(new Date()).format(
                                                        'DD/MM/YYYY hh:mm A'
                                                    )}
                                                    size='small'
                                                    inputVariant='outlined'
                                                    showTodayButton
                                                    format='dd/MM/yyyy hh:mm a'
                                                    InputProps={{className: classes.pickerInput}}
                                                    value={formData.fromDateTime}
                                                    onChange={date => onChangeInput("fromDateTime", date)}
                                                    validators={['required']}
                                                    errorMessages={['From is required']}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Grid>
                                        <Typography variant="h5" className={classes.label}>To:</Typography>
                                    </Grid>
                                    <Grid>
                                        <Box className={classes.pickerContainer}>
                          <span className={classes.formIcon}>
                            <i className='far fa-calendar-alt'></i>
                          </span>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DateTimePickerValidator
                                                    fullWidth
                                                    placeholder={moment(new Date()).format(
                                                        'DD/MM/YYYY hh:mm A'
                                                    )}
                                                    size='small'
                                                    inputVariant='outlined'
                                                    showTodayButton
                                                    format='dd/MM/yyyy hh:mm a'
                                                    InputProps={{className: classes.pickerInput}}
                                                    value={formData.toDateTime}
                                                    minDate={formData.fromDateTime}
                                                    onChange={date => onChangeInput("toDateTime", date)}
                                                    validators={['required', 'isValidTime']}
                                                    errorMessages={['To is required', 'To date time can not be less than from date time']}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{paddingTop: '5px'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box>
                                        <TextValidator
                                            fullWidth
                                            size='small'
                                            value={formData.description}
                                            onChange={(e) => onChangeInput("description", e.target.value)}
                                            variant='outlined'
                                            placeholder='Add a description'
                                            validators={['required']}
                                            errorMessages={['Please enter a description']}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Typography variant="body1" classes={{ root: classes.taskStatusText }}>
                {logDate} » {logStart} - {logEnd}
              </Typography>

              <Typography variant="body2" classes={{ root: classes.taskStatusDescription }}>
                {logDesc}
              </Typography> */}
                    </Box>
                </Box>
            </Box>
        </ValidatorForm>
    );
};
const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTimeLog: (data) => {
            return dispatch(addTimeLogInTask(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        editTimelog: (data, timeLogId) => {
            return dispatch(editTimelogOfTaskRequest(data, timeLogId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentForm);
