import HttpRequest from '../../utilities/HttpRequest';

import { API_ACTIONS, SNACKBAR_VARIAINT } from '../../utilities/Constants';
import { showSnackbar } from '../../utilities/AppLoader/AppLoaderActions'

import {
    getAllProgressTasks
} from "./ProgressActions";

/**
 * Server API call to get all taskLists
 * @returns {function(*)}
 */
export const getProgressTasksOfUsers = (projectId, filterParams) => {
    let url;
    let searchData = {
        userId: filterParams && filterParams.user ? filterParams.user.id : ''
    }
    return (dispatch) => {
        if (filterParams.user) {
            url = `${API_ACTIONS.PROGRESS_TASKS_ALL}/${projectId}/progress?searchData=${JSON.stringify(searchData)}`;
        }
        else {
            url = `${API_ACTIONS.PROGRESS_TASKS_ALL}/${projectId}/progress`;
        }
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                dispatch(getAllProgressTasks(res.data))
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

