import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Typography} from '@material-ui/core';
import {TextValidator} from 'react-material-ui-form-validator';


const SearchableDropDown = ({
                                forNameAttr,
                                errorMessages,
                                validators,
                                isUpdate,
                                setSelectedFunctionalRole,
                                userIndex,
                                selectedRole,
                                setRoleUserIndexForUserDropdown,
                                setRoleIndexForUserDropdown,
                                selectedFunctionalRole,
                                projectDefination,
                                setShowFunctionalRoleDropDown,
                                firstLastName,
                                multiple,
                                type,
                                title,
                                name,
                                checklist,
                                value,
                                setValue,
                                optionsList,
                                label,
                                setOpenAddCheckList,
                                setOpen
                            }) => {
    return (
        <Autocomplete
            fullWidth
            name={forNameAttr ? forNameAttr : ''}
            className={projectDefination ? 'autocomplete-input dropdown-margins width-100-per' : ''}
            // multiple={multiple}
            value={value}
            onChange={(e, value) => {
                if (projectDefination && name) {
                    let data = Object.assign([], selectedFunctionalRole);
                    const findRoleIndex = data.findIndex((role) => role.functionalRoleId === value.id);
                    let obj;
                    if (findRoleIndex > -1) {
                        data[findRoleIndex].users.push('');
                    } else {
                        if (value && value.id) {
                            obj = {
                                functionalRoleId: value.id,
                                name: value.name,
                                users: [''],
                                timeEffort: '',
                                timeUnit: 'hours'
                            }
                            data.push(obj);
                        }
                    }
                    if (forNameAttr) {
                        setSelectedFunctionalRole(forNameAttr, data);
                        setShowFunctionalRoleDropDown(false);
                    } else {
                        setSelectedFunctionalRole(data);
                        setShowFunctionalRoleDropDown(false);
                    }
                } else if (projectDefination && firstLastName && selectedRole) {
                    let data = Object.assign([], selectedFunctionalRole);
                    const findIndex = data.findIndex((role) => role.functionalRoleId === selectedRole.functionalRoleId)
                    if (findIndex > -1 && value) {
                        data[findIndex].users[userIndex] = isUpdate ? value : value;
                        data[findIndex].noOfResources = data[findIndex].users.length;
                    }
                    if (forNameAttr) {
                        setValue(forNameAttr, data);
                        setRoleIndexForUserDropdown(null);
                        setRoleUserIndexForUserDropdown(null);
                    } else {
                        setValue(forNameAttr, data);
                        setRoleIndexForUserDropdown(null);
                        setRoleUserIndexForUserDropdown(null);
                    }
                } else {
                    if (checklist) {
                        if (value && value.title === "Create checklist") {
                            const findCreateNewChecklist = value.title === "Create checklist" ? true : false
                            if (findCreateNewChecklist) {
                                setOpenAddCheckList(true);
                                setOpen(false);
                            } else {
                                setValue(value);
                            }
                        } else if (value && value.title === "None") {
                            const fineNone = value.title === "None" ? true : false
                            if (fineNone) {
                                setValue("None");
                            }
                        } else {
                            setValue(value);
                        }
                    } else {
                        if (forNameAttr) {
                            setValue(forNameAttr, value);
                        } else {
                            setValue(value);
                        }
                    }
                }

            }}
            options={optionsList || []}
            getOptionLabel={option => option.title ? option.title : option.name ? option.name : option.type ? option.type : option.firstName && option.lastName ? `${option.firstName} ${option.lastName}` : ""}
            getOptionSelected={(option, value) =>
                option && value ? option.id === value.id : ""
            }
            renderInput={params => {
                if (validators && validators.length > 0) {
                    return (
                        <TextValidator
                            {...params}
                            value={value}
                            label={
                                <Typography
                                    className="margin-top-minus-2"
                                    variant="body1"
                                >
                                    {label}
                                </Typography>
                            }
                            validators={validators}
                            errorMessages={errorMessages}
                        />
                    )
                } else {
                    return (
                        <TextValidator
                            {...params}
                            value={value}
                            label={
                                <Typography
                                    className="margin-top-minus-2"
                                    variant="body1"
                                >
                                    {label}
                                </Typography>
                            }

                        />
                    )
                }
            }}
        />
    );
};

export default SearchableDropDown;
