import React, {useEffect, useRef, useState} from 'react';
import './Templates.css'
import ChipInput from 'material-ui-chip-input';
import Button from '@material-ui/core/Button';
import {Box, Card, Grid, Typography} from '@material-ui/core';
import Projects from './Projects'
import Avatar from '@material-ui/core/Avatar';
import {useStyles} from '../Tasks/styles';
import {connect} from 'react-redux';
import {useParams} from 'react-router';
import {ROUTER_ACTIONS} from '../../../../utilities/Constants';
import {
    addProjectTemplateRequest,
    addTagRequest,
    editProjectTemplateRequest,
    getAllProjectTemplatesRequest,
    getAllTagsRequset,
    deleteTemplate
} from '../../ConfigurationApiActions'
import CustomTheme from '../../../../utilities/theme/theme.json'
import MessageModal from '../../../../utilities/MessageModal/MessageModal';
const ProjectLandingPage = ({
                                addTags,
                                getAllTags,
                                history,
                                match,
                                getAllProjectTemplates,
                                addProjectTemplate,
                                editProjectTemplate,
                                templateDelete
                            }) => {


    const {templateId} = useParams()
    const modalRef = useRef();
    const classes = useStyles();
    // const [filterByName, setFilterByName] = useState(null)
    // const [filterByTag, setFilterByTag] = useState(null)
    const [hideLandingPage, setHideLandingPage] = useState(!!templateId)
    const [templatesData, setTemplatesData] = useState(null)
    const [chips, setChips] = useState([]);
    const [editTemplate, setEditTemplate] = useState(!!templateId)
    const [onSuccessHide, setOnSuccessHide] = useState(false)
    const [editProjectTemplateId, setEditProjectTemplateId] = useState(null)
    const [projectTypes, setProjectTypes] = useState(null)

    // for add and edit project template states
    const [basicTitle, setBasicTitle] = useState(null);
    const [basicDescription, setBasicDescription] = useState(null);
    const [selectedProjectType, setSelectedProjectType] = useState(null);
    const [selectedProjectRole, setSelectedProjectRole] = useState([]);
    const [fetchedFunctionalRoles, setFetchedFunctionalRoles] = useState(null)
    const [openProjectRoleDialog, setOpenProjectRoleDialog] = useState(false);
    const [shrinkProjectTitle, setShrinkProjectTitle] = useState(false);

    const [allTags, setAllTags] = useState(null)
    const [tagName, setTagName] = useState(null);

    useEffect(() => {
        if (tagName && tagName.length >= 3 && tagName.length < 4) {
            getAllTags(tagName, true).then((res) => {
                setAllTags(res)
            }).catch((err) => {
            })
        }
    }, [tagName])

    useEffect(() => {
        if (match && match.path === `${ROUTER_ACTIONS.CONFIGURATION_TEMPLATE_ADD}`) {
            setHideLandingPage(true)
        }
    }, [])

    useEffect(() => {
        getAllProjectTemplates().then((res) => {
            setTemplatesData(res)
        }).catch((err) => {
        })
    }, [])

    const hideHandler = () => {
        setHideLandingPage(true);
        history.push(`${ROUTER_ACTIONS.CONFIGURATION_TEMPLATE_ADD}`)
    }

    // add chips
    const handleAddChip = (chipTitle) => {
        const data = Object.assign([], chips);
        data.push(chipTitle)
        setChips(data);
    }

    // remove chips
    const handleDeleteChip = (chip, index) => {
        const data = Object.assign([], chips);
        data.splice(index, 1)
        setChips(data);
    }

    // edit template
    const editHandler = (title, description, id, type, projectRole, tags) => {
        history.push(`/configuration/template/${id}/edit`)
        // setBasicTitle(title)
        // setBasicDescription(description)
        // setChips(tags)
        // setHideLandingPage(true);
        // setEditTemplate(true)
        setEditProjectTemplateId(id)
        // setSelectedProjectType(type)
        // setChips(tags)
        // setSelectedProjectRole(projectRole)
    }
    // add tags
    const addChips = (tags) => {
        tags.map((singleTagName) => {
            let data = {
                name: singleTagName,
                color: singleTagName
            }
            addTags(data).then((res) => {
            })
            return singleTagName
        })

    }
    let arr = []
    const handleSubmit = () => {
        if (editTemplate) {
            selectedProjectRole && Object.keys(selectedProjectRole).map((singleRoleObj) => {
                arr.push(selectedProjectRole[singleRoleObj].id)
                return singleRoleObj
            })

            let data = {
                name: basicTitle,
                type: selectedProjectType.id,
                functionalRoles: arr,
                isBillable: true,
                tags: chips
            }
            if (basicDescription) {
                data.metaInfo = basicDescription
            } else {
            }

            let editProjectId = editProjectTemplateId ? editProjectTemplateId : ""
            editProjectTemplate(editProjectId, data).then((res) => {
                // addChips(chips)
                let filterArr = []
                getAllTags().then((res) => {
                    let arr = chips && chips.filter((tag) => {
                        return !(res.some((resTag) => resTag.name === tag))
                    })
                    filterArr.push(arr)
                    addChips(filterArr[0])
                })
                localStorage.setItem(`templateName`, res.name)
                history.push(`/configuration/template/${res.id}`)

                getAllProjectTemplates().then((res) => {
                    setTemplatesData(res)
                    setHideLandingPage(false)

                    setBasicTitle('')
                    setBasicDescription('')
                    setSelectedProjectType(null)
                    setSelectedProjectRole([])
                    // setChips([])
                    setEditTemplate(false)
                    setEditProjectTemplateId(null)
                })

            })


        } else {
            selectedProjectRole && Object.keys(selectedProjectRole).map((singleRoleObj) => {
                arr.push(selectedProjectRole[singleRoleObj].id)
                return singleRoleObj
            })
            const data = {
                name: basicTitle,
                // type:selectedProjectType.id,
                // functionalRoles:arr,
                isBillable: false,
                // tags:chips
            }
            if (basicDescription) {
                data.metaInfo = basicDescription
            } else {
            }
            if (arr.length > 0) {
                data.functionalRoles = arr
            } else {
            }
            if (chips) {
                data.tags = chips
            } else {
            }
            if (selectedProjectType) {
                data.type = selectedProjectType.id
            } else {
            }

            addProjectTemplate(data).then((res) => {
                // addChips(chips)
                let filterArr = []
                getAllTags().then((res) => {
                    let arr = chips && chips.filter((tag) => {
                        return !(res.some((resTag) => resTag.name === tag))
                    })
                    filterArr.push(arr)
                    addChips(filterArr[0])
                })
                localStorage.setItem(`templateName`, res.name)
                history.push(`/configuration/template/${res.id}`)
            })
                .catch((err) => {
                })
        }

    }

    const deleteTemplateHandler = (templateId) => {
        const title = ``
        const message = `Deleting template will erase in all data related to this template, which cannot be recovered. Do you still want to continue?`
        modalRef.current.setState({
            showModal: true,
            message: message,
            title: title,
            showOkButton: true,
            showCancelButton: true,
            showNote: false,
            note: '',
            handleOk: (note) => {
                templateDelete(templateId).then((res)=>{
                    getAllProjectTemplates().then((res) => {
                        setTemplatesData(res)
                    }).catch((err) => {
                    })
                })
            },
            okText: "Yes",
            cancelText: 'No'
        });
    }
    return (
        <>
            {
                hideLandingPage
                    ?
                    <Projects setTagName={setTagName} allTags={allTags}
                              setEditProjectTemplateId={setEditProjectTemplateId} templateId={templateId}
                              history={history} setEditTemplate={setEditTemplate} setProjectTypes={setProjectTypes}
                              projectTypes={projectTypes} onSuccessHide={onSuccessHide}
                              setOnSuccessHide={setOnSuccessHide} handleSubmit={handleSubmit}
                              editTemplate={editTemplate} setShrinkProjectTitle={setShrinkProjectTitle}
                              shrinkProjectTitle={shrinkProjectTitle}
                              setOpenProjectRoleDialog={setOpenProjectRoleDialog}
                              openProjectRoleDialog={openProjectRoleDialog}
                              setFetchedFunctionalRoles={setFetchedFunctionalRoles}
                              fetchedFunctionalRoles={fetchedFunctionalRoles}
                              setSelectedProjectRole={setSelectedProjectRole} selectedProjectRole={selectedProjectRole}
                              setSelectedProjectType={setSelectedProjectType} selectedProjectType={selectedProjectType}
                              chips={chips} setChips={setChips} setBasicDescription={setBasicDescription}
                              basicDescription={basicDescription} basicTitle={basicTitle}
                              setBasicTitle={setBasicTitle}/>
                    :
                    <>
                        <Card className="header-card">
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container justify="space-between">
                                    <Grid onClick={() => {
                                        hideHandler()
                                    }}>
                                        <Button variant="outlined" color="primary" size="small"
                                                className="new-template-btn">
                                            {/* <i className="fas fa-plus-circle"></i> */}
                                            <span className="margin-left-for-template-btn">+ TEMPLATE</span>
                                        </Button>
                                    </Grid>
                                    {/* <Grid className="filter-container" >
                                        <Typography className="text-containt" >
                                            Filter by
                                        </Typography>
                                        <Box className="filter-box" >
                                            <InputBase
                                                className="inputbase-style"
                                                placeholder="Name"
                                                value={filterByName}
                                                onChange={(e) => setFilterByName(e.target.value)}
                                            />
                                        </Box>
                                        <Box className="filter-box" >
                                            <InputBase
                                                className="inputbase-style"
                                                placeholder="Tags"
                                                value={filterByTag}
                                                onChange={(e) => setFilterByTag(e.target.value)}
                                            />
                                        </Box>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Card>
                        <Card className="body-card">
                            {
                                templatesData && Object.keys(templatesData).map((list, listIndex) => {
                                    return (

                                        <Card className="body-subcard" key={listIndex}>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                                  className="subcard-header">
                                                <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}
                                                      justify="space-between">
                                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                                        <Grid container>
                                                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                                                <Avatar className="subcard-image">
                                                                    {templatesData[list].name.charAt(0).toUpperCase()}
                                                                </Avatar>
                                                            </Grid>
                                                            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}
                                                                  className="subcard-title-container">
                                                                <Typography
                                                                    onClick={() => {
                                                                        localStorage.setItem(`templateName`, templatesData[list].name)
                                                                        history.push(`/configuration/template/${templatesData[list].id}`)
                                                                    }}
                                                                    className="subcard-title"
                                                                    style={{color: CustomTheme.linkColor}}
                                                                    variant="h2"
                                                                >
                                                                    {templatesData[list].name}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item xl={1} lg={1} md={1} sm={1} xs={1}
                                                        className="subcard-actions"
                                                        style={{color: CustomTheme.primaryColor}}
                                                    >
                                                        <span className="subcard-edit-icon" onClick={() => editHandler(templatesData[list].name, templatesData[list].metaInfo, templatesData[list].id, templatesData[list].type, templatesData[list].functionalRoles, templatesData[list].tags)}><i
                                                            className="far fa-edit"></i></span>
                                                        <span className="subcard-delete-icon" onClick={()=>deleteTemplateHandler(templatesData[list].id)} ><i className="far fa-trash-alt"></i></span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid className="templates-landing-page-chips-container" item xl={12} lg={12} md={12}
                                                  sm={12} xs={12}>
                                                <Box style={{backgroundColor: CustomTheme.bodyBackground}}
                                                     className="tagcontainer-box">
                                                    <span style={{color: CustomTheme.fontColor}}
                                                          className={classes.tagIcon}>
                                                        <i className='fas fa-tags'></i>
                                                    </span>
                                                    <Box className={classes.chipInputContainer}>
                                                        <ChipInput
                                                            disableUnderline={true}
                                                            fullWidth
                                                            placeholder="No tags added"
                                                            readOnly
                                                            value={templatesData[list].tags}
                                                            onAdd={(chip) => handleAddChip(chip)}
                                                            onChange={(chips) => setChips(chips)}
                                                            onDelete={(chip, index) => handleDeleteChip(chip, index)}
                                                            classes={{
                                                                root: classes.chipInput,
                                                                inputRoot: classes.inputRoot,
                                                                input: classes.input,
                                                                chip: classes.chip,
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid className="subcard-description-container" item xl={12} lg={12} md={12}
                                                  sm={12} xs={12}>
                                                <Typography variant="body1" style={{color: CustomTheme.fontColor}}
                                                            className="subcard-description">
                                                    {templatesData[list].metaInfo}
                                                </Typography>
                                            </Grid>
                                        </Card>

                                    )
                                })
                            }

                        </Card>
                    </>
            }
            <MessageModal
                ref={modalRef}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        // loggedInUser: state.auth.loggedInUser,
        // post: state.postDetails.post,
        // comments:state.postDetails.comments
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllProjectTemplates: () => {
            return dispatch(getAllProjectTemplatesRequest()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addProjectTemplate: (data) => {
            return dispatch(addProjectTemplateRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        editProjectTemplate: (templateId, data) => {
            return dispatch(editProjectTemplateRequest(templateId, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllTags: (name, searchTag) => {
            return dispatch(getAllTagsRequset(name, searchTag)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addTags: (data) => {
            return dispatch(addTagRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        templateDelete: (templateId) => {
            return dispatch(deleteTemplate(templateId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectLandingPage);
