import React from 'react'
import { Box, Card, Grid, Typography } from '@material-ui/core';
import CustomTheme from '../../../../../../utilities/theme/theme.json'
import Button from '@material-ui/core/Button';

const NoTeamFound = ({setAddNewTeam, setShowNewTeamForm}) => {
    return (
        <Box>
            <Grid container>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                    <Card className="blank-screen-new-team">
                        <Grid className="button-grid-margin-top">
                            <Box className="info-blank-page">
                                <Typography style={{ color: CustomTheme.fontColor }}
                                    className="welcome-title-style">Let's begin by creating a new
                                    team</Typography>
                            </Box>
                            <Box className="info-blank-page">
                                <Button size="small" onClick={() => {
                                    setShowNewTeamForm(true)
                                    setAddNewTeam(true)
                                }}
                                    className="blank-page-button-tasklist"
                                    variant="outlined"
                                    color="primary"
                                ><i className="fas fa-users"></i> <span
                                    className="btn-margin-left">+ TEAM</span></Button>
                            </Box>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
export default NoTeamFound