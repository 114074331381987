import {makeStyles} from '@material-ui/core';
import CustomTheme from '../../../../../../../utilities/theme/theme.json'

export const useStyles = makeStyles(theme => {
    return {
        mainContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        imageContainer: {
            height: '55px',
            width: '55px',
            border: '1px solid #fff',
            borderRadius: '100%',
            overflow: 'hidden',
        },
        image: {
            height: '100%',
            width: '100%',
            padding: '0%',
        },
        contentContainer: {
            marginLeft: '0.5rem',
            height: '50px',
        },
        headingContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        heading: {
            color: CustomTheme.topMenuHeadingTitle,
            textTransform: 'uppercase',
            letterSpacing: '2px',
            // fontSize: '1.2rem',
            // fontWeight: '600',
        },
        status: {
            // fontSize: '15px',
            color: CustomTheme.successColor,
            textTransform: 'initial',
            // fontWeight: '100',
            paddingLeft: '5px',
        },
        statusLabel: {
            paddingLeft: '5px',
        },
        bottomLabelContainer: {
            color: 'blue',
            display: 'flex',
            marginTop: '1px'
        },
        bottomLabel: {
            color: CustomTheme.leftTopMenuIconButton,
            marginRight: '0.5rem',
            '&:hover': {
                textDecoration: 'none',
                color: CustomTheme.hoverLinkColor,
                borderBottom: `3px solid ${CustomTheme.hoverLinkColor}`,
            },
        },
        active: {
            textDecoration: 'none',
            color: CustomTheme.activeLinkColor,
            borderBottom: `3px solid ${CustomTheme.activeLinkColor}`,
        },
        outerContainer: {
            width: '95%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        icon: {
            fontSize: '22px',
            color: '#90959e',
            padding: '10px',
            display: 'block',
            marginTop: '0.2rem',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        createMenuContainer: {
            position: 'relative',
        },
        createMenu: {
            width: '170px',
            borderLeft: '1px solid #e0e0e0',
            borderBottom: '1px solid #e0e0e0',
            boxShadow: '2px 2px 4px 0 rgb(32 33 36 / 28%)',
            background: '#fff',
            position: 'absolute',
            right: '0px',
            top: '58px',
            zIndex: '50',
            paddingBottom: '10px',
            textAlign: 'left',
            '& ul': {
                listStyle: 'none',
                display: 'block',
                padding: '5px 20px',
                marginBottom: '0px',
            },
        },
        createMenuLabel: {
            color: '#000',
            fontSize: '18px',
            fontWeight: '800',
            padding: '10px 20px',
        },
        createMenuItemIcon: {
            display: 'inline-block',
            width: '30px',
        },
        createMenuItem: {
            display: 'block',
            padding: '5px 5px',
            fontSize: '16px',
            textAlign: 'left',
            color: '#90959e',
            '&:hover': {
                color: '#90959e',
                textDecoration: 'none',
            },
        },
        actionsCotainer: {
            display: 'flex',
            alignItems: 'center',
        },
        searchContainer: {
            marginRight: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            '&:hover input': {
                width: '250px',
                height: '40px',
                padding: '1rem',
                border: `1px solid ${CustomTheme.leftTopMenuIconButton}`,
                borderRadius: '20px',
            },
        },
        searchIcon: {
            position: 'absolute',
            right: '0px',
            fontSize: '22px',
            color: CustomTheme.leftTopMenuIconButton,
            padding: '10px',
            display: 'block',
            marginTop: '0.2rem',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        searchInput: {
            width: '0px',
            height: '0px',
            padding: '0px',
            border: 'none',
            outline: 'none',
            transition: 'width 1s',
            '&:focus': {
                width: '250px',
                height: '40px',
                padding: '1rem',
                border: '1px solid #90959e',
                borderRadius: '20px',
            },
        },
    };
});
