import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getAllTaskListRequest } from '../../../../../../../../../configuration/ConfigurationApiActions'
import { importTaskListRequest } from '../../../TasksApiActions'
import { Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import CustomTheme from '../../../../../../../../../../utilities/theme/theme.json'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useParams } from 'react-router';
import Button from '@material-ui/core/Button';

const ImportTasklisDialog = ({
    openDialog, closeDialog, getAllConfigTaskList, importTaskList, setMyTasksVisible,
    setAddUpdateRes, showTaskDetailsPane
}) => {
    const [tasklist, setTasklist] = useState([]);
    const [selectedTasklist, setSelectedTasklist] = useState(null);
    const { id } = useParams();
    const projectId = id;
    useEffect(() => {
        if (openDialog) {
            getAllConfigTaskList().then((res) => {
                setTasklist(res);
            })
        }
    }, [openDialog])
    const onHandleInportTasklist = () => {
        const data = {
            tasklistConfigId: selectedTasklist.id
        };
        importTaskList(projectId, data).then((res) => {
            showTaskDetailsPane(res.id, 'taskList')
            setAddUpdateRes({
                res: res.id,
                formName: 'taskList'
            });
            setTasklist([]);
            setSelectedTasklist(null);
            setMyTasksVisible(false)
            closeDialog(false);
        });
    }

    return (
        <Dialog
            open={openDialog}
            keepMounted
            maxWidth="xs"
            fullWidth
            className="dailog-box-tasklist-add"
        >
            <DialogContent>
                <Typography variant="h2" style={{ color: CustomTheme.fontColor }}
                    className="block-titles margin-top-20">
                    Import Tasklist
                </Typography>
                <ValidatorForm
                    onSubmit={() => onHandleInportTasklist()}
                    onError={errors => console.log(errors)}
                >
                    <div className="import-tasklist-div">
                        <Autocomplete
                            id="combo-box-demo"
                            options={tasklist}
                            fullWidth
                            label="Select tasklist"
                            getOptionLabel={(option) => option.title}
                            value={selectedTasklist}
                            onChange={(event, selectedValue) => setSelectedTasklist(selectedValue)}
                            renderInput={(params) =>
                                <TextValidator
                                    {...params}
                                    fullWidth
                                    required
                                    label="Select tasklist"
                                    size="small"
                                    name="listTitle"
                                    value={selectedTasklist}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                />
                            }
                        />
                    </div>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="secondary"
                            className="reset-dialog-button"
                            size="small"
                            onClick={() => {
                                closeDialog(false);
                            }}
                        >
                            CANCEL
                        </Button>
                        <Button
                            className="save-dialog-button"
                            variant="outlined"
                            color="primary"
                            size="small"
                            type="submit"
                        >
                            IMPORT
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </DialogContent>
        </Dialog>
    )
}
const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllConfigTaskList: (taskId) => {
            return dispatch(getAllTaskListRequest(taskId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        importTaskList: (projectId, data) => {
            return dispatch(importTaskListRequest(projectId, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ImportTasklisDialog)