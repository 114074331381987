import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import 'react-phone-input-2/lib/material.css';
import MobileNumber from '../../../../../../utilities/MobileNumber/MobileNumber';
import './CommunicationDetails.css';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    marginTopTwo: {
        marginTop: '2%'
    },
    marginTopOne: {
        marginTop: '1%'
    },
    marginFromLeft: {
        marginLeft: '20px'
    }
});

const CommunicationDetails = ({history}) => {
    const classes = useStyles();
    const [formalOrganisationName, setFormalOrganisationName] = useState(undefined);
    const [organisationNickname, setOrganisationNickname] = useState(undefined);
    const [buildingName, setBuildingName] = useState(undefined);
    const [streetAreaName, setStreetAreaName] = useState(undefined);
    const [landmark, setLandmark] = useState(undefined);
    const [pinCode, setPinCode] = useState(undefined);
    const [city, setCity] = useState(undefined);
    const [state, setState] = useState(undefined);
    const [primaryPhone, setPrimaryPhone] = useState(undefined);
    const [secondaryPhone, setSecondaryPhone] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [websiteName, setWebsiteName] = useState(undefined);

    /**
     * Method executes on update practice mobile number
     */
    const updatePrimaryMobileNumber = (phone, validPhoneNumber) => {
        setPrimaryPhone(phone);
        // setValidPhoneNumber(validPhoneNumber);
    };

    /**
     * Method executes on update practice mobile number
     */
    const updateSecondaryMobileNumber = (phone, validPhoneNumber) => {
        setSecondaryPhone(phone);
        // setValidPhoneNumber(validPhoneNumber);
    };

    const onSubmit = () => {

    }
    return (
        <>
            <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography className="basic-information-title">Basic Information</Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.marginTopTwo}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="body2">Aenean aliquet, leo ut venenatis laoreet, urna nulla suscipit elit, id
                        auctor nulla sem in felis. Nam congue mollis quam, eget sodales mauris vestibulum ut.
                        Pellentesque risus enim, venenatis at massa eget, sagittis eleifend diam. Morbi at ultricies
                        arcu, eu viverra urna. Etiam ut posuere metus, a elementum orci. Nulla ac risus
                        neque.</Typography>
                </Grid>
            </Grid>
            <ValidatorForm onSubmit={onSubmit} className="form-container">
                <Grid container className={classes.marginTopTwo}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography className="information-titles">Organization Name</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.marginTopOne}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="body2">Ut feugiat nisl urna, vel ultrices felis sollicitudin et. Morbi
                            lectus erat, posuere ac diam vitae, vehicula porttitor enim. Vestibulum nec convallis
                            purus.</Typography>
                    </Grid>
                </Grid>
                <Grid className={classes.marginTopOne}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                            <Grid container>
                                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <TextValidator
                                        label='Formal Organisation Name'
                                        fullWidth
                                        required
                                        name='firstname'
                                        value={formalOrganisationName}
                                        onChange={e => setFormalOrganisationName(e.target.value)}
                                        validators={['required']}
                                        errorMessages={['Organisation name is required']}
                                    />
                                </Grid>
                                <Grid className={classes.marginFromLeft} item xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <TextValidator
                                        label='Organisation Nickname'
                                        fullWidth
                                        required
                                        name='organisationnickname'
                                        value={organisationNickname}
                                        onChange={e => setOrganisationNickname(e.target.value)}
                                        validators={['required']}
                                        errorMessages={['Organisation Nickname is required']}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.marginTopTwo}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography className="information-titles">Location</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.marginTopOne}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="body2">Ut feugiat nisl urna, vel ultrices felis sollicitudin et. Morbi
                            lectus erat, posuere ac diam vitae, vehicula porttitor enim. Vestibulum nec convallis
                            purus.</Typography>
                    </Grid>
                </Grid>
                <Grid className={classes.marginTopOne}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                            <Grid container>
                                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <TextValidator
                                        label='Building Name/Number'
                                        fullWidth
                                        required
                                        name='building-name/number'
                                        value={buildingName}
                                        onChange={e => setBuildingName(e.target.value)}
                                        validators={['required']}
                                        errorMessages={['Building Name/Number is required']}
                                    />
                                </Grid>
                                <Grid className={classes.marginFromLeft} item xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <TextValidator
                                        label='Street/Area/Colony/Sector'
                                        fullWidth
                                        required
                                        name='street/area/colony/sector'
                                        value={streetAreaName}
                                        onChange={e => setStreetAreaName(e.target.value)}
                                        validators={['required']}
                                        errorMessages={['Organisation Nickname is required']}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.marginTopOne}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                            <Grid container>
                                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <TextValidator
                                        label='Landmark'
                                        fullWidth
                                        name='landmark'
                                        value={landmark}
                                        onChange={e => setLandmark(e.target.value)}
                                        validators={['required']}
                                        errorMessages={['Landmark is required']}
                                    />
                                </Grid>
                                <Grid className={classes.marginFromLeft} item xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <TextValidator
                                        label='PIN code'
                                        fullWidth
                                        required
                                        name='pincode'
                                        value={pinCode}
                                        onChange={e => setPinCode(e.target.value)}
                                        validators={['required']}
                                        errorMessages={['PIN Code is required']}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.marginTopOne}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                            <Grid container>
                                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <TextValidator
                                        label='City'
                                        fullWidth
                                        required
                                        name='city'
                                        value={city}
                                        onChange={e => setCity(e.target.value)}
                                        validators={['required']}
                                        errorMessages={['Organisation name is required']}
                                    />
                                </Grid>
                                <Grid className={classes.marginFromLeft} item xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <TextValidator
                                        label='State'
                                        fullWidth
                                        required
                                        name='state'
                                        value={state}
                                        onChange={e => setState(e.target.value)}
                                        validators={['required']}
                                        errorMessages={['Organisation Nickname is required']}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.marginTopOne}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography className="information-titles">Contact</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.marginTopOne}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="body2">Ut feugiat nisl urna, vel ultrices felis sollicitudin et. Morbi
                            lectus erat, posuere ac diam vitae, vehicula porttitor enim. Vestibulum nec convallis
                            purus.</Typography>
                    </Grid>
                </Grid>
                <Grid className={classes.marginTopTwo}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid className="phone-number" item xl={8} lg={8} md={8} sm={8} xs={8}>
                            <Grid container>
                                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <MobileNumber updateMobileNumber={updatePrimaryMobileNumber}
                                                  specialLabel="Primary Phone Number *"
                                                  phone={primaryPhone ? primaryPhone : ""}/>
                                </Grid>
                                <Grid className={classes.marginFromLeft} item xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <MobileNumber updateMobileNumber={updateSecondaryMobileNumber}
                                                  specialLabel="Secondary Phone Number"
                                                  phone={secondaryPhone ? secondaryPhone : ""}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.marginTopOne}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                            <Grid container>
                                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <TextValidator
                                        label='Email'
                                        fullWidth
                                        name='email'
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        validators={['isEmail']}
                                        errorMessages={['Email is required']}
                                    />
                                </Grid>
                                <Grid className={classes.marginFromLeft} item xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <TextValidator
                                        label='Website'
                                        fullWidth
                                        name='website'
                                        value={websiteName}
                                        onChange={e => setWebsiteName(e.target.value)}
                                        validators={['required']}
                                        errorMessages={['Website is required']}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </>
    )
};
export default CommunicationDetails;
