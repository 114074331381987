import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import { DateRangePicker} from "materialui-daterange-picker";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none'
    },
    padding20:{
        padding:'20px'
    }
}));

const DateRangePickerDailog = ({ popperOpen, anchorEl , setFilterParams, filterParams}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const toggle = () => setOpen(false);
    return (
            <Popper open={popperOpen} anchorEl={anchorEl} placement="bottom" transition className="popper-border-radius">
                <Paper>
                    <DateRangePicker
                    open={open}
                    toggle={toggle}
                    onChange={(range) => {
                        let starDate = moment(range.startDate).format('YYYY-MM-DD')
                        let endDate = moment(range.endDate).format('YYYY-MM-DD')
                        setFilterParams({
                            ...filterParams,
                            startDate: starDate,
                            endDate: endDate
                        })
                        setOpen(false)
                    }}
                    />
                </Paper>
            </Popper>
    )
}
export default DateRangePickerDailog