import React from 'react';
import './DraggableModal.css';
import {Button, Dialog, DialogContent, DialogTitle, Paper} from '@material-ui/core';
import Draggable from 'react-draggable';

function PaperComponent(props) {
    return (
        <Draggable
            handle='#draggable-dialog-title'
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const DraggableModal = ({open, handleClose, title, children, fullScreen, maxWidth}) => {
    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={maxWidth ? maxWidth : 'sm'}
            fullScreen={fullScreen ? fullScreen : false}
            // onClose={handleClose}
            disableScrollLock={true}
            PaperComponent={PaperComponent}
        >
            <DialogTitle id='draggable-dialog-title' className="draggable-dialog-title-container">
                <span className="draggable-dialog-title">{title ? title : ""}</span>
                {
                    handleClose
                        ? (
                            <Button
                                size="small"
                                color="primary"
                                className="close-dialog-button"
                                onClick={handleClose}
                                startIcon={<i className="far fa-times-circle" title="Close" aria-hidden="true"></i>}
                            >
                                Close
                            </Button>
                        )
                        : <></>
                }
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default DraggableModal;
