import React from 'react';
import {ValidatorComponent} from 'react-material-ui-form-validator';
import {DateTimePicker} from '@material-ui/pickers';
import {Box} from '@material-ui/core';

class DateTimePickerValidator extends ValidatorComponent {
    renderValidatorComponent() {
        const {
            helperText,
            errorMessages,
            validators,
            validatorListener,
            ...rest
        } = this.props;

        const {isValid} = this.state;

        return (
            <Box>
                <DateTimePicker
                    {...rest}
                    error={!isValid}
                    helperText={(!isValid && this.getErrorMessage()) || helperText}
                />
            </Box>
        );
    }
}

export default DateTimePickerValidator;
