import {HIDE_LOADER, HIDE_SNACK_BAR, SHOW_LOADER, SHOW_SNACK_BAR} from './AppLoaderActions';
// import {SIGN_OUT} from "../../../modules/Authentication/AuthenticationActions";

const initialState = {
    showLoader: false,
    showSnackbar: false,
    snackbarVariant: '',
    snackbarMessage: ''
};

/**
 * User reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const AppLoaderReducer = (state = initialState, action) => {

    switch (action.type) {

        //Get list of user to be shown
        case SHOW_LOADER:
            return Object.assign({}, state, {
                showLoader: action.showLoader
            });

        //Update user details
        case HIDE_LOADER: {
            return Object.assign({}, state, {
                showLoader: action.showLoader
            });
        }

        case HIDE_SNACK_BAR:
            return Object.assign({}, state, {
                showSnackbar: false,
                snackbarMessage: '',
                snackbarVariant: ''
            });

        case SHOW_SNACK_BAR: {
            return Object.assign({}, state, {
                showSnackbar: true,
                snackbarMessage: action.snackbarMessage,
                snackbarVariant: action.snackbarVariant,
                showLoader: false
            });
        }

        // case SIGN_OUT:
        //   return Object.assign({}, initialState);

        default:
            return state;
    }
};

export default AppLoaderReducer;
