import React, { useEffect, useRef, useState } from 'react'
import './VendorAddEditForm.css'
import { Box, Grid, Typography } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import MobileNumber from '../../../../../utilities/MobileNumber/MobileNumber';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getAllAuthUsersRequest } from '../../../../authentication/AuthenticationApiActions';
import { deleteMemberForShowingMessage, getAllVendorRequset, addVendorRequest, editVendorRequest, getSingleVendorDetailsRequest } from '../../../ConfigurationApiActions'
import { connect } from 'react-redux';
import CustomTheme from '../../../../../utilities/theme/theme.json'
import MessageModal from '../../../../../utilities/MessageModal/MessageModal'
import LoaderForSaveAndEdit from '../../../../../utilities/AppLoader/LoaderForSaveAndEdit'
const VendorAddEditForm = ({
    setSelectedRow,
    setAddNewVendor,
    addNewVendor,
    getAllAuthUsers,
    deleteMember,
    allUsers,
    setRowIndex,
    getAllVendors,
    addVendor,
    editVendor,
    getVendorDetails,
    rowIndex
}) => {
    const modalRef = useRef();

    const [membersListNames, setMembersListNames] = useState([])
    const [memberName, setMemberName] = useState(null)
    const [member, setMember] = useState(null);
    const [value, setValue] = useState(null)

    // vendors
    const [showCompanyName, setShowCompnayName] = useState(false);
    const [showCompanyAddress, setShowCompanyAddress] = useState(false);
    const [showContactPerson, setShowContactPerson] = useState(null);
    const [showContactPhone, setShowContactPhone] = useState(false);
    const [showContactEmail, setShowContactEmail] = useState(false);

    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false)
    const [phoneValidationMessage, setPhoneValidationMessage] = useState(null)
    const [isVendorOnUpdateForm, setIsVendorOnUpdateForm] = useState(false)
    const [addEditBtnShow, setAddEditBtnShow] = useState(false)
    const [addEditRes, setAddEditRes] = useState(null)
    const [isShowLoader, setIsShowLoader] = useState(false);

    const [vendorFormData, setVendorFormData] = useState({
        name: null,
        address: null,
        contactPerson: null,
        phoneNumber: null,
        emailId: null,
        members: []
    })
    useEffect(() => {
        getAllAuthUsers(true)
    }, [])


    useEffect(() => {
        setValue(null)
    }, [vendorFormData.members])

    useEffect(() => {
        if (addNewVendor) {
            setVendorFormData({
                name: null,
                address: null,
                contactPerson: null,
                phoneNumber: null,
                emailId: null,
                members: []
            })
            setMembersListNames([])
            setShowCompanyAddress(false)
            setShowContactPerson(false)
            setShowContactPhone(false)
            setShowContactEmail(false);
            setShowCompnayName(false)
            setAddEditBtnShow(false)
            setAddEditRes('')
        }
    }, [addNewVendor])


    useEffect(() => {
        if (rowIndex) {
            setIsShowLoader(true)
            getVendorDetails(rowIndex).then((res) => {
                let vendorData = Object.assign([], vendorFormData);
                let arr = [];
                res.members && Object.keys(res.members).map((name, nameIndex) => {
                    arr.push(`${res.members[name].firstName} ${res.members[name].lastName}`)
                    return name
                })

                let arrForIds = [];
                res.members && Object.keys(res.members).map((name, nameIndex) => {
                    arrForIds.push(res.members[name].id)
                    return name
                })

                vendorData.name = res.name
                vendorData.address = res.address
                vendorData.contactPerson = res.contactPerson
                vendorData.phoneNumber = res.phoneNumber
                vendorData.emailId = res.emailId
                vendorData.members = arrForIds
                setVendorFormData(vendorData)
                setMembersListNames(arr)
                setShowCompanyAddress(false)
                setShowContactPerson(false)
                setShowContactPhone(false)
                setShowContactEmail(false);
                setShowCompnayName(false)
                setAddEditBtnShow(true)
                setIsShowLoader(false)
                setAddNewVendor(false)
            })
        } else { }
    }, [rowIndex, addEditRes])

    const clickHandler = () => {
        let data = {
            name: vendorFormData.name,
        }
        if (vendorFormData.address) {
            data.address = vendorFormData.address
        } else {
        }
        if (vendorFormData.contactPerson) {
            data.contactPerson = vendorFormData.contactPerson
        } else {
            // data.contactPerson = ""
        }
        if (vendorFormData.phoneNumber) {
            data.phoneNumber = vendorFormData.phoneNumber
        } else {
        }
        if (vendorFormData.emailId) {
            data.emailId = vendorFormData.emailId
        } else {
        }
        if (vendorFormData.members) {
            data.members = vendorFormData.members
        } else {
        }
        if (rowIndex != null) {
            // edit vendor
            editVendor(data, rowIndex).then((res) => {

                getAllVendors()

                setRowIndex(res.id)
                setSelectedRow(res.id)
                setAddEditBtnShow(true)
                setAddEditRes(res)
            })
        } else {
            //Add Vendor
            addVendor(data).then((res) => {

                getAllVendors()

                setRowIndex(res.id)
                setSelectedRow(res.id)
                setAddEditBtnShow(true)
                setAddEditRes(res)

            })
        }
    }

    const updateMobileNumber = (phone, validPhoneNumber) => {
        handleChangeAutocomplete("phoneNumber", phone)
        setIsPhoneNumberValid(validPhoneNumber)
        if (validPhoneNumber || phone.length === 3) {
            setIsPhoneNumberValid(false)
            setPhoneValidationMessage('')
        } else {
            setIsPhoneNumberValid(true)
            setPhoneValidationMessage('Contact phone should 10 digit')
        }
    }


    const defaultProps = {
        options: allUsers || [],
        getOptionLabel: (option) => `${option.firstName} ${option.lastName}`,
    };


    const addMemeberHandler = () => {
        const data = Object.assign([], vendorFormData.members);
        const newMemberObj = member;
        data.push(newMemberObj)
        setVendorFormData({
            ...vendorFormData,
            members: data
        })
        setMember('');

        const dataMemberName = Object.assign([], membersListNames);
        const newMemberNamesObj = memberName;
        dataMemberName.push(newMemberNamesObj)
        setMembersListNames(dataMemberName)

    }

    const deleteMemberHandler = (nameIndex) => {
        const data = Object.assign([], vendorFormData.members);
        data.splice(nameIndex, 1);
        setVendorFormData({
            ...vendorFormData,
            members: data
        })

        const memberListNameData = Object.assign([], membersListNames);
        memberListNameData.splice(nameIndex, 1);
        setMembersListNames(memberListNameData);

        deleteMember();
    }

    const showAllValuesInFormHandler = () => {
        setAddEditBtnShow(false);
        setShowCompnayName(true)
        setShowCompanyAddress(true)
        setShowContactPerson(true)
        setShowContactPhone(true)
        setShowContactEmail(true);
        setAddNewVendor(false)
    }

    const handleEsc = (e) => {
        if (e.keyCode === 27) {
            if (rowIndex) {
                if (!isVendorOnUpdateForm) {
                    setAddNewVendor(false)
                    setSelectedRow(null)
                } else {

                    const title = `Vendor`
                    const message = `Changes that you made in edit vendor may not be saved.`
                    modalRef.current.setState({
                        showModal: true,
                        message: message,
                        title: title,
                        showOkButton: true,
                        showCancelButton: true,
                        showNote: false,
                        note: '',
                        handleOk: (note) => {
                            setAddNewVendor(false)
                            setSelectedRow(null)
                        },
                        okText: "Yes",
                        cancelText: 'No'
                    });
                }
            } else {
                if (isVendorOnUpdateForm) {
                    const title = `Vendor`
                    const message = `Changes that you made in vendor may not be saved.`
                    modalRef.current.setState({
                        showModal: true,
                        message: message,
                        title: title,
                        showOkButton: true,
                        showCancelButton: true,
                        showNote: false,
                        note: '',
                        handleOk: (note) => {
                            setAddNewVendor(false)
                            setSelectedRow(null)
                        },
                        okText: "Yes",
                        cancelText: 'No'
                    });
                } else {
                    setAddNewVendor(false)
                }
            }
        }
    }

    const handleChangeInput = (event, value) => {
        const data = Object.assign({}, vendorFormData);
        data[event.target.name] = event.target.value;
        if (!isVendorOnUpdateForm) {
            setIsVendorOnUpdateForm(true)
        } else {
        }
        setVendorFormData(data)
    }
    /**
 * Method executes on onChange action of autocomplete form element
 */
    const handleChangeAutocomplete = (elementName, selectedvalue) => {
        let data = Object.assign({}, vendorFormData);
        if (elementName === "phoneNumber") {
            data.phoneNumber = selectedvalue
            if (!isVendorOnUpdateForm) {
                setIsVendorOnUpdateForm(true)
            } else {
            }
        }
        else { }
        setVendorFormData(data)
    }
    return (
        <>
            {
                isShowLoader
                    ?
                    <span className="loader-for-save-and-edit"><LoaderForSaveAndEdit /></span>
                    :
                    <>
                        <ValidatorForm
                            onSubmit={() => clickHandler()}
                            onKeyDown={(e) => handleEsc(e)}
                        >
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                style={{ borderBottom: `1px dashed ${CustomTheme.primaryColor}` }}
                                className={"team-detail-panel-padding"}>
                                <Grid container justify="space-between">
                                    <Grid>
                                    </Grid>
                                    {
                                        addEditBtnShow
                                            ?
                                            <Grid onClick={() => {
                                                showAllValuesInFormHandler();
                                            }} className="team-detail-panel-save-btn"
                                                style={{ color: CustomTheme.primaryColor }}
                                            >
                                                <i className="far fa-edit"></i>
                                            </Grid>
                                            :
                                            <Grid className="save-btn-position-style">
                                                <Button size="small"
                                                    type="submit"
                                                    className="form-panel-save-btn submit-btn"
                                                    style={{ color: CustomTheme.primaryColor }}
                                                >
                                                    <i className="far fa-save"></i>
                                                </Button>
                                            </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                className="padding-to-grid client-vendor-background-color">
                                <Grid container>
                                    <Grid xl={2} lg={2} md={2} sm={2} xs={2}>
                                        <Grid style={{ color: CustomTheme.primaryColor }}>
                                            <span className="edit-icon-small">
                                                {/* <i className="far fa-edit"></i> */}
                                            </span>
                                        </Grid>
                                        <Grid>
                                            {/* <img className="image-icon-grid" src={SampleImage} /> */}
                                            {
                                                vendorFormData.contactPerson && vendorFormData.contactPerson !== ""
                                                    ?
                                                    <Avatar
                                                        className="image-icon-grid-by-default">{vendorFormData.contactPerson.charAt(0).toUpperCase()}</Avatar>
                                                    :
                                                    <Avatar className="image-icon-grid-by-default"></Avatar>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid xl={10} lg={10} md={10} sm={10} xs={10}>
                                        {
                                            showCompanyName
                                                ?
                                                <Grid>
                                                    <TextValidator
                                                        fullWidth
                                                        autoFocus
                                                        // required
                                                        // variant="outlined"
                                                        size="small"
                                                        label="Company Name"
                                                        onChange={handleChangeInput}
                                                        name="name"
                                                        value={vendorFormData.name || ''}
                                                        validators={['required', 'trim', 'maxStringLength:50']}
                                                        errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                                    />
                                                </Grid>
                                                :
                                                <>
                                                    {
                                                        rowIndex
                                                            ?
                                                            <Grid>
                                                                <Typography variant="h1"
                                                                    style={{ color: CustomTheme.primaryColor }}
                                                                    className="add-title-text">
                                                                    <span>
                                                                        {vendorFormData.name}
                                                                    </span>
                                                                </Typography>
                                                            </Grid>
                                                            :
                                                            <Grid onClick={() => setShowCompnayName(true)}>
                                                                <Typography variant="h2"
                                                                    style={{ color: CustomTheme.primaryColor }}
                                                                    className="add-title-text">
                                                                    <i className="far fa-edit"></i> <span>Click to add company name</span>
                                                                </Typography>
                                                            </Grid>
                                                    }
                                                </>
                                        }
                                        {
                                            showCompanyAddress
                                                ?
                                                <Grid className="description-input-container">
                                                    <TextValidator
                                                        fullWidth
                                                        autoFocus
                                                        // required
                                                        // variant="outlined"
                                                        size="small"
                                                        multiLine={true}
                                                        label="Company Address"
                                                        onChange={handleChangeInput}
                                                        name="address" value={vendorFormData.address || ''}
                                                        validators={['required', 'trim', 'maxStringLength:250']}
                                                        errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 250 charachers reached']}
                                                    />
                                                </Grid>
                                                :
                                                <>
                                                    {
                                                        rowIndex
                                                            ?
                                                            <Grid>
                                                                <Typography variant="h3"
                                                                    style={{ color: CustomTheme.primaryColor }}
                                                                    className="add-description-text">
                                                                    <span>
                                                                        {vendorFormData.address}
                                                                    </span>
                                                                </Typography>
                                                            </Grid>
                                                            :
                                                            <Grid onClick={() => setShowCompanyAddress(true)}>
                                                                <Typography variant="h3"
                                                                    style={{ color: CustomTheme.primaryColor }}
                                                                    className="add-description-text">
                                                                    <i className="far fa-edit"></i> <span>
                                                                        Click to add a company address for this vendor
                                                                    </span>
                                                                </Typography>
                                                            </Grid>
                                                    }
                                                </>

                                        }
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                className="padding-to-grid margin-top-team-btn">
                                <Grid container>
                                    <Grid style={{ color: CustomTheme.fontColor, paddingTop: '6px' }} item xl={1} lg={1} md={1} sm={12}
                                        xs={12}>
                                        <span className="client-vendor-contact-icons"><i
                                            className="far fa-user"></i></span>
                                    </Grid>
                                    {
                                        showContactPerson
                                            ?
                                            <Grid className="client-vendor-input-grid" item xl={10} lg={10} md={10}
                                                sm={12} xs={12}>
                                                <TextValidator
                                                    fullWidth
                                                    // required
                                                    autoFocus
                                                    // variant="outlined"
                                                    size="small"
                                                    label="Contact Person"
                                                    onChange={handleChangeInput}
                                                    name="contactPerson" value={vendorFormData.contactPerson || ''}
                                                    validators={['required', 'trim', 'maxStringLength:50']}
                                                    errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                                />
                                            </Grid>
                                            :
                                            <>
                                                {
                                                    rowIndex
                                                        ?
                                                        <Grid className="client-vendor-contact-text-grid">
                                                            <Grid>
                                                                <Typography variant="body2" style={{
                                                                    color: CustomTheme.fontColor,
                                                                    paddingBottom: '3px'
                                                                }} className="client-vendor-contact-person">Contact
                                                                    Person</Typography>
                                                            </Grid>
                                                            <Grid style={{ color: CustomTheme.fontColor }}
                                                                className="client-vendor-contact-person-name">
                                                                <Typography variant="body1"
                                                                    style={{ color: CustomTheme.primaryColor }}><span
                                                                        className="edit-icon-small"></span>{vendorFormData.contactPerson}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        <Grid className="client-vendor-click-to-add-text">
                                                            <Typography variant="h3"
                                                                onClick={() => setShowContactPerson(true)}
                                                                style={{ color: CustomTheme.primaryColor }}
                                                                className="add-description-text">
                                                                <i className="far fa-edit"></i> <span style={{ color: CustomTheme.primaryColor }}
                                                                    className="edit-icon-small">
                                                                    Click to add a contact person for vendor
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                }
                                            </>

                                    }

                                </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="padding-to-grid">
                                <Grid container>
                                    <Grid style={{ color: CustomTheme.fontColor, paddingTop: '5px' }} item xl={1} lg={1} md={1} sm={12}
                                        xs={12}>
                                        <span className="client-vendor-contact-icons"><i
                                            className="fas fa-phone-alt"></i></span>
                                    </Grid>
                                    {
                                        showContactPhone
                                            ?
                                            <Grid className="client-vendor-input-grid-for-contact-phone" item xl={10}
                                                lg={10} md={10} sm={12} xs={12}>
                                                <MobileNumber updateMobileNumber={updateMobileNumber}
                                                    specialLabel="Contact Phone" phone={vendorFormData.phoneNumber} />
                                                {
                                                    isPhoneNumberValid
                                                        ?
                                                        <Typography
                                                            className="error-message-phone">{phoneValidationMessage}</Typography>
                                                        :
                                                        <></>
                                                }
                                            </Grid>
                                            :
                                            <>
                                                {
                                                    rowIndex
                                                        ?
                                                        <Grid className="client-vendor-contact-text-grid">
                                                            <Grid>
                                                                <Typography variant="body2" style={{
                                                                    color: CustomTheme.fontColor,
                                                                    paddingBottom: '4px'
                                                                }} className="client-vendor-contact-person">Contact
                                                                    Phone</Typography>
                                                            </Grid>
                                                            <Grid style={{ color: CustomTheme.primaryColor }}
                                                                className="client-vendor-contact-person-phone-email">
                                                                <Typography variant="body1"
                                                                    style={{ color: CustomTheme.primaryColor }}><span
                                                                        className="edit-icon-small"></span>{vendorFormData.phoneNumber}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        <Grid className="client-vendor-click-to-add-text">
                                                            <Typography variant="h3"
                                                                onClick={() => setShowContactPhone(true)}
                                                                style={{ color: CustomTheme.primaryColor }}
                                                                className="add-description-text">
                                                                <i className="far fa-edit"></i><span style={{ color: CustomTheme.primaryColor }}
                                                                    className="edit-icon-small">
                                                                    Click to add a contact phone for vendor
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                }
                                            </>

                                    }


                                </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="padding-to-grid">
                                <Grid container>
                                    <Grid style={{ color: CustomTheme.fontColor, paddingTop: '5px' }} item xl={1} lg={1} md={1} sm={12}
                                        xs={12}>
                                        <span className="client-vendor-contact-icons"><i
                                            className="far fa-envelope"></i></span>
                                    </Grid>
                                    {
                                        showContactEmail
                                            ?
                                            <Grid className="client-vendor-input-grid" item xl={10} lg={10} md={10}
                                                sm={12} xs={12}>
                                                <TextValidator
                                                    fullWidth
                                                    autoFocus
                                                    // required
                                                    // variant="outlined"
                                                    size="small"
                                                    label="Contact Email"
                                                    onChange={handleChangeInput}
                                                    name="emailId" value={vendorFormData.emailId || ''}
                                                    validators={['required', 'isEmail']}
                                                    errorMessages={['This field is required', 'Email is not valid']}
                                                />
                                            </Grid>
                                            :
                                            <>
                                                {
                                                    rowIndex
                                                        ?
                                                        <Grid className="client-vendor-contact-text-grid">
                                                            <Grid>
                                                                <Typography variant="body2" style={{
                                                                    color: CustomTheme.fontColor,
                                                                    paddingBottom: '3px'
                                                                }} className="client-vendor-contact-person">Contact
                                                                    Email</Typography>
                                                            </Grid>
                                                            <Grid style={{ color: CustomTheme.primaryColor }}
                                                                className="client-vendor-contact-person-phone-email">
                                                                <Typography variant="body1"
                                                                    style={{ color: CustomTheme.primaryColor }}><span
                                                                        className="edit-icon-small"></span>{vendorFormData.emailId}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        <Grid className="client-vendor-click-to-add-text">
                                                            <Typography variant="h3"
                                                                onClick={() => setShowContactEmail(true)}
                                                                style={{ color: CustomTheme.primaryColor }}
                                                                className="add-description-text">
                                                                <i className="far fa-edit"></i> < span style={{ color: CustomTheme.primaryColor }}
                                                                    className="edit-icon-small">
                                                                    Click to add a contact email for vendor
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                }
                                            </>

                                    }
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                        {
                            addEditBtnShow
                                ?
                                <></>
                                :
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                    className="form-panel-assign-name-container team-detail-panel-memeber-container">
                                    <ValidatorForm
                                        onSubmit={() => value ? addMemeberHandler() : ''}
                                    >
                                        <Grid container>
                                            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                                <Box className="team-detail-panel-add-member-box">
                                                    <Typography style={{ color: CustomTheme.fontColor }}><i
                                                        className="fas fa-user-plus"></i></Typography>
                                                    <Typography style={{ color: CustomTheme.fontColor }}
                                                        className="team-detail-panel-new-member-text">New
                                                        Member</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                                                <Autocomplete
                                                    {...defaultProps}
                                                    className="team-detail-panel-inputbase"
                                                    value={value}
                                                    onChange={(event, newValue) => {
                                                        setValue(newValue);
                                                        setMember(newValue.id)
                                                        setMemberName(`${newValue.firstName} ${newValue.lastName}`)
                                                    }}
                                                    renderInput={(params) => <TextValidator   {...params} placeholder="New Member" />}
                                                />
                                            </Grid>
                                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}
                                                className="team-detail-panel-add-member-btn">
                                                <Button size="small"
                                                    // disabled={value? false:true}
                                                    type="submit"
                                                    variant="outlined"
                                                    color="primary"
                                                    className={value != null ? "blank-page-button-tasklist btn-margin-left" : "disable-add-btn blank-page-button-tasklist btn-margin-left"}><span
                                                        className="btn-margin-left">ADD</span>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                </Grid>
                        }
                        {
                            addEditBtnShow && membersListNames && membersListNames.length > 0
                                ?
                                <Grid className="memebers-title-on-read-only" item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Grid>
                                        <Typography variant="body1" style={{ color: CustomTheme.fontColor }}
                                            className="form-panel-dependent-task-text">
                                            Members:
                                        </Typography>
                                    </Grid>
                                </Grid>
                                :
                                <></>
                        }
                        {
                            membersListNames && membersListNames.map((name, nameIndex) => {
                                return (
                                    <Grid key={nameIndex} item xl={12} lg={12} md={12} sm={12} xs={12}
                                        className="form-panel-assign-name-container ">
                                        <Grid container>
                                            <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                                <Typography
                                                    style={{ paddingLeft: rowIndex ? '15px' : '', color: CustomTheme.fontColor }}
                                                    className="team-detail-panel-memeber-name-text">{name}</Typography>
                                            </Grid>
                                            {
                                                rowIndex
                                                    ?
                                                    <></>
                                                    :
                                                    <Grid onClick={() => deleteMemberHandler(nameIndex)} item xl={4} lg={4} md={4}
                                                        sm={12} xs={12}>
                                                        <Typography className="team-detail-panel-delete-memeber-icon"><i
                                                            className="far fa-times-circle"></i></Typography>
                                                    </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                        <MessageModal
                            ref={modalRef}
                        />
                    </>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        allUsers: state.auth.users,
        singleVendorDetail: state.configuration.singleVendorDetails,
        allVendors: state.configuration.vendors
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllVendors: () => {
            return dispatch(getAllVendorRequset()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addVendor: (data) => {
            return dispatch(addVendorRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        editVendor: (data, vendorId) => {
            return dispatch(editVendorRequest(data, vendorId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllAuthUsers: (isLoaderShowing) => {
            return dispatch(getAllAuthUsersRequest(isLoaderShowing)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getVendorDetails: (id) => {
            return dispatch(getSingleVendorDetailsRequest(id)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        deleteMember: () => {
            return dispatch(deleteMemberForShowingMessage())
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorAddEditForm);
