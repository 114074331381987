import {ACTION_STATION_MY_TASKS_LIST} from './ActionStationActions';

const initialState = {
    myTasksOfUser:[]
};

/**
 * PageDetailsReducer reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */

const ActionStationReducer = (state = initialState, action) => {

    switch (action.type) {

        //Get list of user tasks in My Tasks menu for action station
        case ACTION_STATION_MY_TASKS_LIST:
            return Object.assign({}, state, {
                myTasksOfUser: action.myTasksList
            });



        default:
            return state;

    }
};

export default ActionStationReducer;
