import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";

import {ROUTER_ACTIONS} from '../../utilities/Constants';

const Home = React.memo(({loggedInUser}) => {

    if (loggedInUser) {
        return (<Redirect to={ROUTER_ACTIONS.PROJECTS_TEAMS}/>);
    } else {
        return (<Redirect to={ROUTER_ACTIONS.SIGNIN}/>);
    }

});

const mapStateToProps = state => {

    return {
        loggedInUser: state.auth.loggedInUser
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {}
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);

