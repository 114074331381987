import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ChecklistModal from '../../../utilities/CheckListModalProjectDefination/ChecklistModal'
import {useParams} from 'react-router';
import ChecklistTemplateModal
    from '../../../modules/configuration/components/Projects/components/TableAndForm/Components/AddEditForm/ChecklistAdd/ChecklistAdd';
// const filter = createFilterOptions();

const FreeSoloCreateOptionDialog = ({
                                        optionsList,
                                        selectedValue,
                                        setValue,
                                        setAllCheckList,
                                        setOpenAddCheckList,
                                        setIsChecklistNewCreating,
                                        project,
                                        forNameAttr
                                    }) => {
    const {id} = useParams()
    const {templateId} = useParams()
    const [open, toggleOpen] = useState(false);
    const [taskListCheckList, setTaskListCheckList] = useState(null)
    const [taskSelectedCheckList, setTaskSelectedCheckList] = useState(false);
    const [dialogValue, setDialogValue] = useState({
        title: '',
    });
    console.log(taskListCheckList)
    console.log(taskSelectedCheckList)
    return (
        <>
            <Autocomplete
                value={selectedValue}
                name={forNameAttr ? forNameAttr : ''}
                onChange={(event, newValue) => {
                    if (newValue && newValue.title === "Create checklist") {
                        setOpenAddCheckList(true);
                        if (project) {
                        } else {
                        }
                    } else if (newValue && newValue.title === "None") {
                        const fineNone = newValue.title === "None" ? true : false
                        if (fineNone) {
                            if (forNameAttr) {
                                setValue(forNameAttr, "None");
                            } else {
                                setValue("None");
                            }
                        }
                    } else if (typeof newValue === 'string') {
                        setTimeout(() => {
                            toggleOpen(true);
                            setIsChecklistNewCreating(true);
                            setDialogValue({
                                title: newValue,
                            });
                        });
                    } else if (newValue && newValue.inputValue) {
                        toggleOpen(true);
                        setIsChecklistNewCreating(true);
                        setDialogValue({
                            title: newValue.inputValue,
                        });
                    } else {
                        if (forNameAttr) {
                            setValue(forNameAttr, newValue);
                        } else {
                            setValue(newValue);
                        }
                    }
                }}
                options={optionsList}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.title;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(option) => option.title}
                freeSolo
                getOptionSelected={(option, value) =>
                    option && value ? option.id === value.id : ''
                }
                style={{width: 160}}
                renderInput={(params) => (
                    <TextField {...params} label="Select checklist"/>
                )}
            />
            {
                project
                    ?
                    <ChecklistModal nameAtt={forNameAttr} setIsChecklistNewCreating={setIsChecklistNewCreating}
                                    dialogValue={dialogValue} projectId={id} setAllCheckList={setAllCheckList}
                                    setSelectedChecklist={setValue} setTaskListCheckList={setTaskListCheckList}
                                    setTaskSelectedCheckList={setTaskSelectedCheckList} setOpenAddCheckList={toggleOpen}
                                    openAddCheckList={open}/>
                    :
                    <ChecklistTemplateModal setIsChecklistNewCreating={setIsChecklistNewCreating}
                                            dialogValue={dialogValue} projectId={templateId}
                                            setAllCheckList={setAllCheckList} setTaskSelectedCheckList={setValue}
                                            setTaskListCheckList={setTaskListCheckList} setOpenAddCheckList={toggleOpen}
                                            openAddCheckList={open}/>
            }
        </>
    );
};
export default FreeSoloCreateOptionDialog;
