// Export Constants (Action names)
export const GET_ALL_PROGRESS_TASKS = 'GET_ALL_PROGRESS_TASKS';


/**
 * Action called after get all task lists
 * @param tasks
 */
export const getAllProgressTasks = (tasks) => {
    return {
        type: GET_ALL_PROGRESS_TASKS,
        tasks: tasks
    };
};


