import {
    CREATE_PROJECT,
    GET_ALL_MILESTONE_LIST,
    GET_ALL_PARTICIPANTS,
    GET_ALL_PROJECT_OF_PROJECT_MEMBER_AND_TEAM_LEAD,
    GET_ALL_PROJECTS,
    GET_ALL_TASK_LISTS,
    GET_ALL_TASKS,
    GET_SINGLE_TASK_LIST,
    GET_SINGLE_TASK_DETAILS,
    ADD_TASKLIST,
    UPDATE_TASKLIST,
    DELETE_TASKLIST,
    ADD_TASK,
    UPDATE_TASK,
    DELETE_TASK,
    REORDER_TASK_ROW,
    MY_TASKS
} from './ProjectsActions';

const initialState = {
    projects: null,
    taskLists: null,
    milestoneList: null,
    tasks: null,
    participants: null,
    projectsOfTeamLeadAndProjectMember: null,
    singleTasklistDetails: null,
    singleTaskDetails: null,
};

/**
 * ProjectsReducer reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */

const ProjectsReducer = (state = initialState, action) => {

    switch (action.type) {

        //Get list of projects to be shown
        case GET_ALL_PROJECTS:
            return Object.assign({}, state, {
                projects: action.projects
            });

        //Get list of projects to be shown for project member and team lead
        case GET_ALL_PROJECT_OF_PROJECT_MEMBER_AND_TEAM_LEAD:
            return Object.assign({}, state, {
                projectsOfTeamLeadAndProjectMember: action.projectsOfTeamLeadAndProjectMember
            });

        //Append newly created project
        case CREATE_PROJECT:

            return Object.assign({}, state, {
                projects: [...state.projects.rows, action.project]
            });

        //Get list of taskLists to be shown
        case GET_ALL_TASK_LISTS:
            return Object.assign({}, state, {
                taskLists: action.taskLists
            });
        //Get list of milestone to be shown
        case GET_ALL_MILESTONE_LIST:
            return Object.assign({}, state, {
                milestoneList: action.milestoneList
            });
        //Get list of tasks to be shown
        case GET_ALL_TASKS:
            let tempAllTasks = Object.assign([], state.taskLists)
            if (action.typeOfTask === "forTasklistOpen") {
                const listForTasklistOpen = action.tasks.filter(one => {
                    one.isSubTask = true;
                    return true;
                });
                let indexForTasklistOpen = tempAllTasks.findIndex(one => one.id === action.rowId);
                listForTasklistOpen.forEach(one => {
                    tempAllTasks.splice(indexForTasklistOpen + 1, 0, one);
                    indexForTasklistOpen++;
                });
            }
            else if (action.typeOfTask === "forTasklistClose") {
                let indexforTasklistClose = tempAllTasks.findIndex(one => one.id === action.rowId);
                tempAllTasks.splice(indexforTasklistClose + 1, action.tasks.length);
            }
            else if (action.typeOfTask === "forReOrder") {
                action.tasks.push(action.from)
                const listForReOrder = action.tasks.filter(one => {
                    one.isSubTask = true;
                    return true;
                });
                let indexForReOrder = action.newtableData.findIndex(one => one.id === action.rowId);
                listForReOrder.forEach(one => {
                    action.newtableData.splice(indexForReOrder + 1, 0, one);
                    indexForReOrder++;
                });
                tempAllTasks = action.newtableData
            }
            else { }
            return Object.assign({}, state, {
                taskLists: tempAllTasks
            });

        //Get list of participants to be shown
        case GET_ALL_PARTICIPANTS:
            return Object.assign({}, state, {
                participants: action.participants
            });

        //Get single tasklist details
        case GET_SINGLE_TASK_LIST:
            return Object.assign({}, state, {
                singleTasklistDetails: action.singleTasklistDetails
            });

        //Get single task details
        case GET_SINGLE_TASK_DETAILS:
            return Object.assign({}, state, {
                singleTaskDetails: action.singleTaskDetails
            });

        // add tasklist
        case ADD_TASKLIST:
            let tempTasklists = Object.assign([], state.taskLists)
            tempTasklists.unshift(action.addedTasklist)
            return Object.assign({}, state, {
                taskLists: tempTasklists
            });

        // edit tasklist
        case UPDATE_TASKLIST:
            let tempUpdateTasklists = Object.assign([], state.taskLists)
            let findIndex = tempUpdateTasklists && tempUpdateTasklists.findIndex((tasklist) => tasklist.id === action.updatedTasklist.id)
            tempUpdateTasklists[findIndex] = action.updatedTasklist
            return Object.assign({}, state, {
                taskLists: tempUpdateTasklists
            });

        // delete tasklist
        case DELETE_TASKLIST:
            let tempDeleteTasklists = Object.assign([], state.taskLists)
            let findDeleteIndex = tempDeleteTasklists && tempDeleteTasklists.findIndex((tasklist) => tasklist.id === action.deletedTasklist.id)
            let findSubTasks = tempDeleteTasklists && tempDeleteTasklists.filter((task) => task.isSubTask === true);
            let tasklistTasks = findSubTasks && findSubTasks.filter((task) => task.tasklistId === action.deletedTasklist.id)
            let totalLength = 1 + tasklistTasks.length
            tempDeleteTasklists.splice(findDeleteIndex, totalLength)
            return Object.assign({}, state, {
                taskLists: tempDeleteTasklists
            });

        // add task
        case ADD_TASK:
            let tempAddTaskObjOfTasklist = Object.assign([], state.taskLists)
            let findIndexAddedTask = tempAddTaskObjOfTasklist && tempAddTaskObjOfTasklist.findIndex((task) => task.id === action.addedTask.tasklistId)
            tempAddTaskObjOfTasklist.splice(findIndexAddedTask + 1, 0, action.addedTask);
            return Object.assign({}, state, {
                taskLists: tempAddTaskObjOfTasklist
            });

        // update task
        case UPDATE_TASK:
            let tempUpdateTaskObjOfTasklist = Object.assign([], state.taskLists)
            let findIndexUpdatedTask = tempUpdateTaskObjOfTasklist && tempUpdateTaskObjOfTasklist.findIndex((task) => task.id === action.updatedTask.id)
            tempUpdateTaskObjOfTasklist[findIndexUpdatedTask] = action.updatedTask
            return Object.assign({}, state, {
                taskLists: tempUpdateTaskObjOfTasklist
            });

        // delete task
        case DELETE_TASK:
            let tempDeleteTaskObjOfTasklist = Object.assign([], state.taskLists)
            let findIndexForDeletedTask = tempDeleteTaskObjOfTasklist && tempDeleteTaskObjOfTasklist.findIndex((task) => task.id === action.deletedTask)
            tempDeleteTaskObjOfTasklist.splice(findIndexForDeletedTask, 1);
            return Object.assign({}, state, {
                taskLists: tempDeleteTaskObjOfTasklist
            });

        // reorder task row
        case REORDER_TASK_ROW:
            let forReorderTaskRowTasklistObj = Object.assign([], state.taskLists)
            forReorderTaskRowTasklistObj = action.reorderTaskData
            return Object.assign({}, state, {
                taskLists: forReorderTaskRowTasklistObj
            });

        // for showing only loggedinuser tasks
        case MY_TASKS:
            let loggedInUserTasks = action.myTasksData && action.myTasksData.map((taskInfo) => {
                if (taskInfo && taskInfo.assignee) {
                    return {
                        ...taskInfo, assignee: taskInfo.assignee.map((asigneeInfo) => {
                            return {
                                ...asigneeInfo, users: asigneeInfo.users.filter((userInfo) => {
                                    return userInfo.id === action.loggedInUserId
                                })
                            }
                        })
                    }
                } else {
                }
            })
            let arr1 = loggedInUserTasks.filter((task => task != undefined))
            let arr2 = arr1 && arr1.map((taskdata) => {
                return {
                    ...taskdata, assignee: taskdata.assignee.filter((assigneeData) => {
                        return assigneeData.users.length > 0
                    })
                }
            })
            let arr3 = arr2 && arr2.filter((finalTask) => {
                return finalTask.assignee.length > 0
            })
            const list = arr3.filter(one => {
                one.isSubTask = true;
                return true;
            });
            return Object.assign({}, state, {
                taskLists: list
            });

        default:
            return state;

    }
};

export default ProjectsReducer;
