// Export Constants (Action names)
export const ADD_TASK_LIST = 'ADD_TASK_LIST';
export const GET_ALL_TEMPLATES = 'GET_ALL_TEMPLATES';
export const GET_ALL_VENDORS = 'GET_ALL_VENDORS';
export const GET_SINGLE_VENDOR_DETAIL = 'GET_SINGLE_VENDOR_DETAIL';
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_ALL_TEAMS = 'GET_ALL_TEAMS';
export const GET_CONFIG_ALL_TASKS = 'GET_CONFIG_ALL_TASKS';
export const GET_CONFIG_ALL_TASKLIST = 'GET_CONFIG_ALL_TASKLIST';

/**
 * Action called after get single post
 * @param post
 */
export const addTaskList = (taskList) => {
    return {
        type: ADD_TASK_LIST,
        taskList: taskList
    };
};

/**
 * Action called after get all templates
 * @param templates
 */
export const getAllTemplates = (templates) => {
    return {
        type: GET_ALL_TEMPLATES,
        templates: templates
    };
};

/**
 * Action called after get all vendors
 * @param allVendors
 */
 export const getAllVendors = (allVendors) => {
    return {
        type: GET_ALL_VENDORS,
        allVendors: allVendors
    };
};

/**
 * Action called after get single vendor details
 * @param allVendors
 */
 export const getSingleVendorDetails = (singleVendorRes) => {
    return {
        type: GET_SINGLE_VENDOR_DETAIL,
        singleVendorRes: singleVendorRes
    };
};

/**
 * Action called after get all clients
 * @param allClients
 */
 export const getAllClients = (allClients) => {
    return {
        type: GET_ALL_CLIENTS,
        allClients: allClients
    };
};

/**
 * Action called after get all teams
 * @param allTeams
 */
 export const getAllTeams = (allTeams) => {
    return {
        type: GET_ALL_TEAMS,
        allTeams: allTeams
    };
};


/**
 * Action called after get all config tasks
 * @param configAllTasks
 */
 export const getAllConfigTasks = (configAllTasks) => {
    return {
        type: GET_CONFIG_ALL_TASKS,
        configAllTasks: configAllTasks
    };
};

/**
 * Action called after get all config taskslist
 * @param configAllTasklist
 */
 export const getAllConfigTaskslist = (configAllTasklist) => {
    return {
        type: GET_CONFIG_ALL_TASKLIST,
        configAllTasklist: configAllTasklist
    };
};

