import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    timelineActionContainer: {
        margin: '0.5rem',
        marginLeft: '1rem',
    },
    timelineRoot: {
        padding: '6px 0px',
        paddingLeft: '5px',
    },
}));
