import React, {useEffect, useRef, useState} from 'react';
import './EditProject.css';
import {connect} from 'react-redux';
import {Card, FormHelperText, Grid} from '@material-ui/core';
import AppHeader from '../../../userDashboard/components/AppHeader/AppHeader';
import EditProjectHeader from './components/EditProjectHeader/EditProjectHeader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {editProjectRequest, getAllProjectTypesRequest} from '../../ProjectsApiActions';
import {getAllClientsRequset, getAllTeamsRequset} from '../../../configuration/ConfigurationApiActions';
import {ROUTER_ACTIONS} from '../../../../utilities/Constants';
import MessageModal from '../../../../utilities/MessageModal/MessageModal'
import CustomTheme from '../../../../utilities/theme/theme.json'
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';

const EditProject = (({
                          teams,
                          history,
                          match,
                          handleDrawerOpenClose,
                          getAllProjectTypes,
                          getAllTeams,
                          getAllClients,
                          getAllTaskLists,
                          editProject
                      }) => {

    const modalRef = useRef();

    const projectId = match.params.id;

    const [projectTypes, setProjectTypes] = useState(null);

    const [teamList, setTeamList] = useState(null);
    const toggleOptions = [{title: 'Yes'}, {title: 'No'}];
    const [clientList, setClientList] = useState(null);
    // const [billable, setBillable] = useState(toggleOptions[0]);

    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState('');

    const [nameError, setNameError] = useState('');
    const [projectTypeError, setProjectTypeError] = useState('');
    const [teamError, setTeamError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [areaInSqftError,setAreaInSqftError] = useState('')
    const [editProjectFormData, setEditProjectFormData] = useState({
        name: null,
        projectTypes: null,
        team: null,
        ais: null,
        description: null,
        projectObjective: null,
        forClient: toggleOptions[1],
        client: null,
        billable: toggleOptions[0],
        enableEstimates: toggleOptions[0],
        startDate: null,
        endDate: null
    })


    useEffect(() => {
        let projectDetailsObj = [];
        let editFormData = {
            name: null,
            projectTypes: null,
            team: null,
            ais: null,
            description: null,
            projectObjective: null,
            forClient: toggleOptions[1],
            client: null,
            billable: toggleOptions[0],
            enableEstimates: toggleOptions[0],
            startDate: null,
            endDate: null
        }
        if (teams) {
            let teamDetails = teams && teams.map((teamInfo) => {
                return {
                    projects: teamInfo.projects.filter((projectInfo) => {
                        return projectInfo.id === projectId
                    })
                }
            })
            teamDetails.map((project) => {
                return project.projects.map((data) => {
                    projectDetailsObj.push(data)
                    return data
                })
            })
            editFormData.name = projectDetailsObj[0].name
            getAllProjectTypes().then((res) => {
                let typeArray = res && res.find((type) => type.id === projectDetailsObj[0].type)
                editFormData.projectTypes = typeArray
                setProjectTypes(res);
            })
            getAllTeams().then((teamRes) => {
                let teamArray = teamRes && teamRes.find((team) => team.id === projectDetailsObj[0].teamId)
                editFormData.team = teamArray
                setTeamList(teamRes);
            })
            getAllClients().then((res) => {
                setClientList(res);
                let clientArray = res && res.find((client) => client.name === projectDetailsObj[0].client)
                if (clientArray) {
                    editFormData.client = clientArray
                    editFormData.forClient = toggleOptions && toggleOptions.find((option) => {
                        return clientArray != null ? option.title === "Yes" : option.title === "No"
                    })
                }
            })
            editFormData.ais = projectDetailsObj[0].metaInfo
            editFormData.description = projectDetailsObj[0].description
            editFormData.projectObjective = projectDetailsObj[0].objective
            editFormData.startDate = projectDetailsObj[0].startDate
            editFormData.endDate = projectDetailsObj[0].endDate
            editFormData.billable = toggleOptions && toggleOptions.find((option) => {
                return projectDetailsObj[0].isBillable ? option.title === "Yes" : option.title === "No"
            })
            editFormData.enableEstimates = toggleOptions && toggleOptions.find((option) => {
                return projectDetailsObj[0].enableEstimates ? option.title === "Yes" : option.title === "No"
            })
            setEditProjectFormData(editFormData)
        }
    }, [projectId])

    const saveHandler = () => {

        let formData = {
            name: editProjectFormData.name,
            type: editProjectFormData.projectTypes ? editProjectFormData.projectTypes.id : '',
            teamId: editProjectFormData.team ? editProjectFormData.team.id : '',
            description: editProjectFormData.description,
            isBillable: editProjectFormData.billable.title === "Yes",
            enableEstimates: editProjectFormData && editProjectFormData.enableEstimates && editProjectFormData.enableEstimates.title === "Yes" ? editProjectFormData.enableEstimates.title === "Yes" : false,
            startDate: editProjectFormData.startDate,
            endDate: editProjectFormData.endDate,
        }
        if (editProjectFormData.projectObjective) {
            formData.objective = editProjectFormData.projectObjective
        } else {
        }
        if (editProjectFormData.ais) {
            formData.metaInfo = editProjectFormData.ais
        } else {
        }
        if (editProjectFormData.forClient.title === "Yes" || editProjectFormData.forClient.title === "No" && editProjectFormData.client && editProjectFormData.client.name ) {
            formData.client = editProjectFormData.client.name 
        } else {
        }

        if (
            editProjectFormData.name === '' ||
            editProjectFormData.description === '' ||
            editProjectFormData.projectTypes === null ||
            editProjectFormData.team === null ||
            editProjectFormData.startDate === null ||
            editProjectFormData.endDate === null ||
            editProjectFormData.ais < 0
        ) {
            if (editProjectFormData.name === '') {
                setNameError('Name is required');
            }
            if (editProjectFormData.description === '') {
                setDescriptionError('Description is required');
            }
            if (editProjectFormData.projectTypes === null) {
                setProjectTypeError('Project yype is required');
            }
            if (editProjectFormData.team === null) {
                setTeamError('Team is required');
            }
            if (editProjectFormData.startDate === null) {
                setStartDateError('Start date is required');
            }
            if (editProjectFormData.endDate === null) {
                setEndDateError('Target end date is required');
            }
            if (editProjectFormData.ais < 0) {
                setAreaInSqftError('Area in sqft must positive number');
            }
            // if (editProjectFormData.forClient.title === 'Yes' && editProjectFormData.client === null) {
            //   setClientError('Client is required');
            // }
            return false;
        } else {
            editProject(formData, projectId).then((res) => {
                history.push(`${ROUTER_ACTIONS.PROJECTS_TEAMS}`)
                setEditProjectFormData({
                    name: null,
                    projectTypes: null,
                    team: null,
                    ais: null,
                    description: null,
                    projectObjective: null,
                    forClient: toggleOptions[1],
                    client: null,
                    billable: toggleOptions[0],
                    enableEstimates: toggleOptions[0],
                    startDate: null,
                    endDate: null
                })
            })
        }

    }

    const handleChangeInput = (event, value) => {
        const data = Object.assign({}, editProjectFormData);
        data[event.target.name] = event.target.value;
        setEditProjectFormData(data);
    };

    /**
     * Method executes on onChange action of autocomplete form element
     */
    const handleChangeAutocomplete = (elementName, selectedvalue) => {
        let data = Object.assign({}, editProjectFormData);
        if (elementName === "projectTypes") {
            data.projectTypes = selectedvalue
        } else {
        }
        if (elementName === "team") {
            data.team = selectedvalue
        } else {
        }
        if (elementName === "forClient") {
            data.forClient = selectedvalue
        } else {
        }
        if (elementName === "client") {
            data.client = selectedvalue
        } else {
        }
        if (elementName === "billable") {
            data.billable = selectedvalue
        } else {
        }
        if (elementName === "enableEstimates") {
            data.enableEstimates = selectedvalue
        } else {
        }
        if (elementName === "startDate") {
            data.startDate = selectedvalue
        } else {
        }
        if (elementName === "endDate") {
            data.endDate = selectedvalue
        } else {
        }
        setEditProjectFormData(data);
    }

    return (
        <>
            <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                <EditProjectHeader match={match} history={history}/>
            </AppHeader>
            <ValidatorForm
                onSubmit={saveHandler}
                noValidate
                onError={errors => console.log(errors)}
            >
                <Card className="edit-project-card">
                    <Grid style={{borderBottom: `1px dashed ${CustomTheme.primaryColor}`}}
                          className="edit-project-header-actions" item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container justify="space-between">
                            <Grid style={{color: CustomTheme.primaryColor}} className="edit-project-icons back-icon"
                                  onClick={() => history.goBack()}>
                                <i className="far fa-arrow-alt-circle-left"></i>
                            </Grid>
                            <Grid>
                                <Grid container>
                                    <Grid onClick={() => history.goBack()} style={{color: CustomTheme.errorColor}}
                                          className="edit-project-icons cancel-icon">
                                        <i class="far fa-times-circle"></i>
                                    </Grid>
                                    <Grid
                                        className="edit-project-icons save-icon"
                                    >
                                        <Button size="small"
                                                type="submit"
                                                style={{color: CustomTheme.primaryColor}}
                                                className="form-panel-save-btn submit-btn"
                                        >
                                            <i className="far fa-save"></i>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="edit-project-inputs" item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <TextValidator
                                    fullWidth
                                    label='Name'
                                    name="name"
                                    value={editProjectFormData.name}
                                    onChange={handleChangeInput}
                                />
                                {
                                    <FormHelperText className="edit-project-errorText">
                                        {nameError}
                                    </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <Autocomplete
                                    fullWidth
                                    name="projectTypes"
                                    value={editProjectFormData.projectTypes}
                                    onChange={(e, value) => {
                                        handleChangeAutocomplete("projectTypes", value)
                                    }}
                                    options={projectTypes || []}
                                    getOptionLabel={option => option.type}
                                    getOptionSelected={(option, value) =>
                                        option.id === value.id
                                    }
                                    renderInput={params => (
                                        <TextValidator {...params} label='Project Type'/>
                                    )}
                                />
                                {
                                    <FormHelperText className="edit-project-errorText">
                                        {projectTypeError}
                                    </FormHelperText>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="edit-project-inputs" item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <Autocomplete
                                    fullWidth
                                    name="team"
                                    value={editProjectFormData.team}
                                    onChange={(e, value) => {
                                        const title = "Edit Project"
                                        const message = `Please note that changing the team will result in the loss of all role assignment to the project tasks, time logs, and participant-related history. Do you really want to continue and change the team?`
                                        modalRef.current.setState({
                                            showModal: true,
                                            message: message,
                                            title: title,
                                            showOkButton: true,
                                            showCancelButton: true,
                                            showNote: false,
                                            note: '',
                                            handleOk: (note) => {
                                                handleChangeAutocomplete("team", value)
                                            },
                                            okText: "Yes",
                                            cancelText: 'No'
                                        });
                                    }}
                                    options={teamList || []}
                                    getOptionLabel={option => option ? option.name : ""}
                                    getOptionSelected={(option, value) =>
                                        option ? option.name === value.name : ""
                                    }
                                    renderInput={params => (
                                        <TextValidator {...params} label='Select Team'/>
                                    )}
                                />
                                {
                                    <FormHelperText className="edit-project-errorText">
                                        {teamError}
                                    </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <TextValidator
                                    fullWidth
                                    label='Area in sqft'
                                    type="number"
                                    name="ais"
                                    value={editProjectFormData.ais}
                                    onChange={handleChangeInput}
                                />
                                {
                                    <FormHelperText className="edit-project-errorText">
                                        {areaInSqftError}
                                    </FormHelperText>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="edit-project-inputs" item xs={12} sm={12} md={10} lg={10} xl={10}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextValidator
                                    fullWidth
                                    label='Description'
                                    name="description"
                                    value={editProjectFormData.description}
                                    onChange={handleChangeInput}
                                />
                                {
                                    <FormHelperText className="edit-project-errorText">
                                        {descriptionError}
                                    </FormHelperText>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="edit-project-inputs" item xs={12} sm={12} md={10} lg={10} xl={10}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextValidator
                                    fullWidth
                                    label='Project Objective'
                                    name="projectObjective"
                                    value={editProjectFormData.projectObjective}
                                    onChange={handleChangeInput}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        className="edit-project-inputs"
                        item xs={12} sm={12}
                        md={editProjectFormData && editProjectFormData.forClient && editProjectFormData.forClient.title && editProjectFormData.forClient.title === 'Yes' ? 12 : 10}
                        lg={editProjectFormData && editProjectFormData.forClient && editProjectFormData.forClient.title && editProjectFormData.forClient.title === 'Yes' ? 12 : 10}
                        xl={editProjectFormData && editProjectFormData.forClient && editProjectFormData.forClient.title && editProjectFormData.forClient.title === 'Yes' ? 12 : 10}
                    >
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={editProjectFormData && editProjectFormData.forClient && editProjectFormData.forClient.title === 'Yes' ? 5 : 12}
                                lg={editProjectFormData && editProjectFormData.forClient && editProjectFormData.forClient.title === 'Yes' ? 5 : 12}
                                xl={editProjectFormData && editProjectFormData.forClient && editProjectFormData.forClient.title === 'Yes' ? 5 : 12}
                            >
                                <Autocomplete
                                    fullWidth
                                    name="forClient"
                                    value={editProjectFormData && editProjectFormData.forClient ? editProjectFormData.forClient : ''}
                                    onChange={(e, value) => {
                                        if (editProjectFormData && editProjectFormData.forClient && editProjectFormData.forClient.title && editProjectFormData.forClient.title === 'Yes') {
                                            const title = "Edit Project"
                                            const message = `Please note that changing the client association will result in the loss of all client-related assignments to the project tasks, time logs. Do you really want to continue with the change?`
                                            modalRef.current.setState({
                                                showModal: true,
                                                message: message,
                                                title: title,
                                                showOkButton: true,
                                                showCancelButton: true,
                                                showNote: false,
                                                note: '',
                                                handleOk: (note) => {
                                                    handleChangeAutocomplete("forClient", value)
                                                    if (value && value.title === "No") {
                                                        setEditProjectFormData({
                                                            ...editProjectFormData,
                                                            client: "",
                                                            forClient: value
                                                        })
                                                    }
                                                },
                                                okText: "Yes",
                                                cancelText: 'No'
                                            });
                                        } else {
                                            handleChangeAutocomplete("forClient", value)
                                            if (value && value.title === "No") {
                                                setEditProjectFormData({
                                                    ...editProjectFormData,
                                                    client: "",
                                                    forClient: value
                                                })
                                            }
                                        }
                                    }}
                                    options={toggleOptions}
                                    getOptionLabel={option => option.title}
                                    getOptionSelected={(option, value) =>
                                        option.title === value.title
                                    }
                                    renderInput={params => (
                                        <TextValidator
                                            {...params}
                                            label='Is this project for a client?'
                                        />
                                    )}
                                />
                            </Grid>
                            {editProjectFormData && editProjectFormData.forClient && editProjectFormData.forClient.title === 'Yes' && (
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                    <Autocomplete
                                        fullWidth
                                        name="client"
                                        value={editProjectFormData.client}
                                        onChange={(e, value) => {
                                            const title = "Edit Project"
                                            const message = `Please note that changing the client will result in the loss of all role assignment to the project tasks, time logs, and client-related history. Do you really want to continue and change the client?`
                                            modalRef.current.setState({
                                                showModal: true,
                                                message: message,
                                                title: title,
                                                showOkButton: true,
                                                showCancelButton: true,
                                                showNote: false,
                                                note: '',
                                                handleOk: (note) => {
                                                    handleChangeAutocomplete("client", value)
                                                },
                                                okText: "Yes",
                                                cancelText: 'No'
                                            });
                                        }}
                                        options={clientList}
                                        getOptionLabel={option => option.name}
                                        getOptionSelected={(option, value) =>
                                            option.name === value.name
                                        }
                                        renderInput={params => (
                                            <TextValidator {...params}
                                                           label='Select Client'
                                                           value={editProjectFormData.client}
                                                           validators={['required']}
                                                           errorMessages={['This field is required']}
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid className="edit-project-inputs" item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <Autocomplete
                                    fullWidth
                                    name="billable"
                                    value={editProjectFormData.billable}
                                    onChange={(e, value) => {
                                        if (editProjectFormData && editProjectFormData.billable && editProjectFormData.billable.title && editProjectFormData.billable.title === 'Yes') {
                                            const title = "Edit Project"
                                            const message = `Please note that all non-billable time logs will be marked as billable as a result of this action. Do you really want to continue with the change?`
                                            modalRef.current.setState({
                                                showModal: true,
                                                message: message,
                                                title: title,
                                                showOkButton: true,
                                                showCancelButton: true,
                                                showNote: false,
                                                note: '',
                                                handleOk: (note) => {
                                                    handleChangeAutocomplete("billable", value)
                                                },
                                                okText: "Yes",
                                                cancelText: 'No'
                                            });
                                        } else {
                                            handleChangeAutocomplete("billable", value)
                                        }
                                    }}
                                    options={toggleOptions}
                                    getOptionLabel={option => option.title}
                                    getOptionSelected={(option, value) =>
                                        option.title === value.title
                                    }
                                    renderInput={params => (
                                        <TextValidator {...params} label='Is this project billable?'/>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <Autocomplete
                                    fullWidth
                                    name="enableEstimates"
                                    value={editProjectFormData.enableEstimates}
                                    onChange={(e, value) => handleChangeAutocomplete("enableEstimates", value)}
                                    options={toggleOptions}
                                    getOptionLabel={option => option.title}
                                    getOptionSelected={(option, value) =>
                                        option.title === value.title
                                    }
                                    renderInput={params => (
                                        <TextValidator
                                            {...params}
                                            label='Do you want to enable estimates?'
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="edit-project-inputs" item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        fullWidth
                                        name="startDate"
                                        label='Start Date'
                                        format='dd/MM/yyyy'
                                        value={editProjectFormData.startDate}
                                        onChange={date => {
                                            handleChangeAutocomplete("startDate", date);
                                            setStartDateError('');
                                        }}
                                    />
                                    {
                                        <FormHelperText className="edit-project-errorText">
                                            {startDateError}
                                        </FormHelperText>
                                    }
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        fullWidth
                                        name="endDate"
                                        minDate={editProjectFormData.startDate}
                                        label='Target End Date'
                                        format='dd/MM/yyyy'
                                        value={editProjectFormData.endDate}
                                        onChange={date => {
                                            handleChangeAutocomplete("endDate", date);
                                            setStartDateError('');
                                        }}
                                    />
                                    {
                                        <FormHelperText className="edit-project-errorText">
                                            {endDateError}
                                        </FormHelperText>
                                    }
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{borderTop: `1px dashed ${CustomTheme.primaryColor}`}}
                          className="edit-project-footer-actions" item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container justify="space-between">
                            <Grid className="edit-project-icons back-icon">

                            </Grid>
                            <Grid>
                                <Grid container>
                                    <Grid onClick={() => history.goBack()} style={{color: CustomTheme.errorColor}}
                                          className="edit-project-icons cancel-icon">
                                        <i class="far fa-times-circle"></i>
                                    </Grid>
                                    <Grid
                                        className="edit-project-icons save-icon"
                                    >
                                        <Button size="small"
                                                type="submit"
                                                style={{color: CustomTheme.primaryColor}}
                                                className="form-panel-save-btn submit-btn"
                                        >
                                            <i className="far fa-save"></i>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </ValidatorForm>
            <MessageModal
                ref={modalRef}/>
        </>
    )
})

const mapStateToProps = (state) => {
    let teamDetails = state.projects.projects && state.projects.projects.map((teamInfo) => {
        return {
            ...teamInfo, projects: teamInfo.projects.filter((projectInfo) => {
                return state.projects.projectsOfTeamLeadAndProjectMember && state.projects.projectsOfTeamLeadAndProjectMember.find((id) => {
                    return projectInfo.id === id
                })
            })
        }
    })
    return {
        teams: state.auth.loggedInUser.userRoles.isSystemSuperAdmin ? state.projects.projects : teamDetails,
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllProjectTypes: (taskId) => {
            return dispatch(getAllProjectTypesRequest(taskId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllTeams: () => {
            return dispatch(getAllTeamsRequset()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllClients: () => {
            return dispatch(getAllClientsRequset()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        editProject: (formData, projectId) => {
            return dispatch(editProjectRequest(formData, projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProject);
