import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import {I18n} from 'react-redux-i18n';

/**
 * User table component which shows all user data
 */
class MessageModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            message: '',
            title: '',
            showOkButton: false,
            showCancelButton: this.props.showCancelButton ? this.props.showCancelButton : false,
            handleOk: null,
            handleCancel: null,
            okText: 'OK',
            cancelText: 'Cancel',
            showNote: false,
            note: ''
        }
    }

    closeModal = () => {
        this.setState({showModal: false});
    };

    render() {

        return (
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={this.state.showModal}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{this.state.title}</DialogTitle>
                <DialogContent className="message-modal-content-container">
                    <div>{this.state.message}</div>
                    {this.state.showNote && <div className="message-modal-note">
                        <TextField
                            fullWidth
                            size="small"
                            label={I18n.t('global.message_modal_note_field_label')}
                            onChange={(event) => this.setState({note: event.target.value})}
                            multiline
                            rows={2}
                            rowsMax={5}
                            name="name" value={this.state.note || ''}
                        />
                    </div>}
                </DialogContent>
                <DialogActions className="message-modal-actions-container">
                    {
                        this.state.showCancelButton
                            ? (
                                <Button
                                    onClick={() => {
                                        this.setState({showModal: false}, () => {
                                            if (this.state.handleCancel !== null)
                                                this.state.handleCancel();
                                        });
                                    }}
                                    size="small"
                                    color="primary"
                                    variant="outlined">
                                    {this.state.cancelText}
                                </Button>
                            )
                            : <></>
                    }
                    <Button
                        onClick={() => {
                            this.setState({showModal: false}, () => {
                                if (this.state.handleOk !== null)
                                    this.state.handleOk(this.state.note);
                            });
                        }}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        autoFocus>
                        {this.state.okText}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

}

export default MessageModal;
