import { 
    ADD_TASK_LIST, 
    GET_ALL_TEMPLATES, 
    GET_ALL_VENDORS, 
    GET_SINGLE_VENDOR_DETAIL, 
    GET_ALL_CLIENTS, 
    GET_ALL_TEAMS,
    GET_CONFIG_ALL_TASKS,
    GET_CONFIG_ALL_TASKLIST
} from './ConfigurationActions';

const initialState = {
    tasklist: [],
    checklist: [],
    templates: [],
    vendors: [],
    singleVendorDetails: null,
    clients: [],
    teams: [],
    configTasks:[],
    configTasklist:[]
};

/**
 * PageDetailsReducer reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */

const ConfigurationReducer = (state = initialState, action) => {

    switch (action.type) {

        //Get list of post to be shown
        case ADD_TASK_LIST:
            return Object.assign({}, state, {
                tasklist: action.taskList
            });

        //Get all templates to be shown
        case GET_ALL_TEMPLATES:
            return Object.assign({}, state, {
                templates: action.templates
            });

        //Get all vendors to be shown
        case GET_ALL_VENDORS:
            return Object.assign({}, state, {
                vendors: action.allVendors
            });

        //Get single vendor details to be shown
        case GET_SINGLE_VENDOR_DETAIL:
            return Object.assign({}, state, {
                singleVendorDetails: action.singleVendorRes
            });

        //Get all clients to be shown
        case GET_ALL_CLIENTS:
            return Object.assign({}, state, {
                clients: action.allClients
            });

        //Get all teams to be shown
        case GET_ALL_TEAMS:
            return Object.assign({}, state, {
                teams: action.allTeams
            });

        //Get all config tasks to be shown
        case GET_CONFIG_ALL_TASKS:
            return Object.assign({}, state, {
                configTasks: action.configAllTasks
            });

        //Get all config tasklist to be shown
        case GET_CONFIG_ALL_TASKLIST:
            return Object.assign({}, state, {
                configTasklist: action.configAllTasklist
            });

        default:
            return state;

    }
};

export default ConfigurationReducer;
