import React from 'react';
// import { makeStyles } from '@material-ui/core';

// const useStyles = makeStyles({
//     root: {
//       minWidth: 275,
//     },
//     bullet: {
//       display: 'inline-block',
//       margin: '0 2px',
//       transform: 'scale(0.8)',
//     },
//     title: {
//       fontSize: 14,
//     },
//     pos: {
//       marginBottom: 12,
//     },
//   });

const StepThree = ({history}) => {
    // const classes = useStyles();
    return (
        <h1>Step Three</h1>
    )
};
export default StepThree;
