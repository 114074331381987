import HttpRequest from '../../utilities/HttpRequest';

import {API_ACTIONS, SNACKBAR_VARIAINT} from '../../utilities/Constants';
import {showSnackbar} from '../../utilities/AppLoader/AppLoaderActions'

import {
    addTaskList, 
    getAllTemplates, 
    getAllVendors, 
    getSingleVendorDetails, 
    getAllClients, 
    getAllTeams, 
    getAllConfigTasks,
    getAllConfigTaskslist
} from "./ConfigurationActions";


/**
 * Server API call to get add task list
 * @returns {function(*)}
 */
export const addTaskListRequest = (data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.ADD_TASK_LIST}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(addTaskList(res));
                dispatch(showSnackbar("Tasklist added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all checklist
 * @returns {function(*)}
 */
export const getAllChecklist = () => {

    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_ALL_CHECKLIST}`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all checklist in templates
 * @returns {function(*)}
 */
export const getAllChecklistFromTemplates = (projectId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_ALL_CHECKLIST_IN_TEMPLATES}/${projectId}/checklist`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add CheckList
 * @returns {function(*)}
 */
export const addChecklistRequest = (data) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.ADD_CHECKLIST_IN_TASK_TEMPLATE}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Checklist added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                dispatch(showSnackbar("At least 1 item is necessary", SNACKBAR_VARIAINT.ERROR));
                return Promise.reject(error);
            });
    };
};


/**
 * Server API call to add CheckList in templates
 * @returns {function(*)}
 */
export const addChecklistInTemplatesRequest = (data) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.ADD_CHECKLIST_IN_TEMPLATES}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Checklist added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all task list
 * - Dispatch addChecklistRequest action after successful response from server
 * @returns {function(*)}
 */
export const getAllTaskListRequest = () => {
    return (dispatch) => {
        const url = `${API_ACTIONS.GET_ALL_TASK_LIST}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                dispatch(getAllConfigTaskslist(res.data))
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get predecessor of tasklist
 * @returns {function(*)}
 */
export const getPredecessorRequest = (tasklListId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.GET_ALL_TASKS}/${tasklListId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get functional roles
 * @returns {function(*)}
 */
export const getFunctionalRoleRequest = () => {
    return (dispatch) => {
        const url = `${API_ACTIONS.GET_FUNCTIONAL_ROLE}`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to Add Task
 * @returns {function(*)}
 */
export const addTaskRequest = (data) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.ADD_TASK_IN_TASK_LIST}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Task added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get   Task of Task List
 * @returns {function(*)}
 */
export const getTaskOfTaskListRequest = (tasklListId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.GET_ALL_TASKS}/${tasklListId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                dispatch(getAllConfigTasks(res.data))
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};


/**
 * Server API call to add functional roles
 * @returns {function(*)}
 */
export const addFunctionalRoleRequest = (data) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.PROJECT_ROLE_ADD}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Role added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get  single checklist config
 * @returns {function(*)}
 */
export const getSingleCheckListRequest = (checklistId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_SINGLE_CHECKLIST}/${checklistId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add items in checklist
 * @returns {function(*)}
 */
export const addItemsInChecklist = (checklistId, data) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.ADD_ITEMS_IN_CHECKLIST}/${checklistId}`;
        return new HttpRequest(dispatch, url, 'patch', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Items added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all project template
 * @returns {function(*)}
 */
export const getAllProjectTemplatesRequest = () => {
    return (dispatch) => {
        const url = `${API_ACTIONS.GET_ALL_PROJECT_TEMPLATES}`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                dispatch(getAllTemplates(res.data))
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add project template
 * @returns {function(*)}
 */
export const addProjectTemplateRequest = (data) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.ADD_PROJECT_TEMPLATE}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Template added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to edit project template
 * @returns {function(*)}
 */
export const editProjectTemplateRequest = (templateId, data) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.EDIT_PROJECT_TEMPLATE}/${templateId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Template updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all milestones template
 * @returns {function(*)}
 */
export const getAllMilestonesRequest = (projectId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.GET_ALL_MILESTONES}/${projectId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add milestone template
 * @returns {function(*)}
 */
export const addMilestoneTemplateRequest = (data) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.ADD_MILESTONE_TEMPLATE}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Milestone added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to edit milestone template
 * @returns {function(*)}
 */
export const editMilestoneTemplateRequest = (data, milestoneId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.EDIT_MILESTONE_TEMPLATE}/${milestoneId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Milestone updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add tasklist template
 * @returns {function(*)}
 */
export const addTasklistTemplateRequest = (data) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.ADD_TASKLIST_TEMPLATE}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Tasklist added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to fetch task lists of project template
 * @returns {function(*)}
 */
export const getTaskListOfProjectTemplateRequest = (projectId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_ALL_TASKSLIST_OF_PROJECT_TEMPLATE}?projectId=${projectId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to edit task lists of project template
 * @returns {function(*)}
 */
export const editTaskListOfProjectTemplateRequest = (data, tasklistId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.EDIT_TASKLIST_TEMPLATE}/${tasklistId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Tasklist updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to fetch task under task list of project template
 * @returns {function(*)}
 */
export const getTasksUnderTaskListProjectTemplateRequest = (tasklistId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_TASK_UNDER_TASKLIST_TEMPLATE}/${tasklistId}/tasks`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to fetch prdecessor list of project template
 * @returns {function(*)}
 */
export const getPrdecessorListOfTemplateRequest = (templateId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_PREDECESSOR_LIST}/${templateId}/child-tasks`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add task under tasklist
 * @returns {function(*)}
 */
export const addTaskUnderTasklistTemplateRequest = (data) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.ADD_TASK_UNDER_TASKLIST_TEMPLATE}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Task added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to edit task under tasklist
 * @returns {function(*)}
 */
export const editTaskUnderTasklistTemplateRequest = (data, taskId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.EDIT_TASK_UNDER_TASKLIST_TEMPLATE}/${taskId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Task updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get project types
 * @returns {function(*)}
 */
export const getProjectTypeRequest = () => {
    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_PROJECT_TYPES}`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all tags
 * @returns {function(*)}
 */
export const getAllTagsRequset = (name, searchTag) => {
    return (dispatch) => {
        let url;
        if (searchTag) {
            url = `${API_ACTIONS.FETCH_TAGS}?name=${name}`;
        } else {
            url = `${API_ACTIONS.FETCH_TAGS}`;
        }
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all teams
 * @returns {function(*)}
 */
export const getAllTeamsRequset = () => {
    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_TEAMS}`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                dispatch(getAllTeams(res.data))
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add Teams Request
 * @returns {function(*)}
 */
export const addTeamsRequest = (data) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.CREATE_TEAM}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Team added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to edit Teams Request
 * @returns {function(*)}
 */
export const editTeamsRequest = (data, teamId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.EDIT_TEAMS}/${teamId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Team updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all clients
 * @returns {function(*)}
 */
export const getAllClientsRequset = () => {
    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_CLIENTS}`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                dispatch(getAllClients(res.data))
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add client Request
 * @returns {function(*)}
 */
export const addClientRequest = (data) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.CREATE_CLIENT}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Client added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to edit Client Request
 * @returns {function(*)}
 */
export const editClientRequest = (data, clientId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.UPDATE_CLIENT}/${clientId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Client updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all vendors
 * @returns {function(*)}
 */
export const getAllVendorRequset = () => {
    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_VENDORS}`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                dispatch(getAllVendors(res.data))
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add vendor Request
 * @returns {function(*)}
 */
export const addVendorRequest = (data) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.CREATE_VENDOR}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Vendor added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to edit Vendor Request
 * @returns {function(*)}
 */
export const editVendorRequest = (data, vendorId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.UPADTE_VENDORS}/${vendorId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Vendor updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all tasklist
 * @returns {function(*)}
 */
export const getAllTasklistOfTemplate = (templateId, forHideLoader) => {
    return (dispatch) => {
        let url = `${API_ACTIONS.FETCH_MILESTONE_TASKLIST_OF_TEMPLATES}/${templateId}/tasklists`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, forHideLoader)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all  un associated tasklist of template
 * @returns {function(*)}
 */
export const getUnAssociatedTasklistForTemplate = (templateId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.UNASSOCIATED_TASKLIST_OF_TEMPLATE}/tasklists?projectId=${templateId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all  mailstones
 * @returns {function(*)}
 */
export const getAllMilestoneOfTemplate = (templateId, forHideLoader) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_MILESTONE_TASKLIST_OF_TEMPLATES}/${templateId}/milestones`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, forHideLoader)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get milestone details
 * @returns {function(*)}
 */
export const getMilestoneDetails = (milestoneId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.MILESTONE_DETAILS}/${milestoneId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get tasklist details
 * @returns {function(*)}
 */
export const getTasklistDetailsRequest = (tasklistId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.GET_TASKLIST_DETAILS}/${tasklistId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get task details
 * @returns {function(*)}
 */
export const getTaskDetailsRequest = (taskId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.GET_TASK_DETAILS}/${taskId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get tasklist details in tasklist config.
 * @returns {function(*)}
 */
export const getTasklistDetailsInConfigRequest = (tasklistId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_TASKLIST_DETAILS}/${tasklistId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to edit tasklist config.
 * @returns {function(*)}
 */
export const editTasklistDetailsInConfigRequest = (data, tasklistId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.EDIT_TASKLIST}/${tasklistId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Tasklist updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add tag config.
 * @returns {function(*)}
 */
export const addTagRequest = (data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_TAGS}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send(null, true)
            .then(res => {
                // dispatch(showSnackbar("Tasklist updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to edit checklist  config.
 * @returns {function(*)}
 */
export const editChecklistRequest = (checklistId, data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.EDIT_CHECKLIST_CONFIG}/${checklistId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Checklist updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all current checklist in  template
 * - Dispatch addChecklistRequest action after successful response from server
 * @returns {function(*)}
 */
export const getAllChecklistInTemplateRequest = (id) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.GET_ALL_CURRENT_CHECKLIST_IN_TEMPLATE}/${id}/checklist/current`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get  single checklist of current template id
 * @returns {function(*)}
 */
export const getSingleCheckListOfTemplateRequest = (templateId, checklistId, isLoaderShowing) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.GET_SINGLE_CHECKLIST_DETAILS_TEMPLATE}/${templateId}/checklist/${checklistId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, isLoaderShowing)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to edit checklist  template.
 * @returns {function(*)}
 */
export const editChecklistTemplateRequest = (checklistId, data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.EDIT_CHECKLIST_TEMPLATE}/${checklistId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Checklist updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get  single task details in congig -> tasks->tasklist view
 * @returns {function(*)}
 */
export const getTaskDetailsInTasklistConfig = (taskIdConfig) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.GET_TASK_DETAILS_CONFIG}/${taskIdConfig}`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to edit task config
 * @returns {function(*)}
 */
export const ediTaskFromConfig = (taskId, data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.EDI_TASK_CONFIG}/${taskId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Task updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

// for showing delete item from checklist message
export const deleteItemsChecklistForShowingMessage = () => {
    return dispatch => {
        dispatch(showSnackbar("Item deleted successfully", SNACKBAR_VARIAINT.SUCCESS));
    };
};

/**
 * Server API call to import task list in template
 * @returns {function(*)}
 */
export const importTaskListInTemplateRequest = (projectId, data) => {

    return (dispatch) => {
        const url = `/template/project-templates/${projectId}/import`;
        return new HttpRequest(dispatch, url, 'patch', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Tasklist imported successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get  single team details config
 * @returns {function(*)}
 */
export const getSingleTeamDetailsRequest = (teamId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_SINGLE_TEAM}/${teamId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get  single clients details config
 * @returns {function(*)}
 */
export const getSingleClientDetailsRequest = (clientId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_SINGLE_CLIENT}/${clientId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get  single vendor details config
 * @returns {function(*)}
 */
export const getSingleVendorDetailsRequest = (vendorId) => {
    return (dispatch) => {
        const url = `${API_ACTIONS.FETCH_SINGLE_VENDOR}/${vendorId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                dispatch(getSingleVendorDetails(res.data))
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

// for showing delete member from team,vendor,client
export const deleteMemberForShowingMessage = () => {
    return dispatch => {
        dispatch(showSnackbar("Member deleted successfully", SNACKBAR_VARIAINT.SUCCESS));
    };
};

/**
 * Server API call to delete task
 * @returns {function(*)}
 */
export const deleteTaskRequestTemplate = (taskId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.DELETE_TASK_TEMPLATE}/${taskId}`;
        return new HttpRequest(dispatch, url, 'delete')
            .send(null, true)
            .then(res => {
                dispatch(showSnackbar("Task deleted successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to task ordering
 * @returns {function(*)}
 */
export const taskOrderRequestTemplate = (data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.TASK_ORDER_TEMPLATE}`;
        return new HttpRequest(dispatch, url, 'patch', data)
            .send(null, true)
            .then(res => {
                dispatch(showSnackbar("Re order successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to delete tasklist
 * @returns {function(*)}
 */
export const deleteTasklistRequestTemplate = (tasklistId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.TASKLIST_DELETE_TEMPLATE}/${tasklistId}`;
        return new HttpRequest(dispatch, url, 'delete')
            .send(null, true)
            .then(res => {
                dispatch(showSnackbar("Tasklist deleted successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to delete milestone
 * @returns {function(*)}
 */
export const deleteMilestoneRequestTemplate = (milestoneId) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.MILESTONE_DELETE_TEMPLATE}/${milestoneId}`;
        return new HttpRequest(dispatch, url, 'delete')
            .send(null, true)
            .then(res => {
                dispatch(showSnackbar("Milestone deleted successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to delete template
 * @returns {function(*)}
 */
 export const deleteTemplate = (templated) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.DELETE_TEMPLATE}/${templated}`;
        return new HttpRequest(dispatch, url, 'delete')
            .send()
            .then(res => {
                dispatch(showSnackbar("Template deleted successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};
