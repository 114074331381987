import React, {useEffect, useState} from 'react';
import {Box, Grid, Typography} from '@material-ui/core';
import {useStyles} from './styles';
import LoaderForSaveAndEdit from '../../../../../../../../../../../../../utilities/AppLoader/LoaderForSaveAndEdit'

const TimeLineActions = ({taskDetails, isChangeStateLoaderShowing, assigneeStatusHandler, statusName}) => {
    const classes = useStyles();
    const [allStatus, setAllStatus] = useState({})

    useEffect(() => {
        if (statusName) {
            if (statusName === "todo") {
                setAllStatus({in_progress: true, deferred: true})
            } else if (statusName === "in_progress") {
                setAllStatus({stopped: true, blocked: true, complete: true, ready_for_review: true, deferred: true})
            } else if (statusName === "stopped") {
                setAllStatus({blocked: true, deferred: true, in_progress: true})
            } else if (statusName === "blocked") {
                setAllStatus({deferred: true, in_progress: true})
            } else if (statusName === "deferred") {
                setAllStatus({in_progress: true})
            } else if (statusName === "complete") {
                setAllStatus({in_progress: true})
            } else if (statusName === "ready_for_review") {
                setAllStatus({in_progress: true})
            } else {
            }
        }
    }, [statusName])
    return (
        <Grid container alignItems='center' justify="space-between">
            <Grid className={classes.forFlex}>
                <Typography variant="h5"
                            className={`${isChangeStateLoaderShowing ? classes.actionText : classes.actionTextWhenLoaderNotShowing}`}>
                    Change State
                </Typography>
                {
                    isChangeStateLoaderShowing
                        ?
                        <span className={classes.forChangeStateLoaderStyle}><LoaderForSaveAndEdit/></span>
                        :
                        <></>
                }
            </Grid>
            <Grid>
                <Box>
          <span onClick={() => {
              allStatus && allStatus.todo ? assigneeStatusHandler('todo') : console.log()
          }}
              // className={`${classes.timelineActionIcon}  ${statusName==='todo' ? classes.red:classes.blue} `}
                className={`${classes.timelineActionIcon} ${statusName === 'todo' ? classes.activeStatus : allStatus && allStatus.todo ? classes.generalNonActiveStatus : classes.nonActiveIcons} `}
          >
            <i className='far fa-circle' title='Not Started'></i>
          </span>

                    <span onClick={() => {
                        allStatus && allStatus.in_progress ? assigneeStatusHandler('in_progress') : console.log()
                    }}
                        // className={`${classes.timelineActionIcon} ${statusName==='in_progress' ? classes.red:classes.blue} `}
                          className={`${classes.timelineActionIcon} ${statusName === 'in_progress' ? classes.activeStatus : allStatus && allStatus.in_progress ? classes.generalNonActiveStatus : classes.nonActiveIcons} `}
                    >
            <i className='far fa-play-circle' title='In progress'></i>
          </span>

                    <span onClick={() => {
                        allStatus && allStatus.stopped ? assigneeStatusHandler('stopped') : console.log()
                    }}
                        // className={`${classes.timelineActionIcon} ${statusName==='stopped' ? classes.red:classes.blue} `}
                          className={`${classes.timelineActionIcon} ${statusName === 'stopped' ? classes.activeStatus : allStatus && allStatus.stopped ? classes.generalNonActiveStatus : classes.nonActiveIcons} `}
                    >
            <i className='far fa-pause-circle' title='Stopped'></i>
          </span>

                    <span onClick={() => {
                        allStatus && allStatus.blocked ? assigneeStatusHandler('blocked') : console.log()
                    }}
                        // className={`${classes.timelineActionIcon} ${statusName==='blocked' ? classes.red:classes.blue} `}
                          className={`${classes.timelineActionIcon} ${statusName === 'blocked' ? classes.activeStatus : allStatus && allStatus.blocked ? classes.generalNonActiveStatus : classes.nonActiveIcons} `}
                    >
            <i className='fas fa-ban' title='Blocked'></i>
          </span>

                    <span onClick={() => {
                        allStatus && allStatus.complete ? assigneeStatusHandler('complete') : console.log()
                    }}
                        // className={`${classes.timelineActionIcon} ${statusName==='complete' ? classes.red:classes.blue} `}
                          className={`${classes.timelineActionIcon} ${statusName === 'complete' ? classes.activeStatus : allStatus && allStatus.complete ? classes.generalNonActiveStatus : classes.nonActiveIcons} `}
                    >
            <i className='far fa-check-circle' title='Complete'></i>
          </span>

                    <span onClick={() => {
                        allStatus && allStatus.deferred ? assigneeStatusHandler('deferred') : console.log()
                    }}
                        // className={`${classes.timelineActionIcon} ${statusName==='deferred' ? classes.red:classes.blue} `}
                          className={`${classes.timelineActionIcon} ${statusName === 'deferred' ? classes.activeStatus : allStatus && allStatus.deferred ? classes.generalNonActiveStatus : classes.nonActiveIcons} `}
                    >
            <i className='fas fa-stop-circle' title='Deferred'></i>
          </span>
                    {
                        taskDetails && taskDetails.isApprovalNeeded
                            ?
                            <span onClick={() => {
                                allStatus && allStatus.ready_for_review ? assigneeStatusHandler('ready_for_review') : console.log()
                            }}
                                // className={`${classes.timelineActionIcon} ${statusName==='ready_for_review' ? classes.red:classes.blue} `}
                                  className={`${classes.timelineActionIcon} ${statusName === 'ready_for_review' ? classes.activeStatus : allStatus && allStatus.ready_for_review ? classes.generalNonActiveStatus : classes.nonActiveIcons} `}
                            >
            <i className='fas fa-check-double' title='Ready for Review'></i>
            </span>
                            :
                            <></>
                    }
                </Box>
            </Grid>
        </Grid>
    );
};

export default TimeLineActions;
