import {combineReducers} from 'redux';
import {i18nReducer} from "react-redux-i18n";

import auth from './modules/authentication/AuthenticationReducer';
import showLoader from './utilities/AppLoader/AppLoaderReducer';
import configuration from './modules/configuration/ConfigurationReducer';
import projects from './modules/projects/ProjectsReducer';
import tasks from './modules/projects/components/details/Project/components/tasks/TasksReducer'
import milestones from './modules/milestones/MilestonesReducer'
import actionStation from './modules/actionStation/ActionStationReducer'
import progress from './modules/progress/ProgressReducer'
// Combine all reducers into one root reducer
export default combineReducers({
    i18n: i18nReducer,
    auth,
    showLoader,

    configuration,
    projects,
    tasks,
    milestones,
    actionStation,
    progress
});
