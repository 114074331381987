import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        teamContainer: {
            marginBottom: '2rem',
        },
        teamInfo: {
            margin: '20px 0 5px 0',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '5px',
            background: 'aliceblue',
        },
        teamHeader: {
            display: 'inline-block',
        },
        teamName: {
            // fontSize: '22px',
            textTransform: 'uppercase',
            // fontWeight: '700',
            // letterSpacing: '1px',
        },
        teamLead: {
            // fontSize: '18px',
            // fontWeight: '500',
            fontVariant: 'small-caps',
            display: 'flex'
        },
        teamLeadImage: {
            height: '32px',
            width: '32px',
            borderRadius: '50%',
            marginRight: '5px',
        },
        teamMembers: {
            display: 'inline-block',
        },
        teamMembersImage: {
            height: '32px',
            width: '32px',
            borderRadius: '50%',
            marginRight: '5px',
        },
        projectsContainer: {
            marginTop: '0.8rem',
            width: '96%',
            margin: 'auto',
        },
        projectsItemContainer: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridGap: ' 1.5rem'
        },
    };
});
