import React, {useRef, useState} from 'react';
import {Dialog, DialogActions, DialogContent, Grid, Typography,} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import './AddProjectRole.css'
import {addFunctionalRoleRequest, getFunctionalRoleRequest} from '../../../../ConfigurationApiActions';
import {connect} from 'react-redux'
import CustomTheme from '../../../../../../utilities/theme/theme.json'
import MessageModal from '../../../../../../utilities/MessageModal/MessageModal'

const AddProjectRole = ({
                            selectedProjectRole,
                            open,
                            setOpenProjectRoleDialog,
                            addFunctionaRole,
                            setSelectedProjectRole,
                            setFetchedFunctionalRoles,
                            getFunctionalRoles,
                            setShrinkProjectTitle
                        }) => {

    const [projectRoleTitle, setProjectRoleTitle] = useState();
    const modalRef = useRef();
    const handleSubmit = () => {
        const data = {
            name: projectRoleTitle
        }
        addFunctionaRole(data).then((addFunctionaResponse) => {
            setOpenProjectRoleDialog(false)
            setProjectRoleTitle('');

            // fetch all functional roles
            getFunctionalRoles().then((res) => {
                let result = Object.entries(res).map(([k, v]) => (v));
                let createRole = {name: 'Create template role', id: 1}
                result.unshift(createRole)
                setFetchedFunctionalRoles(result)
                let roleFiltered = res && res.filter((role) => {
                    return role.id === addFunctionaResponse.id
                })
                setSelectedProjectRole(selectedProjectRole.concat(roleFiltered))
                setShrinkProjectTitle(true)
            })

        }).catch((err) => {
        })
    }

    const handleClose = () => {
        setOpenProjectRoleDialog(false);
        setShrinkProjectTitle(false)
    }

    const handleEsc = (e) => {
        if (e.keyCode === 27) {
            if (projectRoleTitle) {
                const title = `Add Project Role`
                const message = `Changes that you made in add project role may not be saved.`
                modalRef.current.setState({
                    showModal: true,
                    message: message,
                    title: title,
                    showOkButton: true,
                    showCancelButton: true,
                    showNote: false,
                    note: '',
                    handleOk: (note) => {
                        setOpenProjectRoleDialog(false);
                        setShrinkProjectTitle(false)
                    },
                    okText: "Yes",
                    cancelText: 'No'
                });

            } else {
                setOpenProjectRoleDialog(false);
                setShrinkProjectTitle(false)
            }
        }
    }

    return (
        <>
            <Dialog
                open={open}
                keepMounted
                fullWidth="md"
                className="dailog-box-tasklist-add"
            >
                <ValidatorForm
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => handleEsc(e)}
                >
                    <DialogContent>
                        <Typography variant="h2" style={{color: CustomTheme.fontColor}}
                                    className="block-titles margin-top-20">
                            Add Project Role
                        </Typography>

                        <Grid className="add-project-role-margin-top-container" item xl={12} lg={12} md={12} sm={12}
                              xs={12}>
                            <Grid container>
                                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                    <Typography className="task-title" variant="body1">Enter a title for this project
                                        role</Typography>
                                </Grid>
                                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                    <TextValidator
                                        fullWidth
                                        required
                                        autoFocus
                                        size="small"
                                        onChange={(e) => setProjectRoleTitle(e.target.value)}
                                        name="projectroletitle"
                                        value={projectRoleTitle}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            variant="outlined"
                            style={{color: CustomTheme.errorColor}}
                            color="secondary"
                            className="reset-dialog-button"
                            size="small"
                            onClick={handleClose}
                        >
                            CANCEL
                        </Button>
                        <Button
                            // type="submit"
                            className="save-dialog-button"
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={handleSubmit}
                        >
                            SAVE
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
            <MessageModal
                ref={modalRef}
            />
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        // loggedInUser: state.auth.loggedInUser,
        // post: state.postDetails.post,
        // comments:state.postDetails.comments
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addFunctionaRole: (data) => {
            return dispatch(addFunctionalRoleRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getFunctionalRoles: () => {
            return dispatch(getFunctionalRoleRequest()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(AddProjectRole);
