import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    chipInput: {
        backgroundColor: '#f2f6fa',
    },
    inputRoot: {
        borderRadius: '0px',
        paddingTop: '0px!important',
        height: '47px',
    },
    input: {
        marginTop: '3px!important',
        paddingTop: '3px!important',
        marginBottom: '4px!important',
        paddingBottom: '4px!important',
        paddingLeft: '0.4rem',
    },
    chip: {
        margin: '7px 7px!important',
        marginRight: '0px',
        border: '1px solid #fff',
        borderRadius: '3px',
        backgroundColor: '#02ABEE',
        color: '#fff'
    },
    tagsContaianer: {
        position: 'relative',
        backgroundColor: '#f2f6fa',
        border: '1px solid #c5e2ff',
        borderRadius: '5px'
    },
    chipInputContainer: {
        marginLeft: '45px',
    },
    tagIcon: {
        display: 'block',
        position: 'absolute',
        left: '0px',
        margin: '0.5rem',
        zIndex: '1',
        marginLeft: '1.1rem',
        marginTop: '0.7rem',
    },
    marginLeftInput: {
        marginLeft: '1%'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: '100% !important',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    dropDownContainer: {
        marginTop: '-22px'
    }
    // addButtonTasks:{
    //   marginTop:'30%',
    //   backgroundColor:'#02ABEE',
    //   color:'#eee'

    // },
    // NextButtonTasks:{
    //   margin:'5px',
    //   backgroundColor:'#02ABEE',
    //   color:'#eee',
    //   fontFamily:'Roboto'
    // }
}));
