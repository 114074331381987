import React, {useState} from 'react';
import {connect} from 'react-redux';

import SlideToggle from 'react-slide-toggle';
import {NavLink} from 'react-router-dom';
import {Box, Typography} from '@material-ui/core';
import mkdmLogo from '../../../../../../../assets/mkdm-logo.png';
import ParticipantForm from '../participants/components/ParticipantForm/ParticipantForm';
// import TestModal from '../../../addTask/components/TestModal/TestModal';
// import ModalDown from '../../../../../../utilities/ModalDown/ModalDown';
// import ChecklistModal from '../../../../../../utilities/CheckListModal/ChecklistModal';
import {useStyles} from './styles';

const ProjectHeader = ({history, match, loggedInUser, isParticipantsView}) => {
    const classes = useStyles();
    // const [open, setOpen] = useState(false);
    const [openParticipantForm, setOpenParticipantForm] = useState(false);
    // const [openTaskListForm,setOpenTaskListForm]= useState(false);
    // const [openTaskForm,setOpenTaskForm]= useState(false);
    // const [taskListCheckList,setTaskListCheckList]= useState(null)
    // const [taskSelectedCheckList,setTaskSelectedCheckList]= useState(null);
    // const [shrinkCheckListTaskList,setShrinkCheckListTaskList]= useState(null);
    // const [shrinkChecklistFromTask,setShrinkChecklistFromTask]= useState(null);
    // const [openAddCheckList,setOpenAddCheckList]= useState(false)
    // const [allCheckList,setAllCheckList]= useState(null)
    // const [allTaskList,setAllTaskList]= useState(null)
    // const [allTasks,setAllTasks]=useState(null);

    // const handleClose = () => {
    //   setOpen(false);
    // };


    return (
        <>
            <Box className={classes.outerContainer}>
                <Box className={classes.mainContainer}>
                    <Box className={classes.imageContainer}>
                        <img src={mkdmLogo} alt='logo' className={classes.image}/>
                    </Box>
                    <Box className={classes.contentContainer}>
                        <Box className={classes.headingContainer}>
                            <Box>
                                <Typography className={classes.heading}
                                            variant="h2">{localStorage.getItem('projectName')}</Typography>
                            </Box>
                            <Box className={classes.status}>
                                <Typography className={classes.statusLabel} variant="body1"><i
                                    className='fas fa-circle'></i> On Track</Typography>
                            </Box>
                        </Box>
                        <Box className={classes.bottomLabelContainer}>
                            {/* <NavLink
                to='/project/home'
                className={classes.bottomLabel}
                activeClassName={classes.active}
              >
                Home
              </NavLink> */}
                            <NavLink
                                to={`/project/${match.params.id}/tasks`}
                                className={classes.bottomLabel}
                                activeClassName={classes.active}
                            >
                                Tasks
                            </NavLink>
                            <NavLink
                                to={`/project/${match.params.id}/participants`}
                                className={classes.bottomLabel}
                                activeClassName={classes.active}
                            >
                                Participants
                            </NavLink>
                            <NavLink
                                to={`/project/${match.params.id}/progress`}
                                className={classes.bottomLabel}
                                activeClassName={classes.active}
                            >
                                Progress
                            </NavLink>
                            <NavLink
                                to={`/project/${match.params.id}/checklists`}
                                className={classes.bottomLabel}
                                activeClassName={classes.active}
                            >
                                Checklists
                            </NavLink>
                            <NavLink
                                to={`/project/${match.params.id}/milestones`}
                                className={classes.bottomLabel}
                                activeClassName={classes.active}
                            >
                                Milestones
                            </NavLink>
                            {/* <NavLink
                to='/project/documents'
                className={classes.bottomLabel}
                activeClassName={classes.active}
              >
                Documents
              </NavLink> */}
                            {/* <NavLink
                to='/project/progress'
                className={classes.bottomLabel}
                activeClassName={classes.active}
              >
                Progress
              </NavLink> */}
                            {/* <NavLink
                to='/project/conversations'
                className={classes.bottomLabel}
                activeClassName={classes.active}
              >
                Conversations
              </NavLink> */}
                            {/* <NavLink
                to='/project/files'
                className={classes.bottomLabel}
                activeClassName={classes.active}
              >
                Files
              </NavLink> */}
                            {/* <NavLink
                to='/project/time'
                className={classes.bottomLabel}
                activeClassName={classes.active}
              >
                Time
              </NavLink> */}
                            {/* <NavLink
                to='/project/activity'
                className={classes.bottomLabel}
                activeClassName={classes.active}
              >
                Activity
              </NavLink> */}
                        </Box>
                    </Box>
                </Box>

                <Box className={classes.actionsCotainer}>
                    <Box className={classes.searchContainer}>
                        <input
                            type='text'
                            placeholder='Search...'
                            className={classes.searchInput}
                        />
                        <span className={classes.searchIcon}>
              <i className='fas fa-search'></i>
            </span>
                    </Box>

                    {
                        loggedInUser.userRoles.isSystemSuperAdmin && !isParticipantsView
                            ? (
                                <>
                                    {/* <Box>
                  <span className={classes.icon}>
                    <i className='far fa-edit'></i>
                  </span>
                </Box> */}

                                    <Box>
                                        <SlideToggle
                                            collapsed
                                            duration={500}
                                            render={({toggle, setCollapsibleElement}) => (
                                                <Box className={classes.createMenuContainer}>
                                                    {/* <Box onClick={toggle}>
                          <span className={classes.icon}>
                            <i className='fas fa-plus-circle'></i>
                          </span>
                        </Box> */}
                                                    <Box
                                                        className={classes.createMenu}
                                                        ref={setCollapsibleElement}
                                                    >
                                                        <Box className={classes.createMenuLabel}>Create New</Box>
                                                        <ul>
                                                            <li>
                                                                <NavLink
                                                                    to='/project/milestone'
                                                                    className={classes.createMenuItem}
                                                                >
                                <span className={classes.createMenuItemIcon}>
                                  <i className='fas fa-flag'></i>
                                </span>
                                                                    <span>Milestone</span>
                                                                </NavLink>
                                                            </li>
                                                            <li>
                                                                {/* <NavLink
                                to='/project/tasklist'
                                className={classes.createMenuItem}
                              >
                                <span className={classes.createMenuItemIcon}>
                                  <i className='fas fa-list'></i>
                                </span>
                                <span>Tasklist</span>
                              </NavLink> */}
                                                                <Box
                                                                    className={classes.createMenuItem}
                                                                    onClick={() => {
                                                                        // setOpenTaskListForm(true)
                                                                        toggle();
                                                                    }}
                                                                >
                                <span className={classes.createMenuItemIcon}>
                                  <i className='fas fa-list'></i>
                                </span>
                                                                    <span>Tasklist</span>
                                                                </Box>
                                                            </li>
                                                            <li>
                                                                {/* <NavLink
                                to='/project/task'
                                className={classes.createMenuItem}
                              >
                                <span className={classes.createMenuItemIcon}>
                                  <i className='far fa-list-alt'></i>
                                </span>
                                <span>Task</span>
                              </NavLink> */}
                                                                <Box
                                                                    className={classes.createMenuItem}
                                                                    onClick={() => {
                                                                        // setOpenTaskForm(true);
                                                                        toggle();
                                                                    }}
                                                                >
                                <span className={classes.createMenuItemIcon}>
                                  <i className='far fa-list-alt'></i>
                                </span>
                                                                    <span>Task</span>
                                                                </Box>
                                                            </li>

                                                            <li>
                                                                <Box
                                                                    className={classes.createMenuItem}
                                                                    onClick={() => {
                                                                        setOpenParticipantForm(true);
                                                                        toggle();
                                                                    }}
                                                                >
                                <span className={classes.createMenuItemIcon}>
                                  <i className='far fa-list-alt'></i>
                                </span>
                                                                    <span>Participants</span>
                                                                </Box>
                                                            </li>
                                                            {/* <li>
                              <NavLink
                                to={ROUTER_ACTIONS.PROJECTS_ADD}
                                className={classes.createMenuItem}
                              >
                                <span className={classes.createMenuItemIcon}>
                                  <i className='far fa-folder'></i>
                                </span>
                                <span>Project</span>
                              </NavLink>
                            </li> */}

                                                            {/* <li>
                              <NavLink
                                to='/actionStation'
                                className={classes.createMenuItem}
                              >
                                <span className={classes.createMenuItemIcon}>
                                  <i className='far fa-hourglass'></i>
                                </span>
                                <span>Time Log</span>
                              </NavLink>
                            </li> */}

                                                            {/* <li>
                              <NavLink to='/' className={classes.createMenuItem}>
                                <span className={classes.createMenuItemIcon}>
                                  <i className='far fa-file-alt'></i>
                                </span>
                                <span>Document</span>
                              </NavLink>
                            </li> */}

                                                            {/* <li>
                              <NavLink to='/' className={classes.createMenuItem}>
                                <span className={classes.createMenuItemIcon}>
                                  <i className='far fa-user'></i>
                                </span>
                                <span>User</span>
                              </NavLink>
                            </li> */}

                                                            {/* <li>
                              <NavLink to='/' className={classes.createMenuItem}>
                                <span className={classes.createMenuItemIcon}>
                                  <i className='fas fa-users'></i>
                                </span>
                                <span>Team</span>
                              </NavLink>
                            </li> */}
                                                        </ul>
                                                    </Box>
                                                </Box>
                                            )}
                                        />
                                    </Box>
                                </>
                            )
                            : <></>
                    }
                </Box>
            </Box>

            {/* <TestModal open={open} handleClose={handleClose} /> */}

            <ParticipantForm
                open={openParticipantForm}
                setOpen={setOpenParticipantForm}
                history={history}
            />

            {/* <ModalDown
        open={openTaskListForm}
        setOpen={setOpenTaskListForm}
        history={history}
        setTaskListCheckList={setTaskListCheckList}
        taskListCheckList={taskListCheckList}
        setTaskSelectedCheckList={setTaskSelectedCheckList}
        taskSelectedCheckList={taskSelectedCheckList}
        setShrinkCheckListTaskList={setShrinkCheckListTaskList}
        setShrinkChecklistFromTask={setShrinkChecklistFromTask}
        setOpenAddCheckList={setOpenAddCheckList}
        setAllCheckList={setAllCheckList}
        allCheckList={allCheckList}
        setAllTaskList={setAllTaskList}
        allTaskList={allTaskList}
        setAllTasks={setAllTasks}
        allTasks={allTasks}
      />

      <ModalDown
        open={openTaskForm}
        hideTaskListTemplate={true}
        setOpen={setOpenTaskForm}
        setTaskListCheckList={setTaskListCheckList}
        taskListCheckList={taskListCheckList}
        setTaskSelectedCheckList={setTaskSelectedCheckList}
        taskSelectedCheckList={taskSelectedCheckList}
        setShrinkCheckListTaskList={setShrinkCheckListTaskList}
        setShrinkChecklistFromTask={setShrinkChecklistFromTask}
        setOpenAddCheckList={setOpenAddCheckList}
        setAllCheckList={setAllCheckList}
        allCheckList={allCheckList}
        setAllTaskList={setAllTaskList}
        allTaskList={allTaskList}
        setAllTasks={setAllTasks}
        allTasks={allTasks}
      />

      <ChecklistModal
        setOpen={setOpenTaskListForm}
        setOpenAddCheckList={setOpenAddCheckList}
        openAddCheckList={openAddCheckList}
        setTaskListCheckList={setTaskListCheckList}
        setTaskSelectedCheckList={setTaskSelectedCheckList}
        taskSelectedCheckList={taskSelectedCheckList}
      /> */}


        </>
    );
};

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectHeader);
