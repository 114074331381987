import React, {useEffect, useState} from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Switch from '@material-ui/core/Switch';
import NativeSelect from "@material-ui/core/NativeSelect";
import Box from '@material-ui/core/Box';
import TimePicker from '../../../../../../utilities/TimePicker/TimePicker';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import moment from 'moment';

import './HolidayMeta.css';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    marginTopTwo: {
        marginTop: '2%'
    },
    marginTopOne: {
        marginTop: '1%'
    },
    switchIcon: {
        marginLeft: '-3%',
        marginTop: '3.8%'
    },
    dayNameTitle: {
        marginTop: '5%'
    }
});

const holiday = [{
    title: "India - Corporate",
    value: "IndiaCorporate",
    days: [
        {day: 'Holi', date: '01/01/2021'},
        {day: 'Ganesh Utsav', date: '01/01/2021'},
        {day: 'Ram Navami', date: '01/01/2021'},
        {day: 'Buddha Purnima', date: '01/01/2021'},
        {day: 'Mahavir Jayanti', date: '01/01/2021'},
    ]
},
    {
        title: "India - Bank",
        value: "IndiaBank",
        days: [
            {day: 'Good Friday', date: '01/01/2021'},
            {day: 'Republic day', date: '01/01/2021'},
            {day: 'Ganesh Chaturthi', date: '01/01/2021'},
            {day: 'Independen day', date: '01/01/2021'},
            {day: 'Labour Day', date: '01/01/2021'},
        ]
    },
    {
        title: "India - Government",
        value: "IndiaGovernment",
        days: [
            {day: 'Ead', date: '01/01/2021'},
            {day: 'Gandhi Jayanti', date: '01/01/2021'},
            {day: 'Dussehra', date: '01/01/2021'},
            {day: 'Diwali', date: '01/01/2021'},
            {day: 'Christmas Day', date: '01/01/2021'},
        ]
    }
]

const HolidayMeta = ({history}) => {
    const classes = useStyles();
    // const [state, setState] = useState({
    //   checkedA: true
    // });
    // const [selectedDate, setSelectedDate] = useState(undefined);
    // const [selectedEndDate, setSelectedEndDate] = useState(undefined);
    const [selectedHolidayType, setSelectedHolidayType] = useState(undefined)
    const [addHoliday, setAddHoliday] = useState(false)
    const [organisationHoliday, setOrganisationHoliday] = useState([]);
    const [holidayName, setHolidayName] = useState('');
    const [holidayDate, setHolidayDate] = React.useState(new Date('2021-05-19T21:11:54'));


    const [week, setWeek] = useState([
        {
            title: 'Sun',
            value: 'sunday',
            holiday: true,
            time: [
                {start: '', end: ''},
                {start: '', end: ''}
            ]
        },
        {
            title: 'Mon',
            value: 'Monday',
            holiday: true,
            time: [
                {start: '', end: ''}
            ]
        },
        {
            title: 'Tue',
            value: 'Tuesday',
            holiday: true,
            time: [
                {start: '', end: ''}
            ]
        },
        {
            title: 'Wed',
            value: 'Wednesday',
            holiday: false,
            time: [
                {start: '', end: ''}
            ]
        },
        {
            title: 'Thu',
            value: 'Thursday',
            holiday: false,
            time: [
                {start: '', end: ''}
            ]
        },
        {
            title: 'Fri',
            value: 'Friday',
            holiday: true,
            time: [
                {start: '', end: ''}
            ]
        },
        {
            title: 'Sat',
            value: 'Saturday',
            holiday: true,
            time: [
                {start: '', end: ''}
            ]
        }
    ])

    useEffect(() => {
        holiday.map((holidayList) => {
            if (holidayList.value === selectedHolidayType) {
                let arr = Object.keys(holidayList.days).map((k) => holidayList.days[k])
                setOrganisationHoliday(arr)
            }
            return null;
        })
    }, [selectedHolidayType])


    const selectedStartDatehandler = (date, timeIndex, dayIndex) => {
        const data = Object.assign([], week);
        data[dayIndex].time[timeIndex].start = date;
        setWeek(data);
    }

    const selectedEndDatehandler = (date, timeIndex, dayIndex) => {

        const data = Object.assign([], week);
        data[dayIndex].time[timeIndex].end = date;

        const startTime = data[dayIndex].time[timeIndex].start
        const endTime = data[dayIndex].time[timeIndex].end

        let startHour = moment(startTime).format('hh')
        let endHour = moment(endTime).format('hh')

        if (startHour > endHour) {
            alert("End time is greater than Start Time")
        } else {
            setWeek(data);
        }
    }

    const handleHolidayDateChange = (holiDayDate) => {
        setHolidayDate(holiDayDate)
    }

    const checkHandleChange = (dayIndex, value) => {
        const data = Object.assign([], week);
        data[dayIndex].holiday = !value;
        setWeek(data);
    }


    const handleSelectChange = (e) => {
        setSelectedHolidayType(e.target.value);
    }

    const addTimeSlot = (dayIndex) => {
        const data = Object.assign([], week);
        const newTimeObj = {start: '', end: ''}
        data[dayIndex].time.push(newTimeObj);
        setWeek(data);
    }

    const removeTimeSlot = (timeIndex, dayIndex) => {
        const data = Object.assign([], week);
        data[dayIndex].time.splice(timeIndex, 1);
        setWeek(data);
    }

    const onRemoveSingleHoliday = (singleDayIndex) => {
        const data = Object.assign([], organisationHoliday);
        data.splice(singleDayIndex, 1);
        setOrganisationHoliday(data);
    }

    const onAddSingleHoliday = () => {
        if (selectedHolidayType !== undefined) {
            let dateInFormat = moment(holidayDate).format("DD/MM/YYYY")
            const data = Object.assign([], organisationHoliday);
            const newHoliday = {day: holidayName, date: dateInFormat}
            data.push(newHoliday);
            setOrganisationHoliday(data);
            setAddHoliday(false)
        }

    }

    const handleSubmit = () => {

    }


    return (
        <>
            <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography className="operations-information-title">Operations Information</Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.marginTopTwo}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="body2">Aenean aliquet, leo ut venenatis laoreet, urna nulla suscipit elit, id
                        auctor nulla sem in felis. Nam congue mollis quam, eget sodales mauris vestibulum ut.
                        Pellentesque risus enim, venenatis at massa eget, sagittis eleifend diam. Morbi at ultricies
                        arcu, eu viverra urna. Etiam ut posuere metus, a elementum orci. Nulla ac risus
                        neque.</Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.marginTopTwo}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography className="operations-titles">Business Hours</Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.marginTopOne}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="body2">Ut feugiat nisl urna, vel ultrices felis sollicitudin et. Morbi lectus
                        erat, posuere ac diam vitae, vehicula porttitor enim. Vestibulum nec convallis
                        purus.</Typography>
                </Grid>
            </Grid>
            <ValidatorForm
                onSubmit={handleSubmit}
                noValidate
                onError={errors => console.log(errors)}
            >
                <Grid container>
                    <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                        {
                            week.map((day, dayIndex) => (
                                <Grid container key={dayIndex}>
                                    <Grid className={classes.dayNameTitle} item xl={1} lg={1} md={1} sm={1} xs={1}>
                                        <Typography variant="body2">{day.title}</Typography>
                                    </Grid>
                                    <Grid className={classes.switchIcon} item xl={1} lg={1} md={1} sm={1} xs={1}>
                                        <Switch
                                            checked={day.holiday}
                                            onChange={() => checkHandleChange(dayIndex, day.holiday)}
                                            color="primary"
                                            name="checked"
                                            inputProps={{'aria-label': 'primary checkbox'}}
                                        />
                                    </Grid>
                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                        {
                                            day.holiday ? (
                                                <Grid container>
                                                    {
                                                        day.time.map((time, timeIndex) => (
                                                            <>
                                                                <Grid
                                                                    className={timeIndex > 0 ? 'start-time-picker-grid' : ''}
                                                                    item xl={5} lg={5} md={5} sm={5} xs={5}>
                                                                    <TimePicker timePickerLabel="Start  Time"
                                                                                selectedDate={time.start}
                                                                                selectedDatehandler={(startDate) => selectedStartDatehandler(startDate, timeIndex, dayIndex)}/>
                                                                </Grid>
                                                                <Grid
                                                                    className={timeIndex > 0 ? 'end-time-picker-grid' : ''}
                                                                    item xl={5} lg={5} md={5} sm={5} xs={5}>
                                                                    <TimePicker timePickerLabel="End  Time"
                                                                                selectedDate={time.end}
                                                                                selectedDatehandler={(endDate) => selectedEndDatehandler(endDate, timeIndex, dayIndex)}/>
                                                                </Grid>
                                                                {
                                                                    (timeIndex === (day.time.length - 1))
                                                                        ? (
                                                                            <i
                                                                                className="add-time-icon fas fa-plus-circle"
                                                                                aria-hidden="true"
                                                                                onClick={() => addTimeSlot(dayIndex)}
                                                                            ></i>
                                                                        )
                                                                        : (
                                                                            <i
                                                                                className="remove-time-icon far fa-times-circle"
                                                                                onClick={() => removeTimeSlot(timeIndex, dayIndex)}
                                                                            ></i>
                                                                        )
                                                                }
                                                            </>
                                                        ))
                                                    }
                                                </Grid>
                                            ) : <></>
                                        }

                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>

                    </Grid>
                </Grid>

                <Grid container className={classes.marginTopTwo}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography className="operations-titles">Holidays</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.marginTopOne}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="body2">Ut feugiat nisl urna, vel ultrices felis sollicitudin et. Morbi
                            lectus erat, posuere ac diam vitae, vehicula porttitor enim. Vestibulum nec convallis
                            purus.</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.marginTopOne}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid container>
                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                <NativeSelect
                                    // value={state.age}
                                    // className="holiday-true-false"
                                    onChange={handleSelectChange}
                                    inputProps={{
                                        name: "age",
                                        id: "age-native-label-placeholder"
                                    }}
                                >
                                    <option value="">Select Holiday Schedule</option>
                                    {holiday.map((holidayType, holidayTypeIndex) => {
                                        return <option key={holidayTypeIndex}
                                                       value={holidayType.value}>{holidayType.title}</option>
                                    })}
                                </NativeSelect>
                            </Grid>
                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                <Typography onClick={() => setAddHoliday(true)} variant="body2"><span><i
                                    className="fas fa-plus-circle"></i></span></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    addHoliday ?
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container>
                                <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                                    <TextValidator
                                        label='Name'
                                        className="add-holiday-name"
                                        // fullWidth
                                        required
                                        name='holidayName'
                                        value={holidayName}
                                        onChange={e => setHolidayName(e.target.value)}
                                        validators={['required']}
                                        errorMessages={['Holiday Name is required']}
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="Holiday-date-picker-dialog"
                                            label="Holiday date "
                                            format="MM/dd/yyyy"
                                            value={holidayDate}
                                            onChange={handleHolidayDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                                    <Button onClick={onAddSingleHoliday} className="add-button-holiday">Add</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <></>
                }

                <Box className="holiday-list" display="flex" flexDirection="row" justifyContent="space-evenly">
                    {
                        organisationHoliday.map((singleDay, singleDayIndex) => {
                            return (
                                <Box className="holiday" key={singleDayIndex}>
                                    <Typography variant="body2">{singleDay.day}</Typography>
                                    <Typography variant="h5">{singleDay.date}</Typography>
                                    <Typography onClick={() => onRemoveSingleHoliday(singleDayIndex)}
                                                component="span"><i className="fas fa-minus-circle"></i></Typography>
                                </Box>
                            )
                        })
                    }
                </Box>
            </ValidatorForm>
        </>
    )
};
export default HolidayMeta;
