import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {Box, Dialog, DialogActions, DialogContent, Grid, Typography} from '@material-ui/core';
import MaterialTable, {MTableBodyRow} from 'material-table';
import './TableView.css'
import {connect} from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import LoaderForSaveAndEdit from '../../../../../../../../utilities/AppLoader/LoaderForSaveAndEdit'
import {
    getAllTaskListRequest,
    getMilestoneDetails,
    getTaskDetailsRequest,
    getTasklistDetailsRequest,
    getTasksUnderTaskListProjectTemplateRequest,
    importTaskListInTemplateRequest,
    taskOrderRequestTemplate
} from '../../../../../../ConfigurationApiActions'
import CustomTheme from '../../../../../../../../utilities/theme/theme.json'
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {useParams} from 'react-router';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';

const tableIcons = {
    Filter: forwardRef((props, ref) => <div/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
};
const TableView = ({
                       showTaskDetailsPane,
                       history,
                       importTaskList,
                       getAllConfigTaskList,
                       toggleTableView,
                       loggedInUser,
                       orderingRowHandler,
                       setIsMileStone,
                       isMilestone,
                       setIsRightPanelShow,
                       selectedRow,
                       setSelectedRow,
                       setTasklistIndex,
                       setTaskIndex,
                       setMilestoneIndex,
                       getTaskOfTasklistProject,
                       templateData,
                       isShowLoader,
                       setIsShowLoader,
                       idForDsiplalyLoader,
                       setIdForDisplayLoader,
                       createViewPanel,
                       addUpdateRes,
                       setAddUpdateRes,
                       taskOrderRequest,
                   }) => {
    const {templateId} = useParams()
    const [isShowLoaderFotFetchTasks, setIsShowLoaderFotFetchTasks] = useState(false)
    // const [currentRow, setCurrentRow] = useState(null);
    const [currentSubRow, setCurrentSubRow] = useState(null);
    const [isRowDrag, setIsRowDrag] = useState(false)
    const DragState = {row: -1, dropIndex: -1};

    const [showedImportMemu, setShowedImportMemu] = useState(false)
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false)
    const prevOpen = useRef(open);
    const [isImportTasklistOpenDialog, setIsImportTasklistOpenDialog] = useState(false);
    const [tasklist, setTasklist] = useState([]);
    const [selctedConfigTasklist, setSelectedConfigTasklist] = useState(null);
    const [mergedTasks, setMergedTasks] = useState([]);
    const [showTaskOfTasklist, setShowTaskOfTasklist] = useState({});

    useEffect(() => {
        // setCurrentRow(null);
        setCurrentSubRow(null);
        setMergedTasks([...templateData]);
        setShowTaskOfTasklist({})
    }, [templateData]);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (isImportTasklistOpenDialog) {
            getAllConfigTaskList().then((res) => {
                setTasklist(res);
            })
        }
    }, [isImportTasklistOpenDialog])

    useEffect(() => {
        if (addUpdateRes.res && addUpdateRes.formName === "Task") {
            let findObj = mergedTasks && mergedTasks.find((task) => task.id === addUpdateRes.res.id)
            if (addUpdateRes.res && addUpdateRes.res.isDelete) {
                let findIndex = mergedTasks && mergedTasks.findIndex((task) => task.id === addUpdateRes.res.taskId)
                mergedTasks.splice(findIndex, 1);
            } else {
                if (findObj) {
                    let findIndex = mergedTasks && mergedTasks.findIndex((task) => task.id === addUpdateRes.res.id)
                    // setSelectedRow(addUpdateRes.res.id)
                    setShowTaskOfTasklist(prev =>
                        Boolean(!prev[addUpdateRes.res.tasklistId])
                            ? {...prev, [addUpdateRes.res.tasklistId]: true}
                            : {...prev, [addUpdateRes.res.tasklistId]: true}
                    );
                    addUpdateRes.res.isSubTask = true;
                    if (findIndex !== -1) {
                        mergedTasks[findIndex] = addUpdateRes.res;
                    }
                } else {
                    let findIndex = mergedTasks && mergedTasks.findIndex((task) => task.id === addUpdateRes.res.tasklistId)
                    if (showTaskOfTasklist[addUpdateRes.res.tasklistId]) {
                        // setSelectedRow(addUpdateRes.res.id)
                        addUpdateRes.res.isSubTask = true;
                        mergedTasks.splice(findIndex + 1, 0, addUpdateRes.res);
                    }
                }
            }
        } else if (addUpdateRes.res && addUpdateRes.formName === "Tasklist") {
            let findObj = mergedTasks && mergedTasks.find((task) => task.id === addUpdateRes.res.id)
            if (addUpdateRes.res && addUpdateRes.res.isDelete) {
                let data = Object.assign([], mergedTasks);
                let findIndex = data && data.findIndex((task) => task.id === addUpdateRes.res.taskId)
                let findSubTasks = data && data.filter((task) => task.isSubTask === true);
                let tasklistTasks = findSubTasks && findSubTasks.filter((task) => task.tasklistId === addUpdateRes.res.taskId)
                let totalLength = 1 + tasklistTasks.length
                data.splice(findIndex, totalLength);
                setMergedTasks(data);
            } else {
                if (findObj) {
                    let findIndex = mergedTasks && mergedTasks.findIndex((task) => task.id === addUpdateRes.res.id)
                    if (findIndex !== -1) {
                        mergedTasks[findIndex] = addUpdateRes.res;
                    }
                } else {
                    mergedTasks.splice(0, 0, addUpdateRes.res);
                    setCurrentSubRow(null);
                }
            }
        } else if (addUpdateRes.res && addUpdateRes.formName === "Milestone") {
            if (addUpdateRes.res && addUpdateRes.res.isDelete) {
                let data = Object.assign([], mergedTasks);
                let findIndex = data && data.findIndex((task) => task.id === addUpdateRes.res.milestoneId)
                let findSubTasklist = data && data.filter((tasklist) => tasklist.isSubTask === true);
                let milestoneTasklist = findSubTasklist && findSubTasklist.filter((tasklist) => tasklist.milestoneId === addUpdateRes.res.milestoneId)
                let totalLength = 1 + milestoneTasklist.length
                data.splice(findIndex, totalLength);
                setMergedTasks(data);
            } else {
                let findObj = mergedTasks && mergedTasks.find((task) => task.id === addUpdateRes.res.id)
                if (findObj) {
                    let findIndex = mergedTasks && mergedTasks.findIndex((task) => task.id === addUpdateRes.res.id)
                    mergedTasks.splice(findIndex, 1, addUpdateRes.res)
                } else {
                    mergedTasks.splice(0, 0, addUpdateRes.res);
                }
            }
        }
    }, [addUpdateRes])

    const handleToggle = (e) => {
        e.stopPropagation()
        setOpen((prevOpen) => !prevOpen);
        let prev = showedImportMemu
        setShowedImportMemu(!prev)
    };

    const onHandleInportTasklist = () => {

        const data = {
            tasklistConfigId: selctedConfigTasklist.id
        };

        importTaskList(templateId, data).then((res) => {
            setIsMileStone(false)
            setAddUpdateRes({
                res: res,
                formName: 'Tasklist'
            });
            setSelectedRow(res.id)
            setTasklist([]);
            setSelectedConfigTasklist(null);
            setIsImportTasklistOpenDialog(false);
        });
    }

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        let prev = showedImportMemu
        setShowedImportMemu(!prev)
        setOpen(false);
    };


    const offsetIndex = (from, to, arr = []) => {
        if (from < to) {
            let start = arr.slice(0, from),
                between = arr.slice(from + 1, to + 1),
                end = arr.slice(to + 1);
            // let modifiedArray = [...start, ...between, arr[from], ...end];
            // let findIndex = modifiedArray.findIndex((task)=>task.title=== mergedTasks[from].title)
            // let belowIndex=findIndex
            if (mergedTasks[from].isSubTask || mergedTasks[to].isSubTask) {
                orderingRowHandler("task", mergedTasks[from], mergedTasks[to], false)
            }
            return [...start, ...between, arr[from], ...end];
        }
        if (from > to) {
            let start = arr.slice(0, to),
                between = arr.slice(to, from),
                end = arr.slice(from + 1);
            let modifiedArray = [...start, arr[from], ...between, ...end];
            let findIndex = modifiedArray.findIndex((task) => task.title === mergedTasks[from].title)
            let belowIndex = findIndex - 1
            if (mergedTasks[from].isSubTask || mergedTasks[to].isSubTask) {
                orderingRowHandler("task", mergedTasks[from], mergedTasks[belowIndex], false)
            }
            return [...start, arr[from], ...between, ...end];
        }
        return arr;
    };

    const offsetIndexIfTasklistNotOpen = (from, to, arr = []) => {
        if (from < to) {
            let start = arr.slice(0, from),
                between = arr.slice(from + 1, to + 1),
                end = arr.slice(to + 1);
            let data = {
                tasklistId: mergedTasks[to].id,
                taskId: mergedTasks[from].id,
                belowTaskId: null
            }
            taskOrderRequest(data);
            return [...start, ...between, ...end];
        }
        if (from > to) {
            let start = arr.slice(0, to + 1),
                between = arr.slice(to + 1, from),
                end = arr.slice(from + 1);
            let data = {
                tasklistId: mergedTasks[to].id,
                taskId: mergedTasks[from].id,
                belowTaskId: null,
            }
            taskOrderRequest(data);
            return [...start, ...between, ...end];
        }
        return arr;
    }

    const reOrderRow = (from, to) => {
        let newtableData;
        if (!mergedTasks[to].isSubTask && !showTaskOfTasklist[mergedTasks[to].id]) {
            newtableData = offsetIndexIfTasklistNotOpen(from, to, mergedTasks);
            if (newtableData) {
                setIdForDisplayLoader(mergedTasks[to].id);
                setIsShowLoaderFotFetchTasks(true);
                getTaskOfTasklistProject(mergedTasks[to].id).then((res) => {
                    res.push(mergedTasks[from])
                    const list = res.filter(one => {
                        one.isSubTask = true;
                        return true;
                    });
                    const currentList = newtableData;
                    let index = currentList.findIndex(one => one.id === mergedTasks[to].id);
                    list.forEach(one => {
                        currentList.splice(index + 1, 0, one);
                        index++;
                    });
                    setMergedTasks(currentList);
                    setSelectedRow(mergedTasks[from].id)
                    setIdForDisplayLoader(null);
                    setIsShowLoaderFotFetchTasks(false);
                    setShowTaskOfTasklist(prev =>
                        Boolean(!prev[mergedTasks[to].id])
                            ? {...prev, [mergedTasks[to].id]: true}
                            : {...prev, [mergedTasks[to].id]: false}
                    );
                });
            }
        } else {
            newtableData = offsetIndex(from, to, mergedTasks);
        }
        //Update react state
        if (mergedTasks[from].isSubTask && mergedTasks[to].isSubTask) {
            setMergedTasks(newtableData);
        } else {
            if (isMilestone) {
                setMergedTasks(newtableData);
            } else {
                setMergedTasks(newtableData);
            }
        }
        setIsRowDrag(false)
    };

    const columns = [
        {
            field: '',
            title: '',
            cellStyle: {
                width: '5%',
            },
            headerStyle: {
                borderRadius: '5px 0rem 0rem 0rem',
                // display:'none'
            },
            render: rowData => {
                if (rowData.isSubTask) {
                    return (
                        <>
                            {
                                !isMilestone
                                    ?
                                    <div className="drag-icon-div">
                                        <span className="drag-icon" onMouseDown={() => setIsRowDrag(true)}><i
                                            className="fas fa-grip-vertical"></i></span>
                                    </div>
                                    :
                                    <></>
                            }
                        </>
                    )
                } else {
                    return (
                        <div className="for-display-flex">
                            {/* {
                hoveringOver === rowData.tableData.id
                  ?
                  <span onMouseDown={() => setIsRowDrag(true)} style={{ paddingRight: '5px' }}><i className="fas fa-grip-vertical"></i></span>
                  :
                  ''
              } */}
                            {
                                !isMilestone ? (
                                    showTaskOfTasklist[rowData.id] ? (
                                        <span>
                      <i className='fas fa-caret-down'/>
                    </span>
                                    ) : (
                                        <span>
                      <i className='fas fa-caret-right'/>
                    </span>
                                    )
                                ) : (
                                    <span>
                    <i className='fas fa-flag'/>
                  </span>
                                )
                            }
                        </div>
                    );
                }
            },
        },
        {
            field: 'title',
            title: 'Task',
            filterPlaceholder: 'Task',
            cellStyle: {
                width: '50%',
            },
            headerStyle: {
                borderRadius: '5px 0rem 0rem 0rem',
                // display:'none'
            },
            render: rowData => {
                return (
                    <div>
                        {
                            isMilestone
                                ?
                                <div className="for-display-flex">
                                    <Typography
                                        variant="body1"
                                        className={isShowLoaderFotFetchTasks && idForDsiplalyLoader === rowData.id ? 'padding-top-8' : ''}
                                    >
                                        {rowData.title}
                                    </Typography>
                                    <span>
                    {
                        isShowLoaderFotFetchTasks && idForDsiplalyLoader === rowData.id && (
                            <LoaderForSaveAndEdit/>
                        )
                    }
                  </span>
                                </div>
                                :
                                <div className="for-display-flex">
                                    <Typography
                                        variant="body1"
                                        style={{
                                            paddingTop: isShowLoaderFotFetchTasks && idForDsiplalyLoader === rowData.id ? '8px' : '',
                                            display: "flex"
                                        }}
                                    >
                                        {/* <span className="milestone-flag-icon">
                      {
                        rowData && rowData.milestoneId ?
                          <Tooltip placement="top" title={rowData && rowData.milestone ? rowData.milestone.title : ""}>
                            <span style={{ paddingRight: '5px' }} >
                              <i className='fas fa-flag'></i>
                            </span>
                          </Tooltip>
                          :
                          <></>
                      }
                    </span> */}
                                        {
                                            rowData && rowData.milestoneId
                                                ?
                                                <span className="milestone-flag-icon">
                             <Tooltip title={rowData && rowData.milestone ? rowData.milestone.title : ""}
                                      placement="top">
                                <span className="padding-right-5">
                                <i className='fas fa-flag'/>
                                </span>
                            </Tooltip>
                         </span>
                                                :
                                                <span className="padding-right-5 visibility-hidden-class">
                            <i className='fas fa-flag'/>
                        </span>
                                        }
                                        {rowData.title}
                                    </Typography>
                                    <span className="position-relative-class top-2">
                    {
                        isShowLoaderFotFetchTasks && idForDsiplalyLoader === rowData.id && (
                            <LoaderForSaveAndEdit/>
                        )
                    }
                  </span>
                                </div>
                        }
                    </div>
                );
            },
        },
        {
            field: 'priority',
            title: 'Priority',
            filterPlaceholder: 'Priority',
            cellStyle: {
                width: '42%',
            },
            headerStyle: {
                borderRadius: '0px 5px 0px 0px',
                // display:'none'
            },
            render: ({priority}) => {
                switch (priority) {
                    case 1:
                        return <span><i className='fas fa-angle-up'/></span>;

                    case 2:
                        return <span><i className='fas fa-angle-double-up'/></span>;

                    case 3:
                        return <span><i className='fas fa-angle-double-up'/></span>;

                    default:
                        return <span></span>;

                }
            },
        },
        {
            field: '',
            title: '',
            cellStyle: {
                width: '10%',
            },
            headerStyle: {
                borderRadius: '0px 5px 0px 0px',
                // display:'none'
            },
            render: rowData => {
                if (rowData.isSubTask) {
                    return <></>;
                } else {
                    return (
                        rowData && !isMilestone ?
                            <span
                                className="table-status-add-icon"
                                style={{color: CustomTheme.primaryColor}}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    createViewPanel("Task", rowData.id, rowData.title);
                                    setSelectedRow(rowData.id)
                                    setCurrentSubRow(null);
                                }}
                            >
                <i className='fas fa-plus-circle'></i>
              </span>
                            :
                            <></>

                    );
                }
            },
        },
    ];

    const tableTitle = () => {
        return (
            <Grid style={{borderBottom: `1px dashed ${CustomTheme.primaryColor}`}} container spacing={0}
                  className='table-title'>
                <Grid className="width-100-per">
                    <Box className="margin-top-5">
                        <Tooltip title="Back">
              <span style={{color: CustomTheme.primaryColor}}
                    className="template-back-icon position-relative-class top-4"
                    onClick={() => history.push(`/configuration/templates`)}> <span
                  className="back-btn-styling-forvertical-align"><i
                  style={{fontSize: '20px'}} className="far fa-arrow-alt-circle-left"/></span> </span>
                        </Tooltip>
                        <Button size="small" variant="outlined" color="primary"
                                onClick={() => {
                                    toggleTableView(setMergedTasks, setShowTaskOfTasklist);
                                }}
                                className="project-blank-page-button-milestone btn-margin-left"
                        >
                            {
                                isMilestone
                                    ?
                                    'Show Tasklists'
                                    :
                                    'Show Milestones'
                            }
                        </Button>
                        {
                            loggedInUser.userRoles.isSystemSuperAdmin || loggedInUser.userRoles.isTeamLead
                                ? (
                                    <>
                                        <Button size="small" variant="outlined" color="primary"
                                                onClick={() => {
                                                    history.push(`/configuration/template/${templateId}/edit`);
                                                    setCurrentSubRow(null);
                                                }}
                                                className="project-blank-page-button-milestone float-right"><span
                                            className="style-for-table-header-icons"><i className="fas fa-edit"/></span>
                                            <span className="btn-margin-left"><span
                                                className="table-header-icon-title">Edit</span></span>
                                        </Button>
                                        <Button size="small" variant="outlined" color="primary"
                                                onClick={() => {
                                                    createViewPanel("Tasklist");
                                                    setCurrentSubRow(null);
                                                    setSelectedRow(null);
                                                }}
                                                className="project-blank-page-button-tasklist float-right"
                                        >
                      <span className="style-for-table-header-icons">
                        <i className="fas fa-list-alt"/>
                      </span>
                                            <span style={{borderRight: `1px solid ${CustomTheme.primaryColor}`}}
                                                  className="btn-margin-left for-border-right">
                        <span className="table-header-icon-title for-padding-right">
                          +Tasklist
                        </span>
                      </span>
                                            {
                                                showedImportMemu
                                                    ?
                                                    <span
                                                        ref={anchorRef}
                                                        className="clickbale-icon"
                                                        aria-controls={open ? "menu-list-grow" : undefined}
                                                        aria-haspopup="true"
                                                        onClick={(e) => handleToggle(e)}
                                                    >
                            <i className="fas fa-angle-up"/>
                          </span>
                                                    :
                                                    <span
                                                        ref={anchorRef}
                                                        className="clickbale-icon"
                                                        aria-controls={open ? "menu-list-grow" : undefined}
                                                        aria-haspopup="true"
                                                        onClick={(e) => handleToggle(e)}
                                                    >
                            <i className="fas fa-angle-down"/>
                          </span>
                                            }
                                        </Button>
                                        <Button size="small" variant="outlined" color="primary"
                                                onClick={() => {
                                                    createViewPanel("Milestone");
                                                    setCurrentSubRow(null);
                                                    setSelectedRow(null);
                                                }}
                                                className="project-blank-page-button-milestone float-right"><span
                                            className="style-for-table-header-icons"><i className="fas fa-flag"/></span>
                                            <span className="btn-margin-left"><span
                                                className="table-header-icon-title">+Milestone</span></span>
                                        </Button>

                                    </>
                                )
                                : <></>
                        }
                    </Box>
                </Grid>
                <Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <TableContainer>
            {tableTitle()}
            <MaterialTable
                icons={tableIcons}
                columns={columns}
                data={mergedTasks}
                options={{
                    padding: 'dense',
                    header: false,
                    toolbar: false,
                    filtering: true,
                    paging: false,
                    sorting: false,
                    emptyRowsWhenPaging: false,
                    draggable: false,
                    detailPanelColumnStyle: {
                        display: 'none',
                    },
                    headerStyle: {
                        borderBottom: '1px dashed #c5e2ff',
                        fontSize: '14px',
                        fontWeight: '600',
                        height: '40px',
                    },
                    rowStyle: rowData => {
                        return {
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#77797c',
                            borderBottom: '1px solid #f2f6fa',
                            padding: '10px',
                            lineHeight: '24px',
                            height: '50px',
                            backgroundColor: (selectedRow === rowData.id || currentSubRow === rowData.id) ? '#0faeec17' : '#FFF'
                        };
                    },
                }}
                style={{
                    boxShadow: 'none',
                    borderRadius: '5px',
                    paddingBottom: '0.8rem',
                    color: '#77797c'
                }}
                components={{
                    Row: props => {
                        return (
                            <MTableBodyRow
                                {...props}
                                draggable={isRowDrag ? "true" : 'false'}
                                onDragStart={(e) => {
                                    DragState.row = props.data.tableData.id;
                                }}
                                onDragEnter={e => {
                                    e.preventDefault();
                                    if (props.data.tableData.id !== DragState.row) {
                                        DragState.dropIndex = props.data.tableData.id;
                                    }
                                }}
                                onDragEnd={(e) => {
                                    if (DragState.dropIndex !== -1) {
                                        reOrderRow(DragState.row, DragState.dropIndex);
                                    }
                                    DragState.row = -1;
                                    DragState.dropIndex = -1;
                                }}
                            />
                        )
                    },
                }}
                onRowClick={(e, rowData) => {
                    e.stopPropagation();
                    setIsRightPanelShow(true)
                    setIdForDisplayLoader(rowData.id)
                    setCurrentSubRow(null)
                    if (rowData.isSubTask) {
                        if (!isMilestone) {
                            setSelectedRow(rowData.tasklistId)
                            setSelectedRow(rowData.id);
                            setIsRightPanelShow(true)
                            setTaskIndex(rowData.id)
                            setTasklistIndex(rowData.tasklistId)
                            // onRowEditTask(e,rowData.id,rowData.title)
                            showTaskDetailsPane(rowData.id, "Task");
                            setIsShowLoader(true)
                            setIdForDisplayLoader(rowData.id)
                        }
                    } else {
                        setCurrentSubRow(null)
                        setSelectedRow(rowData.id)
                        if (isMilestone === false) {

                            if (showTaskOfTasklist[rowData.id] === true) {

                                setTasklistIndex(rowData.id)
                                setIsShowLoaderFotFetchTasks(true)
                                setIsShowLoader(true)
                                // onRowEditTasklist(e,rowData.id);
                                showTaskDetailsPane(rowData.id, isMilestone ? "Milestone" : "Tasklist");


                                getTaskOfTasklistProject(rowData.id).then((res) => {
                                    const currentList = mergedTasks.length > 0 ? [...mergedTasks] : [...templateData];
                                    let index = currentList.findIndex(one => one.id === rowData.id);
                                    currentList.splice(index + 1, res.length);
                                    setMergedTasks(currentList);
                                    // setCurrentRow(rowData.id);

                                    setIsShowLoaderFotFetchTasks(false)
                                    setIsShowLoader(false)
                                    setIdForDisplayLoader(null)
                                    setShowTaskOfTasklist(prev =>
                                        Boolean(!prev[rowData.id])
                                            ? {...prev, [rowData.id]: true}
                                            : {...prev, [rowData.id]: false}
                                    );

                                })

                            } else {
                                setTasklistIndex(rowData.id)
                                setIsShowLoaderFotFetchTasks(true)
                                setIsShowLoader(true)
                                // onRowEditTasklist(e,rowData.id);
                                showTaskDetailsPane(rowData.id, isMilestone ? "Milestone" : "Tasklist");

                                getTaskOfTasklistProject(rowData.id).then((res) => {
                                    const list = res.filter(one => {
                                        one.isSubTask = true;
                                        return true;
                                    });
                                    const currentList = mergedTasks.length > 0 ? [...mergedTasks] : [...templateData];
                                    let index = currentList.findIndex(one => one.id === rowData.id);
                                    list.forEach(one => {
                                        currentList.splice(index + 1, 0, one);
                                        index++;
                                    });
                                    setMergedTasks(currentList);
                                    // setCurrentRow(rowData.id);

                                    setIsShowLoaderFotFetchTasks(false)
                                    setIsShowLoader(false)
                                    setIdForDisplayLoader(null)
                                    if (res.length > 0) {
                                        setShowTaskOfTasklist(prev =>
                                            Boolean(!prev[rowData.id])
                                                ? {...prev, [rowData.id]: true}
                                                : {...prev, [rowData.id]: false}
                                        );
                                    }
                                })
                            }
                        } else {
                            if (showTaskOfTasklist[rowData.id] === true) {
                                setMilestoneIndex(rowData.id);
                                setIsMileStone(true)
                                setIsShowLoaderFotFetchTasks(true)
                                setIsShowLoader(true)
                                // onRowEditMilestone(e,rowData.id);
                                showTaskDetailsPane(rowData.id, isMilestone ? "Milestone" : "Tasklist");
                                setIsShowLoaderFotFetchTasks(false)

                                let data = Object.assign([], mergedTasks);
                                let findIndex = data && data.findIndex((task) => task.id === rowData.id)
                                let findSubTasklist = data && data.filter((tasklist) => tasklist.isSubTask === true);
                                let milestoneTasklist = findSubTasklist && findSubTasklist.filter((tasklist) => tasklist.milestoneId === rowData.id)
                                let totalLength = milestoneTasklist.length
                                data.splice(findIndex + 1, totalLength);
                                setMergedTasks(data);
                                // setCurrentRow(rowData.id);
                                if (rowData.tasklists && rowData.tasklists.length > 0) {
                                    setShowTaskOfTasklist(prev =>
                                        Boolean(!prev[rowData.id])
                                            ? {...prev, [rowData.id]: false}
                                            : {...prev, [rowData.id]: false}
                                    );
                                }
                            } else {
                                setMilestoneIndex(rowData.id);
                                setIsMileStone(true)
                                setIsShowLoaderFotFetchTasks(true)
                                setIsShowLoader(true)
                                // onRowEditMilestone(e,rowData.id);
                                showTaskDetailsPane(rowData.id, isMilestone ? "Milestone" : "Tasklist");

                                setIsShowLoaderFotFetchTasks(false)
                                let list;
                                if (rowData.tasklists) {
                                    list = rowData.tasklists.filter(one => {
                                        one.isSubTask = true;
                                        return true;
                                    });
                                }
                                const currentList = mergedTasks.length > 0 ? [...mergedTasks] : [...templateData];
                                let index = currentList.findIndex(one => one.id === rowData.id);
                                list && list.forEach(one => {
                                    currentList.splice(index + 1, 0, one);
                                    index++;
                                });
                                setMergedTasks(currentList);
                                // setCurrentRow(rowData.id);
                                if (rowData.tasklists && rowData.tasklists.length > 0) {
                                    setShowTaskOfTasklist(prev =>
                                        Boolean(!prev[rowData.id])
                                            ? {...prev, [rowData.id]: true}
                                            : {...prev, [rowData.id]: false}
                                    );
                                }


                            }
                        }
                    }
                }}
            />
            <Dialog
                open={isImportTasklistOpenDialog}
                keepMounted
                maxWidth="xs"
                fullWidth
                className="dailog-box-tasklist-add"
            >
                <DialogContent>
                    <Typography variant="h2" style={{color: CustomTheme.fontColor}}
                                className="block-titles margin-top-20">
                        Import Tasklist
                    </Typography>
                    <ValidatorForm
                        onSubmit={() => onHandleInportTasklist()}
                        onError={errors => console.log(errors)}
                    >
                        <div className="import-tasklist-dropdown">
                            <Autocomplete
                                id="combo-box-demo"
                                options={tasklist}
                                fullWidth
                                label="Select tasklist"
                                getOptionLabel={(option) => option.title}
                                value={selctedConfigTasklist}
                                onChange={(event, selectedValue) => setSelectedConfigTasklist(selectedValue)}
                                renderInput={(params) =>
                                    <TextValidator
                                        {...params}
                                        fullWidth
                                        required
                                        label="Select tasklist"
                                        size="small"
                                        name="listTitle"
                                        value={selctedConfigTasklist}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                    />
                                }
                            />
                        </div>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                className="reset-dialog-button"
                                color="secondary"
                                size="small"
                                onClick={() => {
                                    setIsImportTasklistOpenDialog(false);
                                }}
                            >
                                CANCEL
                            </Button>
                            <Button
                                className="save-dialog-button"
                                variant="outlined"
                                color="primary"
                                size="small"
                                type="submit"
                            >
                                IMPORT
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                className="popper-style"
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom"
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem style={{color: CustomTheme.primaryColor}} variant="body2"
                                              className="import-menuItem-styling"
                                              onClick={() => setIsImportTasklistOpenDialog(true)}><span
                                        className="style-for-table-header-icons "><i
                                        className="far fa-list-alt"></i></span> <span
                                        className="btn-margin-left table-header-icon-title"> Import Tasklist</span></MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </TableContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTaskOfTasklistProject: (tasklistId) => {
            return dispatch(getTasksUnderTaskListProjectTemplateRequest(tasklistId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getMilestoneDetailsRequest: (milestoneId) => {
            return dispatch(getMilestoneDetails(milestoneId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getTasklistDetails: (tasklistId) => {
            return dispatch(getTasklistDetailsRequest(tasklistId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getTaskDetails: (taskId) => {
            return dispatch(getTaskDetailsRequest(taskId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllConfigTaskList: (taskId) => {
            return dispatch(getAllTaskListRequest(taskId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        importTaskList: (templateId, data) => {
            return dispatch(importTaskListInTemplateRequest(templateId, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        taskOrderRequest: (data) => {
            return dispatch(taskOrderRequestTemplate(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },


    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TableView);
