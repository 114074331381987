import React, { useEffect, useRef, useState } from 'react'
import './AddEditForm.css'
import { Box, Grid, Typography } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getAllAuthUsersRequest } from '../../../../../authentication/AuthenticationApiActions';
import { deleteMemberForShowingMessage, addTeamsRequest, editTeamsRequest, getAllTeamsRequset, getSingleTeamDetailsRequest } from '../../../../ConfigurationApiActions'
import { connect } from 'react-redux';
import CustomTheme from '../../../../../../utilities/theme/theme.json'
import MessageModal from '../../../../../../utilities/MessageModal/MessageModal'
import LoaderForSaveAndEdit from '../../../../../../utilities/AppLoader/LoaderForSaveAndEdit'
const AddEditForm = ({
    addNewTeam,
    getAllAuthUsers,
    setAddNewTeam,
    deleteMember,
    allUsers,
    getAllTeams,
    addTeam,
    editTeam,
    rowIndex,
    setRowIndex,
    getTeamDetails,
    setSelectedRow,
}) => {
    const modalRef = useRef();

    const [membersListNames, setMembersListNames] = useState([])
    const [memberName, setMemberName] = useState(null)
    const [member, setMember] = useState(null);
    const [value, setValue] = useState(null)

    const [showName, setShowName] = useState(false)
    const [showDescription, setShowDescription] = useState(false);
    const [showAssign, setShowAssign] = useState(false);
    const [addEditBtnToggle, setAddEditBtnToggle] = useState(false)
    const [isTeamOnEditForm, setIsTeamOnEditForm] = useState(false)
    const [isShowLoader, setIsShowLoader] = useState(false);
    const [addEditRes, setAddEditRes] = useState(null)

    const [teamFormData, setTeamFormData] = useState({
        name: null,
        description: null,
        members: null,
        head: null
    })

    useEffect(() => {
        getAllAuthUsers(true)
    }, [rowIndex])

    useEffect(() => {
        if (addNewTeam) {
            setTeamFormData({
                name: null,
                description: null,
                members: null,
                head: null
            })
            setMember('');
            setShowName(false);
            setShowDescription(false);
            setShowAssign(false)
            setMembersListNames([])
            setIsTeamOnEditForm(false)
            setAddEditRes('')
            setAddEditBtnToggle(false)
        }
    }, [addNewTeam])

    useEffect(() => {
        if (rowIndex) {
            setIsShowLoader(true)
            getTeamDetails(rowIndex).then((res) => {
                setIsShowLoader(false)
                let teamData = Object.assign({}, teamFormData)
                let arr = [];
                res.members && Object.keys(res.members).map((name, nameIndex) => {
                    arr.push(`${res.members[name].firstName} ${res.members[name].lastName}`)
                    return name
                })

                let arrForIds = [];
                res.members && Object.keys(res.members).map((name, nameIndex) => {
                    arrForIds.push(res.members[name].id)
                    return name
                })

                if (res.head && res.head.id) {
                    teamData.head = res.head
                }
                teamData.name = res.name
                teamData.description = res.description
                teamData.members = arrForIds
                setTeamFormData(teamData)
                setMembersListNames(arr)
                setShowName(false);
                setShowDescription(false);
                setShowAssign(false)
                setAddEditBtnToggle(true)
                setAddNewTeam(false)
            })
        } else { }
    }, [rowIndex, addEditRes])

    const hideTeamNameHandler = () => {
        setShowName(true);
    }

    const hideDescriptionHandler = () => {
        setShowDescription(true);
    }

    const clickHandler = () => {
        let data = {
            name: teamFormData.name,
        }
        if (teamFormData.description) {
            data.description = teamFormData.description
        } else {
        }
        if (teamFormData.members) {
            data.members = teamFormData.members
        } else {
        }
        if (teamFormData.head) {
            data.head = teamFormData.head.id
        } else {
        }
        if (rowIndex != null) {
            //Edit Member
            editTeam(data, rowIndex).then((res) => {

                getAllTeams()

                setRowIndex(res.id)
                setSelectedRow(res.id)
                setAddEditBtnToggle(true)
                setAddEditRes(res)
            })
        } else {
            //Add Member
            addTeam(data).then((res) => {

                getAllTeams()

                setRowIndex(res.id)
                setSelectedRow(res.id)
                setAddEditBtnToggle(true)
                setAddEditRes(res)
            })
        }
    }

    const defaultProps = {
        options: allUsers || [],
        getOptionLabel: (option) => `${option.firstName} ${option.lastName}`,
    };


    const addMemeberHandler = () => {
        const data = Object.assign([], teamFormData.members);
        const newMemberObj = member;
        data.push(newMemberObj)
        setTeamFormData({
            ...teamFormData,
            members: data
        })
        setMember('');
        setValue(null)

        const dataMemberName = Object.assign([], membersListNames);
        const newMemberNamesObj = memberName;
        dataMemberName.push(newMemberNamesObj)
        setMembersListNames(dataMemberName)

    }

    const deleteMemberHandler = (nameIndex) => {
        const data = Object.assign([], teamFormData.members);
        data.splice(nameIndex, 1);
        setTeamFormData({
            ...teamFormData,
            members: data
        })

        const memberListNameData = Object.assign([], membersListNames);
        memberListNameData.splice(nameIndex, 1);
        setMembersListNames(memberListNameData);

        deleteMember();
    }

    const showAllValuesInFormHandler = () => {
        setAddEditBtnToggle(false);
        if (teamFormData.name) {
            setShowName(true);
        } else {
        }
        if (teamFormData.description) {
            setShowDescription(true);
        } else {
        }
        if (teamFormData.head) {
            setShowAssign(true)
        } else {
        }
        setAddNewTeam(false)
    }

    const handleEsc = (e) => {
        if (e.keyCode === 27) {
            if (rowIndex) {
                if (!isTeamOnEditForm) {
                    setAddNewTeam(false)
                    setSelectedRow(null)
                } else {

                    const title = `Team`
                    const message = `Changes that you made in edit team may not be saved.`
                    modalRef.current.setState({
                        showModal: true,
                        message: message,
                        title: title,
                        showOkButton: true,
                        showCancelButton: true,
                        showNote: false,
                        note: '',
                        handleOk: (note) => {
                            setAddNewTeam(false)
                            setSelectedRow(null)
                        },
                        okText: "Yes",
                        cancelText: 'No'
                    });
                }
            } else {
                if (isTeamOnEditForm) {
                    const title = `Team`
                    const message = `Changes that you made in team may not be saved.`
                    modalRef.current.setState({
                        showModal: true,
                        message: message,
                        title: title,
                        showOkButton: true,
                        showCancelButton: true,
                        showNote: false,
                        note: '',
                        handleOk: (note) => {
                            setAddNewTeam(false)
                            setSelectedRow(null)
                        },
                        okText: "Yes",
                        cancelText: 'No'
                    });
                } else {
                    setAddNewTeam(false)
                }
            }
        }
    }

    const handleChangeInput = (event, value) => {
        const data = Object.assign({}, teamFormData);
        data[event.target.name] = event.target.value;
        if (!isTeamOnEditForm) {
            setIsTeamOnEditForm(true)
        } else {
        }
        setTeamFormData(data)
    }

    /**
* Method executes on onChange action of autocomplete form element
*/
    const handleChangeAutocomplete = (elementName, selectedvalue) => {
        let data = Object.assign({}, teamFormData);
        if (elementName === "head") {
            data.head = selectedvalue
            if (!isTeamOnEditForm) {
                setIsTeamOnEditForm(true)
            } else {
            }
        }
        else { }
        setTeamFormData(data)
    }

    return (
        <>
            {
                isShowLoader
                    ?
                    <span className="loader-for-save-and-edit"><LoaderForSaveAndEdit /></span>
                    :
                    <>
                        <ValidatorForm
                            onSubmit={() => clickHandler()}
                            onKeyDown={(e) => handleEsc(e)}
                        >
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                style={{ borderBottom: `1px dashed ${CustomTheme.primaryColor}` }}
                                className="team-detail-panel-padding">
                                <Grid container justify="space-between">
                                    <Grid>
                                    </Grid>
                                    {
                                        addEditBtnToggle
                                            ?
                                            <Grid onClick={() => {
                                                showAllValuesInFormHandler();
                                            }} className="team-detail-panel-save-btn"
                                                style={{ color: CustomTheme.primaryColor }}
                                            >
                                                <i className="far fa-edit"></i>
                                            </Grid>
                                            :
                                            <Grid className="save-btn-position-style">
                                                <Button size="small"
                                                    type="submit"
                                                    className="form-panel-save-btn submit-btn"
                                                    style={{ color: CustomTheme.primaryColor }}
                                                >
                                                    <i className="far fa-save"></i>
                                                </Button>
                                            </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                className="form-panel-assign-name-container">
                                <Grid container className="margin-top-six padding-bottom-10">
                                    <Grid item xl={1} lg={1} md={1} sm={12} xs={12}>
                                        {
                                            teamFormData.head
                                                ?
                                                <Avatar
                                                    className="team-detail-panel-team-lead-user-image">{teamFormData.head ? teamFormData.head.firstName.charAt(0) : ''}</Avatar>
                                                :
                                                <span className="team-detail-panel-user-icon"><i
                                                    className="far fa-user-circle"></i></span>
                                        }
                                    </Grid>
                                    <Grid item xl={10} lg={10} md={10} sm={12}
                                        xs={12}>
                                        {
                                            showAssign
                                                ?
                                                <Autocomplete
                                                    {...defaultProps}
                                                    className="autocomplete-text-filed"
                                                    value={teamFormData.head}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            handleChangeAutocomplete("head", newValue)
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField
                                                        label="Team Lead"  {...params} />}
                                                />
                                                :
                                                <>
                                                    <Typography variant="body2" style={{ color: CustomTheme.fontColor }}
                                                        className="form-panel-mini-text">Team Lead</Typography>
                                                    {
                                                        rowIndex
                                                            ?
                                                            <Typography
                                                                variant="body2"
                                                                onClick={() => rowIndex ? null : setShowAssign(true)}
                                                                className="form-panel-assign-style btn-cursor"
                                                                style={{ color: CustomTheme.primaryColor }}
                                                            >
                                                                <span>
                                                                    {teamFormData.head ? teamFormData.head.firstName : ''}
                                                                </span>
                                                            </Typography>
                                                            :
                                                            <Typography
                                                                variant="body2"
                                                                onClick={() => rowIndex ? null : setShowAssign(true)}
                                                                className="form-panel-assign-style btn-cursor"
                                                                style={{ color: CustomTheme.primaryColor }}
                                                            >
                                                                <i className="fas fa-plus-circle"></i>  <span>Assign</span>
                                                            </Typography>
                                                    }
                                                </>

                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid style={{ backgroundColor: CustomTheme.bodyBackground }} item xl={12} lg={12} md={12}
                                sm={12} xs={12} className="for-background-color for-padding-ten">
                                <Grid container>
                                    {
                                        showName
                                            ?
                                            <>
                                                <Grid item xl={11} lg={11} md={11} sm={12} xs={12}>
                                                    <TextValidator
                                                        fullWidth
                                                        autoFocus
                                                        // required
                                                        // variant="outlined"
                                                        size="small"
                                                        label="Team Name"
                                                        InputLabelProps={{ shrink: teamFormData.name ? true : false }}
                                                        onChange={handleChangeInput}
                                                        name="name"
                                                        value={teamFormData.name || ''}
                                                        validators={['required', 'trim', 'maxStringLength:50']}
                                                        errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                                    />
                                                </Grid>
                                            </>
                                            :
                                            <Grid onClick={rowIndex ? null : hideTeamNameHandler}>
                                                <Typography variant={rowIndex ? "h1" : "h2"}
                                                    className="add-title-text"
                                                    style={{ color: CustomTheme.primaryColor }}>
                                                    <span>
                                                        {
                                                            rowIndex
                                                                ?
                                                                teamFormData.name
                                                                :
                                                                <>
                                                                    <i className="far fa-edit"></i> Click to add team name
                                                                </>
                                                        }

                                                    </span>
                                                </Typography>
                                            </Grid>
                                    }

                                </Grid>
                            </Grid>
                            <Grid style={{ backgroundColor: CustomTheme.bodyBackground, paddingTop: '5px' }} item xl={12}
                                lg={12} md={12} sm={12} xs={12} className="for-background-color for-padding-description">
                                <Grid container>
                                    {
                                        showDescription
                                            ?
                                            <>
                                                <Grid className="description-input-container" item xl={11} lg={11}
                                                    md={11} sm={12} xs={12}>
                                                    <TextValidator
                                                        fullWidth
                                                        // required
                                                        autoFocus
                                                        // variant="outlined"
                                                        size="small"
                                                        label="Team Description"
                                                        InputLabelProps={{ shrink: teamFormData.description ? true : false }}
                                                        onChange={handleChangeInput}
                                                        name="description" value={teamFormData.description || ''}
                                                        validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:250']}
                                                        errorMessages={['At least one alphanumeric characters', 'The max length of 250 charachers reached']}
                                                    />
                                                </Grid>
                                            </>
                                            :
                                            <Grid onClick={rowIndex ? null : hideDescriptionHandler}>
                                                <Typography variant="h3" style={{ color: CustomTheme.primaryColor }}
                                                    className="add-description-text">
                                                    <span>
                                                        {
                                                            rowIndex
                                                                ?
                                                                teamFormData.description
                                                                :
                                                                <>
                                                                    <i className="far fa-edit"></i> Click to add a description for this team
                                                                </>
                                                        }
                                                    </span>
                                                </Typography>
                                            </Grid>
                                    }

                                </Grid>
                            </Grid>
                        </ValidatorForm>
                        {
                            addEditBtnToggle
                                ?
                                <></>
                                :
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                    className="form-panel-assign-name-container team-detail-panel-memeber-container">
                                    <ValidatorForm
                                        onSubmit={() => value ? addMemeberHandler() : ''}
                                    >
                                        <Grid container>
                                            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                                <Box className="team-detail-panel-add-member-box">
                                                    <Typography style={{ color: CustomTheme.fontColor }}><i
                                                        className="fas fa-user-plus"></i></Typography>
                                                    <Typography style={{ color: CustomTheme.fontColor }}
                                                        className="team-detail-panel-new-member-text">New
                                                        Member</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                                                <Autocomplete
                                                    {...defaultProps}
                                                    className="team-detail-panel-inputbase"
                                                    value={value}
                                                    onChange={(event, newValue) => {
                                                        setValue(newValue);
                                                        setMember(newValue.id)
                                                        setMemberName(`${newValue.firstName} ${newValue.lastName}`)
                                                    }}
                                                    renderInput={(params) => <TextValidator   {...params} placeholder="New Member" />}
                                                />
                                            </Grid>
                                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}
                                                className="team-detail-panel-add-member-btn">
                                                <Button size="small"
                                                    // disabled={value? false:true}
                                                    type="submit"
                                                    variant="outlined"
                                                    color="primary"
                                                    className={value != null ? "blank-page-button-tasklist btn-margin-left" : "disable-add-btn blank-page-button-tasklist btn-margin-left"}><span
                                                        className="btn-margin-left">ADD</span>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                </Grid>
                        }
                        {
                            rowIndex && membersListNames && membersListNames.length > 0
                                ?
                                <Grid className="memebers-title-on-read-only" item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Grid>
                                        <Typography variant="body1" style={{ color: CustomTheme.fontColor }}
                                            className="form-panel-dependent-task-text">
                                            Members:
                                        </Typography>
                                    </Grid>
                                </Grid>
                                :
                                <></>
                        }
                        {
                            membersListNames && membersListNames.map((name, nameIndex) => {
                                return (
                                    <Grid key={nameIndex} item xl={12} lg={12} md={12} sm={12} xs={12}
                                        className="form-panel-assign-name-container ">
                                        <Grid container>
                                            <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                                <Typography
                                                    style={{ paddingLeft: rowIndex ? '15px' : '', color: CustomTheme.fontColor }}
                                                    className="team-detail-panel-memeber-name-text">{name}</Typography>
                                            </Grid>
                                            {
                                                addEditBtnToggle
                                                    ?
                                                    <></>
                                                    :
                                                    <Grid onClick={() => deleteMemberHandler(nameIndex)} item xl={4} lg={4} md={4}
                                                        sm={12} xs={12}>
                                                        <Typography className="team-detail-panel-delete-memeber-icon"><i
                                                            className="far fa-times-circle"></i></Typography>
                                                    </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                        <MessageModal
                            ref={modalRef}
                        />
                    </>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        allUsers: state.auth.users,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllTeams: () => {
            return dispatch(getAllTeamsRequset()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addTeam: (data) => {
            return dispatch(addTeamsRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        editTeam: (data, teamId) => {
            return dispatch(editTeamsRequest(data, teamId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getTeamDetails: (id) => {
            return dispatch(getSingleTeamDetailsRequest(id)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllAuthUsers: (isLoaderShowing) => {
            return dispatch(getAllAuthUsersRequest(isLoaderShowing)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        deleteMember: () => {
            return dispatch(deleteMemberForShowingMessage())
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditForm);
