import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';

const PrivateRoute = ({component: Component, loggedInUser, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props =>
                !loggedInUser ? (
                    <Redirect to='/login'/>
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};
const mapStateToProps = state => {
    return {
        loggedInUser: state.auth.loggedInUser
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {}
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivateRoute);
