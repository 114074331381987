exports.ROUTER_ACTIONS = {

    SIGNIN: '/login',
    CHANGE_PASSWORD: '/changePassword',

    ACTION_STATION: '/actionStation',
    ACTION_STATION_MY_TASKS:'/actionStation/mytasks',

    CONFIGURATION: '/configuration',
    CONFIGURATION_ORGANIZATION: '/configuration/organization',
    CONFIGURATION_ROLES: '/configuration/roles',
    CONFIGURATION_TASKS: '/configuration/tasks',
    CONFIGURATION_PROJECTS: '/configuration/templates',
    CONFIGURATION_CHECKLISTS: '/configuration/checklists',
    CONFIGURATION_TEAMS: '/configuration/teams',
    CONFIGURATION_TEAM_ADD: '/configuration/teams/add',
    CONFIGURATION_CLIENTS: '/configuration/clients',
    CONFIGURATION_CLIENT_ADD: '/configuration/clients/add',
    CONFIGURATION_VENDORS: '/configuration/vendors',
    CONFIGURATION_VENDOR_ADD: '/configuration/vendors/add',
    CONFIGURATION_TEMPLATE: '/configuration/template/:templateId',
    CONFIGURATION_TEMPLATE_EDIT: '/configuration/template/:templateId/edit',
    CONFIGURATION_TEMPLATE_ADD: '/configuration/templateAdd',
    CONFIGURATION_TEMPLATE_CHECKLIST: '/configuration/:templateId/checklist',
    CONFIGURATION_TEMPLATE_CHECKLIST_EDIT: '/configuration/:templateId/checklist/:singleChecklistId',
    CONFIGURATION_TASKLIST_VIEW: '/configuration/tasks/:tasklistId',
    CONFIGURATION_CHECKLIST_VIEW: '/configuration/checklists/:singleChecklistId',
    CONFIGURATION_USERS: '/configuration/users',
    CONFIGURATION_USER_ADD: '/configuration/users/add',


    PROJECTS_TEAMS: '/projects/teams',
    PROJECTS_CLIENTS: '/projects/clients',
    PROJECTS_ADD: '/projects/addProject',
    PROJECTS_EDIT: '/projects/editProject/:id',

    PROJECT_TASK: '/project/:id/tasks',
    PROJECT_PARTICIPANTS: '/project/:id/participants',
    PROJECT_CHECKLISTS: '/project/:id/checklists',
    PROJECT_CHECKLIST_DETAILS: '/project/:id/checklists/:singleChecklistId',
    PROJECT_MILESTONES: '/project/:id/milestones',
    PROJECT_PROGRESS: '/project/:id/progress'
}

//Server api actions
exports.API_ACTIONS = {
    "LOGIN": "/login",
    "FORGOT_PASSWORD": "/auth/forgotPassword",
    "RESET_PASSWORD": "/resetPIN",
    "SET_PASSWORD": '/auth/setpassword',
    "CHANGE_PASSWORD": "/auth/changePassword",
    "RESOURCES": '/auth/resources',
    "ROLES_PERMISSIONS": '/auth/roles/permissions',
    "ROLES": '/auth/roles',
    "RESEND_INVITATION": '/auth/resendInvitation',

    "ADD_TASK_LIST": '/config/tasklists',
    "FETCH_ALL_CHECKLIST": '/config/checklists',
    "FETCH_SINGLE_CHECKLIST": '/config/checklists',
    "ADD_ITEMS_IN_CHECKLIST": '/config/checklists',
    "EDIT_CHECKLIST_CONFIG": '/config/checklists',
    "ADD_CHECKLIST_IN_TASK_TEMPLATE": 'config/checklists',
    "GET_ALL_TASK_LIST": 'config/tasklists',
    "GET_PREDECESSOR_TASK": 'config/tasklists',
    "GET_ALL_TASKS": 'config/tasks/tasklists',
    "GET_FUNCTIONAL_ROLE": 'config/functional-roles',
    "ADD_TASK_IN_TASK_LIST": 'config/tasks',
    "GET_TASK_DETAILS_CONFIG": 'config/tasks',
    "EDI_TASK_CONFIG": 'config/tasks',
    "FETCH_TASKLIST_DETAILS": 'config/tasklists',
    "EDIT_TASKLIST": 'config/tasklists',
    "FETCH_CHECKLIST_DETAILS": 'config/checklists',

    "CREATE_TEAM": 'config/teams',
    "FETCH_TEAMS": 'config/teams',
    "FETCH_SINGLE_TEAM": 'config/teams',
    "EDIT_TEAMS": 'config/teams',

    "FETCH_CLIENTS": 'config/clients',
    "CREATE_CLIENT": 'config/clients',
    "FETCH_SINGLE_CLIENT": 'config/clients',
    "UPDATE_CLIENT": 'config/clients',

    "CREATE_VENDOR": 'config/vendors',
    "FETCH_VENDORS": 'config/vendors',
    "FETCH_SINGLE_VENDOR": 'config/vendors',
    "UPADTE_VENDORS": 'config/vendors',

    "GET_AUTH_ALL_USERS": '/users',
    "GET_SINGLE_AUTH_USER_DETAILS": '/users',
    "ADD_AUTH_USER": '/users',
    "EDIT_USER_DETAILS": '/users',


    "PROJECT_ROLE_ADD": 'config/functional-roles',

    "GET_ALL_PROJECT_TEMPLATES": 'template/project-templates',
    "ADD_PROJECT_TEMPLATE": 'template/project-templates',
    "EDIT_PROJECT_TEMPLATE": 'template/project-templates',
    "GET_ALL_MILESTONES": 'template/milestones/projects',
    "ADD_MILESTONE_TEMPLATE": 'template/milestones',
    "EDIT_MILESTONE_TEMPLATE": 'template/milestones',
    "ADD_TASKLIST_TEMPLATE": 'template/tasklists',
    "FETCH_ALL_TASKSLIST_OF_PROJECT_TEMPLATE": 'template/tasklists',
    "EDIT_TASKLIST_TEMPLATE": 'template/tasklists',
    "FETCH_TASK_UNDER_TASKLIST_TEMPLATE": 'template/tasklists',
    "FETCH_PREDECESSOR_LIST": 'template/project-templates',
    "ADD_TASK_UNDER_TASKLIST_TEMPLATE": 'template/tasks',
    "EDIT_TASK_UNDER_TASKLIST_TEMPLATE": 'template/tasks',
    "FETCH_PROJECT_TYPES": 'config/projectsConfig',
    "FETCH_TAGS": 'config/tags',
    "ADD_TAGS": 'config/tags',
    "FETCH_ALL_CHECKLIST_IN_TEMPLATES": 'template/project-templates',
    "ADD_CHECKLIST_IN_TEMPLATES": 'template/project-templates/checklist',
    "FETCH_MILESTONE_TASKLIST_OF_TEMPLATES": 'template/project-templates',
    "MILESTONE_DETAILS": 'template/milestones',
    "GET_TASKLIST_DETAILS": 'template/tasklists',
    "GET_TASK_DETAILS": 'template/tasks',
    "GET_ALL_CURRENT_CHECKLIST_IN_TEMPLATE": 'template/project-templates',
    "GET_SINGLE_CHECKLIST_DETAILS_TEMPLATE": 'template/project-templates',
    "EDIT_CHECKLIST_TEMPLATE": 'template/project-templates/checklist',
    "DELETE_TASK_TEMPLATE": 'template/tasks',
    "TASK_ORDER_TEMPLATE": "template/tasklists/taskOrder",
    "TASKLIST_DELETE_TEMPLATE": "template/tasklists",
    "MILESTONE_DELETE_TEMPLATE": 'template/milestones',
    "UNASSOCIATED_TASKLIST_OF_TEMPLATE": 'template',
    "DELETE_TEMPLATE":'template/project-templates',

    "PROJECTS": '/projects',
    "TASKSLIST": '/tasklists',
    "TASKS": '/tasks',
    "FETCH_ALL_TASKS": '/tasklists',
    "MILESTONES": '/milestones',
    "FETCH_ALL_CHECKLIST_FOR_PROJECT": '/projects',
    "ADD_CHECKLIST_FOR_PROJECT": '/projects/checklist',
    "ADD_ITEMS_IN_CHECKLIST_FOR_PROJECT": '/projects/checklist',
    "FETCH_SINGLE_CHECKLIST_PROJECT": '/projects',
    "EDIT_CHECKLIST_PROJECT": '/projects/checklist',
    "GET_POROJECTS_OF_USER": '/users/projects',
    "ADD_PARTICIPANTS": '/projects',
    "EDIT_PROJECT": '/projects',
    "DELETE_TASK_PROJECT": '/tasks',
    "DELETE_TASKLIST_PROJECT": '/tasklists',
    "DELETE_MILESTONE_PROJECT": '/milestones',
    "TASK_ORDER_WITHIN_TASKLIST": "/tasklists/taskOrder",
    "UNASSOCIATED_TASKLIST_OF_PROJECT": '/tasklists',
    "ADD_TIMELOG_IN_TASK": '/timesheets',
    "FETCH_ALL_TIMELOG": '/timesheets/tasks',
    "EDIT_TIMELOG": '/timesheets',
    "DELETE_TIMELOG": 'timesheets',
    "DELETE_PROJECT":'projects',

    "ACTION_STATION_MY_TASKS":'actionStation/userActions',

    "PROGRESS_TASKS_ALL":'projects'

    // "SIGN_UP": "/signup",
    // "RESET_PASSWORD": "/resetpassword",
};

exports.SNACKBAR_VARIAINT = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info'
};

exports.ONLY_BLANK_SPACES = "onlyBlankSpaces";
exports.VALIDATE_PASSWORD = "validatePassword";
exports.IS_PASSWORD_MATCH = "isPasswordMatch";

exports.ROW_PER_PAGE_10 = 10;
exports.ROW_PER_PAGE_20 = 20;
exports.ROW_PER_PAGE_5 = 5;
exports.POST_TITLE_LENGTH_VALIDATION_280 = 280;
exports.PAGE_SIZE_OPTIONS = [1, 5, 10, 20, 30, 40, 50];
