import React, {useState} from 'react';
import "./Roles.css";
import CustomTheme from '../../../../utilities/theme/theme.json'
import {Button, Card, CardContent, Grid, Typography} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';

const Roles = ({history}) => {

    const [roles, setRoles] = useState([{name: '', description: ''}])

    /**
     * Function executes on onChange action of form element
     */
    const handleChangeInput = (event, roleIndex) => {
        const data = Object.assign([], roles);
        data[roleIndex][event.target.name] = event.target.value;
        setRoles(data);
    };

    /**
     * Function executes on submit form action
     */
    const handleSubmit = () => {

    };

    const addRole = () => {
        const data = Object.assign([], roles);
        const newRoleObj = {name: '', description: ''};
        data.push(newRoleObj);
        setRoles(data);
    }

    const removeRole = (roleIndex) => {
        const data = Object.assign([], roles);
        data.splice(roleIndex, 1);
        setRoles(data);
    }

    return (
        <Card>
            <CardContent>
                <Typography style={{color: CustomTheme.fontColor}} variant="h1" className="role-info-title">Roles
                    Information</Typography>
                <Typography variant="body1">
                    Aenean aliquet, leo ut venenatis laoreet, urna nulla suscipit elit, id auctor nulla sem in felis.
                    Nam congue mollis quam, eget sodales mauris vestibulum ut.
                    Pellentesque risus enim, venenatis at massa eget, sagittis eleifend diam. Morbi at ultricies arcu,
                    eu viverra urna.
                    Etiam ut posuere metus, a elementum orci. Nulla ac risus neque.
                </Typography>
                <Typography variant="h2" style={{color: CustomTheme.fontColor}} className="block-titles margin-top-20">Functional
                    Roles</Typography>
                <Typography variant="body1">
                    Ut feugiat nisl urna, vel ultrices felis sollicitudin et.
                    Morbi lectus erat, posuere ac diam vitae, vehicula porttitor enim.
                    Vestibulum nec convallis purus.
                </Typography>

                <Grid container>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <ValidatorForm
                            onSubmit={handleSubmit}
                            noValidate
                            onError={errors => console.log(errors)}
                        >
                            {
                                roles.length > 0 && roles.map((role, roleIndex) => (
                                    <Grid container spacing={3} key={role} className="role-details-container">
                                        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                                            <TextValidator
                                                fullWidth
                                                required
                                                // variant="outlined"
                                                size="small"
                                                label="Role Name"
                                                onChange={(e) => handleChangeInput(e, roleIndex)}
                                                name="name" value={role.name}
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            />
                                        </Grid>
                                        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                                            <TextValidator
                                                fullWidth
                                                required
                                                // variant="outlined"
                                                size="small"
                                                label="Description"
                                                onChange={(e) => handleChangeInput(e, roleIndex)}
                                                name="description" value={role.description}
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            />
                                        </Grid>
                                        <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                            {
                                                (roleIndex === (roles.length - 1))
                                                    ? (
                                                        <i
                                                            className="add-role-icon fas fa-plus-circle"
                                                            aria-hidden="true"
                                                            onClick={addRole}
                                                        ></i>
                                                    )
                                                    : (
                                                        <i
                                                            className="remove-role-icon far fa-times-circle"
                                                            onClick={() => removeRole(roleIndex)}
                                                        ></i>
                                                    )
                                            }
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </ValidatorForm>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>

                    </Grid>
                </Grid>
                <Button className="save-button" variant="contained" color="primary">Save</Button>
                <Button className="reset-button" variant="contained" color="primary">Reset</Button>
            </CardContent>
        </Card>
    )

}

export default Roles;
