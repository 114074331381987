import React, {useEffect, useState} from 'react'
import './AddEditForm.css';
import {Box, Grid, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Divider from '@material-ui/core/Divider';
import {connect} from 'react-redux';
import Chip from '@material-ui/core/Chip';
import ChecklistAdd from './ChecklistAdd/ChecklistAdd';
import SearchableDropDown from '../../../../../../../../utilities/SearchableDropDown/SearchableDropDown';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CustomTheme from '../../../../../../../../utilities/theme/theme.json'
import ChecklistItemsDraggableDialog
    from '../../../../../../../../utilities/ChecklistItemsDraggableDialog/ChecklistItemsDraggableDialog';
import {
    getAllChecklistFromTemplates,
    getAllMilestonesRequest,
    getAllTaskListRequest,
    getPredecessorRequest
} from '../../../../../../ConfigurationApiActions'
import ChecklistDropdownSearchable
    from '../../../../../../../../utilities/SearchableDropDown/ChecklistDropdownSearchable/ChecklistDropdownSearchable'

const AddEditForm = ({
                       setTagName,
                       allTags,
                       deleteForm,
                       handleEsc,
                       hideTemplate,
                       hideTitle,
                       setHideTitle,
                       title,
                       setTitle,
                       description,
                       setDescription,
                       hideDescription,
                       setHideDescription,
                       chips,
                       setChips,
                       hideChipTitle,
                       setHideChipTitle,
                       saveHandler,
                       saveTaskHandler,
                       saveHandlerMilestone,
                       predecessorList,
                       selecetedPredecessor,
                       setSelecetedPredecessor,
                       setShowTasklistDropDown,
                       showTasklistDropDown,
                       allTaskList,
                       setSelectedTaskList,
                       selectedTaskList,
                       approver,
                       setSelectedApprover,
                       selectedApprover,
                       showApprovalDropdown,
                       setShowApprovalDropdown,
                       setShowChecklistDropdown,
                       showChecklistDropdown,
                       setAllCheckList,
                       allCheckList,
                       setTaskSelectedCheckList,
                       taskSelectedCheckList,
                       getAllChecklistRequest,
                       setShowMilestoneDropdown,
                       showMilestoneDropdown,
                       setSelectedMilestone,
                       selectedMilestone,
                       priority,
                       selectedPriority,
                       setSelectedPriority,
                       showPriorityDropdown,
                       setShowPriorityDropdown,
                       setSlectedTaskForUnderTasklist,
                       slectedTaskForUnderTasklist,
                       projectId,
                       onCancelTaskFormHandler,
                       fotStoreTaskName,
                       editHandler,
                       isFormEdit,
                       setIsFormEdit,
                       allMilestones,
                       arrayOfTasklistName,
                       isEditPredecessor
                   }) => {
    const [openAddCheckList, setOpenAddCheckList] = useState(false);
    const [openChecklistItemsDialog, setOpenChecklistItemsDialog] = useState(false);
    const [isChecklistNewCreating, setIsChecklistNewCreating] = useState(false);
    useEffect(() => {

        // getting all predecessor task
        // getPredecessorTask().then((res) => {
        //     setPredecessorList(res)
        // }).catch((err) => {
        // })

        // get all checklist

        getAllChecklistRequest(projectId).then((res) => {
            let result = Object.entries(res).map(([k, v]) => (v));
            let createChecklistObj = {title: 'Create checklist', id: "1"}
            let nonelistObj = {title: 'None', id: null}
            result.unshift(createChecklistObj, nonelistObj)
            setAllCheckList(result)
        })


    }, [])


    const hideTitleHandler = () => {
        setHideTitle(true)
    }

    const hideDescriptionHandler = () => {
        setHideDescription(true)
    }

    const hideTagHandler = () => {
        setHideChipTitle(true)
    }

    const clickHandler = () => {
        if (hideTemplate === 'Tasklist') {
            saveHandler()
        } else if (hideTemplate === 'Task') {
            saveTaskHandler()
        } else if (hideTemplate === 'Milestone') {
            saveHandlerMilestone()
        }
    }

    const removeTaskFromSelection = (id) => {
        let data = Object.assign([], selecetedPredecessor);
        data = data.filter((item) => item.id !== id);
        setSelecetedPredecessor(data);
    }

    const renderSinglePredecessor = (id) => {

        const findTask = predecessorList.find((item) => item.id === id);
        if (findTask) {
            return (
                <>
                    <Grid container justify="space-between">
                        <Grid className="for-display-flex">
                            {
                                isEditPredecessor
                                    ?
                                    // <span style={{color:CustomTheme.errorColor}} className='blocked'>
                                    //     <i className='fas fa-share-alt'></i> Blocked
                                    // </span>
                                    <Typography style={{color: CustomTheme.errorColor}} variant="h4">
                                        <i className='fas fa-share-alt'></i> Blocked
                                    </Typography>
                                    :
                                    <></>
                            }
                            <Typography variant={isEditPredecessor ? 'h4' : "body2"}
                                        style={{color: CustomTheme.fontColor}}
                                        className={isEditPredecessor ? 'on-edit-task-view-predecessors' : "form-panel-followers-text"}>{findTask.title}</Typography>
                        </Grid>
                        {
                            isEditPredecessor
                                ?
                                <></>
                                :
                                <Grid>
                                    <Typography style={{color: CustomTheme.errorColor}}
                                                className="form-panel-task-delete-icon"
                                                onClick={() => removeTaskFromSelection(findTask.id)}><i
                                        className="far fa-times-circle"></i></Typography>
                                </Grid>
                        }
                    </Grid>
                </>
            )
        } else {
            return <></>
        }
    }

    return (
        <>
            <ValidatorForm
                action='#'
                method='post'
                onSubmit={() => {
                    isChecklistNewCreating ? console.log() : clickHandler()
                }}
                onKeyDown={(e) => handleEsc(e)}
            >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                      style={{borderBottom: `1px dashed ${CustomTheme.primaryColor}`}} className="form-panel-padding">
                    <Grid container justify="flex-end">
                        {
                            isFormEdit
                                ?
                                <Grid>

                                </Grid>
                                :
                                <Grid style={{color: CustomTheme.errorColor}}
                                      className="form-panel-canel-btn-task padding-right-5 "
                                      onClick={() => {
                                          onCancelTaskFormHandler();
                                      }}
                                >
                                    {
                                        hideTemplate === 'Task'
                                            ?
                                            <i className="far fa-times-circle"></i>
                                            :
                                            <></>
                                    }
                                </Grid>
                        }

                        {
                            isFormEdit
                                ?
                                <>
                                    <Grid
                                        onClick={() => {
                                            deleteForm(hideTemplate)
                                        }}
                                        style={{color: CustomTheme.primaryColor, paddingRight: '10px'}}
                                        className="form-panel-save-btn"
                                    >
                                        <i className="far fa-trash-alt"></i>
                                    </Grid>
                                    <Grid
                                        onClick={() => {
                                            editHandler();
                                            setIsFormEdit(false);
                                        }}
                                        style={{color: CustomTheme.primaryColor}}
                                        className="form-panel-save-btn">
                                        <i className="far fa-edit"></i>
                                    </Grid>
                                </>
                                :
                                <Grid className="form-panel-save-btn">

                                    <Button size="small"
                                            type="submit"
                                            style={{color: CustomTheme.primaryColor}}
                                            className="form-panel-save-btn submit-btn"
                                    >
                                        <i className="far fa-save"></i>
                                    </Button>
                                </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid style={{backgroundColor: CustomTheme.bodyBackground}} item xl={12} lg={12} md={12} sm={12} xs={12}
                      className="form-panel-assign-name-container for-background-color">
                    <Grid container>
                        {
                            hideTitle
                                ?
                                <>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <TextValidator
                                            fullWidth
                                            autoFocus
                                            size="small"
                                            label={
                                                hideTemplate === "Milestone"
                                                    ?
                                                    'Add milestone title'
                                                    :
                                                    hideTemplate === "Tasklist"
                                                        ?
                                                        'Add tasklist title'
                                                        :
                                                        hideTemplate === "Task"
                                                            ?
                                                            'Add task title'
                                                            :
                                                            ''
                                            }
                                            onChange={(e) => setTitle(e.target.value)}
                                            name="name" value={title || ''}
                                            validators={['required', 'trim', 'maxStringLength:50']}
                                            errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached.']}
                                        />
                                    </Grid>
                                </>
                                :
                                <>
                                    {
                                        isFormEdit
                                            ?
                                            <Grid>
                                                <Typography variant="h1" style={{color: CustomTheme.primaryColor}}
                                                            onClick={() => {
                                                                setHideTitle(false)
                                                            }} className="add-title-text">
                                                <span>
                                                    {title}
                                                </span>
                                                </Typography>
                                            </Grid>
                                            :
                                            <Grid onClick={hideTitleHandler}>
                                                <Typography variant="h2" style={{color: CustomTheme.primaryColor}}
                                                            className="add-title-text">
                                                <span><i className="far fa-edit"></i> Click to add
                                                    {
                                                        hideTemplate === "Milestone"
                                                            ?
                                                            ' milestone '
                                                            :
                                                            hideTemplate === "Tasklist"
                                                                ?
                                                                ' tasklist '
                                                                :
                                                                hideTemplate === "Task"
                                                                    ?
                                                                    ' task '
                                                                    :
                                                                    ''
                                                    }
                                                    title
                                                </span>
                                                </Typography>
                                            </Grid>
                                    }
                                </>

                        }

                    </Grid>
                </Grid>
                <Grid style={{backgroundColor: CustomTheme.bodyBackground}} item xl={12} lg={12} md={12} sm={12} xs={12}
                      className="form-panel-assign-name-container for-background-color padding-bottom-10 ">
                    <Grid container>
                        {
                            hideDescription
                                ?
                                <>
                                    <Grid className="" item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <TextValidator
                                            fullWidth
                                            autoFocus
                                            size="small"
                                            label={
                                                hideTemplate === "Milestone"
                                                    ?
                                                    'Add milestone description'
                                                    :
                                                    hideTemplate === "Tasklist"
                                                        ?
                                                        'Add tasklist description'
                                                        :
                                                        hideTemplate === "Task"
                                                            ?
                                                            'Add task description'
                                                            :
                                                            ''
                                            }
                                            onChange={(e) => setDescription(e.target.value)}
                                            name="name" value={description || ''}
                                            validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:250']}
                                            errorMessages={['At least one alphanumeric characters', 'The max length of 250 charachers reached']}
                                        />
                                    </Grid>
                                </>
                                :
                                <>
                                    {
                                        isFormEdit
                                            ?
                                            <Grid>
                                                <Typography variant="h3" onClick={() => {
                                                    setHideDescription(false)
                                                }} style={{color: CustomTheme.primaryColor}}
                                                            className="add-description-text">
                                                <span>
                                                   {description ? description : ' -'}
                                                </span>
                                                </Typography>
                                            </Grid>
                                            :
                                            <Grid onClick={hideDescriptionHandler}>
                                                <Typography variant="h3" style={{color: CustomTheme.primaryColor}}
                                                            className="add-description-text">
                                                <span><i className="far fa-edit"></i> Click to add a description for this
                                                    {
                                                        hideTemplate === "Milestone"
                                                            ?
                                                            ' milestone '
                                                            :
                                                            hideTemplate === "Tasklist"
                                                                ?
                                                                ' tasklist '
                                                                :
                                                                hideTemplate === "Task"
                                                                    ?
                                                                    ' task '
                                                                    :
                                                                    ''
                                                    }
                                                </span>
                                                </Typography>
                                            </Grid>
                                    }
                                </>

                        }

                    </Grid>
                </Grid>
                {
                    hideChipTitle
                        ?
                        // <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        //     <Box className={classes.tagsContaianer}>
                        //         <span className={classes.tagIcon}>
                        //             <i className='fas fa-tags'></i>
                        //         </span>
                        //         <Box className={classes.chipInputContainer}>
                        //             <ChipInput
                        //                 disableUnderline={true}
                        //                 fullWidth
                        //                 value={chips}
                        //                 onAdd={(chip) => handleAddChips(chip)}
                        //                 onChange={(chips) => setChips(chips)}
                        //                 onDelete={(chip, index) => handleDeleteChips(chip, index)}
                        //                 classes={{
                        //                     root: classes.chipInput,
                        //                     inputRoot: classes.inputRoot,
                        //                     input: classes.input,
                        //                     chip: classes.chip,
                        //                 }}
                        //             />
                        //         </Box>
                        //     </Box>
                        // </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <span className="tag-icon-span-in-formpanel">
                                <i className='fas fa-tags'></i>
                            </span>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                value={chips}
                                className="tags-contianer"
                                onChange={(event, newValue) => {
                                    setChips(newValue);
                                    // alert(newValue)
                                    // addChips(event,newValue);
                                }}
                                onInputChange={(event, newValue) => {
                                    setTagName(newValue);
                                }}
                                clearOnEscape={true}
                                options={allTags ? Object.keys(allTags).map((option) => allTags[option].name) : []}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip key={index} variant="outlined" label={option} {...getTagProps({index})} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="filled" placeholder="Type tag and hit enter"/>
                                )}
                            />
                        </Grid>
                        :
                        <>
                            {
                                isFormEdit || chips.length > 0
                                    ?
                                    <Grid onClick={() => {
                                        setHideChipTitle(false)
                                    }} className="add-tag-text" item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Grid container>
                                            <Grid style={{color: CustomTheme.fontColor}} className="tag-icon-container">
                                                <i className='fas fa-tags'></i>
                                            </Grid>
                                            <Grid style={{color: CustomTheme.primaryColor}}
                                                  className="add-tag-container">
                                                <Grid container>
                                                    <Grid>
                                                        {/* <i className='fas fa-plus-circle'></i> */}
                                                    </Grid>
                                                    <Grid style={{marginTop: '-5px'}}>
                                                        <div className="chip-render-in-table">
                                                            {chips && chips.map((chipsData) => {
                                                                return (
                                                                    <Chip
                                                                        key={chipsData}
                                                                        style={{backgroundColor: CustomTheme.primaryColor}}
                                                                        size="small"
                                                                        label={chipsData}
                                                                        className="margin-right-5  chip-element"
                                                                    />
                                                                )
                                                            })}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid onClick={hideTagHandler} className="add-tag-text" item xl={12} lg={12} md={12}
                                          sm={12} xs={12}>
                                        <Grid container>
                                            <Grid style={{color: CustomTheme.fontColor}} className="tag-icon-container">
                                                <i className='fas fa-tags'></i>
                                            </Grid>
                                            <Grid style={{color: CustomTheme.primaryColor}}
                                                  className="add-tag-container">
                                                <i className='fas fa-plus-circle'></i>  Add a tag
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            }
                        </>

                }


                {
                    hideTemplate === 'Milestone'
                        ?
                        <>
                            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}
                                  className="form-panel-assign-name-container margin-top-10 ">
                                {/* <Box className="task-items-list " > */}
                                {
                                    showTasklistDropDown
                                        ?
                                        <Grid className="dropdown-margins for-paddong-right" item xl={4} lg={4} md={4}
                                              sm={12} xs={12}>
                                            <Autocomplete
                                                multiple
                                                id="tags-standard"
                                                options={allTaskList || []}
                                                getOptionLabel={(option) => option ? option.title : ''}
                                                value={selectedTaskList ? selectedTaskList : {}}
                                                onChange={(event, newRole) => {
                                                    setSelectedTaskList(newRole)
                                                }}
                                                getOptionSelected={(option, value) =>
                                                    option.id === value.id
                                                }
                                                renderInput={(params) => (
                                                    <TextValidator
                                                        {...params}
                                                        variant="standard"
                                                        label="Select tasklist"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        :
                                        <>
                                            {
                                                isFormEdit || selectedTaskList.length > 0
                                                    ?
                                                    <Grid className="dropdown-margins for-paddong-right padding-top-10"
                                                          item xl={4} lg={4} md={4} sm={12} xs={12}
                                                          onClick={() => {
                                                              setShowTasklistDropDown(false);

                                                          }}
                                                    >
                                                        <Typography variant="body2"
                                                                    style={{color: CustomTheme.fontColor}}
                                                                    className="form-panel-followers-text"><i
                                                            className="far fa-list-alt"></i> Tasklist</Typography>
                                                        <Typography variant="h4"
                                                                    style={{color: CustomTheme.primaryColor}}
                                                                    className="form-panel-bold-text">
                                                            {
                                                                arrayOfTasklistName
                                                                    ? (
                                                                        <span>
                                                            {arrayOfTasklistName.join(",\n")}
                                                        </span>
                                                                    )
                                                                    : "-"
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    :
                                                    <Grid className="dropdown-margins for-paddong-right padding-top-10"
                                                          item xl={4} lg={4} md={4} sm={12} xs={12}
                                                          onClick={() => {
                                                              setShowTasklistDropDown(true);

                                                          }}
                                                    >
                                                        <Typography variant="body2"
                                                                    style={{color: CustomTheme.fontColor}}
                                                                    className="form-panel-followers-text"><i
                                                            className="far fa-list-alt"></i> Tasklist</Typography>
                                                        <Typography variant="body2"
                                                                    style={{color: CustomTheme.primaryColor}}
                                                                    className="form-panel-bold-text"><i
                                                            className="fas fa-link"></i> Link tasklist</Typography>
                                                    </Grid>
                                            }
                                        </>

                                }
                                {
                                    showApprovalDropdown
                                        ?
                                        <Grid className="dropdown-margins for-paddong-right" item xl={4} lg={4} md={4}
                                              sm={12} xs={12}>
                                            <SearchableDropDown title={false} name={true} checklist={false}
                                                                value={selectedApprover} setValue={setSelectedApprover}
                                                                label="Select Approval" optionsList={approver}/>
                                        </Grid>
                                        :
                                        <>
                                            {
                                                selectedApprover != null
                                                    ?
                                                    <Grid className="dropdown-margins for-paddong-right padding-top-10"
                                                          item xl={4} lg={4} md={4} sm={12} xs={12}
                                                          onClick={() => {
                                                              isFormEdit ? setShowApprovalDropdown(false) : setShowApprovalDropdown(true)
                                                          }}>
                                                        <Typography variant="body2"
                                                                    style={{color: CustomTheme.fontColor}}
                                                                    className="form-panel-followers-text"><i
                                                            className="fas fa-check-double"></i> Approval</Typography>
                                                        <Typography variant={isFormEdit ? "h4" : "body2"}
                                                                    style={{color: CustomTheme.primaryColor}}
                                                                    className="form-panel-bold-text">
                                                            {
                                                                isFormEdit
                                                                    ?
                                                                    <></>
                                                                    :
                                                                    <i className="far fa-edit"></i>
                                                            }
                                                            {selectedApprover.name === "Yes" ? " Yes" : " No"}</Typography>
                                                    </Grid>
                                                    :
                                                    <Grid className="dropdown-margins for-paddong-right padding-top-10"
                                                          item xl={4} lg={4} md={4} sm={12} xs={12}
                                                          onClick={() => setShowApprovalDropdown(true)}>
                                                        <Typography variant="body2"
                                                                    style={{color: CustomTheme.fontColor}}
                                                                    className="form-panel-followers-text"><i
                                                            className="fas fa-check-double"></i> Approval</Typography>
                                                        <Typography variant="body2"
                                                                    style={{color: CustomTheme.primaryColor}}
                                                                    className="form-panel-bold-text"><i
                                                            className="far fa-edit"></i> Set Approval</Typography>
                                                    </Grid>
                                            }
                                        </>

                                }
                                {
                                    showChecklistDropdown
                                        ?
                                        <Grid className="dropdown-margins for-paddong-right" item xl={4} lg={4} md={4}
                                              sm={12} xs={12}>
                                            {/* <SearchableDropDown title={true} name={true} checklist={true} setOpenAddCheckList={setOpenAddCheckList} setOpen={setOpen} value={taskSelectedCheckList} setValue={setTaskSelectedCheckList} label="Select checklist" optionsList={allCheckList} /> */}
                                            <ChecklistDropdownSearchable project={false}
                                                                         setIsChecklistNewCreating={setIsChecklistNewCreating}
                                                                         setValue={setTaskSelectedCheckList}
                                                                         selectedValue={taskSelectedCheckList}
                                                                         setOpenAddCheckList={setOpenAddCheckList}
                                                                         setAllCheckList={setAllCheckList}
                                                                         optionsList={allCheckList}/>
                                        </Grid>
                                        :
                                        <>
                                            {
                                                isFormEdit
                                                    ?
                                                    <Grid className="dropdown-margins for-paddong-right padding-top-10"
                                                          item xl={4} lg={4} md={4} sm={12} xs={12}

                                                    >
                                                        <Typography variant="body2"
                                                                    style={{color: CustomTheme.fontColor}}
                                                                    className="form-panel-followers-text"><i
                                                            className="far fa-list-alt"></i> Checklist</Typography>
                                                        <Typography variant="h4"
                                                                    onClick={() => setOpenChecklistItemsDialog(true)}
                                                                    style={{
                                                                        color: CustomTheme.primaryColor,
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    className="form-panel-bold-text for-ovarlapping-text">
                                                            {
                                                                taskSelectedCheckList && taskSelectedCheckList.id && allCheckList && allCheckList.filter((checklist) => checklist.id === taskSelectedCheckList.id).map((singleChecklist) => {
                                                                    return ` ${singleChecklist.title}`
                                                                })
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    :
                                                    <Grid className="dropdown-margins for-paddong-right padding-top-10"
                                                          item xl={4} lg={4} md={4} sm={12} xs={12}
                                                          onClick={() => setShowChecklistDropdown(true)}>
                                                        <Typography variant="body2"
                                                                    style={{color: CustomTheme.fontColor}}
                                                                    className="form-panel-followers-text"><i
                                                            className="far fa-list-alt"></i> Checklist</Typography>
                                                        <Typography variant="body2"
                                                                    style={{color: CustomTheme.primaryColor}}
                                                                    className="form-panel-bold-text"><i
                                                            className="fas fa-link"></i> Add Checklist</Typography>
                                                    </Grid>
                                            }
                                        </>

                                }

                                {/* </Box> */}
                            </Grid>
                        </>
                        :
                        hideTemplate === 'Tasklist'
                            ?
                            <>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                      className="form-panel-assign-name-container margin-top-10">
                                    <Grid container>
                                        {
                                            showMilestoneDropdown
                                                ?
                                                <Grid className="dropdown-margins for-paddong-right" item xl={4} lg={4}
                                                      md={4} sm={12} xs={12}>
                                                    <SearchableDropDown title={true} name={false} checklist={false}
                                                                        value={selectedMilestone}
                                                                        setValue={setSelectedMilestone}
                                                                        label="Select milestone"
                                                                        optionsList={allMilestones}/>
                                                </Grid>
                                                :
                                                <>
                                                    {
                                                        isFormEdit
                                                            ?
                                                            <Grid onClick={() => {
                                                                setShowMilestoneDropdown(false)
                                                            }} item xl={4} lg={4} md={4} sm={12} xs={12}
                                                            >
                                                                <Typography variant="body2"
                                                                            style={{color: CustomTheme.fontColor}}
                                                                            className="form-panel-followers-text"><i
                                                                    className="fas fa-flag"></i> Milestone</Typography>
                                                                <Typography variant="h4"
                                                                            style={{color: CustomTheme.primaryColor}}
                                                                            className="form-panel-bold-text">
                                                                    {
                                                                        selectedMilestone && selectedMilestone.id && allMilestones && allMilestones.filter((milestone) => milestone.id === selectedMilestone.id).map((milestoneArray) => {
                                                                            return `${milestoneArray.title}`
                                                                        })
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            :
                                                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}
                                                                  onClick={() => {
                                                                      setShowMilestoneDropdown(true);

                                                                  }}>
                                                                <Typography variant="body2"
                                                                            style={{color: CustomTheme.fontColor}}
                                                                            className="form-panel-followers-text"><i
                                                                    className="fas fa-flag"></i> Milestone</Typography>
                                                                <Typography variant="body2"
                                                                            style={{color: CustomTheme.primaryColor}}
                                                                            className="form-panel-bold-text"><i
                                                                    className="fas fa-link"></i> Link
                                                                    milestone</Typography>
                                                            </Grid>
                                                    }
                                                </>

                                        }

                                        {
                                            showApprovalDropdown
                                                ?
                                                <Grid className="dropdown-margins for-paddong-right" item xl={4} lg={4}
                                                      md={4} sm={12} xs={12}>
                                                    <SearchableDropDown title={false} name={true} checklist={false}
                                                                        value={selectedApprover}
                                                                        setValue={setSelectedApprover}
                                                                        label="Select Approval" optionsList={approver}/>
                                                </Grid>
                                                :
                                                <>
                                                    {
                                                        selectedApprover != null
                                                            ?
                                                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}
                                                                  onClick={() => isFormEdit ? setShowApprovalDropdown(false) : setShowApprovalDropdown(true)}>
                                                                <Typography variant="body2"
                                                                            style={{color: CustomTheme.fontColor}}
                                                                            className="form-panel-followers-text"><i
                                                                    className="fas fa-check-double"></i> Approval</Typography>
                                                                <Typography variant={isFormEdit ? "h4" : "body2"}
                                                                            style={{color: CustomTheme.primaryColor}}
                                                                            className="form-panel-bold-text">
                                                                    {
                                                                        isFormEdit
                                                                            ?
                                                                            <></>
                                                                            :
                                                                            <i className="far fa-edit"></i>
                                                                    }
                                                                    {selectedApprover.name === "Yes" ? " Yes" : " No"}</Typography>
                                                            </Grid>
                                                            :
                                                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}
                                                                  onClick={() => setShowApprovalDropdown(true)}>
                                                                <Typography variant="body2"
                                                                            style={{color: CustomTheme.fontColor}}
                                                                            className="form-panel-followers-text"><i
                                                                    className="fas fa-check-double"></i> Approval</Typography>
                                                                <Typography variant="body2"
                                                                            style={{color: CustomTheme.primaryColor}}
                                                                            className="form-panel-bold-text"><i
                                                                    className="far fa-edit"></i> Set
                                                                    Approval</Typography>
                                                            </Grid>
                                                    }
                                                </>

                                        }

                                        {
                                            showChecklistDropdown
                                                ?
                                                <Grid className="dropdown-margins for-paddong-right" item xl={4} lg={4}
                                                      md={4} sm={12} xs={12}>
                                                    <ChecklistDropdownSearchable project={false}
                                                                                 setIsChecklistNewCreating={setIsChecklistNewCreating}
                                                                                 setValue={setTaskSelectedCheckList}
                                                                                 selectedValue={taskSelectedCheckList}
                                                                                 setOpenAddCheckList={setOpenAddCheckList}
                                                                                 setAllCheckList={setAllCheckList}
                                                                                 optionsList={allCheckList}/>
                                                    {/* <SearchableDropDown title={true} name={true} checklist={true} setOpenAddCheckList={setOpenAddCheckList} setOpen={setOpen} value={taskSelectedCheckList} setValue={setTaskSelectedCheckList} label="Select checklist" optionsList={allCheckList} /> */}
                                                </Grid>
                                                :
                                                <>
                                                    {
                                                        isFormEdit
                                                            ?
                                                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                                                <Typography variant="body2"
                                                                            style={{color: CustomTheme.fontColor}}
                                                                            className="form-panel-followers-text"><i
                                                                    className="far fa-list-alt"></i> Checklist</Typography>
                                                                <Typography variant="h4"
                                                                            onClick={() => setOpenChecklistItemsDialog(true)}
                                                                            style={{
                                                                                color: CustomTheme.primaryColor,
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            className="form-panel-bold-text for-ovarlapping-text">
                                                                    {
                                                                        taskSelectedCheckList && taskSelectedCheckList && allCheckList && allCheckList.filter((checklist) => checklist.id === taskSelectedCheckList.id).map((singleChecklist) => {
                                                                            return `${singleChecklist.title}`
                                                                        })
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            :
                                                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}
                                                                  onClick={() => setShowChecklistDropdown(true)}>
                                                                <Typography variant="body2"
                                                                            style={{color: CustomTheme.fontColor}}
                                                                            className="form-panel-followers-text"><i
                                                                    className="far fa-list-alt"></i> Checklist</Typography>
                                                                <Typography variant="body2"
                                                                            style={{color: CustomTheme.primaryColor}}
                                                                            className="form-panel-bold-text"><i
                                                                    className="fas fa-link"></i> Add
                                                                    Checklist</Typography>
                                                            </Grid>
                                                    }
                                                </>

                                        }

                                    </Grid>
                                </Grid>
                            </>
                            :
                            hideTemplate === 'Task'
                                ?
                                <>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                          className="form-panel-assign-name-container margin-top-10">
                                        <Grid>
                                            <Box className="task-items-list ">
                                                {
                                                    showTasklistDropDown
                                                        ?
                                                        <Box className="dropdown-margins">
                                                            <SearchableDropDown title={true} name={false}
                                                                                checklist={false}
                                                                                value={slectedTaskForUnderTasklist}
                                                                                setValue={setSlectedTaskForUnderTasklist}
                                                                                label="Select tasklist"
                                                                                optionsList={allTaskList}/>
                                                        </Box>
                                                        :
                                                        <>
                                                            {
                                                                isFormEdit || fotStoreTaskName != null
                                                                    ?
                                                                    <Box
                                                                        onClick={() => setShowTasklistDropDown(false)}
                                                                    >
                                                                        <Typography variant="body2"
                                                                                    style={{color: CustomTheme.fontColor}}
                                                                                    className="form-panel-followers-text"><i
                                                                            className="far fa-list-alt"></i> Tasklist</Typography>
                                                                        <Typography
                                                                            variant={isFormEdit ? "h4" : "body2"}
                                                                            style={{color: CustomTheme.primaryColor}}
                                                                            className="form-panel-bold-text for-ovarlapping-text">
                                                                            {
                                                                                isFormEdit
                                                                                    ?
                                                                                    <></>
                                                                                    :
                                                                                    <i className="fas fa-link"></i>
                                                                            }
                                                                            {
                                                                                fotStoreTaskName != null
                                                                                    ?
                                                                                    <>
                                                                                        {` ${fotStoreTaskName}`}
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            allTaskList && slectedTaskForUnderTasklist && allTaskList.filter((tasklist) => tasklist.id === slectedTaskForUnderTasklist.id).map((singleTasklist) => {
                                                                                                return ` ${singleTasklist.title}`
                                                                                            })
                                                                                        }
                                                                                    </>

                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                    :
                                                                    <Box
                                                                        onClick={() => {
                                                                            setShowTasklistDropDown(true);
                                                                        }}
                                                                    >
                                                                        <Typography variant="body2"
                                                                                    style={{color: CustomTheme.fontColor}}
                                                                                    className="form-panel-followers-text"><i
                                                                            className="far fa-list-alt"></i> Tasklist</Typography>
                                                                        <Typography variant="body2"
                                                                                    style={{color: CustomTheme.primaryColor}}
                                                                                    className="form-panel-bold-text"><i
                                                                            className="fas fa-link"></i> Link
                                                                            tasklist</Typography>
                                                                    </Box>
                                                            }
                                                        </>

                                                }
                                                {
                                                    showPriorityDropdown
                                                        ?
                                                        <Box className="dropdown-margins">
                                                            <SearchableDropDown title={false} name={true}
                                                                                checklist={false}
                                                                                value={selectedPriority}
                                                                                setValue={setSelectedPriority}
                                                                                label="Select priority"
                                                                                optionsList={priority}/>
                                                        </Box>
                                                        :
                                                        <>
                                                            {
                                                                selectedPriority != null
                                                                    ?
                                                                    <Box
                                                                        onClick={() => isFormEdit ? setShowPriorityDropdown(false) : setShowPriorityDropdown(true)}>
                                                                        <Typography variant="body2"
                                                                                    style={{color: CustomTheme.fontColor}}
                                                                                    className="form-panel-followers-text"><i
                                                                            className="fas fa-angle-double-up"></i> Priority</Typography>
                                                                        <Typography
                                                                            variant={isFormEdit ? "h4" : "body2"}
                                                                            style={{color: CustomTheme.primaryColor}}
                                                                            className="form-panel-bold-text">
                                                                            {
                                                                                isFormEdit
                                                                                    ?
                                                                                    <></>
                                                                                    :
                                                                                    <i className="far fa-edit"></i>
                                                                            }
                                                                            {
                                                                                selectedPriority && selectedPriority.value && priority && priority.filter((priority) => priority.value === selectedPriority.value).map((singlePriority) => {
                                                                                    return ` ${singlePriority.name}`
                                                                                })
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                    :
                                                                    <Box onClick={() => setShowPriorityDropdown(true)}>
                                                                        <Typography variant="body2"
                                                                                    style={{color: CustomTheme.fontColor}}
                                                                                    className="form-panel-followers-text"><i
                                                                            className="fas fa-angle-double-up"></i> Priority</Typography>
                                                                        <Typography variant="body2"
                                                                                    style={{color: CustomTheme.primaryColor}}
                                                                                    className="form-panel-bold-text"><i
                                                                            className="far fa-edit"></i> Set
                                                                            Priority</Typography>
                                                                    </Box>
                                                            }
                                                        </>

                                                }
                                                {/* <Box>
                                                <Typography variant="body2" style={{color:CustomTheme.fontColor}} className="form-panel-followers-text" ><i className="fas fa-angle-double-up"></i> Priority</Typography>
                                                <Typography variant="body2" style={{color:CustomTheme.primaryColor}} className="form-panel-bold-text" ><i className="far fa-edit"></i>Set Priority</Typography>
                                            </Box> */}
                                                {
                                                    showChecklistDropdown
                                                        ?
                                                        <Box className="dropdown-margins">
                                                            {/* <SearchableDropDown title={true} name={true} checklist={true} setOpenAddCheckList={setOpenAddCheckList} setOpen={setOpen} value={taskSelectedCheckList} setValue={setTaskSelectedCheckList} label="Select checklist" optionsList={allCheckList} /> */}
                                                            <ChecklistDropdownSearchable project={false}
                                                                                         setIsChecklistNewCreating={setIsChecklistNewCreating}
                                                                                         setValue={setTaskSelectedCheckList}
                                                                                         selectedValue={taskSelectedCheckList}
                                                                                         setOpenAddCheckList={setOpenAddCheckList}
                                                                                         setAllCheckList={setAllCheckList}
                                                                                         optionsList={allCheckList}/>
                                                        </Box>
                                                        :
                                                        <>
                                                            {
                                                                isFormEdit
                                                                    ?
                                                                    <Box>
                                                                        <Typography variant="body2"
                                                                                    style={{color: CustomTheme.fontColor}}
                                                                                    className="form-panel-followers-text"><i
                                                                            className="far fa-list-alt"></i> Checklist</Typography>
                                                                        <Typography onClick={() => {
                                                                            setOpenChecklistItemsDialog(true)
                                                                        }} variant="h4" style={{
                                                                            color: CustomTheme.primaryColor,
                                                                            cursor: 'pointer'
                                                                        }}
                                                                                    className="form-panel-bold-text for-ovarlapping-text">
                                                                            {
                                                                                taskSelectedCheckList && taskSelectedCheckList.id && allCheckList && allCheckList.filter((checklist) => checklist.id === taskSelectedCheckList.id).map((singleChecklist) => {
                                                                                    return `${singleChecklist.title}`
                                                                                })
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                    :
                                                                    <Box onClick={() => setShowChecklistDropdown(true)}>
                                                                        <Typography variant="body2"
                                                                                    style={{color: CustomTheme.fontColor}}
                                                                                    className="form-panel-followers-text"><i
                                                                            className="far fa-list-alt"></i> Checklist</Typography>
                                                                        <Typography variant="body2"
                                                                                    style={{color: CustomTheme.primaryColor}}
                                                                                    className="form-panel-bold-text"><i
                                                                            className="fas fa-link"></i> Add
                                                                            Checklist</Typography>
                                                                    </Box>
                                                            }
                                                        </>

                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                          className="form-panel-assign-name-container margin-top-10">
                                        <Box>
                                            <Box className="task-items-list ">
                                                {
                                                    showApprovalDropdown
                                                        ?
                                                        <Box>
                                                            <SearchableDropDown title={false} name={true}
                                                                                checklist={false}
                                                                                value={selectedApprover}
                                                                                setValue={setSelectedApprover}
                                                                                label="Select Approval"
                                                                                optionsList={approver}/>
                                                        </Box>
                                                        :
                                                        <>
                                                            {
                                                                selectedApprover != null
                                                                    ?
                                                                    <Box
                                                                        onClick={() => isFormEdit ? setShowApprovalDropdown(false) : setShowApprovalDropdown(true)}>
                                                                        <Typography variant="body2"
                                                                                    style={{color: CustomTheme.fontColor}}
                                                                                    className="form-panel-followers-text"><i
                                                                            className="fas fa-check-double"></i> Approval</Typography>
                                                                        <Typography
                                                                            variant={isFormEdit ? "h4" : "body2"}
                                                                            style={{color: CustomTheme.primaryColor}}
                                                                            className="form-panel-bold-text">
                                                                            {
                                                                                isFormEdit
                                                                                    ?
                                                                                    <></>
                                                                                    :
                                                                                    <i className="far fa-edit"></i>
                                                                            }
                                                                            {selectedApprover.name === "Yes" ? " Yes" : " No"}</Typography>
                                                                    </Box>
                                                                    :
                                                                    <Box onClick={() => setShowApprovalDropdown(true)}>
                                                                        <Typography variant="body2"
                                                                                    style={{color: CustomTheme.fontColor}}
                                                                                    className="form-panel-followers-text"><i
                                                                            className="fas fa-check-double"></i> Approval</Typography>
                                                                        <Typography variant="body2"
                                                                                    style={{color: CustomTheme.primaryColor}}
                                                                                    className="form-panel-bold-text"><i
                                                                            className="far fa-edit"></i> Set
                                                                            Approval</Typography>
                                                                    </Box>
                                                            }
                                                        </>

                                                }
                                                {/* <Box>
                                    <Typography variant="body2" style={{color:CustomTheme.fontColor}} className="form-panel-followers-text" ><i className="far fa-calendar-alt"></i> Due Date</Typography>
                                    <Typography variant="body2" style={{color:CustomTheme.primaryColor}} className="form-panel-bold-text" ><i className="fas fa-plus-circle"></i>Set Date</Typography>
                                </Box> */}
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {
                                        isEditPredecessor
                                            ?
                                            <></>
                                            :
                                            <Divider className="divider-style"/>
                                    }
                                    {
                                        isEditPredecessor
                                            ?
                                            <></>
                                            :
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                                  className="form-panel-assign-name-container">
                                                <Grid>
                                                    <Typography variant="body1" style={{color: CustomTheme.fontColor}}
                                                                className="form-panel-dependent-task-text"><i
                                                        className="fas fa-share-alt"></i> Depends on</Typography>
                                                </Grid>
                                                <Grid>
                                                    <Autocomplete
                                                        multiple
                                                        id="tags-standard"
                                                        className="dropdown-margins padding-top-15"
                                                        options={predecessorList || []}
                                                        getOptionLabel={(option) => option ? option.title : ''}
                                                        value={selecetedPredecessor ? selecetedPredecessor : []}
                                                        onChange={(event, newRole) => {
                                                            setSelecetedPredecessor(newRole)
                                                        }}
                                                        getOptionSelected={(option, value) =>
                                                            option.id === value.id
                                                        }
                                                        renderInput={(params) => (
                                                            <TextValidator
                                                                {...params}
                                                                variant="standard"
                                                                label="Select predecessor"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                    }
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                          className="form-panel-assign-name-container">
                                        {
                                            isEditPredecessor && selecetedPredecessor && selecetedPredecessor.length > 0
                                                ?
                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <Grid>
                                                        <Typography variant="body1"
                                                                    style={{color: CustomTheme.fontColor}}
                                                                    className="form-panel-dependent-task-text">
                                                            Dependent Tasks
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <></>
                                        }
                                        {
                                            selecetedPredecessor && selecetedPredecessor.length > 0 && selecetedPredecessor.map((predecessorId, index) => (
                                                <div key={index}>{renderSinglePredecessor(predecessorId.id)}</div>
                                            ))
                                        }
                                    </Grid>
                                    {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="form-panel-assign-name-container">
                                    <Grid container justify="space-between" >
                                        <Grid>
                                            <Typography variant="body2" style={{color:CustomTheme.fontColor}} className="form-panel-followers-text" >Layout Preparation</Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography className="form-panel-delete-icon" ><i className="far fa-trash-alt"></i></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid> */}

                                </>
                                :
                                <></>

                }
                {
                    openAddCheckList
                        ?
                        <ChecklistAdd setIsChecklistNewCreating={setIsChecklistNewCreating} projectId={projectId}
                                      setAllCheckList={setAllCheckList}
                                      setTaskSelectedCheckList={setTaskSelectedCheckList}
                                      setOpenAddCheckList={setOpenAddCheckList} openAddCheckList={openAddCheckList}/>
                        :
                        <></>
                }
            </ValidatorForm>
            {
                openChecklistItemsDialog
                    ?
                    <ChecklistItemsDraggableDialog project={false} template={true}
                                                   title={taskSelectedCheckList ? taskSelectedCheckList.title : ''}
                                                   open={openChecklistItemsDialog}
                                                   setOpenChecklistItemsDialog={setOpenChecklistItemsDialog}
                                                   checklistItemsArray={taskSelectedCheckList ? taskSelectedCheckList : []}/>
                    :
                    <></>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        // loggedInUser: state.auth.loggedInUser,
        // post: state.postDetails.post,
        // comments:state.postDetails.comments
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPredecessorTask: () => {
            return dispatch(getPredecessorRequest()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllTaskList: () => {
            return dispatch(getAllTaskListRequest()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllChecklistRequest: (projectId) => {
            return dispatch(getAllChecklistFromTemplates(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllMilestones: (projectId) => {
            return dispatch(getAllMilestonesRequest(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        // getTaskListOfProject: (projectId) => {
        //     return dispatch(getAllTasklistAndMilestones(projectId)).then((res) => {
        //         return Promise.resolve(res);
        //     }, (error) => {
        //         return Promise.reject(error);
        //     });
        // },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AddEditForm);
