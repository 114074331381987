import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    trackTasksCountContainer:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-evenly',
        boxShadow:'0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%)',
        padding:'20px 70px 20px 70px'
    },
    teackDetailsContainer: {
        textAlign: 'center',
        height: '61px',
        padding: '5px',
        borderRadius: '5px',
        width: 'fit-content',
    },
    onTrackTasksColor: {
        backgroundColor: '#97d7f161'
    },
    strackCount: {
        fontSize: '24px',
        fontWeight: '700',
    },
    onLateTasksColor: {
        backgroundColor: '#ff640040'
    },
    onCompleteTasksColor: {
        backgroundColor: '#D2FDBB'
    },
    onNextTasksColor: {
        backgroundColor: '#a1def72e'
    },
    trackCountTitle: {
        color:'#77797c'
    },
    progressFilterAndActivityListCard: {
        marginTop: '15px !important',
        padding:'10px'
    },
    filterTasksContainer: {
        display:'flex',
        alignItems:'center',
        justifyContent:'space-evenly',
    },
    filterBorderContainer: {
        border:'1px solid black',
        paddingLeft:'5px',
        marginLeft:'5px',
        // borderRadius:'5px',
        backgroundColor:'#ffffff',
        width:'120px'
    },
    forPaddingLeft:{
        paddingLeft:'2px'
    },

}));
