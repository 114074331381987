import React, {useEffect, useState} from 'react'
import DraggableModal from '../DraggableModal/DraggableModal'
import {Card, DialogActions, Divider, Grid, Typography,} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import './ChecklistItemsDraggableDialog.css';
import CustomTheme from '../../utilities/theme/theme.json'
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import {
    editChecklistProjectRequest,
    getSingleChecklistInProjectRequest
} from '../../modules/projects/ProjectsApiActions'
import {
    editChecklistTemplateRequest,
    getSingleCheckListOfTemplateRequest
} from '../../modules/configuration/ConfigurationApiActions'

const ChecklistItemsDraggableDialog = ({
                                           panel,
                                           taskDetails,
                                           updateTaskFromReadView,
                                           editChecklistTemplate,
                                           getSingleChecklistTemplate,
                                           editChecklistProject,
                                           getSingleChecklistProject,
                                           project,
                                           template,
                                           loggedInUser,
                                           open,
                                           setOpenChecklistItemsDialog,
                                           checklistItemsArray,
                                           title
                                       }) => {
    const [checked, setChecked] = useState({});
    const [note, setNote] = useState(null)
    const [isHistoryShowing, setIsHistoryShowing] = useState(false);
    const [checklistRes, setChecklistRes] = useState()
    const [checklistItemsHistory, setChecklistItemsHIstory] = useState()

    useEffect(() => {
        if (project) {
            getSingleChecklistProject(checklistItemsArray.projectId, checklistItemsArray.id, true).then((projectRes) => {
                setChecklistRes(projectRes)
                if (projectRes && projectRes.note) {
                    setNote(projectRes.note)
                } else {
                    setNote('')
                }
                if (projectRes.history && projectRes.history.length > 0) {
                    let reverseArray = projectRes.history
                    let finalArray = [...reverseArray].reverse()
                    setChecklistItemsHIstory(finalArray)
                } else {
                }
            })
        } else if (template) {
            getSingleChecklistTemplate(checklistItemsArray.projectId, checklistItemsArray.id, true).then((projectRes) => {
                setChecklistRes(projectRes)
                if (projectRes && projectRes.note) {
                    setNote(projectRes.note)
                } else {
                    setNote('')
                }
                if (projectRes.history && projectRes.history.length > 0) {
                    let reverseArray = projectRes.history
                    let finalArray = [...reverseArray].reverse()
                    setChecklistItemsHIstory(finalArray)
                } else {
                }
            })
        } else {
        }
    }, [checklistItemsArray])
    const alternatingColor = ['#ffffff', '#bbbbbb47']; // because this is a static array, you can move it out of the component

    const checklistApproveSavehandler = () => {
        if (note) {
            checklistRes.note = note
        } else {
        }
        delete checklistRes["_rev"]
        delete checklistRes["_id"]
        delete checklistRes["updatedAt"]
        delete checklistRes["createdAt"]
        delete checklistRes["approverDetails"]
        delete checklistRes["history"]
        delete checklistRes["createdBy"]
        if (project) {
            editChecklistProject(checklistItemsArray.id, checklistRes).then((editProjectRes) => {
                setOpenChecklistItemsDialog(false);
            })
            if (taskDetails && panel === "task") {
                let formData = {
                    mark_as_complete: true
                }
                updateTaskFromReadView(formData, "Mark as complete sucsessfully")
            } else {
            }
        } else if (template) {
            editChecklistTemplate(checklistItemsArray.id, checklistRes).then((editTemplateRes) => {
                setOpenChecklistItemsDialog(false);
            })
        } else {
        }
    }
    const checkBoxCheckHandler = (index, isChecked) => {
        let data = Object.assign({}, checklistRes);
        data.items[index].isCompleted = isChecked
        setChecklistRes(data)
    }

    const reOpenHandler = () => {
        let formData = {
            reopen: true
        }
        updateTaskFromReadView(formData, "Reopen sucsessfully")
        setOpenChecklistItemsDialog(false);
    }

    return (
        <DraggableModal
            open={open}
            handleClose={() => setOpenChecklistItemsDialog(false)}
            title={`${title} Items`}
            maxWidth="md"
        >
            <ValidatorForm
                onSubmit={checklistApproveSavehandler}
                noValidate
                onError={errors => console.log(errors)}
            >
                {
                    isHistoryShowing
                        ?
                        <></>
                        :
                        <Typography variant="body2" className="float-right margin-top-6 for-display-flex">
              <span className="for-padding-right-avatar ">
                <Tooltip
                    title={checklistRes && checklistRes.approverDetails ? `${checklistRes.approverDetails.firstName} ${checklistRes.approverDetails.lastName}` : ''}
                    placement="top">
                  <img
                      src={`https://ui-avatars.com/api/?name=${checklistRes && checklistRes.approverDetails ? checklistRes.approverDetails.firstName.charAt(0) : ''}&background=random`}
                      alt='profile'
                      className="user-image"
                  />
                </Tooltip>
              </span>
                            {checklistRes && checklistRes.approverDetails ? `${checklistRes.approverDetails.firstName} ${checklistRes.approverDetails.lastName} ` : ''}
                            {moment(checklistRes && checklistRes.updatedAt ? checklistRes.updatedAt : '').format('MM/DD/YYYY » h:mm A')}
                        </Typography>
                }
                {
                    loggedInUser.userRoles.isSystemSuperAdmin || loggedInUser.userRoles.isTeamLead
                        ?
                        <>
                            {
                                checklistItemsHistory && checklistItemsHistory.length > 0
                                    ?
                                    <Typography
                                        style={{color: CustomTheme.linkColor}}
                                        className="float-left history-btn-style history-current-btn-position"
                                        onClick={() => {
                                            let prevIsHistoryShowing = isHistoryShowing
                                            setIsHistoryShowing(!prevIsHistoryShowing);
                                        }}
                                        variant="body1"
                                    >
                                        {isHistoryShowing ? "Current" :
                                            <span><i className="fas fa-history"></i> History</span>}
                                    </Typography>
                                    :
                                    <></>
                            }
                        </>
                        :
                        <></>
                }
                {
                    isHistoryShowing
                        ?
                        <>
                            {
                                checklistItemsHistory && checklistItemsHistory.map((itemData, index) => {
                                    return (
                                        <Card className="history-blocks"
                                              style={{backgroundColor: alternatingColor[index % alternatingColor.length]}}>
                                            <Typography variant="body2"
                                                        className="float-left margin-top-6 for-display-flex">
                                                Version
                                                : {index === 0 ? 'Current' : checklistItemsHistory.length - index}
                                            </Typography>
                                            <Typography variant="body2"
                                                        className="float-right margin-top-6 for-display-flex">
                        <span className="for-padding-right-avatar ">
                          <Tooltip title={`${itemData.approverDetails.firstName} ${itemData.approverDetails.lastName}`}
                                   placement="top">
                            <img
                                src={`https://ui-avatars.com/api/?name=${checklistRes.approverDetails.firstName.charAt(0)}&background=random`}
                                alt='profile'
                                className="user-image"
                            />
                          </Tooltip>
                        </span>
                                                {`${itemData.approverDetails.firstName} ${itemData.approverDetails.lastName}`} {moment(itemData.updatedAt).format('MM/DD/YYYY » h:mm A')}
                                            </Typography>
                                            {
                                                itemData && itemData.items.map((item, index) => {
                                                    return (
                                                        <>
                                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                <Grid container>
                                                                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                                                        <Checkbox
                                                                            checked={item.isCompleted}
                                                                            inputProps={{
                                                                                readOnly: true,
                                                                            }}
                                                                            color="primary"
                                                                        />
                                                                    </Grid>
                                                                    <Grid style={{paddingTop: '1%'}} item xl={11}
                                                                          lg={11} md={11} sm={11} xs={11}>
                                                                        <Typography
                                                                            variant="body1">{item.title}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Divider/>
                                                        </>
                                                    )
                                                })
                                            }
                                            <Divider/>
                                            {
                                                itemData && itemData.note
                                                    ?
                                                    <Grid className="margin-top-10" item xl={12} lg={12} md={12} sm={12}
                                                          xs={12}>
                                                        <Grid container>
                                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                                <TextValidator
                                                                    fullWidth
                                                                    size="small"
                                                                    inputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    label="Review Note"
                                                                    defaultValue={itemData.note}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    <></>
                                            }
                                        </Card>
                                    )
                                })
                            }
                        </>
                        :
                        <>
                            {
                                checklistRes && checklistRes.items.map((item, index) => {
                                    return (
                                        <>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Grid container>
                                                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                                        <Checkbox
                                                            checked={item.isCompleted}
                                                            value={item.title}
                                                            onChange={(e) => {
                                                                setChecked({...checked, [index]: e.target.checked});
                                                                checkBoxCheckHandler(index, e.target.checked)
                                                            }}
                                                            color="primary"
                                                        />
                                                    </Grid>
                                                    <Grid style={{paddingTop: '1%'}} item xl={11} lg={11} md={11}
                                                          sm={11} xs={11}>
                                                        <Typography variant="body1">{item.title}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider/>
                                        </>
                                    )
                                })
                            }
                            {
                                loggedInUser.userRoles.isSystemSuperAdmin || loggedInUser.userRoles.isTeamLead
                                    ?
                                    <>
                                        <Grid className="margin-top-10" item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Grid container>
                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <TextValidator
                                                        fullWidth
                                                        size="small"
                                                        label="Review Note"
                                                        onChange={(e) => setNote(e.target.value)}
                                                        name="name" value={note || ''}
                                                        validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:50']}
                                                        errorMessages={['At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    <></>
                            }
                            {
                                loggedInUser.userRoles.isProjectMember
                                    ?
                                    <></>
                                    :
                                    <DialogActions>
                                        {
                                            template
                                                ?
                                                <></>
                                                :
                                                <Button
                                                    disabled={taskDetails && taskDetails.status === "ready_for_review" ? false : true}
                                                    variant="outlined"
                                                    color="secondary"
                                                    className="reset-dialog-button"
                                                    size="small"
                                                    onClick={() => {
                                                        reOpenHandler();
                                                    }}
                                                >
                                                    Reopen
                                                </Button>
                                        }
                                        <Button
                                            disabled={template && template ? false : taskDetails && taskDetails.status === "ready_for_review" ? false : true}
                                            type="submit"
                                            className="save-dialog-button"
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                        >
                                            Mark as Complete
                                        </Button>
                                    </DialogActions>
                            }

                        </>
                }
            </ValidatorForm>
        </DraggableModal>
    );
};
const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        editChecklistTemplate: (checklistId, data) => {
            return dispatch(editChecklistTemplateRequest(checklistId, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getSingleChecklistTemplate: (templateId, checklistId, isLoaderShowing) => {
            return dispatch(getSingleCheckListOfTemplateRequest(templateId, checklistId, isLoaderShowing)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        editChecklistProject: (checklistId, data) => {
            return dispatch(editChecklistProjectRequest(checklistId, data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getSingleChecklistProject: (id, checklistId, isLoaderShowing) => {
            return dispatch(getSingleChecklistInProjectRequest(id, checklistId, isLoaderShowing)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistItemsDraggableDialog);
