import HttpRequest from '../../utilities/HttpRequest';

import { API_ACTIONS, SNACKBAR_VARIAINT } from '../../utilities/Constants';
import { showSnackbar } from '../../utilities/AppLoader/AppLoaderActions'

import {
    getAllMilestone,
    getAllParticipantLists,
    getAllTaskLists,
    getMilestoneDetails,
    addMilestone,
    updateMilestone,
    deleteMilestone,
    showTasklists
} from "./MilestonesActions";

/**
 * Server API call to get all taskLists
 * @returns {function(*)}
 */
export const getAllTaskListsRequest = (projectId, isResStoringInRedux) => { 
    return (dispatch) => {
        let url = `${API_ACTIONS.PROJECTS}/${projectId}/tasklists`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                if (isResStoringInRedux) {
                    dispatch(getAllTaskLists(res.data));
                }
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all un associated tasklist
 * @returns {function(*)}
 */
export const getAllUnAssociatedTasklistOfProject = (projectId) => { 

    return (dispatch) => {
        const url = `${API_ACTIONS.UNASSOCIATED_TASKLIST_OF_PROJECT}?projectId=${projectId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all milestone
 * @returns {function(*)}
 */
export const getAllMilestoneRequest = (projectId) => { 

    return (dispatch) => {
        const url = `${API_ACTIONS.PROJECTS}/${projectId}/milestones`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                dispatch(getAllMilestone(res.data));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add new milestone
 * @returns {function(*)}
 */
export const addMilestoneRequest = (projectId, data) => { 

    return (dispatch) => {
        const url = `/milestones`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(addMilestone(res.data))
                dispatch(showSnackbar("Milestone added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to update milestone
 * @returns {function(*)}
 */
export const updateMilestoneRequest = (milestoneId, data) => {  

    return (dispatch) => {
        const url = `${API_ACTIONS.MILESTONES}/${milestoneId}`;
        return new HttpRequest(dispatch, url, 'put', data)
            .send()
            .then(res => {
                dispatch(updateMilestone(res.data))
                dispatch(showSnackbar("Milestone updated successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get milestone details
 * @returns {function(*)}
 */
export const getMilestoneDetailsByIdRequest = (milestoneId) => { 

    return (dispatch) => {
        const url = `${API_ACTIONS.MILESTONES}/${milestoneId}`;
        return new HttpRequest(dispatch, url, 'get')
            .send(null, true)
            .then(res => {
                dispatch(getMilestoneDetails(res.data))
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all taskLists
 * @returns {function(*)}
 */
export const getAllParticipantsRequest = (projectId) => {  

    return (dispatch) => {
        const url = `${API_ACTIONS.PROJECTS}/${projectId}/participants`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                dispatch(getAllParticipantLists(res.data));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to get all project checklist
 * @returns {function(*)}
 */
export const getAllProjectCheckListRequest = (projectId) => { 

    return (dispatch) => {
        const url = `/projects/${projectId}/checklist`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add project check list
 * @returns {function(*)}
 */
export const addProjectCheckListRequest = (data) => {

    return (dispatch) => {
        const url = `/projects/checklist`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to add checklist in project
 * @returns {function(*)}
 */
export const addChecklistInProjectRequest = (data) => {

    return (dispatch) => {
        const url = `${API_ACTIONS.ADD_CHECKLIST_FOR_PROJECT}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("Checklist added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

/**
 * Server API call to delete milestone
 * @returns {function(*)}
 */
export const deleteMilestoneRequestProject = (milestoneId) => { 

    return (dispatch) => {
        const url = `${API_ACTIONS.DELETE_MILESTONE_PROJECT}/${milestoneId}`;
        return new HttpRequest(dispatch, url, 'delete')
            .send(null, true)
            .then(res => {
                res.data.deleteId = milestoneId
                dispatch(deleteMilestone(res.data))
                dispatch(showSnackbar("Milestone deleted successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
};

// for showing tasklists under milestone
export const showTasklistUnderMilestones = (data,type, milestoneId) => { 
    return dispatch => {
        dispatch(showTasklists(data,type, milestoneId));
    };
};