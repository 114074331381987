import React from 'react';
import {isValidPhoneNumber} from 'react-phone-number-input';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const MobileNumber = React.memo(({phone, updateMobileNumber, specialLabel}) => {
    // const [countryCode, setCountryCode] = useState("");
    // const [mobile, setMobile] = useState("");
    // useEffect(() => {
    //   if(phone !== mobile){
    //     setMobile(phone);
    //   }
    // }, [phone]);

    return (
        <>
            <ReactPhoneInput
                defaultCountry='in'
                country='in'
                specialLabel={specialLabel}
                // onlyCountries={['in']}
                enableSearchField={true}
                disableAreaCodes={true}
                countryCodeEditable={false}
                value={phone}
                // className="inputElement"
                onChange={(phone, data) => {
                    if (phone) {
                        let phoneNumber = '+' + phone.replace(/[^0-9]+/g, '');
                        updateMobileNumber(phoneNumber, isValidPhoneNumber(phoneNumber));
                    }
                }}
            />
            {/* <ReactPhoneInput
        defaultCountry='in'
        country='in'
        enableSearchField={true}
        disableAreaCodes={true}
        countryCodeEditable={false}
        value={countryCode+mobile}
        onChange={(phone, data) => {
          if (phone) {
            // setCountryCode("+"+data.dialCode);
            let phoneNumber = '+' + phone.replace(/[^0-9]+/g, '');
            // // const mobile = parsePhoneNumber(phoneNumber);
            // phone = phone.substring(data.dialCode.toString().length);
            updateMobileNumber(phoneNumber, isValidPhoneNumber(phoneNumber));
          }
        }} */}
            {/* /> */}
        </>
    )
});

export default MobileNumber;
