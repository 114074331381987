import React, {useEffect, useState} from 'react';
import "./Tasks.css";
import {Box, Card, CardContent, Grid, Typography} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import ModalDown from '../../../../utilities/ModalDown/ModalDown'
import MaterialTable from 'material-table';
import Chip from '@material-ui/core/Chip';
import {connect} from 'react-redux';
import {
    addChecklistRequest,
    addTagRequest,
    addTaskListRequest,
    addTaskRequest,
    editTasklistDetailsInConfigRequest,
    getAllChecklist,
    getAllTagsRequset,
    getAllTaskListRequest,
    getFunctionalRoleRequest,
    getTasklistDetailsInConfigRequest,
    getTaskOfTaskListRequest
} from '../../ConfigurationApiActions'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {useParams} from 'react-router';
import Tooltip from '@material-ui/core/Tooltip';
import CustomTheme from '../../../../utilities/theme/theme.json';

const Tasks = ({
    addTags, 
    getAllTags, 
    history, 
    getAllTaskList, 
    getSingleTasklistDetails, 
    editSingleTasklistDetails,
    configAllTasks,
    configAllTasklist
}) => {


    const {tasklistId} = useParams()

    const [open, setOpen] = useState(false);
    const [hideTaskListTemplate, setHideTaskListTemplate] = useState(false);
    const [selectedTaskList, setSelectedTaskList] = useState(undefined)

    const [isEditTask, setIsEditTask] = useState(false)
    const [editTaskId, setEditTaskId] = useState(null)

    const [allTags, setAllTags] = useState(null)
    const [tagName, setTagName] = useState(null);

    const [editTasklistFormData, setEditTasklistFormData] = useState({
        title:null,
        description:null,
        chips:[],
        isApprovalNeeded:false
    })
    
    useEffect(() => {
        if (tasklistId != null) {
            getSingleTasklistDetails(tasklistId).then((res) => {
                let editTasklistData = Object.assign({}, editTasklistFormData)
                editTasklistData.title = res.title
                editTasklistData.description = res.description
                editTasklistData.chips = res.tags
                editTasklistData.isApprovalNeeded = res.isApprovalNeeded
                setEditTasklistFormData(editTasklistData)
                setHideTaskListTemplate(true)
                setSelectedTaskList(res.id);
            })
        }

    }, [tasklistId])

    useEffect(() => {
        if (tagName && tagName.length >= 3 && tagName.length < 4) {
            getAllTags(tagName, true).then((res) => {
                setAllTags(res)
            }).catch((err) => {
            })
        }
    }, [tagName])

    // for opening model tasklist and task page
    const handleClickOpen = () => {
        setOpen(true)
    }

    console.log("configAllTasks",configAllTasks)
    const columns = [
        {
            field: 'title',
            title: (<Typography style={{fontWeight:500,color:'black'}} variant="h4">Title</Typography>),
            cellStyle: {
                width: '30%',
            },
            render: rowData => {
                return (
                    <div style={{color: hideTaskListTemplate ? '' : CustomTheme.linkColor}}
                         className={hideTaskListTemplate ? '' : 'title-tasklist-table'}>
                        <Tooltip title={rowData.title.length > 49 ? rowData.title : ''} placement="top">
                            <Typography
                                variant="body1"
                                onClick={() => {
                                    if (hideTaskListTemplate) {
                                    } else {
                                        history.push(`/configuration/tasks/${rowData.id}`)
                                    }
                                }}
                            >
                                <span className="overflow-styling">{rowData.title}</span>
                            </Typography>
                        </Tooltip>
                    </div>
                )
            },
        },

        {
            field: 'description',
            title: (<Typography style={{fontWeight:500,color:'black'}} variant="h4">Description</Typography>),
            cellStyle: {
                width: '40%',
            },
            render: rowData => {
                return (
                    <Typography
                        variant="body1"
                    >
                        {rowData.description}
                    </Typography>
                )
            },
        },
        {
            field: 'tags',
            title: (<Typography style={{fontWeight:500,color:'black'}} variant="h4">Tags</Typography>),
            cellStyle: {
                width: '20%',
            },
            render: ({tags}) => {
                return (
                    <div className="chip-render-in-table">
                        {tags && tags.map((chipsData) => {
                            return (
                                <Chip
                                    key={chipsData}
                                    style={{backgroundColor: CustomTheme.primaryColor}}
                                    size="small"
                                    label={chipsData}
                                    className="margin-right-5 margin-top-5 chip-element"
                                />
                            )
                        })}
                    </div>
                )
            },
        },

        //   {
        //     field: 'actions',
        //     title: 'Actions',
        //     cellStyle: {
        //       width: '40%',
        //     },
        //     render: ({}) => {
        //         return (
        //             <span className="icon"><i className="fas fa-link"></i></span>
        //         )
        //     },
        //   }
    ]

    if (hideTaskListTemplate) {
        columns.splice(4, 0,
            {
                field: 'actions',
                title: (<Typography style={{fontWeight:500,color:'black'}} variant="h4">Actions</Typography>),
                cellStyle: {
                    width: '40%',
                },
                render: rowData => {
                    return (
                        <>
                            <Tooltip title="Edit" placement="top" arrow>
                         <span
                             onClick={() => {
                                 setOpen(true);
                                 setIsEditTask(true);
                                 setEditTaskId(rowData.id)
                             }}
                             style={{color: CustomTheme.primaryColor}}
                             className="edit-icon-task-config"
                         ><i className="far fa-edit"></i></span>
                            </Tooltip>
                        </>
                    )
                },
            }
        )
    } else {
    }

    // add tags
    const addChips = (tags) => {
        tags.map((singleTagName) => {
            let data = {
                name: singleTagName,
                color: singleTagName
            }
            addTags(data).then((res) => {
            })
            return singleTagName
        })

    }

    const onEditTasklistHandler = (TasklistId) => {
        let filterArr = []
        getAllTags().then((res) => {
            let arr = editTasklistFormData.chips && editTasklistFormData.chips.filter((tag) => {
                return !(res.some((resTag) => resTag.name === tag))
            })
            filterArr.push(arr)
            addChips(filterArr[0])
        })
        let data = {
            title: editTasklistFormData.title,
            // description:editTasklistDescription,
            isApprovalNeeded: editTasklistFormData.isApprovalNeeded
        }
        if (editTasklistFormData.chips) {
            data.tags = editTasklistFormData.chips
        } else {
        }
        if (editTasklistFormData.description) {
            data.description = editTasklistFormData.description
        } else {
        }

        editSingleTasklistDetails(data, tasklistId).then((res) => {

            let editTasklistData = Object.assign({}, editTasklistFormData)
            editTasklistData.title = res.title
            editTasklistData.description = res.description
            editTasklistData.chips = res.tags
            editTasklistData.isApprovalNeeded = res.isApprovalNeeded
            setEditTasklistFormData(editTasklistData)

            getAllTaskList()

        })
    }

    return (
        <>
            <Card>
                <CardContent>
                    {
                        hideTaskListTemplate ?
                            <>
                                <ValidatorForm
                                    onSubmit={() => {
                                        onEditTasklistHandler(tasklistId)
                                    }}
                                >
                                    <Grid style={{borderBottom: `1px dashed ${CustomTheme.primaryColor}`}} item xl={12}
                                          lg={12} md={12} sm={12} xs={12} className="header-actions-btns">
                                        <Grid container justify="space-between">
                                            <Grid onClick={() => {
                                                history.goBack()
                                            }}>
                                                <span style={{color: CustomTheme.primaryColor}}
                                                      className="header-icons-styling"><i
                                                    className="far fa-arrow-alt-circle-left"></i></span>
                                            </Grid>
                                            <Grid>
                                                <Button size="small"
                                                        type="submit"
                                                        style={{color: CustomTheme.primaryColor}}
                                                        className="form-panel-save-btn submit-btn"
                                                >
                                                    <i className="far fa-save"></i>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Typography variant="h1" style={{color: CustomTheme.fontColor}}
                                                className="block-titles margin-top-20">Tasklist </Typography>
                                    <Typography variant="body1">
                                        You can edit tasklist details, and add tasks to this tasklist
                                    </Typography>

                                    <Grid container spacing={3}>
                                        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                            <TextValidator
                                                fullWidth
                                                required
                                                size="small"
                                                label="Tasklist title"
                                                onChange={(e) => setEditTasklistFormData({...editTasklistFormData, title: e.target.value})}
                                                name="name"
                                                value={editTasklistFormData ? editTasklistFormData.title : ''}
                                                validators={['required', 'trim', 'maxStringLength:50']}
                                                errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                            />
                                        </Grid>
                                        <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                label="Tasklist description"
                                                onChange={(e) =>setEditTasklistFormData({...editTasklistFormData, description: e.target.value})}
                                                name="Tasklist description"
                                                value={editTasklistFormData ? editTasklistFormData.description : ''}
                                                validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:250']}
                                                errorMessages={['At least one alphanumeric characters', 'The max length of 250 charachers reached']}
                                            />
                                        </Grid>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <span className="tag-icon-span-on-edit-tasklist">
                                <i className='fas fa-tags'></i>
                            </span>
                                            <Autocomplete
                                                multiple
                                                id="tags-filled"
                                                value={editTasklistFormData.chips}
                                                className="tags-contianer"
                                                onChange={(event, newValue) => {
                                                    setEditTasklistFormData({...editTasklistFormData, chips: newValue})
                                                }}
                                                onInputChange={(event, newValue) => {
                                                    setTagName(newValue);
                                                }}
                                                clearOnEscape={true}
                                                options={allTags ? Object.keys(allTags).map((option) => allTags[option].name) : []}
                                                freeSolo
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip key={index} variant="outlined"
                                                              label={option} {...getTagProps({index})} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="filled"
                                                               placeholder="Type tag and hit enter"/>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </>
                            :
                            <>
                                <Typography variant="h1" style={{color: CustomTheme.fontColor}}
                                            className="task-info-title">Tasklist Configuration</Typography>
                                <Typography variant="body1">
                                    You can create template tasklists here, which can be used in multiple projects and
                                    project templates. Once you create a tasklist, you can add tasks to it.
                                </Typography>
                            </>
                    }

                    <Grid container>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container justify="space-between">
                                <Grid>
                                    <Typography variant="h2" style={{color: CustomTheme.fontColor}}
                                                className="block-titles margin-top-20">Add {hideTaskListTemplate ? 'Tasks' : ' Tasklist'}</Typography>
                                </Grid>
                                <Grid>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={handleClickOpen}
                                        className="add-task-button"
                                        startIcon={<i className="fas fa-plus-circle"/>}
                                    >
                                        ADD
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography variant="body1">
                        {
                            hideTaskListTemplate
                                ?
                                'Click to add a new task to this tasklist'
                                :
                                'Click to add a new tasklist'
                        }
                    </Typography>
                </CardContent>
                <Box component="div" className="task-table">
                    <MaterialTable
                        columns={columns}
                        data={
                            hideTaskListTemplate ? configAllTasks : configAllTasklist
                        }
                        options={{
                            padding: 'dense',
                            toolbar: false,
                            paging: false,
                            sorting: false,
                            emptyRowsWhenPaging: false,
                            draggable: false,
                            rowStyle: {
                                fontSize: '14px',
                            },
                            headerStyle: {
                                backgroundColor: '#eee',
                                borderBottom: '1px solid #77797c',
                                fontSize: '14px',
                                fontWeight: '600',
                                height: '40px',
                            }
                        }}
                        cellStyle={{
                            padding: '10px 24px 10px 16px'
                        }}
                        style={{
                            boxShadow: 'none',
                            borderRadius: '0px',
                            color: '#77797c',
                        }}
                    />
                </Box>
                <Grid container>
                </Grid>
            </Card>
            <ModalDown 
            setIsEditTask={setIsEditTask} 
            setEditTaskId={setEditTaskId} 
            editTaskId={editTaskId}
            isEditTask={isEditTask} 
            onClickTableRowIdTaskList={tasklistId} 
            selectedTaskList={selectedTaskList} 
            setSelectedTaskList={setSelectedTaskList}
            hideTaskListTemplate={hideTaskListTemplate} 
            setOpen={setOpen}
            open={open}
            />
            
        </>
    )

}

const mapStateToProps = (state) => {
    return {
        configAllTasks: state.configuration.configTasks,
        configAllTasklist: state.configuration.configTasklist
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllTaskList: () => {
            return dispatch(getAllTaskListRequest()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getTasks: (tasklListId) => {
            return dispatch(getTaskOfTaskListRequest(tasklListId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getFunctionalRoles: () => {
            return dispatch(getFunctionalRoleRequest()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addTask: (data) => {
            return dispatch(addTaskRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getSingleTasklistDetails: (tasklistId) => {
            return dispatch(getTasklistDetailsInConfigRequest(tasklistId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        editSingleTasklistDetails: (data, tasklistId) => {
            return dispatch(editTasklistDetailsInConfigRequest(data, tasklistId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllTags: (name, searchTag) => {
            return dispatch(getAllTagsRequset(name, searchTag)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addTags: (data) => {
            return dispatch(addTagRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
