import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import ChipInput from 'material-ui-chip-input';
import moment from 'moment';
import { Box, Divider, Grid, Tab, Tooltip, Typography, Card } from '@material-ui/core';
import { useStyles } from './styles';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
// import TimeLine from './components/TimeLine/TimeLine';
// import Conversations from './components/Conversations/Conversations';
// import Documents from './components/Documents/Documents';
// import Effort from './components/Effort/Effort';
import { getAllTaskListsRequest, deleteMilestoneRequestProject, getMilestoneDetailsByIdRequest } from '../../MilestonesApiActions';

import ChecklistItemsDraggableDialog
    from '../../../../utilities/ChecklistItemsDraggableDialog/ChecklistItemsDraggableDialog'
import CustomTheme from '../../../../utilities/theme/theme.json'
import MessageModal from '../../../../utilities/MessageModal/MessageModal'
import LoaderForSaveAndEdit from '../../../../utilities/AppLoader/LoaderForSaveAndEdit'
const TaskMilestoneDetails = ({
    setRightPane,
    match,
    showAddUpdateForm,
    loggedInUser,
    getAllTasklistOfProject,
    deleteMilestoneRequest,
    setAddUpdateRes,
    milestoneId,
    getMilestoneDetailsById,
    milestoneDetails
}) => {
    const classes = useStyles();
    const [openChecklistItemsDialog, setOpenChecklistItemsDialog] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [taskList, setTaskList] = useState(null);
    //for showing loader
    const [isShowLoader, setIsShowLoader] = useState(false);
    const projectId = match.params.id;
    const modalRef = useRef();

    useEffect(() => {
        setIsShowLoader(true)
        getMilestoneDetailsById(milestoneId).then((MilestoneRes) => {
            setIsShowLoader(false)
            if (MilestoneRes && MilestoneRes.tasklists && MilestoneRes.tasklists.length > 0) {
                getAllTasklistOfProject(projectId, false).then((res) => {
                    let tasklistObj = res && res.filter((tasklist) => {
                        return (
                            MilestoneRes.tasklists && MilestoneRes.tasklists.find((tasklistId) => {
                                return tasklistId === tasklist.id
                            })
                        )
                    })
                    let list = [];
                    tasklistObj.forEach((item) => {
                        list.push(item.title);
                    });
                    setTaskList(list);
                });
            } else { }
        })
    }, [milestoneId])
    /**
     * for showing color to the status
     * @param status
     */
    const forStatusColorhandler = (status) => {
        switch (status) {
            case "todo":
                return CustomTheme.not_statred_status
            case "in_progress":
                return CustomTheme.in_progress_status
            case "stopped":
                return CustomTheme.stoppped_status
            case "blocked":
                return CustomTheme.blockked_status
            case "complete":
                return CustomTheme.complete_status
            case "deferred":
                return CustomTheme.deferred_status
            case "ready_for_review":
                return CustomTheme.ready_for_review
            default:
                return CustomTheme.not_statred_status
        }
    }

    /*
     function delete milestone
     @param id 
    */
    const deleteForm = (id) => {
        const title = `Delete milestone`
        const message = `Are you sure you want to proceed?`
        modalRef.current.setState({
            showModal: true,
            message: message,
            title: title,
            showOkButton: true,
            showCancelButton: true,
            showNote: false,
            note: '',
            handleOk: (note) => {
                deleteMilestoneRequest(id).then((res) => {
                    setRightPane("")
                })
                let data = {
                    isDelete: true,
                    milestoneId: id
                }
                setAddUpdateRes({
                    res: data,
                    formName: 'milestone'
                });
            },
            okText: "Yes",
            cancelText: 'No'
        });
    }


    return (
        <>
            {
                isShowLoader
                    ?
                    <Card className="add-update-form for-card-shadow">
                        <span
                            className="loader-for-save-and-edit"><LoaderForSaveAndEdit /></span>
                    </Card>
                    :
                    <Box className={classes.paneContainer}>
                        <Box className={classes.detailsPane}>
                            <Box
                                className={loggedInUser.userRoles.isProjectMember ? classes.detailsActionsForProjectMember : classes.detailsActions}>
                                <Box className={classes.detailsHeaderContent}>
                                    <Box>
                                        {
                                            loggedInUser.userRoles.isSystemSuperAdmin || loggedInUser.userRoles.isTeamLead
                                                ? (
                                                    <>
                                                        <Tooltip title='Delete milestone' placement='top' arrow>
                                                            <span className={classes.Icon} onClick={() => deleteForm(milestoneDetails.id)}>
                                                                <i className='far fa-trash-alt'></i>
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip title='Edit milestone' placement='top' arrow>
                                                            <span className={classes.Icon} onClick={() => showAddUpdateForm(milestoneDetails.id, 'milestone')}>
                                                                <i className='far fa-edit'></i>
                                                            </span>
                                                        </Tooltip>
                                                    </>
                                                )
                                                : <></>
                                        }
                                        {/* <Tooltip title='Link to Task' placement='top' arrow>
                <span className={classes.Icon}>
                  <i className='far fa-clone'></i>
                </span>
              </Tooltip>
              <Tooltip title='Add subtask' placement='top' arrow>
                <span className={classes.Icon}>
                  <i className='far fa-trash-alt'></i>
                </span>
              </Tooltip>
              <Tooltip title='Edit task' placement='top' arrow>
                <span className={classes.Icon}>
                  <i className='far fa-bell'></i>
                </span>
              </Tooltip>
              <Tooltip title='Make Recurring' placement='top' arrow>
                <span className={classes.Icon}>
                  <i className='far fa-eye'></i>
                </span>
              </Tooltip> */}
                                    </Box>
                                </Box>
                            </Box>

                            <Box className={classes.detailsMainHeader}>
                                <Box className={classes.detailsHeader}>
                                    <Box className={classes.detailsHeaderContentForUsersIcon}>
                                        <Box className={classes.teamLead}>
                                            <Box>
                                                <img
                                                    src={`https://ui-avatars.com/api/?name=${milestoneDetails && milestoneDetails.assignee ? milestoneDetails.assignee.firstName : ""}&background=random`}
                                                    alt='profile'
                                                    className="task-assignee-image"
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant='body2'>Responsible person</Typography>
                                                <Typography variant='h6' className={`user-name ${classes.userNameColor}`}>
                                                    {milestoneDetails && milestoneDetails.assignee ? milestoneDetails.assignee.firstName + " " + milestoneDetails.assignee.lastName : "-"}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box className="inline-flex">
                                            <Box>
                                                <img
                                                    src={`https://ui-avatars.com/api/?name=${milestoneDetails && milestoneDetails.approver ? milestoneDetails.approver.firstName : ""}&background=random`}
                                                    alt='profile'
                                                    className="task-assignee-image"
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant='body2'>Approver</Typography>
                                                <Typography variant='h6' className={`user-name ${classes.userNameColor}`}>
                                                    {milestoneDetails && milestoneDetails.approver ? milestoneDetails.approver.firstName + " " + milestoneDetails.approver.lastName : "-"}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className={classes.infoContainer}>
                                    <Typography variant='h1' classes={{ root: classes.detailsTitle }}>
                                        {milestoneDetails && milestoneDetails.title ? milestoneDetails.title : ''}
                                    </Typography>
                                    {/* <Typography variant='body2' classes={{ root: classes.detailsBy }}>
              Added by Jeetal C. on March 15, 2021 at 9:32 AM
            </Typography> */}
                                    <Typography
                                        variant='h3'
                                        classes={{ root: classes.detailsDescription }}
                                    >
                                        {milestoneDetails && milestoneDetails.description ? milestoneDetails.description : ''}
                                    </Typography>
                                </Box>

                                <Box className={classes.tagsContaianer}>
                                    <span className={classes.tagIcon}>
                                        <i className='fas fa-tags'></i>
                                    </span>
                                    <Box className={classes.chipInputContainer}>
                                        <ChipInput
                                            disableUnderline={true}
                                            fullWidth
                                            disabled
                                            value={milestoneDetails && milestoneDetails.tags ? milestoneDetails.tags : []}
                                            classes={{
                                                root: classes.chipInput,
                                                inputRoot: classes.inputRoot,
                                                input: classes.input,
                                                chip: classes.chip,
                                                chipContainer: classes.chipContainer,
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box className={classes.detailsContainer}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Box>
                                                <Typography variant='body2'>
                                                    <span>
                                                        <i className='far fa-list-alt'></i>
                                                    </span>{' '}
                                                    Tasklists
                                                </Typography>{' '}
                                                <Typography variant='h6' className="display-ellipsis-line-2"
                                                    classes={{ root: classes.info }}>
                                                    {
                                                        taskList
                                                            ? (
                                                                <>
                                                                    {taskList.join(", ")}
                                                                </>
                                                            )
                                                            : "-"
                                                    }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box>
                  <Typography variant='body2'>
                    <span>
                      <i className='fas fa-list'></i>
                    </span>{' '}
                    Tasks
                  </Typography>{' '}
                  <Typography variant='h6' classes={{ root: classes.info }}>
                    Design Complete
                  </Typography>
                </Box>
              </Grid> */}
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Box>
                                                <Typography variant='body2'>
                                                    <span>
                                                        <i className='fas fa-check-double'></i>
                                                    </span>{' '}
                                                    Approval
                                                </Typography>{' '}
                                                <Typography variant='h6' classes={{ root: classes.info }}>
                                                    Pending
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Box>
                                                <Typography variant='body2'>
                                                    <span>
                                                        <i className='fas fa-clipboard-list'></i>
                                                    </span>{' '}
                                                    Checklist
                                                </Typography>{' '}
                                                {
                                                    milestoneDetails && milestoneDetails.checklist
                                                        ? (
                                                            <Typography
                                                                variant='h6'
                                                                classes={{ root: classes.info }}
                                                                className="cursor-pointer"
                                                                onClick={() => setOpenChecklistItemsDialog(true)}
                                                            >
                                                                {milestoneDetails.checklist.title}
                                                            </Typography>
                                                        )
                                                        : "-"
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box className={classes.detailsStatusContainer}>
                                    <Box className={classes.detailsStatusContent}>
                                        <Box
                                            className={`${classes.detailsStatusItemContainer} ${classes.statusGreen}`}
                                        >
                                            <Typography
                                                variant='body2'
                                                classes={{ root: classes.statusHeading }}
                                            >
                                                Due Date
                                            </Typography>
                                            <Typography classes={{ root: classes.statusText }}>
                                                {milestoneDetails && milestoneDetails.dueDate ? moment(milestoneDetails.dueDate).format('DD/MM/YYYY') :
                                                    <Typography variant='h6' classes={{ root: classes.info }}>-</Typography>}
                                            </Typography>
                                        </Box>

                                        <Box
                                            className={`${classes.detailsStatusItemContainer} ${classes.statusGreen}`}
                                        >
                                            <Typography
                                                variant='body2'
                                                classes={{ root: classes.statusHeading }}
                                            >
                                                Current State
                                            </Typography>
                                            <Typography classes={{ root: classes.statusText }} style={{ color: milestoneDetails && milestoneDetails.status ? forStatusColorhandler(milestoneDetails.status) : '' }}>
                                                {
                                                    milestoneDetails && milestoneDetails.status === "todo"
                                                        ?
                                                        "Not Started"
                                                        :
                                                        milestoneDetails && milestoneDetails.status === "in_progress"
                                                            ?
                                                            "In Progress"
                                                            :
                                                            milestoneDetails && milestoneDetails.status === "stopped"
                                                                ?
                                                                "Stopped"
                                                                :
                                                                milestoneDetails && milestoneDetails.status === "blocked"
                                                                    ?
                                                                    "Blocked"
                                                                    :
                                                                    milestoneDetails && milestoneDetails.status === "complete"
                                                                        ?
                                                                        "Complete"
                                                                        :
                                                                        milestoneDetails && milestoneDetails.status === "deferred"
                                                                            ?
                                                                            "Deferred"
                                                                            :
                                                                            milestoneDetails && milestoneDetails.status === "ready_for_review"
                                                                                ?
                                                                                "Ready for Review"
                                                                                :
                                                                                milestoneDetails && milestoneDetails.status === "overdue"
                                                                                    ?
                                                                                    "Overdue"
                                                                                    :
                                                                                    ""
                                                }
                                            </Typography>
                                        </Box>

                                        {/* <Box
                className={`${classes.detailsStatusItemContainer} ${classes.statusOrange}`}
              >
                <Typography
                  variant='body2'
                  classes={{ root: classes.statusHeading }}
                >
                  Effort Hours
                </Typography>
                <Typography classes={{ root: classes.statusText }}>36</Typography>
              </Box> */}
                                    </Box>
                                </Box>

                                <Box className={classes.tabsOuterContainer}>
                                    <TabContext value={activeTab}>
                                        <TabList
                                            classes={{
                                                flexContainer: classes.TabListRoot,
                                                indicator: classes.TabsIndicator,
                                            }}
                                            onChange={(e, newValue) => setActiveTab(newValue)}
                                        >
                                            <Tab
                                                // label='Progress'
                                                label={
                                                    <Typography
                                                        className={activeTab === "1" ? classes.selected : classes.notSelected}
                                                        variant="h4">Progress</Typography>
                                                }
                                                value='1'
                                                classes={{
                                                    wrapper: classes.TabWrapper,
                                                    root: classes.TabRoot,
                                                    selected: classes.selected,
                                                }}
                                            />
                                            <Tab
                                                // label='Conversations'
                                                label={
                                                    <Typography
                                                        className={activeTab === "2" ? classes.selected : classes.notSelected}
                                                        variant="h4">Conversations</Typography>
                                                }
                                                value='2'
                                                classes={{
                                                    wrapper: classes.TabWrapper,
                                                    root: classes.TabRoot,
                                                    selected: classes.selected,
                                                }}
                                            />
                                            <Tab
                                                // label='Documents'
                                                label={
                                                    <Typography
                                                        className={activeTab === "3" ? classes.selected : classes.notSelected}
                                                        variant="h4">Documents</Typography>
                                                }
                                                value='3'
                                                classes={{
                                                    wrapper: classes.TabWrapper,
                                                    root: classes.TabRoot,
                                                    selected: classes.selected,
                                                }}
                                            />
                                            <Tab
                                                // label='Time'
                                                label={
                                                    <Typography
                                                        className={activeTab === "4" ? classes.selected : classes.notSelected}
                                                        variant="h4">Time</Typography>
                                                }
                                                value='4'
                                                classes={{
                                                    wrapper: classes.TabWrapper,
                                                    root: classes.TabRoot,
                                                    selected: classes.selected,
                                                }}
                                            />
                                        </TabList>

                                        <Divider />

                                        <TabPanel
                                            value='1'
                                            classes={{
                                                root: classes.tabPanelRoot,
                                            }}
                                        >
                                            {/* <TimeLine /> */}
                                        </TabPanel>
                                        <TabPanel
                                            value='2'
                                            classes={{
                                                root: classes.tabPanelRoot,
                                            }}
                                        >
                                            {/* <Conversations /> */}
                                        </TabPanel>
                                        <TabPanel
                                            value='3'
                                            classes={{
                                                root: classes.tabPanelRoot,
                                            }}
                                        >
                                            {/* <Documents /> */}
                                        </TabPanel>
                                        <TabPanel
                                            value='4'
                                            classes={{
                                                root: classes.tabPanelRoot,
                                            }}
                                        >
                                            {/* <Effort /> */}
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </Box>
                        </Box>
                        {
                            openChecklistItemsDialog
                                ?
                                <ChecklistItemsDraggableDialog panel={"milestone"} project={true} template={false}
                                    title={milestoneDetails && milestoneDetails.checklist ? milestoneDetails.checklist.title : ''}
                                    open={openChecklistItemsDialog}
                                    setOpenChecklistItemsDialog={setOpenChecklistItemsDialog}
                                    checklistItemsArray={milestoneDetails && milestoneDetails.checklist ? milestoneDetails.checklist : []} />
                                :
                                <></>
                        }
                        <MessageModal
                            ref={modalRef} />
                    </Box>
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
        milestoneDetails: state.milestones.singleMilestoneDetails
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllTasklistOfProject: (projectId, isResStoringInRedux) => {
            return dispatch(getAllTaskListsRequest(projectId, isResStoringInRedux)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        deleteMilestoneRequest: (milestoneId) => {
            return dispatch(deleteMilestoneRequestProject(milestoneId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getMilestoneDetailsById: (data) => {
            return dispatch(getMilestoneDetailsByIdRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskMilestoneDetails);
