import React from 'react';

import {KeyboardTimePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

const TimePicker = React.memo(({timePickerLabel, selectedDatehandler, selectedDate}) => {

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    key={selectedDate}
                    margin="normal"
                    id="time-picker"
                    label={timePickerLabel}
                    value={selectedDate}
                    onChange={selectedDatehandler}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                />
            </MuiPickersUtilsProvider>
        </>
    )
})

export default TimePicker;
