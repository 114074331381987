import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {connect} from 'react-redux';
import {Box, FormHelperText, Grid, TextField, Typography,} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {useStyles} from './styles';

import {getAllClientsRequset} from '../../../../../../configuration/ConfigurationApiActions';

const OptionsForm = forwardRef((props, ref) => {
    const classes = useStyles();

    const toggleOptions = [{title: 'Yes'}, {title: 'No'}];

    const [forClient, setForClient] = useState(toggleOptions[1]);
    const [clientList, setClientList] = useState(null);
    const [client, setClient] = useState(null);
    const [billable, setBillable] = useState(toggleOptions[0]);
    const [enableEstimates, setEnableEstimates] = useState(toggleOptions[0]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    // const [attachDocument, setAttachDocument] = useState('');

    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState('');
    // const [attachDocumentError, setAttachDocumentError] = useState('');
    const [clientError, setClientError] = useState('');

    useEffect(() => {
        props.getAllClients().then((res) => {
            setClientList(res);
        })
    }, []);

    const validateForm = () => {
        if (
            startDate === null ||
            endDate === null
            // attachDocument === '' ||
            // client === null
        ) {
            if (startDate === null) {
                setStartDateError('Start date is required');
            }
            if (endDate === null) {
                setEndDateError('Target end date is required');
            }
            // if (attachDocument === '') {
            //   setAttachDocumentError('Attach document required');
            // }
            if (forClient && forClient.title === 'Yes' && client === null) {
                setClientError('Client is required');
            }
            return false;
        } else {
            return true;
        }
    };

    const isValidated = () => {
        return validateForm();
    };

    useImperativeHandle(ref, () => ({
        isValidated: () => {
            return isValidated();
        },
        state: {
            forClient,
            client,
            billable,
            enableEstimates,
            startDate,
            endDate,
        },
    }));
    return (
        <Box>
            <Box className={classes.titleContainer}>
                <Typography variant='h2' className={classes.titleLabel}>
                    Let's define the project options
                </Typography>
            </Box>
            <Box>
                <>
                    <Grid container spacing={3}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={forClient && forClient.title === 'Yes' ? 6 : 12}
                            lg={forClient && forClient.title === 'Yes' ? 6 : 12}
                            xl={forClient && forClient.title === 'Yes' ? 6 : 12}
                        >
                            <Autocomplete
                                fullWidth
                                value={forClient || ''}
                                onChange={(e, value) => setForClient(value)}
                                options={toggleOptions}
                                getOptionLabel={option => option.title}
                                getOptionSelected={(option, value) =>
                                    option.title === value.title
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        // label='Is this project for a client?'
                                        label={
                                            <Typography variant="body1"> Is this project for a client? </Typography>
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        {forClient && forClient.title === 'Yes' && (
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Autocomplete
                                    fullWidth
                                    value={client}
                                    onChange={(e, value) => {
                                        setClient(value);
                                        setClientError('');
                                    }}
                                    options={clientList}
                                    getOptionLabel={option => option.name}
                                    getOptionSelected={(option, value) =>
                                        option.name === value.name
                                    }
                                    renderInput={params => (
                                        <TextField {...params}
                                            // label='Select Client'
                                                   label={
                                                       <Typography variant="body1"> Select Client </Typography>
                                                   }
                                        />
                                    )}
                                />
                                {
                                    <FormHelperText className={classes.errorText}>
                                        {clientError}
                                    </FormHelperText>
                                }
                            </Grid>
                        )}

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Autocomplete
                                fullWidth
                                value={billable}
                                onChange={(e, value) => setBillable(value)}
                                options={toggleOptions}
                                getOptionLabel={option => option.title}
                                getOptionSelected={(option, value) =>
                                    option.title === value.title
                                }
                                renderInput={params => (
                                    <TextField {...params}
                                        // label='Is this project billable?'
                                               label={
                                                   <Typography variant="body1"> Is this project billable? </Typography>
                                               }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Autocomplete
                                fullWidth
                                value={enableEstimates}
                                onChange={(e, value) => setEnableEstimates(value)}
                                options={toggleOptions}
                                getOptionLabel={option => option.title}
                                getOptionSelected={(option, value) =>
                                    option.title === value.title
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        // label='Do you want to enable estimates?'
                                        label={
                                            <Typography variant="body1"> Do you want to enable estimates? </Typography>
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    fullWidth
                                    // label='Start Date'
                                    label={
                                        <Typography variant="body1"> Start Date </Typography>
                                    }
                                    format='dd/MM/yyyy'
                                    value={startDate}
                                    onChange={date => {
                                        setStartDate(date);
                                        setStartDateError('');
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            {
                                <FormHelperText className={classes.errorText}>
                                    {startDateError}
                                </FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    fullWidth
                                    // label='Target End Date'
                                    minDate={startDate}
                                    label={
                                        <Typography variant="body1"> Target End Date </Typography>
                                    }
                                    format='dd/MM/yyyy'
                                    value={endDate}
                                    onChange={date => {
                                        setEndDate(date);
                                        setEndDateError('');
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            {
                                <FormHelperText className={classes.errorText}>
                                    {endDateError}
                                </FormHelperText>
                            }
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                fullWidth
                label='Attach Project Requirements/Scope Document'
                value={attachDocument}
                onChange={e => {
                  setAttachDocument(e.target.value);
                  setAttachDocumentError('');
                }}
              />
              {
                <FormHelperText className={classes.errorText}>
                  {attachDocumentError}
                </FormHelperText>
              }
            </Grid> */}
                    </Grid>
                </>
            </Box>
        </Box>
    );
});

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllClients: () => {
            return dispatch(getAllClientsRequset()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef: true}
)(OptionsForm);
