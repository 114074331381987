import React, { useEffect, useRef, useState } from 'react'
import './TableView.css';
import { Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import TableContainer from '@material-ui/core/TableContainer';
// import Tooltip from '@material-ui/core/Tooltip';
// import { withStyles } from "@material-ui/core/styles";
// import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux';
// import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
// import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MessageModal from '../../../../../utilities/MessageModal/MessageModal'
import { ROUTER_ACTIONS } from '../../../../../utilities/Constants';
import { getAllAuthUsersRequest, getSingleAuthUserDetailsRequest } from '../../../../authentication/AuthenticationApiActions'
// const CustomSwitch = withStyles({
//   colorSecondary: {
//     "&.Mui-checked + .MuiSwitch-track": {
//       backgroundColor: "green"
//     }
//   },
// })(Switch);

const TeamList = ({
    setRowIndex,
    setSelectedRow,
    selectedRow,
    allUsers,
    getAllAuthUsers,
    match,
    history,
    setAddNewUser,
}) => {
    const modalRef = useRef();
    
    const getDetailsHandler = (e, id) => {
        e.stopPropagation();
        setAddNewUser(false)
    }


    const columns = [
        {
            field: 'name',
            title: 'name',
            cellStyle: {
                width: '20%',
            },
            headerStyle: {
                display: 'none'
            },
            render: rowData => {
                return (
                    <Typography variant="body1">{`${rowData.firstName} ${rowData.lastName}`}</Typography>
                )
            },
        },
        {
            field: 'email',
            title: 'email',
            cellStyle: {
                width: '15%',
            },
            headerStyle: {
                display: 'none'
            },
            render: rowData => {
                return (
                    <Typography variant="body1">{rowData.email}</Typography>
                )
            },
        },
        {
            field: 'mobile',
            title: 'mobile',
            cellStyle: {
                width: '15%',
            },
            headerStyle: {
                display: 'none'
            },
            render: rowData => {
                return (
                    <Typography variant="body1">{rowData.mobile}</Typography>
                )
            },
        },
        {
            field: 'roles',
            title: 'roles',
            cellStyle: {
                width: '15%',
            },
            headerStyle: {
                display: 'none'
            },
            render: rowData => {
                return (
                    <Typography variant="body1">{rowData && rowData.roles ? rowData.roles[0] : []}</Typography>
                )
            },
        }
        // {
        //   field: 'status',
        //   title: 'status',
        //   cellStyle: {
        //     width: '5%',
        //   },
        //   headerStyle: {
        //     display: 'none'
        //   },
        //   render: rowData => {
        //     return (
        //       <CustomSwitch
        //         className="custome-switch-color"
        //         checkedIcon={<div className="checkedIconDivStyle-user" ></div>}
        //         icon={<div className="notCheckedIconDivStyle-user" ></div>}
        //         checked={rowData.enabled}
        //         onClick={(e) => {
        //           e.stopPropagation();
        //           let data = Object.assign([], authUsers);
        //           data[rowData.tableData.id].enabled = e.target.checked
        //           const title = `User`
        //           const message = `Are you sure,you want to chnage status`
        //           modalRef.current.setState({
        //               showModal: true,
        //               message: message,
        //               title: title,
        //               showOkButton: true,
        //               showCancelButton: true,
        //               showNote: false,
        //               note: '',
        //               handleOk: (note) => {
        //                 setAuthUsers(data)
        //               },
        //               okText: "Yes",
        //               cancelText: 'No'
        //           });

        //         }}
        //         inputProps={{ "aria-label": "secondary checkbox" }}
        //       />
        //     )
        //   },
        // }
    ]

    return (
        <TableContainer>
            <MaterialTable
                columns={columns}
                data={allUsers || []}
                options={{
                    padding: 'dense',
                    toolbar: false,
                    paging: false,
                    sorting: false,
                    emptyRowsWhenPaging: false,
                    draggable: false,
                    rowStyle: rowData => ({
                        backgroundColor: (selectedRow === rowData.id) ? '#0faeec17' : '#FFF',
                        color: '#77797c',
                        borderBottom: 'none',
                        hover: {
                            backgroundColor: '#0faeec17 !important'
                        }
                    })
                }}
                onRowClick={(e, rowData) => {
                    getDetailsHandler(e, rowData.id);
                    setRowIndex(rowData.id);
                    setSelectedRow(rowData.id)
                }}
            />
            <MessageModal
                ref={modalRef}
            />
        </TableContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        allUsers: state.auth.users
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllAuthUsers: () => {
            return dispatch(getAllAuthUsersRequest()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getSingleAuthUserDetails: (id) => {
            return dispatch(getSingleAuthUserDetailsRequest(id)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamList);
