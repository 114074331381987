import React from 'react';
import {NavLink} from 'react-router-dom';
import clsx from 'clsx';
import SlideToggle from 'react-slide-toggle';
import {AppBar, Box, IconButton, Toolbar} from '@material-ui/core';
import platformLogo from '../../../../assets/platform-logo.png';
import {useStyles} from './styles';
import ThemeOne from '../../../../utilities/theme/theme.json';

const AppHeader = ({handleDrawerOpenClose, children}) => {
    const classes = useStyles();

    return (
        <AppBar
            position='fixed'
            className={clsx(classes.appBar)}
            elevation={0}
            style={{
                background: ThemeOne.topMenuBackground
            }}
        >
            <Toolbar disableGutters={true}>
                <IconButton
                    disableRipple={true}
                    color='inherit'
                    onClick={handleDrawerOpenClose}
                    edge='start'
                    className={clsx(classes.menuButton)}
                >
                    <Box className={classes.hamburgerButton}>
                        <Box className={classes.hamburgerButtonInner}>
                            <Box className={classes.hamburgerOne}></Box>
                            <Box className={classes.hamburgerTwo}></Box>
                            <Box className={classes.hamburgerThree}></Box>
                        </Box>
                    </Box>
                </IconButton>

                <Box className={classes.headerChildrenContainer}>{children}</Box>

                <Box>
                    <SlideToggle
                        collapsed
                        duration={500}
                        render={({toggle, setCollapsibleElement}) => (
                            <Box>
                                <Box
                                    // onClick={toggle}
                                    className={classes.appToggleImageContainer}
                                >
                                    <img
                                        src={platformLogo}
                                        alt='logo'
                                        className={classes.appToggleImage}
                                    />
                                </Box>

                                <div className={classes.appPanel} ref={setCollapsibleElement}>
                                    <ul>
                                        <li>
                                            <NavLink to='/' className={classes.panelItem}>
                        <span className={classes.icon}>
                          <i className='fab fa-artstation'></i>
                        </span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/' className={classes.panelItem}>
                        <span className={classes.icon}>
                          <i className='fab fa-autoprefixer'></i>
                        </span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/' className={classes.panelItem}>
                        <span className={classes.icon}>
                          <i className='fab fa-atlassian'></i>
                        </span>
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink to='/' className={classes.panelItem}>
                        <span className={classes.icon}>
                          <i className='fab fa-asymmetrik'></i>
                        </span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/' className={classes.panelItem}>
                        <span className={classes.icon}>
                          <i className='fab fa-airbnb'></i>
                        </span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </Box>
                        )}
                    />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default AppHeader;
