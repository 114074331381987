import React from 'react';
import {connect} from 'react-redux';
import './AppLoader.css';
import {CircularProgress} from '@material-ui/core';

class AppLoader extends React.Component {

    render() {
        return (
            <span>
        <CircularProgress style={{width: '20px', height: '20px', marginLeft: '10px'}} size="10px"
                          className="circular-progress-app-loader-for-save-edit"/>
      </span>
        )
    }
}

const mapStateToProps = state => {
    return {}
};

AppLoader = connect(
    mapStateToProps
)(AppLoader);

export default AppLoader;
