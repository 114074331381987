import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        formHeading: {
            fontWeight: '400',
            color: '#77797c',
        },
        orText: {
            marginTop: '0.6rem',
            color: '#77797c',
        },
        addButton: {
            marginTop: '1rem',
            marginLeft: '0.5rem',
        },
        participantsContainer: {
            margin: '1.5rem auto',
        },
        field: {
            marginLeft: '0.5rem',
        },
        label: {
            color: '#77797c',
            fontSize: '15px',
            fontWeight: '400',
        },
        folderIcon: {
            fontSize: '18px',
            marginRight: '5px',
        },
        green: {
            color: 'limegreen',
        },
        blue: {
            color: '#428bca',
        },
        orange: {
            color: '#f5b01e',
        },
        actionsContainer: {
            marginRight: '1rem',
            marginBottom: '0.7rem',
            marginTop: '0.5rem',
        },
        cancelButton: {
            color: 'red',
            backgroundColor: '#f8cce2',
            '&:hover': {
                color: 'red',
                backgroundColor: '#f8cce2',
            },
        },
    };
});
