import React from 'react'
import { Box, Card, Grid, Typography } from '@material-ui/core';
import CustomTheme from '../../../../../utilities/theme/theme.json'
import Button from '@material-ui/core/Button';

const NoVendorFound = ({ setAddNewVendor, setShowNewVendorForm }) => {
    return (
        <Box>
            <Grid container>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                    <Card className="blank-screen-new-team">
                        <Grid className="for-margin-top">
                            <Box className="info-blank-page">
                                <Typography style={{ color: CustomTheme.fontColor }}
                                    className="welcome-title">Let's begin by creating a new
                                    vendor</Typography>
                            </Box>
                            <Box className="info-blank-page">
                                <Button size="small"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        setAddNewVendor(true)
                                        setShowNewVendorForm(true)
                                    }} className="blank-page-button-tasklist"><i
                                        className="fas fa-user"></i> <span
                                            className="btn-margin-left">+ Vendor</span></Button>
                            </Box>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
export default NoVendorFound