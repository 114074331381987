import React from 'react';
import {Route, Switch} from 'react-router-dom';

import ActionStation from '../actionStation/ActionStation';
import ActionStationMyTasks from '../actionStation/components/MyTasks/MyTasks'
import ChangePassword from '../authentication/ChangePassword/ChangePassword';

import ProjectTeams from '../projects/components/teams/Teams';
import ProjectAddProject from '../projects/components/addProject/AddProject';
import ProjectEditProject from '../projects/components/editProject/EditProject';
import ProjectTasks from '../projects/components/details/Project/components/tasks/Tasks';
import ProjectParticipants from '../projects/components/details/Project/components/participants/Participants';
import ProjectChecklists from '../projects/components/details/Project/components/checklist/Checklists'
import ProjectMilestones from '../milestones/Milestones'
import ProjectProgress from '../progress/Progress';

import AppHeader from '../userDashboard/components/AppHeader/AppHeader';
import ConfigurationHeader from '../configuration/components/ConfigurationHeader/ConfigurationHeader';

import ConfigurationOrganization from '../configuration/components/Organization/Organization';
import ConfigurationRoles from '../configuration/components/Roles/Roles';
import ConfigurationTasks from '../configuration/components/Tasks/Tasks';
import ConfigurationProjectsLandingPage from '../configuration/components/Projects/Templates';
import ConfigurationChecklists from '../configuration/components/Checklists/Checklists';
import ConfigurationTeams from '../configuration/components/Teams/Teams';
import ConfigurationClients from '../configuration/components/Clients/Clients';
import ConfigurationVendors from '../configuration/components/Vendors/Vendors';
import ConfigurationTableAndForm
    from '../configuration/components/Projects/components/TableAndForm/TableAndForm';
import ConfigurationTemplateChecklist
    from '../configuration/components/Projects/components/TableAndForm/Components/checklist/Checklists';
import ConfigurationUsers from '../configuration/components/Users/Users'
import ConfigurationUsersLandingPage from '../configuration/components/Users/UserLandingPage/UserLandingPage'
import {ROUTER_ACTIONS} from '../../utilities/Constants';

const UserDashboardRoutes = React.memo(({handleDrawerOpenClose}) => {
    return (
        <Switch>
            <Route
                exact
                path={ROUTER_ACTIONS.ACTION_STATION}
                render={(props) => (
                    <ActionStation handleDrawerOpenClose={handleDrawerOpenClose} {...props}/>
                )}
            />

            <Route
                exact
                path={ROUTER_ACTIONS.ACTION_STATION_MY_TASKS}
                render={(props) => (
                    <ActionStationMyTasks handleDrawerOpenClose={handleDrawerOpenClose} {...props}/>
                )}
            />

            <Route
                exact
                path={ROUTER_ACTIONS.CHANGE_PASSWORD}
                render={(props) => (
                    <ChangePassword handleDrawerOpenClose={handleDrawerOpenClose} {...props}/>
                )}
            />

            {/* Configuration Menu Routes */}
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationOrganization
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_ORGANIZATION}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationOrganization
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_ROLES}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationRoles
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_TASKS}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationTasks
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_PROJECTS}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationProjectsLandingPage
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_CHECKLISTS}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationChecklists
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_TEAMS}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationTeams
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_TEAM_ADD}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationTeams
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_CLIENTS}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationClients
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_CLIENT_ADD}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationClients
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_VENDORS}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationVendors
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_VENDOR_ADD}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationVendors
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_TEMPLATE}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationTableAndForm
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_TEMPLATE_EDIT}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationProjectsLandingPage
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_TEMPLATE_ADD}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationProjectsLandingPage
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_TASKLIST_VIEW}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationTasks
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_CHECKLIST_VIEW}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationChecklists
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />

            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_TEMPLATE_CHECKLIST}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationTemplateChecklist
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />

            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_TEMPLATE_CHECKLIST_EDIT}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationTemplateChecklist
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />

            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_USERS}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationUsers
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />

            <Route
                exact
                path={ROUTER_ACTIONS.CONFIGURATION_USER_ADD}
                render={(props) => (
                    <>
                        <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                            <ConfigurationHeader {...props} />
                        </AppHeader>
                        <ConfigurationUsersLandingPage
                            handleDrawerOpenClose={handleDrawerOpenClose}
                            {...props}
                        />
                    </>
                )}
            />



            {/* Projects Menu Routes */}
            <Route
                exact
                path={ROUTER_ACTIONS.PROJECTS_TEAMS}
                render={(props) => (
                    <ProjectTeams
                        handleDrawerOpenClose={handleDrawerOpenClose}
                        {...props}
                    />
                )}
            />
            <Route
                exact
                path={ROUTER_ACTIONS.PROJECTS_ADD}
                render={(props) => (
                    <ProjectAddProject
                        handleDrawerOpenClose={handleDrawerOpenClose}
                        {...props}
                    />
                )}
            />

            <Route
                exact
                path={ROUTER_ACTIONS.PROJECTS_EDIT}
                render={(props) => (
                    <ProjectEditProject
                        handleDrawerOpenClose={handleDrawerOpenClose}
                        {...props}
                    />
                )}
            />

            <Route
                exact
                path={ROUTER_ACTIONS.PROJECT_TASK}
                render={(props) => (
                    <ProjectTasks
                        handleDrawerOpenClose={handleDrawerOpenClose}
                        {...props}
                    />
                )}
            />

            <Route
                exact
                path={ROUTER_ACTIONS.PROJECT_PARTICIPANTS}
                render={(props) => (
                    <ProjectParticipants
                        handleDrawerOpenClose={handleDrawerOpenClose}
                        {...props}
                    />
                )}
            />

            <Route
                exact
                path={ROUTER_ACTIONS.PROJECT_CHECKLISTS}
                render={(props) => (
                    <ProjectChecklists
                        handleDrawerOpenClose={handleDrawerOpenClose}
                        {...props}
                    />
                )}
            />

            <Route
                exact
                path={ROUTER_ACTIONS.PROJECT_CHECKLIST_DETAILS}
                render={(props) => (
                    <ProjectChecklists
                        handleDrawerOpenClose={handleDrawerOpenClose}
                        {...props}
                    />
                )}
            />

            <Route
                exact
                path={ROUTER_ACTIONS.PROJECT_PROGRESS}
                render={(props) => (
                    <ProjectProgress
                        handleDrawerOpenClose={handleDrawerOpenClose}
                        {...props}
                    />
                )}
            />

            <Route
                exact
                path={ROUTER_ACTIONS.PROJECT_MILESTONES}
                render={(props) => (
                    <ProjectMilestones
                        handleDrawerOpenClose={handleDrawerOpenClose}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
});

export default UserDashboardRoutes;
