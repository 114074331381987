import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";

import {Box, Button, Grid, Link, Typography} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import logo from '../../../assets/logo.png';

import './Login.css';
import {useStyles} from './styles';

import AppLoader from '../../../utilities/AppLoader/AppLoader';
import MessageModal from "../../../utilities/MessageModal/MessageModal";

import {signInRequest} from '../AuthenticationApiActions';

const Login = React.memo(({signIn, loggedInUser}) => {
    const classes = useStyles();

    const modalRef = useRef();
    const [switchForm, setSwitchForm] = useState(false);

    const [username, setUserName] = useState('');
    const [pin, setPin] = useState('');
    const [otp, setOtp] = useState('');

    const onLogin = () => {
    };

    const onSubmit = () => {
        // localStorage.setItem('isLoggedIn', true);
        const formData = {
            username: username,
            password: pin
        }
        signIn(formData).then(() => {
        }, (error) => {
            if (error.response) {
                showLoginError(error.response.data.error.message);
            }
        });
    };

    /**
     * Show modal with the message and title received from the server
     * @param loginErrorMessage
     * @param loginErrorTitle
     */
    const showLoginError = (loginErrorMessage = 'User name or password is incorrect', loginErrorTitle = 'Invalid credentials') => {
        modalRef.current.setState({
            showModal: true, message: loginErrorMessage, title: loginErrorTitle,
            showOkButton: true,
            handleOk: () => {
            },
            okText: 'Ok'
        });
    }

    if (loggedInUser) {
        return <Redirect to="/"/>;
    } else {
        return (
            <Box className='login-container'>
                <Box className='login-form-container'>
                    <Box className='login-form-holder'>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                className='login-brand'
                            >
                                <Box component={Link} to='/' className='login-logo-container'>
                                    <img src={logo} alt='logo' className='login-logo'/>
                                    <Typography component='span' className='login-logo-text'>
                                        ARTIFEX
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                className='main-form'
                            >
                                <Box
                                    className={`main-form-piece ${switchForm ? '' : 'switched'}`}
                                >
                                    <ValidatorForm
                                        className='login-main-form'
                                        action='#'
                                        method='post'
                                        onSubmit={() => onLogin()}
                                    >
                                        <Box>
                                            <Typography className='login-form-heading'>
                                                Activate Your Account
                                            </Typography>
                                        </Box>

                                        <Box className='login-form-group'>
                                            <Box className='login-form-activate-text'>
                                                <Typography variant="h5" className="font-weight-400">
                                                    Please enter the One Time Password you received in the
                                                    welcome message.
                                                </Typography>
                                            </Box>
                                            <TextValidator
                                                fullWidth
                                                label='OTP'
                                                value={otp}
                                                onChange={e => setOtp(e.target.value)}
                                                validators={['required']}
                                                errorMessages={['OTP is required']}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.label,
                                                        focused: classes.labelText,
                                                    },
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        focused: classes.inputField,
                                                    },
                                                }}
                                            />
                                        </Box>

                                        <Box className='login-form-buttons-container'>
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                disableElevation={true}
                                                size='small'
                                                className='login-submit-button'
                                                type='submit'
                                            >
                                                Login
                                            </Button>
                                            <Typography
                                                component={Link}
                                                className='login-form-switch'
                                                onClick={() => setSwitchForm(false)}
                                            >
                                                Click here to sign in
                                            </Typography>
                                        </Box>
                                    </ValidatorForm>
                                </Box>

                                <Box
                                    className={`main-form-piece ${switchForm ? 'switched' : ''}`}
                                >
                                    <ValidatorForm
                                        className='login-main-form'
                                        action='#'
                                        method='post'
                                        onSubmit={() => onSubmit()}
                                    >
                                        <Box>
                                            <Typography className='login-form-heading'>
                                                Sign In
                                            </Typography>
                                        </Box>

                                        <Box className='login-form-group'>
                                            <TextValidator
                                                fullWidth
                                                label='USERNAME'
                                                value={username}
                                                onChange={e => setUserName(e.target.value)}
                                                validators={['required']}
                                                errorMessages={['Mobile number is required']}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.label,
                                                        focused: classes.labelText,
                                                    },
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        focused: classes.inputField,
                                                    },
                                                }}
                                            />
                                        </Box>

                                        <Box className='login-form-group'>
                                            <TextValidator
                                                fullWidth
                                                type="password"
                                                label='PIN'
                                                value={pin}
                                                onChange={e => setPin(e.target.value)}
                                                validators={['required']}
                                                errorMessages={['PIN is required']}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.label,
                                                        focused: classes.labelText,
                                                    },
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        focused: classes.inputField,
                                                    },
                                                }}
                                            />
                                        </Box>

                                        <Box className='login-form-buttons-container'>
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                disableElevation={true}
                                                size='small'
                                                className='login-submit-button'
                                                type='submit'
                                            >
                                                Sign In
                                            </Button>
                                            <Typography
                                                component={Link}
                                                className='login-form-switch'
                                                onClick={() => setSwitchForm(true)}
                                            >
                                                Activate your account
                                            </Typography>
                                        </Box>
                                    </ValidatorForm>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box component='footer' className='login-footer'>
                        <Typography className='login-footer-text'>
                            &copy; VIA SIMPLEX {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>

                <MessageModal
                    ref={modalRef}/>
                <AppLoader/>
            </Box>
        );
    }
});

const mapStateToProps = state => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        signIn: (data) => {
            return dispatch(signInRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });

        }
    }
};

const LoginConnect = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

export default LoginConnect;
