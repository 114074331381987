import React, {useEffect, useRef, useState} from 'react';
// import './CheckListModal.css';
import {Dialog, DialogActions, DialogContent, Grid, Typography,} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CustomTheme from '../../../../../../../../utilities/theme/theme.json'
import {addChecklistInProjectRequest, getAllChecklistInProjectRequest} from '../../../../../../ProjectsApiActions'
import MessageModal from '../../../../../../../../utilities/MessageModal/MessageModal'
import {addTagRequest, getAllTagsRequset} from '../../../../../../../configuration/ConfigurationApiActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const CheckListModal = ({
                            addTags,
                            getAllTags,
                            openAddCheckList,
                            setOpenAddCheckList,
                            addCheckList,
                            setAllCheckList,
                            getAllChecklistRequest,
                            projectId
                        }) => {
    const [checkListTitle, setCheckListTitle] = useState(undefined)
    const [checkListDescriptiion, setCheckListDescription] = useState(undefined);
    const [addChecklistChips, setAddChecklistChips] = useState([]);
    const [itemList, setItemList] = useState([
        {title: "", isCompleted: false}
    ])
    const modalRef = useRef();

    const [allTags, setAllTags] = useState(null)
    const [tagName, setTagName] = useState(null);

    useEffect(() => {
        if (tagName && tagName.length >= 3 && tagName.length < 4) {
            getAllTags(tagName, true).then((res) => {
                setAllTags(res)
            }).catch((err) => {
            })
        }
    }, [tagName])

    // storing values of item list in checklist
    const handleChangeInputListItem = (event, itemIndex) => {
        const data = Object.assign([], itemList);
        data[itemIndex] = {title: event.target.value, isCompleted: false};
        setItemList(data);
    }

    // for onclick Add icon add new row in checklist item list
    const addList = () => {
        const data = Object.assign([], itemList);
        const newRoleObj = {title: '', isCompleted: false};
        data.push(newRoleObj);
        setItemList(data);
    }


    // for onclick of cancel Button remove respected item list from cehcklist item list
    const removeItem = (itemIndex) => {
        const data = Object.assign([], itemList);
        data.splice(itemIndex, 1);
        setItemList(data);
    }


    // closing checklist model
    const handleCloseForAddCheckList = () => {
        setOpenAddCheckList(false)
        setAddChecklistChips([])
        setCheckListTitle(null)
        setCheckListDescription(null)
    }

    // add tags
    const addChips = (tags) => {
        tags.map((singleTagName) => {
            let data = {
                name: singleTagName,
                color: singleTagName
            }
            addTags(data).then((res) => {
            })
            return singleTagName
        })

    }

    // for add checklist
    const handleChecklistSaveData = () => {

        let newItemList = []
        newItemList = itemList && itemList.filter((item) => item.title !== "")

        let data = {
            projectId: projectId,
            title: checkListTitle
            //   description:checkListDescriptiion,
            //   items:itemList,
            //   tags:addChecklistChips
        }
        if (checkListDescriptiion) {
            data.description = checkListDescriptiion
        } else {
        }
        if (itemList) {
            data.items = newItemList
        } else {
        }
        if (addChecklistChips) {
            data.tags = addChecklistChips
        } else {
        }

        addCheckList(data).then((res) => {
            // addChips(addChecklistChips)
            let filterArr = []
            getAllTags().then((res) => {
                let arr = addChecklistChips && addChecklistChips.filter((tag) => {
                    return !(res.some((resTag) => resTag.name === tag))
                })
                filterArr.push(arr)
                addChips(filterArr[0])
            })
            setOpenAddCheckList(false)

            setCheckListTitle('')
            setCheckListDescription('')
            setItemList([{title: "", isCompleted: false}])
            setAddChecklistChips([])

            getAllChecklistRequest(projectId).then((res) => {
                setAllCheckList(res)
                // setShrink(true)
            })

        }).catch((err) => {
        })
    }

    // const  handleAddChip=(chipTitle)=>{
    //     const data = Object.assign([],addChecklistChips);
    //     data.push(chipTitle)
    //     setAddChecklistChips(data);
    // }

    // const handleDeleteChip=(chip,index)=>
    // {
    //     const data = Object.assign([], addChecklistChips);
    //     data.splice(index,1)
    //     setAddChecklistChips(data);
    // }

    const handleEsc = (e) => {
        if (e.keyCode === 27) {
            if (checkListTitle != null || checkListDescriptiion != null || itemList.length > 1 || addChecklistChips.length > 0) {
                const title = `Close Without Saving?`
                const message = `Are you sure you want to close this checklist without saving?`
                modalRef.current.setState({
                    showModal: true,
                    message: message,
                    title: title,
                    showOkButton: true,
                    showCancelButton: true,
                    showNote: false,
                    note: '',
                    handleOk: (note) => {
                        handleCloseForAddCheckList();
                    },
                    okText: "Yes",
                    cancelText: 'No'
                });
            } else {
                handleCloseForAddCheckList();
            }
        }
    }

    return (
        <Dialog
            open={openAddCheckList}
            // TransitionComponent={Transition}
            fullWidth="md"
            className="dailog-box-tasklist-add"
        >
            <DialogContent>
                <Typography variant="h2" style={{color: CustomTheme.fontColor}} className="block-titles margin-top-20">
                    New Checklist
                </Typography>
                <ValidatorForm
                    onSubmit={handleChecklistSaveData}
                    onKeyDown={(e) => handleEsc(e)}
                >
                    <Grid className="marginTop" item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid container>
                            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                <Typography className="task-title" variant="body1">Enter a title for this
                                    checklist</Typography>
                            </Grid>
                            <Grid className="checklist-title-container" item xl={3} lg={3} md={3} sm={12} xs={12}>
                                <TextValidator
                                    fullWidth
                                    required
                                    autoFocus
                                    // variant="outlined"
                                    size="small"
                                    // label="Title"
                                    onChange={(e) => setCheckListTitle(e.target.value)}
                                    name="name" value={checkListTitle || ""}
                                    validators={['required', 'trim', 'maxStringLength:50']}
                                    errorMessages={['This field is required', 'At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="marginTop" item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="body1">Enter a brief description</Typography>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <TextValidator
                            fullWidth
                            // variant="outlined"
                            size="small"
                            // label="Description"
                            onChange={(e) => setCheckListDescription(e.target.value)}
                            name="description" value={checkListDescriptiion || ""}
                            validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:250']}
                            errorMessages={['At least one alphanumeric characters', 'The max length of 250 charachers reached']}
                        />
                    </Grid>
                    {/* <Grid className="chip-container" item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Box className={classes.tagsContaianer}>
                                <span className={classes.tagIcon}>
                                    <i className='fas fa-tags'></i>
                                </span>
                                <Box className={classes.chipInputContainer}>
                                    <ChipInput
                                        disableUnderline={true}
                                        fullWidth
                                        value={addChecklistChips}
                                        onAdd={(chip) => handleAddChip(chip)}
                                        onDelete={(chip, index) => handleDeleteChip(chip, index)}
                                        onChange={(chips) => setAddChecklistChips(chips)}
                                        classes={{
                                            root: classes.chipInput,
                                            inputRoot: classes.inputRoot,
                                            input: classes.input,
                                            chip: classes.chip,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid> */}
                    <Grid className="chip-container" item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <span className="tag-icon-span">
                                <i className='fas fa-tags'></i>
                            </span>
                        <Autocomplete
                            multiple
                            id="tags-filled"
                            value={addChecklistChips}
                            className="tags-contianer"
                            onChange={(event, newValue) => {
                                setAddChecklistChips(newValue);
                                // alert(newValue)
                                // addChips(event,newValue);
                            }}
                            onInputChange={(event, newValue) => {
                                setTagName(newValue);
                            }}
                            clearOnEscape={true}
                            options={allTags ? Object.keys(allTags).map((option) => allTags[option].name) : []}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip key={index} variant="outlined" label={option} {...getTagProps({index})} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} variant="filled" placeholder="Type tag and hit enter"/>
                            )}
                        />
                    </Grid>
                    <Grid className="padding-top-15">
                        <Typography variant="body1">Add items to the checklist:</Typography>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid container>
                            {
                                itemList.length > 0 && itemList.map((item, itemIndex) => (
                                    <Grid container spacing={3} className="role-details-container" key={itemIndex}>
                                        <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                                            <TextValidator
                                                fullWidth
                                                className="list-of-item"
                                                // variant="outlined"
                                                size="small"
                                                // label="List Item"
                                                onChange={(e) => handleChangeInputListItem(e, itemIndex)}
                                                name="listTitle"
                                                value={item.title || ""}
                                                validators={['matchRegexp:^.*[a-zA-Z0-9]+.*$', 'maxStringLength:50']}
                                                errorMessages={['At least one alphanumeric characters', 'The max length of 50 charachers reached']}
                                            />
                                        </Grid>
                                        <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                            {
                                                (itemIndex === (itemList.length - 1))
                                                    ? (
                                                        <i
                                                            className="add-role-icon fas fa-plus-circle"
                                                            aria-hidden="true"
                                                            onClick={addList}
                                                        ></i>
                                                    )
                                                    : (
                                                        <i
                                                            className="remove-role-icon far fa-times-circle"
                                                            onClick={() => removeItem(itemIndex)}
                                                        ></i>
                                                    )
                                            }
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                            <Grid container>
                                <Grid xl={1} lg={1} md={1} sm={1} xs={1}>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="secondary"
                            className="reset-dialog-button"
                            size="small"
                            onClick={() => {
                                handleCloseForAddCheckList();
                            }}
                            // className={classes.addButtonTasks}
                        >
                            CANCEL
                        </Button>
                        <Button
                            type="submit"
                            className="save-dialog-button"
                            variant="outlined"
                            color="primary"
                            size="small"
                            // className={classes.addButtonTasks}
                        >
                            SAVE
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </DialogContent>
            <MessageModal
                ref={modalRef}/>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        // loggedInUser: state.auth.loggedInUser,
        // post: state.postDetails.post,
        // comments:state.postDetails.comments
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllChecklistRequest: (projectId) => {
            return dispatch(getAllChecklistInProjectRequest(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addCheckList: (data) => {
            return dispatch(addChecklistInProjectRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getAllTags: (name, searchTag) => {
            return dispatch(getAllTagsRequset(name, searchTag)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        addTags: (data) => {
            return dispatch(addTagRequest(data)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(CheckListModal);
