import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        label: {
            fontSize: '0.8rem',
        },
        labelText: {
            color: 'rgba(0, 0, 0, 0.54) !important',
            fontSize: '0.8rem',
            fontWeight: '400',
        },
        inputField: {
            '&:after': {
                borderBottom: '2px solid red !important',
            },
        },
    };
});
