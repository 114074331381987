/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import Auth from '../../utilities/Auth';

// Export Constants
export const SIGN_IN = 'SIGN_IN';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const SIGN_OUT = 'SIGN_OUT';
export const SESSION_EXPIRED_HIDE_MESSAGE = 'SESSION_EXPIRED_HIDE_MESSAGE';
export const GET_ALL_USESRS = 'GET_ALL_USESRS';
export const GET_SINGLE_USER_DETAILS = 'GET_SINGLE_USER_DETAILS';

/**
 * Actions related to the authentication module
 */

/**
 * Action called after sign in api call
 * @param data
 * @returns {{type: string, user: (*|number), accessToken: *}}
 */
export function signIn(data) {
    return {
        type: SIGN_IN,
        user: data.user,
        accessToken: data.access_token
    };
}

/**
 * User logout/signout action
 * @param data
 * @returns {{type: string, data: *}}
 */
export function signOut() {
    Auth.logout();
    return {
        type: SIGN_OUT
    }
}

export function sessionExpired(error = 'Session expired') {
    return {
        type: SESSION_EXPIRED,
        error
    }
}

/**
 * User sessionExpired action
 */
export const sessionExpiredHideMessage = () => {
    return {
        type: SESSION_EXPIRED_HIDE_MESSAGE,
    }
};


/**
 * get all users
 * @param usersRes
 */
 export function getAllUsers(usersRes) {
    return {
        type: GET_ALL_USESRS,
        users:usersRes
    }
}

/**
 * get single  user details
 * @param singleUserDetails
 */
 export function getSingleUser(singleUserDetails) {
    return {
        type: GET_SINGLE_USER_DETAILS,
        singleUserDetails:singleUserDetails
    }
}
