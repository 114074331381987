import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    oppositeContent: {
        marginBottom:'-17px',
        '&:before': {
            content: '""',
            flex: 0,
            padding: '0px',
        },
    },
    profileContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '-32px',
    },
    profileImage: {
        height: '30px',
        width: '30px',
        borderRadius: '50%',
        marginLeft: '-10px',
        backgroundColor: '#fff',
        padding: '2px',
    },
    contentContainer: {
        display: 'flex',
        padding: '4px 20px',
        marginTop: '5px',
    },
    contentInnerContainer: {
        display: 'flex',
        // width: 'fit-content',
        width:'165px',
        // backgroundColor: '#CCF1FF',
        // color: 'darkslategrey',
        // borderRadius: '7px',
        borderTopLeftRadius: '7px',
        borderBottomLeftRadius: '7px',
        marginBottom: '1rem',
        // padding: '0.5rem 0.9rem 0.5rem 0.9rem',
        padding:'16px'
    },
    headingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: '18px',
        marginTop: '5px',
        marginRight: '5px',
    },
    nameText: {
        marginLeft: '5px',
        fontSize: '15px',
        fontWeight: '500',
        color: '#77797c'
        // fontVariant: 'small-caps',
    },
    timeText: {},
    taskInfoContainer: {
        display: 'flex',
        marginTop: '8px',
    },
    taskIconContainer: {
        borderRight: '1px dashed #77797c',
        width: '43px',
        textAlign: 'center',
    },
    icon: {
        fontSize: '32px',
    },
    taskTextContainer: {
        marginLeft: '0.7rem',
    },
    taskStatusText: {},
    taskStatusDescription: {},
    hoursContainer: {
        borderRight: '1px dashed #77797c',
        width: '50px',
        textAlign: 'center',
    },
    hoursCountText: {
        fontSize: '24px',
        lineHeight: '28px',
        fontWeight: '600',
    },
    hoursLabel: {
        textTransform: 'uppercase',
    },
    grey: {
        color: '#d0d0d0',
    },
    userTaskUpdateDate: {
        color: '#77797c',
        marginLeft: '8px'
    },
    forWhiteBorder: {

    },
    stateContainer: {
        display: 'flex',
        // width: 'fit-content',
        width:'215px',
        // backgroundColor: '#CCF1FF',
        marginBottom: '1rem',
        // padding: '0.5rem 2.9rem 0.5rem 0.9rem',
        marginLeft: '2px',
        padding:'16px'
    },
    stateIconContainer: {
        paddingRight: '9px',
        borderRight: '1px dashed #77797c',
        color: '#77797c'
    },
    stateIcon: {
        fontSize: '35px',
        position: 'relative',
        top: '4px',
        color: '#77797c'
    },
    stateTitleContainer: {
        paddingRight:'9px',
        paddingLeft:'9px'
    },
    stateTitle: {
        color: '#77797c',
        fontWeight:500,
        marginTop:'10px'
    },
    stateDueDate: {
        color: '#77797c',
        marginTop:'5px'
    },
    tasksDetailsContainer: {
        display: 'flex',
        // width: 'fit-content',
        // backgroundColor: '#CCF1FF',
        marginBottom: '1rem',
        // padding: '0.5rem 31.9rem 0.5rem 0.9rem',
        marginLeft: '2px',
        borderTopRightRadius: '7px',
        borderBottomRightRadius: '7px',
        padding:'13px',
        width:'67%'
    },
    taskTitleHeader: {
        marginLeft:'3px',
        position:'relative',
        // top:'2px'
        top:'15px'
    },
    taskhaederTitle: {
        color: '#77797c',
        fontWeight:500,
    },
    tasksDetailsText: {
        color:'#77797c'
    },
    tasksTitle: {
        marginLeft:'25px',
        position:'relative',
        // top:'2px'
        top:'15px'
    },
    taskhaederTitleForTasklist:{
        marginTop:'1px'
    },
    tasksDetailsTextForTasklist: {
        marginTop:'1px'
    }
}));
