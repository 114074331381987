import React from 'react';
import './App.css';
import {ThemeProvider} from '@material-ui/core';
import theme from './utilities/theme';
import AppRoutes from './routes/AppRoutes';

/**
 * TODO: Remove console logs, errors and warnings throught the project.
 */
const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <AppRoutes/>
        </ThemeProvider>
    );
};

export default App;
