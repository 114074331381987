import ThemeOne from './theme.json';

const typography = {
    h1: {
        fontFamily: ThemeOne.h1.fontFamily,
        fontWeight: ThemeOne.h1.fontWeight,
        fontSize: ThemeOne.h1.fontSize,
        fontStyle: ThemeOne.h1.fontStyle,
        color: ThemeOne.fontColor,
        lineHeight: '32px',
        letterSpacing: '-0.01562em',
    },
    h2: {
        fontFamily: ThemeOne.h2.fontFamily,
        fontWeight: ThemeOne.h2.fontWeight,
        fontSize: ThemeOne.h2.fontSize,
        fontStyle: ThemeOne.h2.fontStyle,
        color: ThemeOne.fontColor,
        lineHeight: '30px',
        letterSpacing: '-0.00833em',
    },
    h3: {
        fontFamily: ThemeOne.h3.fontFamily,
        fontWeight: ThemeOne.h3.fontWeight,
        fontSize: ThemeOne.h3.fontSize,
        fontStyle: ThemeOne.h3.fontStyle,
        color: ThemeOne.fontColor,
        lineHeight: '32px',
        letterSpacing: '-0.00833em',
    },
    h4: {
        fontFamily: ThemeOne.h4.fontFamily,
        fontWeight: ThemeOne.h4.fontWeight,
        fontSize: ThemeOne.h4.fontSize,
        fontStyle: ThemeOne.h4.fontStyle,
        color: ThemeOne.fontColor,
        lineHeight: 1.235,
        letterSpacing: '0.00735em',
    },
    h5: {
        fontFamily: ThemeOne.h5.fontFamily,
        fontWeight: ThemeOne.h5.fontWeight,
        fontSize: ThemeOne.h5.fontSize,
        fontStyle: ThemeOne.h5.fontStyle,
        color: ThemeOne.fontColor,
        lineHeight: '20px',
        letterSpacing: '0em',
    },
    h6: {
        fontFamily: ThemeOne.h6.fontFamily,
        fontWeight: ThemeOne.h6.fontWeight,
        fontSize: ThemeOne.h6.fontSize,
        fontStyle: ThemeOne.h6.fontStyle,
        color: ThemeOne.fontColor,
        lineHeight: '20px',
        letterSpacing: '0.0075em',
    },
    subtitle1: {
        fontFamily: ThemeOne.subtitle1.fontFamily,
        fontWeight: ThemeOne.subtitle1.fontWeight,
        fontSize: ThemeOne.subtitle1.fontSize,
        fontStyle: ThemeOne.subtitle1.fontStyle,
        letterSpacing: '0.00938em',
    },
    subtitle2: {
        fontFamily: ThemeOne.subtitle2.fontFamily,
        fontWeight: ThemeOne.subtitle2.fontWeight,
        fontSize: ThemeOne.subtitle2.fontSize,
        fontStyle: ThemeOne.subtitle2.fontStyle,
        letterSpacing: '0.00714em',
    },
    body1: {
        fontFamily: ThemeOne.body1.fontFamily,
        fontWeight: ThemeOne.body1.fontWeight,
        fontSize: ThemeOne.body1.fontSize,
        fontStyle: ThemeOne.body1.fontStyle,
        lineHeight: '20px',
        letterSpacing: '0.00938em',
    },
    body2: {
        fontFamily: ThemeOne.body2.fontFamily,
        fontWeight: ThemeOne.body2.fontWeight,
        fontSize: ThemeOne.body2.fontSize,
        fontStyle: ThemeOne.body2.fontStyle,
        lineHeight: '20px',
        letterSpacing: '0.01071em',
    },

    button: {
        fontFamily: ThemeOne.body1.fontFamily,
        fontWeight: ThemeOne.body2.fontWeight,
        fontSize: ThemeOne.body2.fontSize,
        fontStyle: ThemeOne.body2.fontStyle,
        lineHeight: 1.75,
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
    },
    caption: {
        // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif"',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 1.66,
        letterSpacing: '0.03333em',
    },
    overline: {
        // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif"',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 2.66,
        letterSpacing: '0.08333em',
        textTransform: 'uppercase',
    },
};

export default typography;
