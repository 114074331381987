import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import '../Teams/Teams.css';
import ClientAddEditForm from './ClientAddEditForm/ClientAddEditForm';
import TableView from './TableView/ClientList';
import { connect } from 'react-redux';
import './Clients.css'
import { ROUTER_ACTIONS } from '../../../../utilities/Constants';
import CustomTheme from '../../../../utilities/theme/theme.json'
import NoClientFound from './NoClientFound/NoClientFound';
import { getAllClientsRequset } from '../../ConfigurationApiActions'
const Clients = ({ history, match, allClients, getAllClients }) => {

    const [showNewClientForm, setShowNewClientForm] = useState(false)
    const [rowIndex, setRowIndex] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [addNewClient, setAddNewClient] = useState(false)

    useEffect(() => {
        getAllClients().then((res) => {
            if (res.length !== 0) {
                setShowNewClientForm(true)
            } else { }
        })
    }, [])

    return (
        <>
            {
                allClients.length !== 0 || showNewClientForm
                    ?
                    <Box>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                <Card className="new-team-list">
                                    <Grid container justify="space-between"
                                        style={{ borderBottom: `1px dashed ${CustomTheme.primaryColor}` }} spacing={0}
                                        className='table-title-style'>
                                        <Grid>
                                        </Grid>
                                        <Grid>
                                            <Box className="header-buttons-in-list">
                                                <Button size="small"
                                                    onClick={() => {
                                                        setSelectedRow(null);
                                                        setAddNewClient(true);
                                                        setRowIndex(null);
                                                    }}
                                                    variant="outlined"
                                                    color="primary"
                                                    className="blank-page-button-milestone margin-top-team-btn"><i
                                                        className="fas fa-user"></i> <span className="btn-margin-left"> + CLIENT</span>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <TableView
                                        setSelectedRow={setSelectedRow}
                                        selectedRow={selectedRow}
                                        setRowIndex={setRowIndex}
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                {
                                    selectedRow != null || addNewClient
                                        ?
                                        <Card className="new-team-detail-panel">
                                            <ClientAddEditForm
                                                setRowIndex={setRowIndex}
                                                rowIndex={rowIndex}
                                                addNewClient={addNewClient}
                                                setAddNewClient={setAddNewClient}
                                                setSelectedRow={setSelectedRow}
                                            />
                                        </Card>
                                        :
                                        <></>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <NoClientFound setShowNewClientForm={setShowNewClientForm} setAddNewClient={setAddNewClient} />
            }
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        allClients: state.configuration.clients
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllClients: () => {
            return dispatch(getAllClientsRequset()).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
