import {makeStyles} from '@material-ui/core';
import CustomTheme from '../../../../utilities/theme/theme.json'

export const useStyles = makeStyles(theme => ({
    mainContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    imageContainer: {
        height: '55px',
        width: '55px',
        border: '1px solid #fff',
        borderRadius: '100%',
        overflow: 'hidden',
    },
    image: {
        height: '100%',
        width: '100%',
        padding: '0%',
    },
    contentContainer: {
        marginLeft: '0.5rem',
        height: '55px',
    },
    heading: {
        color: CustomTheme.topMenuHeadingTitle,
        textTransform: 'uppercase',
        letterSpacing: '2px',
        // fontSize: '1.2rem',
        // fontWeight: '600',
    },
    bottomLabelContainer: {
        color: 'blue',
        display: 'flex',
        marginTop: '3px'
    },
    bottomLabel: {
        color: CustomTheme.leftTopMenuIconButton,
        marginRight: '0.5rem',
        '&:hover': {
            textDecoration: 'none',
            color: CustomTheme.hoverLinkColor,
            borderBottom: `3px solid ${CustomTheme.hoverLinkColor}`,
        },
    },
    forAddEditTemplateHighlight: {
        color: CustomTheme.hoverLinkColor,
        borderBottom: `3px solid ${CustomTheme.hoverLinkColor}`,
        '&:hover': {
            textDecoration: 'none',
            color: CustomTheme.hoverLinkColor,
            borderBottom: `3px solid ${CustomTheme.hoverLinkColor}`,
        },
    },
    active: {
        textDecoration: 'none',
        color: CustomTheme.activeLinkColor,
        borderBottom: `3px solid ${CustomTheme.activeLinkColor}`,
    },
}));
