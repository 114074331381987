import React from 'react';
import {NavLink} from 'react-router-dom';
import {Box, Typography} from '@material-ui/core';
import mkdmLogo from '../../../../assets/mkdm-logo.png';
import {connect} from 'react-redux';
import SlideToggle from 'react-slide-toggle';
import {ROUTER_ACTIONS} from '../../../../utilities/Constants'
import {useStyles} from './styles';

const ActionStationHeader = ({loggedInUser}) => {
    const classes = useStyles();
    return (
        <>
        <Box className={classes.outerContainer}>
            <Box className={classes.mainContainer}>
                <Box className={classes.imageContainer}>
                    <img src={mkdmLogo} alt='logo' className={classes.image}/>
                </Box>
                <Box className={classes.contentContainer}>
                    <Box> <Typography className={classes.heading} variant="h2">Action Station</Typography> </Box>
                    <Box className={classes.bottomLabelContainer}>
                        <NavLink
                            to={ROUTER_ACTIONS.ACTION_STATION_MY_TASKS}
                            className={classes.bottomLabel}
                            activeClassName={classes.active}
                        >
                            My Tasks
                        </NavLink>
                    </Box>
                </Box>
            </Box>

            <Box className={classes.actionsCotainer}>
                <Box className={classes.searchContainer}>
                    <input
                        type='text'
                        placeholder='Search...'
                        className={classes.searchInput}
                    />
                    <span className={classes.searchIcon}>
          <i className='fas fa-search'></i>
        </span>
                </Box>
                {
                    (loggedInUser&&loggedInUser.userRoles&&loggedInUser.userRoles.isSystemSuperAdmin) || (loggedInUser&&loggedInUser.userRoles&&loggedInUser.userRoles.isTeamLead)
                        ? (
                            <Box>
                                <SlideToggle
                                    collapsed
                                    duration={500}
                                    render={({toggle, setCollapsibleElement}) => (
                                        <Box className={classes.createMenuContainer}>
                                            <Box onClick={toggle}>
                    <span className={classes.icon}>
                      <i className='fas fa-plus-circle'></i>
                    </span>
                                            </Box>
                                            <Box
                                                className={classes.createMenu}
                                                ref={setCollapsibleElement}
                                            >
                                                <Box className={classes.createMenuLabel}>Create New</Box>
                                                <ul>
                                                    {/* <li>
                        <NavLink to='/' className={classes.createMenuItem}>
                          <span className={classes.createMenuItemIcon}>
                            <i className='far fa-list-alt'></i>
                          </span>
                          <span>Task</span>
                        </NavLink>
                        <Box
                          className={classes.createMenuItem}
                          onClick={() => {
                            setOpen(true);
                            toggle();
                          }}
                        >
                          <span className={classes.createMenuItemIcon}>
                            <i className='far fa-list-alt'></i>
                          </span>
                          <span>Task</span>
                        </Box>
                      </li> */}
                                                    <li>
                                                        <NavLink
                                                            to={ROUTER_ACTIONS.PROJECTS_ADD}
                                                            className={classes.createMenuItem}
                                                        >
                          <span className={classes.createMenuItemIcon}>
                            <i className='far fa-folder'></i>
                          </span>
                                                            <span>Project</span>
                                                        </NavLink>
                                                        {/* <Box
                          onClick={onProjectClick}
                          className={classes.createMenuItem}
                        >
                          <span className={classes.createMenuItemIcon}>
                            <i className='far fa-folder'></i>
                          </span>
                          <span>Project</span>
                        </Box> */}
                                                    </li>
                                                    {/* <li>
                        <NavLink
                          to={ROUTER_ACTIONS.ACTION_STATION}
                          className={classes.createMenuItem}
                        >
                          <span className={classes.createMenuItemIcon}>
                            <i className='far fa-hourglass'></i>
                          </span>
                          <span>Time Log</span>
                        </NavLink>
                      </li> */}

                                                    {/* <li>
                        <NavLink to='/' className={classes.createMenuItem}>
                          <span className={classes.createMenuItemIcon}>
                            <i className='far fa-file-alt'></i>
                          </span>
                          <span>Document</span>
                        </NavLink>
                      </li> */}

                                                    {/* <li>
                        <NavLink to='/' className={classes.createMenuItem}>
                          <span className={classes.createMenuItemIcon}>
                            <i className='far fa-user'></i>
                          </span>
                          <span>User</span>
                        </NavLink>
                      </li> */}

                                                    {/* <li>
                        <NavLink to='/' className={classes.createMenuItem}>
                          <span className={classes.createMenuItemIcon}>
                            <i className='fas fa-users'></i>
                          </span>
                          <span>Team</span>
                        </NavLink>
                      </li> */}
                                                </ul>
                                            </Box>
                                        </Box>
                                    )}
                                />
                            </Box>
                        )
                        : <></>
                }
            </Box>
        </Box>
    </>
    );
};
const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionStationHeader);