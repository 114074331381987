import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        titleContainer: {
            textAlign: 'center',
            marginBottom: '2rem',
        },
        titleLabel: {
            fontSize: '1.3rem',
            opacity: '0.7',
        },
        errorText: {
            color: 'red',
            marginLeft: '0.5rem',
        },
        cardsContainer: {
            width: '90%',
            margin: 'auto',
        },
        iconText: {
            fontSize: '0.8rem',
            marginTop: '1rem',
        },
        cardContainer: {
            border: '1px solid #eee',
            padding: '1rem',
            textAlign: 'center',
            '&:hover': {
                cursor: 'pointer',
                '& $iconContainer': {
                    border: '4px solid red',
                },
                '& $icon': {
                    color: 'red',
                },
            },
        },
        iconContainer: {
            border: '4px solid #cccccc',
            width: '100px',
            height: '100px',
            margin: 'auto',
            borderRadius: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'all 0.2s ease-in-out',
        },
        icon: {
            fontSize: '4rem',
            color: '#999999',
            transition: 'all 0.2s ease-in-out',
        },
        iconActive: {
            color: 'red',
        },
        iconContainerActive: {
            border: '4px solid red',
        },
        templateListContainer: {
            marginTop: '-1rem',
        },
    };
});
