import React from 'react'
import { Box, Card, Grid , Typography} from '@material-ui/core';
import { useStyles } from '../../styles';

const TrackCountCard = ({}) => {
    const classes = useStyles();
    return (
        <Card className={classes.trackTasksCountContainer}>
            <Box className={`${classes.teackDetailsContainer} ${classes.onTrackTasksColor}`} >
                <Typography variant="body1" className={classes.trackCountTitle}>Open Tasks</Typography>
                <Typography className={classes.strackCount} variant="h3">0</Typography>
            </Box>
            <Box  className={`${classes.teackDetailsContainer} ${classes.onLateTasksColor}`} >
                <Typography variant="body1" className={classes.trackCountTitle}>Overdue Tasks</Typography>
                <Typography className={classes.strackCount} variant="h3">0</Typography>
            </Box>
            <Box  className={`${classes.teackDetailsContainer} ${classes.onCompleteTasksColor}`}>
                <Typography variant="body1" className={classes.trackCountTitle}>Complete Tasks</Typography>
                <Typography className={classes.strackCount} variant="h3">0</Typography>
            </Box>
            {/* <Box className={`${classes.teackDetailsContainer} ${classes.onNextTasksColor}`}>
                <Typography variant="body1" className={classes.trackCountTitle}>Next Tasks</Typography>
                <Typography className={classes.strackCount} variant="h3">454</Typography>
            </Box> */}
        </Card>
    )
}
export default TrackCountCard