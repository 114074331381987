import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { TimelineContent, TimelineItem } from '@material-ui/lab';
import { getProgressTasksOfUsers } from '../../ProgressApiACtions'
const ProgressTasksActivityList = ({
    getProgressTasks, projectId, progressTask, filterParams
}) => {
    useEffect(() => {
        getProgressTasks(projectId, filterParams)
    }, [filterParams])
    const classes = useStyles();

    const stateNameHandler = (state) => {
        switch (state) {
            case "todo":
                return 'Not Started'
            case "in_progress":
                return "In Progress"
            case "stopped":
                return 'Stopped'
            case "blocked":
                return "Blocked"
            case "complete":
                return "Complete"
            case "deferred":
                return "Deferred"
            case "ready_for_review":
                return "Ready For Review"
            default:
                return ""
        }
    }

    const stateIconsHandler = (state) => {
        switch (state) {
            case "todo":
                return <i className="far fa-circle" />
            case "in_progress":
                return <i className="far fa-play-circle" />
            case "stopped":
                return <i className="far fa-pause-circle" />
            case "blocked":
                return <i className="fas fa-ban" />
            case "complete":
                return <i className="far fa-check-circle" />
            case "deferred":
                return <i className="fas fa-stop-circle" />
            case "ready_for_review":
                return <i className="fas fa-check-double" />
            default:
                return ""
        }
    }

    const backgrounColorHandler = (state) => {
        switch (state) {
            case "todo":
                return "#CCF1FF"
            case "in_progress":
                return "#CCF1FF"
            case "stopped":
                return "#ff640040"
            case "blocked":
                return "#CCF1FF"
            case "complete":
                return "#D2FDBB"
            case "deferred":
                return "#CCF1FF"
            case "ready_for_review":
                return "#CCF1FF"
            default:
                return ""
        }
    }

    return (
        <>
            {
                progressTask && progressTask.length > 0
                    ?
                    progressTask && progressTask.map((task, index) => {
                        console.log("index", index)
                        return (
                            <TimelineItem
                                classes={{
                                    missingOppositeContent: classes.oppositeContent,
                                }}
                            >
                                <TimelineContent classes={{ root: classes.contentContainer }}>
                                    <Box className={classes.contentInnerContainer} style={{ backgroundColor: backgrounColorHandler(task.userStatus) }}>
                                        <Box>
                                            <Box className={classes.headingContainer}>
                                                <Box className={classes.profileContainer}>
                                                    <Box>
                                                        <img
                                                            src={`https://ui-avatars.com/api/?name=${task.userFirstName.charAt(0)}&background=random`}
                                                            alt='profile'
                                                            className={classes.profileImage}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="body1" classes={{ root: classes.nameText }}>
                                                            <span>{`${task.userFirstName} ${task.userLastName.charAt(0)}.`}</span>
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {/* <Typography variant="body2" className={classes.userTaskUpdateDate}>
                                        {task.userTimeAndADate}
                                    </Typography> */}
                                        </Box>
                                    </Box>
                                    <Box className={classes.stateContainer} style={{ backgroundColor: backgrounColorHandler(task.userStatus) }}>
                                        <Box className={classes.stateIconContainer}>
                                            <span className={classes.stateIcon}>{stateIconsHandler(task.userStatus)}</span>
                                        </Box>
                                        <Box className={classes.stateTitleContainer} >
                                            <Typography variant="body1" className={classes.stateTitle}>{stateNameHandler(task.userStatus)}</Typography>
                                            <Typography variant="body2" className={classes.stateDueDate}>Due : {task.dueDate ? task.dueDate : '-'}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.tasksDetailsContainer} style={{ backgroundColor: backgrounColorHandler(task.userStatus) }}>
                                        <Box className={classes.taskTitleHeader} >
                                            {/* <Typography variant="body1" className={classes.taskhaederTitle}><i className="far fa-sticky-note"></i> Last Activity</Typography> */}
                                            <Typography variant="body1" className={classes.taskhaederTitle}><i className="fas fa-tasks" /> Task</Typography>
                                            <Typography variant="body1" className={`${classes.taskhaederTitle} ${classes.taskhaederTitleForTasklist}`}><i className="fas fa-list" /> Tasklist</Typography>
                                        </Box>
                                        <Box className={classes.tasksTitle} >
                                            {/* <Typography variant="body1" className={classes.tasksDetailsText}>{task.lastActivity}</Typography> */}
                                            <Typography variant="body1" className={classes.tasksDetailsText}>{task.taskName}</Typography>
                                            <Typography variant="body1" className={`${classes.tasksDetailsText} ${classes.tasksDetailsTextForTasklist}`}>{task.tasklistName}</Typography>
                                        </Box>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        )
                    })
                    :
                    <Typography variant="body1" className={`${classes.tasksDetailsText}`}>No tasks found</Typography>
            }
        </>
    )
}
const mapStateToProps = (state) => {
    let loggedInUserTasks = state.progress.progressTasks && state.progress.progressTasks.filter((task)=>task.userId===state.auth.loggedInUser.id)
    return {
        progressTask: state.auth.loggedInUser.userRoles.isProjectMember ? loggedInUserTasks :  state.progress.progressTasks,
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProgressTasks: (projectId, filterParams) => {
            return dispatch(getProgressTasksOfUsers(projectId, filterParams)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProgressTasksActivityList);