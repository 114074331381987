import React, { useEffect, useRef } from 'react'
import CustomTheme from '../../../../../../../../../../utilities/theme/theme.json'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
const ImportTasklistMenu = ({
    setShowedImportMemu, openMenu, closeMenu, anchorRef, showedImportMemu, openImportDialog
}) => {
    const prevOpen = useRef(openMenu);

    useEffect(() => {
        if (prevOpen.current === true && openMenu === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = openMenu;
    }, [openMenu]);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setShowedImportMemu(!showedImportMemu)
        closeMenu(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            closeMenu(false);
        }
    }
    return (
        <Popper
            open={openMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            className="popper-style"
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === "bottom" ? "center top" : "center bottom"
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                autoFocusItem={openMenu}
                                id="menu-list-grow"
                                onKeyDown={handleListKeyDown}
                            >
                                <MenuItem style={{ color: CustomTheme.primaryColor }} variant="body2"
                                    className="import-menuItem-styling"
                                    onClick={() => openImportDialog(true)}><span
                                        className="style-for-table-header-icons "><i
                                            className="far fa-list-alt" /></span> <span
                                                className="btn-margin-left table-header-icon-title"> Import Tasklist</span></MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}
export default ImportTasklistMenu