import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        appBar: {
            zIndex: theme.zIndex.drawer - 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            borderBottom: '1px solid #e0e0e0',
            boxShadow: '0 2px 6px 0 rgb(32 33 36 / 28%)',
        },
        menuButton: {
            borderRadius: '0%',
            height: '64px',
            fontSize: '28px',
            paddingRight: '0px',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        hamburgerButton: {
            width: '73px',
            position: 'relative',
            fontSize: '28px',
        },
        hamburgerButtonInner: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            cursor: 'pointer',
            width: '40px',
            height: '20px',
            '& > div': {
                width: '30px',
                height: '2px',
                background: '#90959e',
                position: 'absolute',
                top: '0',
                left: '0',
            },
        },
        hamburgerTwo: {
            top: '10px!important',
            width: '40px!important',
        },
        hamburgerThree: {
            top: '20px!important',
        },
        headerChildrenContainer: {
            width: '100%',
        },
        appToggleImageContainer: {
            height: '50px',
            position: 'absolute',
            right: '5px',
            top: '10%',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        appToggleImage: {
            height: '100%',
            padding: '0.5rem',
        },
        appPanel: {
            width: '60px',
            height: '100%',
            borderLeft: '1px solid #e0e0e0',
            borderBottom: '1px solid #e0e0e0',
            boxShadow: '0 4px 4px 0 rgb(32 33 36 / 28%)',
            background: '#fff',
            position: 'fixed',
            right: '0',
            top: '65px',
            zIndex: '50',
            paddingBottom: '10px',
        },
        icon: {
            marginRight: '25px',
            fontSize: '22px',
        },
        panelItem: {
            color: '#02abee',
            fontSize: '15px',
            padding: '20px 20px',
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1px',
            transition: 'all 0.3s ease',
            '&:hover': {
                color: '#ffb85d',
            },
        },
    };
});
