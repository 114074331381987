/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import HttpRequest from '../../utilities/HttpRequestAuth';
import Auth from '../../utilities/Auth';
import UtilHelper from '../../utilities/UtilHelper';
import {showSnackbar} from '../../utilities/AppLoader/AppLoaderActions'


import {
    signIn,
    getAllUsers, 
    getSingleUser
} from "./AuthenticationActions";

import {API_ACTIONS, SNACKBAR_VARIAINT} from '../../utilities/Constants';


/**
 * Request the server to validate user credentials and get the access token
 * @param credentials
 * @returns {function(*)}
 */
export function signInRequest(credential) {
    return (dispatch) => {
        return new HttpRequest(dispatch, API_ACTIONS.LOGIN, 'post', credential)
            .send()
            .then(res => {
                let {user, access_token} = res.data.data;
                // Store access token(JWT) and user details inside cookies
                const userRoles = {
                    isSystemSuperAdmin: UtilHelper.checkRoleAvalibility(user.roles, "Organisation super admin"),
                    isProjectMember: UtilHelper.checkRoleAvalibility(user.roles, "Project Member"),
                    isTeamLead: UtilHelper.checkRoleAvalibility(user.roles, "Team Lead"),
                    // isLimitAccessUser: UtilHelper.checkRoleAvalibility(user.roles, "Limited Access Users"),
                }
                user.userRoles = userRoles;
                Auth.setToken(access_token);
                Auth.login(user);
                dispatch(signIn(res.data.data));
                return Promise.resolve();
            }, error => {
                return Promise.reject(error);
            });
    };
}

/**
 * Request the server to get all auth users
 * @returns {function(*)}
 */
export function getAllAuthUsersRequest(isLoaderShowing) {
    return (dispatch) => {
        return new HttpRequest(dispatch, API_ACTIONS.GET_AUTH_ALL_USERS, 'get')
            .send(null, isLoaderShowing)
            .then(res => {
                dispatch(getAllUsers(res.data))
                return Promise.resolve(res);
            }, error => {
                return Promise.reject(error);
            });
    };
}

/**
 * Request the server to reset password
 * @returns {function(*)}
 */
export function resetPasswordRequest(data) {
    return (dispatch) => {
        return new HttpRequest(dispatch, API_ACTIONS.RESET_PASSWORD, 'post', data)
            .send(null, true)
            .then(res => {
                dispatch(showSnackbar("Reset password successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res);
            }, error => {
                return Promise.reject(error);
            });
    };
}

/**
 * Request the server to get single auth user details
 * @returns {function(*)}
 */
export function getSingleAuthUserDetailsRequest(id) {
    return (dispatch) => {
        return new HttpRequest(dispatch, `${API_ACTIONS.GET_SINGLE_AUTH_USER_DETAILS}/${id}`, 'get')
            .send(null, true)
            .then(res => {
                dispatch(getSingleUser(res.data))
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
}

/**
 * Request the server to add user to auth
 * @returns {function(*)}
 */
export function addAuthUserRequest(data) {
    return (dispatch) => {
        return new HttpRequest(dispatch, `${API_ACTIONS.GET_SINGLE_AUTH_USER_DETAILS}`, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("User added successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
}

/**
 * Request the server to edit user to auth
 * @returns {function(*)}
 */
export function editAuthUserRequest(id, data) {
    return (dispatch) => {
        return new HttpRequest(dispatch, `${API_ACTIONS.EDIT_USER_DETAILS}/${id}`, 'patch', data)
            .send()
            .then(res => {
                dispatch(showSnackbar("User edited successfully", SNACKBAR_VARIAINT.SUCCESS));
                return Promise.resolve(res.data);
            }, error => {
                return Promise.reject(error);
            });
    };
}
