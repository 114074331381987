import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import './Tasks.css'
import { Box, Card, Grid } from '@material-ui/core';
import AppHeader from '../../../../../../userDashboard/components/AppHeader/AppHeader';
import ProjectHeader from '../ProjectHeader/ProjectHeader';
import TaskList from './components/TaskList/TaskList';
import TaskDetails from './components/TaskDetails/TaskDetails';
import TaskListDetails from './components/TaskListDetails/TaskListDetails';
import TasklistForm from './components/AddEditForms/TasklistForm/TasklistForm';
import TaskForm from './components/AddEditForms/TaskForm/TaskForm';
import CustomTheme from '../../../../../../../utilities/theme/theme.json'
import MessageModal from '../../../../../../../utilities/MessageModal/MessageModal';

const Tasks = ({
    handleDrawerOpenClose,
    history,
    match,
    taskList,
}) => {
    const modalRef = useRef();
    const projectId = match.params.id;
    const [taskId, setTaskId] = useState(null);
    const [rightPane, setRightPane] = useState(null);
    // formpanel
    const [isDisplayForm, setIsDisplayForm] = useState(false);
    const [taskListId, setTaskListId] = useState(null);

    // Formpanel states End //

    const [addUpdateRes, setAddUpdateRes] = useState({
        res: [],
        formName: ''
    });


    // for showing add and edit form for tasklist and task
    const showAddUpdateForm = (id, rightPaneName, taskListId = null) => {
        setRightPane(rightPaneName);
        setIsDisplayForm(true);
        if (id) {
            if (rightPaneName === "taskList") {
                setTaskListId(id);
            }
            else if (rightPaneName === "task") {
                setTaskId(id);
            } else { }
        } else {
            setTaskId(null);
            setTaskListId(null);
        }
        if(rightPaneName === "task")
        {
            setTaskListId(taskListId);
        } else {}
    }

    // whenever submit add/edit task/tasklist then closing that form
    const closeAddUpdateFrom = () => {
        setTaskId(null);
        setRightPane(null);
        setIsDisplayForm(false);
        setTaskListId(null);
    }

    // for showing task/tasklist deatisl
    const showTaskDetailsPane = (id, rightPaneName) => {
        setIsDisplayForm(false);
        if(rightPaneName === "taskList")
        {
            setTaskListId(id);
        }
        else {
            setTaskId(id);
        }
        setRightPane(rightPaneName);
    }

    const renderDetailsOfTasklistAndTask = (rightPane) => {
        switch(rightPane) {
            case "taskList" :
                return (<TaskListDetails
                            setRightPane={setRightPane}
                            match={match}
                            showAddUpdateForm={showAddUpdateForm}
                            tasklistId={taskListId}
                        />)
            case "task" :
                return (<TaskDetails
                            setRightPane={setRightPane}
                            match={match}
                            showAddUpdateForm={showAddUpdateForm}
                            taskId={taskId}
                        />)
            default:
                return <></>
        }
    }

    const formDisplayHandler = (rightPane) => {
        switch(rightPane) {
            case "taskList" :
                return (<TasklistForm
                            taskListId={taskListId}
                            projectId={projectId}
                            closeAddUpdateFrom={closeAddUpdateFrom}
                            showTaskDetailsPane={showTaskDetailsPane}
                            setAddUpdateRes={setAddUpdateRes}
                            showAddUpdateForm={showAddUpdateForm}
                            setIsDisplayForm={setIsDisplayForm}
                        />)
            case "task" :
                return (<TaskForm
                            taskId={taskId}
                            projectId={projectId}
                            taskListId={taskListId}
                            showAddUpdateForm={showAddUpdateForm}
                            setIsDisplayForm={setIsDisplayForm}
                            closeAddUpdateFrom={closeAddUpdateFrom}
                            showTaskDetailsPane={showTaskDetailsPane}
                            setAddUpdateRes={setAddUpdateRes}
                        />)
            default:
                return <></>
        }
    }

    return (
        <>
            <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                <ProjectHeader history={history} match={match} />
            </AppHeader>

            <Box>
                <Grid container>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className="table-view-tasklist-container">
                        {
                            taskList && taskList.status === "draft"
                                ?
                                <Card style={{ color: CustomTheme.fontColor }} className="draft-status-container">
                                    <Box className="draft-message-span">Project creation is in progress. Please come
                                        back in a few minutes.</Box>
                                </Card>
                                :
                                <TaskList
                                    history={history}
                                    showTaskDetailsPane={showTaskDetailsPane}
                                    showAddUpdateForm={showAddUpdateForm}
                                    closeAddUpdateFrom={closeAddUpdateFrom}
                                    addUpdateRes={addUpdateRes}
                                    setAddUpdateRes={setAddUpdateRes}
                                />
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        {
                            isDisplayForm
                                ?
                                <Card className="add-update-form-onclick for-card-shadow for-padding-bottom-10 ">
                                    {rightPane!=="" && formDisplayHandler(rightPane)}
                                </Card>
                                :
                                (
                                    rightPane === ""
                                        ?
                                        <>
                                        </>
                                        :
                                        <>
                                            {(taskId || taskListId) && rightPane!="" && renderDetailsOfTasklistAndTask(rightPane)}
                                        </>
                                )

                        }

                    </Grid>
                </Grid>
            </Box>
            <MessageModal
                ref={modalRef} />
        </>
    );
};

const mapStateToProps = (state) => {

    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tasks);
