import React, {useEffect, useState} from 'react';
import {Timeline} from '@material-ui/lab';
import {Box} from '@material-ui/core';
import EffortForm from './components/EffortForm/EffortForm';
import EffortItem from './components/EffortItem/EffortItem';
import {useStyles} from './styles';
import {connect} from 'react-redux';
import {getAllTimeLogRequest} from '../../../../TasksApiActions';

/**
 * TODO: Refactor the code for EffortForm, lets discuss.
 */
const TimeLine = ({taskId, getAllTimelogs}) => {
    const classes = useStyles();
    const [allTimelogs, setAllTimeLogs] = useState(null);
    const [isEditForm, setIsEditForm] = useState(false);

    const [timeLogDetails, setTimeLogDetails] = useState(null);

    const getAllTimelogList = () => {
        getAllTimelogs(taskId).then((res) => {
            setAllTimeLogs(res)
        })
    }
    useEffect(() => {
        getAllTimelogList()
    }, [])

    const onEditTimeLog = (data) => {
        setTimeLogDetails(data);
        setIsEditForm(true);
    }

    const doneEditTimeLog = () => {
        setTimeLogDetails(null);
        setIsEditForm(false);
        getAllTimelogList();
    }

    return (
        <>
            <Box className={classes.timelineActionContainer}>
                <EffortForm
                    taskId={taskId}
                    isEditForm={isEditForm}
                    timeLogDetails={timeLogDetails}
                    getAllTimelogList={getAllTimelogList}
                    doneEditTimeLog={doneEditTimeLog}
                />
            </Box>

            {
                isEditForm
                    ?
                    <></>
                    :
                    <Timeline align='left' classes={{root: classes.timelineRoot}}>
                        {
                            /**
                             * TODO: Pass the key attribute for map function, check it for throughout the system
                             */
                            allTimelogs && allTimelogs.map((timelog, i) => {
                                return (
                                    <EffortItem
                                        key={i}
                                        timelog={timelog}
                                        onEditTimeLog={onEditTimeLog}
                                        getAllTimelogList={getAllTimelogList}
                                    />
                                )
                            })
                        }


                        {/**
                         * TODO: Clean the commented throughout the system.
                         */}
                        {/* <EffortItem
            ProfileUrl='https://ui-avatars.com/api/?name=J&background=random'
            profileName='Jeetal'
            time='8:32 PM, Yesterday'
            logIcon='far fa-play-circle'
            hours='1.5 HRS'
            logDate='March 24, 2021'
            logStart='10:00 AM'
            logEnd='11:30 AM'
            logDesc='Meeting with team to discuss task'
            isGrey={false}
          />

          <EffortItem
            ProfileUrl='https://ui-avatars.com/api/?name=J&background=random'
            profileName='Jeetal'
            time='8:32 PM, Yesterday'
            logIcon='far fa-play-circle'
            hours='30 MIN'
            logDate='March 22, 2021'
            logStart='3:30 PM'
            logEnd='4:00 PM'
            logDesc='Created document of initial requirements'
            isGrey={true}
          />

          <EffortItem
            ProfileUrl='https://ui-avatars.com/api/?name=J&background=random'
            profileName='Jeetal'
            time='12:13 PM, March 21, 2021'
            logIcon='far fa-play-circle'
            hours='45 MIN'
            logDate='March 22, 2021'
            logStart='3:30 PM'
            logEnd='4:00 PM'
            logDesc='Initial requirements obtained from client'
            isGrey={false}
          /> */}
                    </Timeline>

            }

        </>
    );
};
const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllTimelogs: (taskId) => {
            return dispatch(getAllTimeLogRequest(taskId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeLine);
