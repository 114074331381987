import React, {useEffect, useState} from 'react';
// import { Timeline } from '@material-ui/lab';
import {Box} from '@material-ui/core';
import TimeLineActions from './components/TimeLineActions/TimeLineActions';
// import TimeLineContentItem from './components/TimeLineContentItem/TimeLineContentItem';
import {useStyles} from './styles';
import {connect} from 'react-redux';

const TimeLine = ({taskDetails, updateTaskFromReadView, loggedInUser}) => {
    const classes = useStyles();
    const [isChangeStateLoaderShowing, setIsChangeStateLoaderShowing] = useState(false)
    useEffect(() => {
        changeStateLoaderHandler();
    }, [taskDetails])
    const assigneeStatusHandler = (clickedStatus) => {
        setIsChangeStateLoaderShowing(true)
        let formData = {
            assigneeStatus: {
                userId: loggedInUser.id,
                status: clickedStatus
            }
        }
        updateTaskFromReadView(formData, "Assignee status updated successfully")
    }
    let statusName;
    if (taskDetails.assigneeStatus && taskDetails.assigneeStatus.hasOwnProperty(`${loggedInUser.id}`)) {
        statusName = taskDetails.assigneeStatus[loggedInUser.id];
    }
    const changeStateLoaderHandler = () => {
        setIsChangeStateLoaderShowing(false)
    }
    return (
        <>
            {
                taskDetails && taskDetails.assigneeStatus && taskDetails.assigneeStatus.hasOwnProperty(`${loggedInUser.id}`)
                    ?
                    <Box className={classes.timelineActionContainer}>
                        <TimeLineActions taskDetails={taskDetails}
                                         isChangeStateLoaderShowing={isChangeStateLoaderShowing} statusName={statusName}
                                         assigneeStatusHandler={assigneeStatusHandler}/>
                    </Box>
                    :
                    <></>
            }


            {/* <Timeline align='left' classes={{ root: classes.timelineRoot }}>
        <TimeLineContentItem
          ProfileUrl='https://ui-avatars.com/api/?name=A&background=random'
          profileName='Abhinandan'
          taskTime='5:16 PM, Today'
          taskLogo='far fa-play-circle'
          taskStatus='Task Resumed'
          taskDesc=''
        />

        <TimeLineContentItem
          ProfileUrl='https://ui-avatars.com/api/?name=A&background=random'
          profileName='Abhinandan'
          taskTime='5:16 PM, Today'
          taskLogo='far fa-stop-circle'
          taskStatus='Task Blocked'
          taskDesc="Blocked due to dependence on 'Create design layout'"
        />

        <TimeLineContentItem
          ProfileUrl='https://ui-avatars.com/api/?name=A&background=random'
          profileName='Abhinandan'
          taskTime='5:16 PM, Today'
          taskLogo='far fa-play-circle'
          taskStatus='Task Started'
          taskDesc=''
        />
      </Timeline> */}
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TimeLine);
