import React, {useState} from 'react';
import AppHeader from '../../../userDashboard/components/AppHeader/AppHeader';
import ConfigurationHeader from '../ConfigurationHeader/ConfigurationHeader';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Check from '@material-ui/icons/Check';
import './Organization.css';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import CommunicationDetails from './components/CommunicationDetails/CommunicationDetails';
import HolidayMeta from './components/HolidayMeta/HolidayMeta';
import StepThree from './components/StepThree/StepThree';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    cardRoot: {
        minWidth: 275,
        borderRadius: '6px !important'
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));


const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
});

const Organization = ({handleDrawerOpenClose, history}) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function getSteps() {
        return ['', '', ''];
    }

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <CommunicationDetails history={history}/>;
            case 1:
                return <HolidayMeta history={history}/>;
            case 2:
                return <StepThree history={history}/>;
            default:
                return 'Unknown stepIndex';
        }
    }

    function QontoStepIcon(props) {
        const classes = useQontoStepIconStyles();
        const {active, completed} = props;

        // for custom icon showing
        // const icons = {
        //   1: <SettingsIcon />,
        //   2: <GroupAddIcon />,
        //   3: <VideoLabelIcon />,
        // };
        // icons[String(props.icon)] put in false condi.
        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                })}
            >
                {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
            </div>
        );
    }

    return (
        <>
            <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                <ConfigurationHeader history={history}/>
            </AppHeader>
            <div>

            </div>
            <Box className="stepper-container" component="div">
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>

            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Card className={classes.cardRoot}>
                            <CardContent>
                                <Typography className={classes.instructions}>All steps completed</Typography>
                                <Box className="reset-btn" component="div">
                                    <Button onClick={handleReset}>Reset</Button>
                                </Box>
                            </CardContent>
                        </Card>

                    </div>
                ) : (
                    <div>
                        <Card className={classes.cardRoot}>
                            <CardContent>
                                {getStepContent(activeStep)}
                                <div className="back-next-btn">
                                    <Button
                                        style={{marginLeft: "50px"}}
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.backButton}
                                    >
                                        Back
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </div>
                            </CardContent>
                        </Card>
                        {/* <Typography className={classes.instructions}></Typography> */}

                    </div>
                )}
            </div>
        </>
    );
};

export default Organization;
