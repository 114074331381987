import {
    GET_ALL_PROGRESS_TASKS
} from './ProgressActions';

const initialState = {
    progressTasks: null,
};

/**
 * ProgressReducer reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */

const ProgressReducer = (state = initialState, action) => {

    switch (action.type) {

        //Get list of progress tasks to be shown
        case GET_ALL_PROGRESS_TASKS:
            return Object.assign({}, state, {
                progressTasks: action.tasks
            });
        
        default:
            return state;

    }
};

export default ProgressReducer;
