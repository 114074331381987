import React, {useState} from 'react'
import {connect} from 'react-redux';
import CustomTheme from '../../../../../../../../../../../utilities/theme/theme.json';

import {Box, FormControl, Grid, Typography} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';

const EstimateSection = React.memo(({
                                        handleChangeAutocomplete, formData
                                    }) => {
    const [roleIndexForEffortInput, setRoleIndexForEffortInput] = useState(null)

    return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="form-panel-assign-name-container">
            <Grid>
                <Typography variant="body1" style={{color: CustomTheme.fontColor}}
                            className="form-panel-dependent-task-text">
                    <i className="far fa-hourglass" style={{paddingRight: '3px'}}></i>
                    Estimated Effort
                </Typography>
            </Grid>
            <Grid>
                <Box className="details-header">
                    <Box className="detail-header-content-for-functional-role">
                        {
                            formData && formData.functionalRole &&formData.functionalRole.map((selectedRole, index) => (
                                <div key={index} className="inline-flex box-padding">
                                    {
                                        roleIndexForEffortInput === index
                                            ? (
                                                <FormControl
                                                    className="dropdown-margins-task-form inline-flex effort-form-control">
                                                    <ValidatorForm>
                                                        <TextValidator
                                                            fullWidth
                                                            className="margin-top-minus-16"
                                                            autoFocus={true}
                                                            size="small"
                                                            forNameAttr="functionalRole"
                                                            label={selectedRole.name}
                                                            onChange={(e) => {
                                                                let data = Object.assign([], formData.functionalRole);
                                                                const findIndex = data.findIndex((role) => role.functionalRoleId === selectedRole.functionalRoleId)
                                                                if (findIndex > -1) {
                                                                    data[findIndex].timeEffort = e.target.value;
                                                                }
                                                                // setSelectedFunctionalRole(data);
                                                                handleChangeAutocomplete("functionalRole", data)
                                                            }}
                                                            name="timeEffort" value={selectedRole.timeEffort || ''}
                                                            validators={['isNumber']}
                                                            errorMessages={['Must be integer']}
                                                        />
                                                    </ValidatorForm>
                                                </FormControl>
                                            )
                                            : (
                                                <Box
                                                    className="inline-flex box-padding"
                                                    onClick={() => setRoleIndexForEffortInput(index)}
                                                >
                                                    <Box>
                                                        <img
                                                            src={`https://ui-avatars.com/api/?name=${selectedRole.name}&background=random`}
                                                            alt='profile'
                                                            className="task-assignee-image"
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Typography style={{color: CustomTheme.fontColor}}
                                                                    variant='body2'>{selectedRole.name}</Typography>
                                                        <Typography style={{color: CustomTheme.primaryColor}}
                                                                    variant='body2' className='user-name'>
                                                            {
                                                                selectedRole.timeEffort || (
                                                                    <span style={{color: CustomTheme.fontColor}}>
                                                                        <i className="fas fa-plus-circle"></i> Add
                                                                    </span>
                                                                )
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )
                                    }
                                </div>
                            ))
                        }
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )

});

/**
 * Used for selecting the part of the data from the redux store that the connected component needs and pass it as a props
 * It is called every time when update the state of redux store
 * @param state
 */
const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

/**
 * Used to specify the which actions your component might need to dispatch and pass it to connected component as a props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {};
};

/**
 * Used to connects a react component to a redux store
 */
export default connect(mapStateToProps, mapDispatchToProps)(EstimateSection);
