import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useStyles } from './styles';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getAllAuthUsersRequest } from '../../../authentication/AuthenticationApiActions'

const FilterProgressActivity = ({setFilterParams, filterParams, allUsers, getAllUsers}) => {
    const classes = useStyles();
    useEffect(()=>{
        getAllUsers(true)
    },[])
    return (
        <>
            <Box>
                <Typography variant="body1" className={classes.trackCountTitle}>Filter progress activity by</Typography>
            </Box>
            <Box className={classes.filterBorderContainer}>
                <Autocomplete
                    fullWidth
                    className={classes.forPaddingLeft}
                    value={filterParams.user}
                    onChange={(e, value) => {
                        setFilterParams({
                            ...filterParams,
                            user: value,
                        })
                    }}
                    options={allUsers || []}
                    getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                    getOptionSelected={(option, value) =>
                        option.id === value.id
                    }
                    renderInput={params => (
                        <TextField placeholder="Users" {...params} />
                    )}
                />
            </Box>
            {/* <Box className={classes.filterBorderContainer}>
                <Autocomplete
                    fullWidth
                    className={classes.forPaddingLeft}
                    value={filterParams.user}
                    onChange={(e, value) => {
                        setFilterParams({
                            ...filterParams,
                            user: value,
                        })
                    }}
                    options={tempObject || []}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) =>
                        option.id === value.id
                    }
                    renderInput={params => (
                        <TextField placeholder="Event" {...params} />
                    )}
                />
            </Box> */}
            {/* <Box className={classes.filterBorderContainer}>
                <Autocomplete
                    fullWidth
                    className={classes.forPaddingLeft}
                    value={filterParams.user}
                    onChange={(e, value) => {
                        setFilterParams({
                            ...filterParams,
                            user: value,
                        })
                    }}
                    options={tempObject || []}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) =>
                        option.id === value.id
                    }
                    renderInput={params => (
                        <TextField placeholder="Task" {...params} />
                    )}
                />
            </Box>
            <Box className={classes.filterBorderContainer}>
                <Autocomplete
                    fullWidth
                    className={classes.forPaddingLeft}
                    value={filterParams.user}
                    onChange={(e, value) => {
                        setFilterParams({
                            ...filterParams,
                            user: value,
                        })
                    }}
                    options={tempObject || []}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) =>
                        option.id === value.id
                    }
                    renderInput={params => (
                        <TextField placeholder="State" {...params} />
                    )}
                />
            </Box> */}
        </>
    )
}
const mapStateToProps = (state) => {

    return {
        allUsers: state.auth.users
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllUsers: (isLoaderShowing) => {
            return dispatch(getAllAuthUsersRequest(isLoaderShowing)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterProgressActivity);