import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {getAllProjectsRequest, getProjectOfUserRequest} from '../../ProjectsApiActions'

import AppHeader from '../../../userDashboard/components/AppHeader/AppHeader';
import ProjectHeader from '../ProjectHeader/ProjectHeader';
import TeamItem from './components/TeamItem/TeamItem';

const Teams = React.memo(({loggedInUser, handleDrawerOpenClose, history, getAllProjects, teams, getProjectsOfUser}) => {


    const getAllProjectList = () => {
        getAllProjects(true);
    }

    const getAllProjectsOfUser = () => {
        getProjectsOfUser(true).then((res) => {

        })
    }

    useEffect(() => {
        getAllProjectList();
        getAllProjectsOfUser();
        document.title = "Projects - Artifex"
    }, []);

    return (
        <>
            <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                <ProjectHeader history={history}/>
            </AppHeader>
            {
                teams && teams.length > 0 && teams.map((team) => (
                    <TeamItem
                        key={team.name}
                        history={history}
                        teamDetails={team}
                    />
                ))
            }
        </>
    );
});

const mapStateToProps = (state) => {
    let teamDetails = state.projects.projects && state.projects.projects.map((teamInfo) => {
        return {
            ...teamInfo, projects: teamInfo.projects.filter((projectInfo) => {
                return state.projects.projectsOfTeamLeadAndProjectMember && state.projects.projectsOfTeamLeadAndProjectMember.find((id) => {
                    return projectInfo.id === id
                })
            })
        }
    })
    return {
        teams: state.auth.loggedInUser.userRoles.isSystemSuperAdmin ? state.projects.projects : teamDetails,
        loggedInUser: state.auth.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllProjects: (isLoaderShow) => {
            return dispatch(getAllProjectsRequest(isLoaderShow)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
        getProjectsOfUser: (isLoaderShow) => {
            return dispatch(getProjectOfUserRequest(isLoaderShow)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Teams);
