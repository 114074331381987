import {makeStyles} from '@material-ui/core';
import CustomTheme from '../../../../../../utilities/theme/theme.json'

export const useStyles = makeStyles(theme => {
    return {
        cardContainer: {
            position: 'relative',
            perspective: '1000px',
            // width: '440px',
            width: '100%',
            height: '135px',
            borderRadius: '5px',
            transformOrigin: 'bottom',
            // margin: '20px',
            cursor: 'pointer',
            transition: '0.9s',
        },
        cardContainerClicked: {
            height: '480px',
            transition: '0.9s',
        },
        firstDisplay: {
            // width: '100%',
            width: '100%',
            height: '135px',
            position: 'absolute',
            background: '#fff',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '18px 23px',
            flexWrap: 'wrap',
            backfaceVisibility: 'hidden',
            boxShadow: '0px 0px 25px -1px rgba(0, 0, 0, 0.17)',
        },
        first: {
            width: '100%',
            // width: '440px',
            height: '135px',
            position: 'absolute',
            color: '#000',
            transformOrigin: 'bottom',
            transformStyle: 'preserve-3d',
            transition: '0.5s',
            borderRadius: '8px',
            display: 'flex',
            transform: 'rotate3d(1, 0, 0, 0deg)',
            transitionDelay: '0.3s',
        },
        firstClicked: {
            transform: 'rotate3d(1, 0, 0, -180deg)',
            transitionDelay: '0s',
        },
        firstTop: {
            width: '100%',
            // width: '440px',
            height: '135px',
            position: 'absolute',
            background: '#ffffff',
            backfaceVisibility: 'hidden',
            borderRadius: '8px',
            boxShadow: '0px 0px 3px 0px rgba(132, 132, 132, 0.15)',
            // display: 'flex',
            // justifyContent: 'space-around',
            // alignItems: 'center',
        },
        firstBehind: {
            width: '100%',
            // width: '440px',
            height: '135px',
            position: 'absolute',
            background: '#fff',
            transformOrigin: 'center',
            transform: 'rotate3d(1, 0, 0, -180deg)',
            backfaceVisibility: 'hidden',
            borderRadius: '8px',
            border: '1px dashed #d1d1d1',
            borderLeft: 'none',
            borderRight: 'none',
        },
        firstBehindDisplay: {
            width: '100%',
            // width: '100%',
            height: '135px',
            position: 'absolute',
            background: '#fff',
            borderRadius: '8px',
            padding: '12px 23px',
            display: 'flex',
            justifyContent: 'space-between',
            boxShadow: '0px 11px 25px -1px rgba(0, 0, 0, 0.17)',
        },
        second: {
            width: '100%',
            // width: '440px',
            height: '50px',
            position: 'absolute',
            bottom: '-2px',
            transformOrigin: 'bottom',
            transformStyle: 'preserve-3d',
            transition: '0.2s',
            borderRadius: '8px',
            transform: 'rotate3d(1, 0, 0, 0deg)',
            transitionDelay: '0.2s',
        },
        secondClicked: {
            transform: 'rotate3d(1, 0, 0, -180deg)',
            transitionDelay: '0.2s',
        },
        secondTop: {
            width: '100%',
            // width: '440px',
            height: '50px',
            position: 'absolute',
            background: 'rgb(231, 231, 231)',
            backfaceVisibility: 'hidden',
            borderRadius: '8px',
        },
        secondBehind: {
            width: '100%',
            // width: '440px',
            height: '50px',
            position: 'absolute',
            background: '#fff',
            transformOrigin: 'center',
            transform: 'rotate3d(1, 0, 0, -180deg)',
            backfaceVisibility: 'hidden',
            borderRadius: '8px',
            border: '1px dashed #d1d1d1',
            borderLeft: 'none',
            borderRight: 'none',
        },
        secondBehindDisplay: {
            width: '100%',
            // width: '100%',
            height: '50px',
            position: 'absolute',
            background: '#fff',
            borderRadius: '8px',
            borderBottom: '1px dashed #d1d1d1',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 23px',
            boxShadow: '0px 11px 25px -1px rgba(0, 0, 0, 0.17)',
        },
        third: {
            width: '100%',
            // width: '440px',
            height: '50px',
            position: 'absolute',
            transformOrigin: 'bottom',
            transformStyle: 'preserve-3d',
            transition: '0.2s',
            borderRadius: '8px',
            transform: 'rotate3d(1, 0, 0, 0deg)',
            transitionDelay: '0s',
        },
        thirdClicked: {
            // width: '440px',
            height: '50px',
            position: 'absolute',
            transformOrigin: 'bottom',
            transformStyle: 'preserve-3d',
            transition: '0.2s',
            borderRadius: '8px',
            transform: 'rotate3d(1, 0, 0, -180deg)',
            transitionDelay: '0.4s',
        },
        thirdTop: {
            width: '100%',
            // width: '440px',
            height: '50px',
            position: 'absolute',
            background: 'rgb(190, 190, 190)',
            backfaceVisibility: 'hidden',
            borderRadius: '8px',
        },
        secondBehindBottom: {
            width: '100%',
            // width: '440px',
            height: '50px',
            position: 'absolute',
            background: '#fff',
            transformOrigin: 'center',
            transform: 'rotate3d(1, 0, 0, -180deg)',
            backfaceVisibility: 'hidden',
            borderRadius: '8px',
            boxShadow: '0px 11px 25px -1px rgba(0, 0, 0, 0.17)',
            padding: '10px 23px',
        },
        cardFaceContainer: {
            backgroundColor: CustomTheme.projectCardBackground,
            position: 'relative',
            padding: '1rem 2rem',
        },
        statusContainer: {
            position: 'absolute',
            top: '8px',
            right: '15px',
            paddingTop: '9px'
        },
        editContainer: {
            position: 'absolute',
            top: '8px',
            right: '60px',
            paddingTop: '9px',
            cursor: 'pointer'
        },
        deleteContainer: {
            position: 'absolute',
            top: '8px',
            right: '40px',
            paddingTop: '9px',
            cursor: 'pointer'
        },
        projectImageContainer: {
            width: '56px',
            height: '56px',
            borderRadius: '50%',
            backgroundColor: '#a9d9ff',
            display: 'inline-block',
            marginRight: '2rem',
        },
        projectImage: {
            height: '56px',
            width: '56px',
            borderRadius: '50%',
        },
        projectHeadingContainer: {
            display: 'flex',
            width: '78%'
        },
        cardProjectTitle: {
            textTransform: 'uppercase',
            color: '#000',
        },
        cardProjectDeveloper: {
            // fontWeight:'400 !important'
        },
        statusGreen: {
            color: CustomTheme.successColor,
            fontSize: '18px',
        },
        statusBlue: {
            color: CustomTheme.primaryColor,
            fontSize: '18px',
        },
        statusRed: {
            color: "red",
            fontSize: '18px',
        },
        projectDetailsContainer: {
            marginTop: '0.5rem',
        },
        detailHeading: {
            // fontWeight: '600',
            // fontSize: '15px',
        },
        detailText: {},
        cardProjectTitleAndClientNameContainer: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop: '5px'
        }
    };
});
