import {makeStyles} from '@material-ui/core';
import CustomTheme from '../../../../../../utilities/theme/theme.json'

export const useStyles = makeStyles(theme => {
    return {
        cardStyle:{
            padding:'10px',
            marginTop:'-13px',
            boxShadow:'0 3px 1px rgb(0 0 0 / 12%), 0 1px 5px rgb(0 0 0 / 20%)',
            cursor:'pointer',
            borderRadius:'6px'
        },
        todayHeaderBox:{
            backgroundColor:CustomTheme.fontColor,
            border:`1px solid ${CustomTheme.fontColor}`,
            borderRadius:'5px',
            width:'50px',
            color:'#ffffff',
            padding:'3px',
            marginBottom:'30px',
            marginTop:'20px',
            // position:'relative',
            // top:'-25px'
        },
        tomorrowHeaderBox:{
            backgroundColor:CustomTheme.fontColor,
            border:`1px solid ${CustomTheme.fontColor}`,
            borderRadius:'5px',
            width:'75px',
            color:'#ffffff',
            padding:'3px',
            position:'relative',
            top:'-25px'
        },
        thisWeekHeaderBox:{
            backgroundColor:CustomTheme.fontColor,
            border:`1px solid ${CustomTheme.fontColor}`,
            borderRadius:'5px',
            width:'75px',
            color:'#ffffff',
            padding:'3px',
            position:'relative',
            top:'-25px'
        },
        tomorrowSectionBox: {
            marginTop:'55px'
        },
        thisWeekSectionBox: {
            marginTop:'55px'
        },
    };
});
