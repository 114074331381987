import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux';
import CustomTheme from '../../../../../../../../../../../utilities/theme/theme.json';

import {Grid, TextField, Typography} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {getPredecessorRequest} from '../../../../TasksApiActions';

const DependsOnSection = React.memo(({
                                         handleChangeAutocomplete, formData, projectId, removeTaskFromSelection,
                                         getPredecessorTask
                                     }) => {
    const [predecessors, setPredecessors] = useState([]);

    /**
     * Trigger the getPredecessorTask API request and update the state value of predecessors
     */
    const getData = () => {
        // getting all predecessor task
        getPredecessorTask(projectId).then((res) => {
            setPredecessors(res)
        }).catch((err) => {
        });
    };

    /**
     * Function is triggred when component render first time
     */
    useEffect(() => {
        setTimeout(() => {
            getData();
        }, 0)
    }, []);

    /**
     * Function return the single predecessor ui from predecessor id
     */
    const renderSinglePredecessor = (id) => {

        const findTask = predecessors && predecessors.find((item) => item.id === id);
        if (findTask) {
            return (
                <Grid container justify="space-between">
                    <Grid>
                        <Typography variant="body2" style={{color: CustomTheme.fontColor}}
                                    className="form-panel-followers-text">{findTask.title}</Typography>
                    </Grid>
                    <Grid>
                        <Typography style={{color: CustomTheme.errorColor}} className="form-panel-task-delete-icon"
                                    onClick={() => removeTaskFromSelection(findTask.id)}><i
                            className="far fa-times-circle"></i></Typography>
                    </Grid>
                </Grid>
            )
        } else {
            return <></>
        }
    }

    return (
        <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="form-panel-assign-name-container">
                <Grid>
                    <Typography variant="body1" style={{color: CustomTheme.fontColor}}
                                className="form-panel-dependent-task-text"><i className="fas fa-share-alt"></i> Depends
                        on</Typography>
                </Grid>
                <Grid>
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        name="predecessors"
                        className="dropdown-margins-task-form padding-top-15"
                        options={predecessors || []}
                        getOptionLabel={(option) => option ? option.title : ''}
                        value={formData.predecessors ? formData.predecessors : {}}
                        onChange={(event, newRole) => {
                            handleChangeAutocomplete("predecessors", newRole)
                        }}
                        getOptionSelected={(option, value) =>
                            option.id === value.id
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Select predecessor"
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="form-panel-assign-name-container">
                {
                    formData && formData.predecessors.length > 0 && formData.predecessors.map((predecessorId, index) => (
                        <div key={index}>{renderSinglePredecessor(predecessorId.id)}</div>
                    ))
                }
            </Grid>
        </>
    )

});

/**
 * Used for selecting the part of the data from the redux store that the connected component needs and pass it as a props
 * It is called every time when update the state of redux store
 * @param state
 */
const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.loggedInUser,
    }
};

/**
 * Used to specify the which actions your component might need to dispatch and pass it to connected component as a props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getPredecessorTask: (projectId) => {
            return dispatch(getPredecessorRequest(projectId)).then((res) => {
                return Promise.resolve(res);
            }, (error) => {
                return Promise.reject(error);
            });
        },
    };
};

/**
 * Used to connects a react component to a redux store
 */
export default connect(mapStateToProps, mapDispatchToProps)(DependsOnSection);
