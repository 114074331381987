import React from 'react';
import {ValidatorComponent} from 'react-material-ui-form-validator';
import {Box, RadioGroup} from '@material-ui/core';

class RadioGroupValidator extends ValidatorComponent {
    renderValidatorComponent() {
        const {
            value,
            label,
            errorMessages,
            validators,
            validatorListener,
            ...rest
        } = this.props;

        return (
            <Box>
                <RadioGroup value={value} {...rest} />
                {this.errorText()}
            </Box>
        );
    }

    errorText() {
        const {isValid} = this.state;

        if (isValid) {
            return null;
        }

        return (
            <Box style={{color: 'red', fontSize: '0.75rem', marginLeft: '14px'}}>
                {this.getErrorMessage()}
            </Box>
        );
    }
}

export default RadioGroupValidator;
