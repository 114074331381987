import React from 'react';
import AppHeader from '../userDashboard/components/AppHeader/AppHeader';
import ActionStationHeader from './components/ActionStationHeader/ActionStationHeader';
import './ActionStation.css';
import {Button, Typography} from '@material-ui/core';

const ActionStation = ({handleDrawerOpenClose, history}) => {

    return (
        <>
            <AppHeader handleDrawerOpenClose={handleDrawerOpenClose}>
                <ActionStationHeader history={history}/>
            </AppHeader>
            <h1>Action Station</h1>
            <Typography variant="h1">Heading 1</Typography>
            <Typography variant="h2">Heading 2</Typography>
            <Typography variant="h3">Heading 3</Typography>
            <Typography variant="h4">Heading 4</Typography>
            <Typography variant="h5">Heading 5</Typography>
            <Typography variant="h6">Heading 6</Typography>
            <Button color="primary">Text Button</Button>
            <Button variant="contained" color="primary">Contained Button</Button>
            <Button variant="outlined" color="primary">Outlined Button</Button>
        </>
    );
};

export default ActionStation;
