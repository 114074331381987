import React from 'react';
import {Box, Button, Grid, Typography} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useStyles} from './styles';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

export default function FormDialog({open, setOpen, history}) {
    const classes = useStyles();
    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = () => {
        history.push('/project/participants');
    };

    const belongsTo = 3;
    return (
        <>
            <Dialog maxWidth='md' fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography variant='h1' classes={{root: classes.formHeading}}>
                        New Participant
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Box>
                            <Box className={classes.field}>
                                <TextField placeholder='Name of existing user*' required/>
                            </Box>
                            <Box className={classes.orText}>OR</Box>
                            <Box className={classes.field}>
                                <TextField placeholder='Invite guest with email'/>
                            </Box>
                        </Box>
                        <Box className={classes.addButton}>
                            <Button
                                disableElevation
                                variant='contained'
                                color='primary'
                                size='small'
                            >
                                Add
                            </Button>
                        </Box>
                    </Box>

                    <Box className={classes.participantsContainer}>
                        <Grid container alignItems='center'>
                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                <Box className={classes.label}>
                  <span className={classes.folderIcon}>
                    <i className='fas fa-folder'></i>
                  </span>{' '}
                                    These participants are part of the project
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <TextField label='Dew Drops' fullWidth/>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container alignItems='center'>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box className={classes.label}>Assign project roles:</Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                <Box className={classes.label}>
                                    <AccountCircleOutlinedIcon
                                        classes={{
                                            root:
                                                belongsTo === 1
                                                    ? classes.green
                                                    : belongsTo === 2
                                                    ? classes.blue
                                                    : belongsTo === 3
                                                        ? classes.orange
                                                        : '',
                                        }}
                                    />{' '}
                                    Prakash Thete
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <TextField label='Dew Drops' fullWidth/>
                            </Grid>

                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                <Box className={classes.label}>
                                    <AccountCircleOutlinedIcon
                                        classes={{
                                            root:
                                                belongsTo === 1
                                                    ? classes.green
                                                    : belongsTo === 2
                                                    ? classes.blue
                                                    : belongsTo === 3
                                                        ? classes.orange
                                                        : '',
                                        }}
                                    />{' '}
                                    p.kulkarni@gmail.com
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <TextField label='Dew Drops' fullWidth/>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions classes={{root: classes.actionsContainer}}>
                    <Button
                        disableElevation
                        onClick={handleClose}
                        variant='contained'
                        size='small'
                        classes={{root: classes.cancelButton}}
                    >
                        Cancel
                    </Button>
                    <Button
                        disableElevation
                        onClick={onSubmit}
                        variant='contained'
                        size='small'
                        color='primary'
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
